import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Form } from "semantic-ui-react";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const Setting = (props) => {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.auth._id);
  const token = useSelector((state) => state.auth.auth.token);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useHistory();
  const [loading, setLoading] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const formStates = watch();
  const { currentPassword, newPassword, confirmPassword } = formStates;

  //Password requirements popover
  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom" className="popoverSection">
      Password must contain{" "}
      <strong>
        {" "}
        upper case, lower case, special char and minimum of 6 characters
      </strong>
    </Popover>
  );

  const onSubmit = async (e) => {

    let data = new FormData(e.target);
    data.append("currentPassword", currentPassword);
    data.append("newPassword", newPassword);
    data.append("confirmPassword", confirmPassword);

    const response = await fetch(`${API_ROOT_URL}/users/setting`, {
      method: "PUT",
      body: data,
      headers: {
        "x-access-token": token,
      },
    }).then((data) => data.json());

    if ("status" in response && response.status == true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        navigate.push("/login");
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", response?.message, "error");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  useEffect(() => {
    setLoading(false);
  }, [props]);

  const pageContent = loading ? (
    <h2>Loading</h2>
  ) : (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="cardheader">
            <h2
              className="text-white"
              style={{ fontSize: "25px", fontWeight: "900px" }}
            >
              <AiOutlineAppstoreAdd color="#E7EEF0" />
              <span className="font-w700"> Change </span>
              <span
                className="fs-25 font-w700 text-center"
                style={{ color: "#FEBA30" }}
              >
                {" "}
                Password
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Field>
                  <Row>
                    <Col lg="3">
                      <label className="col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                        Current Password{" "}
                        <span className="required-field" style={{ color: "red", fontWeight: "200px" }}>
                          *
                        </span>
                      </label>
                    </Col>
                    <Col>
                      <div className="form-control d-flex justify-content-end mb-2">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          className="input-group"
                          name="currentPassword"
                          placeholder="Enter your current password"
                          value={currentPassword}
                          style={{ border: "none" }}
                          {...register("currentPassword", {
                            required: true,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span className="" onClick={togglePasswordVisibility}>
                          {passwordVisible ? (
                            <FaEye size={20} />
                          ) : (
                            <FaEyeSlash size={20} />
                          )}
                        </span>
                      </div>
                      {errors.currentPassword && (
                        <p style={{ color: "red" }}>
                          Please enter your Current Password
                        </p>
                      )}
                      {!errors.currentPassword && (
                        <p style={{ minHeight: "15px" }}>
                        </p>
                      )}
                    </Col>
                  </Row>
                </Form.Field>

                <div className="row">
                  <Form.Field>
                    <Row>
                      <Col lg="3">
                        <label className="col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                          New Password{" "}
                          <span className="required-field" style={{ color: "red", fontWeight: "200px" }}>
                            *
                          </span>
                        </label>
                      </Col>
                      <Col>
                        <div className="form-control d-flex justify-content-end mb-2">
                          <input
                            type={newPasswordVisible ? "text" : "password"}
                            className="input-group"
                            name="newPassword"
                            placeholder="Enter your new password"
                            value={newPassword}
                            style={{ border: "none" }}
                            {...register("newPassword", {
                              required: true,
                              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,10})/,
                            })}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                          />
                          <span className="" onClick={toggleNewPasswordVisibility}>
                            {newPasswordVisible ? (
                              <FaEye size={20} />
                            ) : (
                              <FaEyeSlash size={20} />
                            )}
                          </span>
                        </div>
                        {errors.newPassword && (
                          <p style={{ color: "red", minHeight: "20px" }}>
                            Please enter a valid password
                            <span>
                              <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverBottom}>
                                <div className="icon mx-2">
                                  <i className="fa fa-info" style={{ fontSize: "10px", paddingLeft: "40%", paddingTop: "15%" }} />
                                </div>
                              </OverlayTrigger>
                            </span>
                          </p>
                        )}
                        {!errors.newPassword && (
                          <p style={{ minHeight: "15px" }}>
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Form.Field>

                  <Form.Field>
                    <Row>
                      <Col lg="3">
                        <label className="col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                          Confirm Password{" "}
                          <span
                            className="required-field"
                            style={{ color: "red", fontWeight: "200px" }}
                          >
                            *
                          </span>
                        </label>
                      </Col>
                      <Col>
                        <div className="form-control d-flex justify-content-end mb-2">
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            className="input-group"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            style={{ border: "none" }}
                            {...register("confirmPassword", {
                              required: true,
                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,10})/,
                              validate: (val) => {
                                const confirmPassword = watch('newPassword');
                                if (!val) {
                                  return "Please confirm your password";
                                }
                                if (val !== confirmPassword) {
                                  return "Your passwords do not match";
                                }
                              },
                            })}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                          />
                          <span className="" onClick={toggleConfirmPasswordVisibility}>
                            {confirmPasswordVisible ? (
                              <FaEye size={20} />
                            ) : (
                              <FaEyeSlash size={20} />
                            )}
                          </span>
                        </div>
                        {errors.confirmPassword && (
                          <p style={{ color: "red" }}>
                            {confirmPassword === "" ? (
                              <span style={{ color: "red" }}>Re-enter the Password</span>
                            ) : confirmPassword !== newPassword ? (
                              "Password Not Matched"
                            ) : (
                              ""
                            )}
                          </p>
                        )}
                        {!errors.confirmPassword && (
                          <p style={{ minHeight: "15px" }}>
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Form.Field>

                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <input
                        type="submit"
                        value="UPDATE"
                        className="submitButtonInduction"
                        name="submit"
                        style={{ display: "flex", float: "right" }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return <>{pageContent}</>;
};
export default Setting;
