import React, { useState, useEffect } from "react";
import { API_ROOT_URL } from "../../constants";
import { useSelector } from "react-redux";

const TopicDropdown = ({ deptID, createOnly }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState();
  const token = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        let getTopics;
        getTopics = `${API_ROOT_URL}/department/fetching/topics?listOnly=${createOnly}&filterBydepartment=${deptID}`;
        const response = await fetch(getTopics, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data?.status === true) {
            const topicOptions = data.data.map((row) => (
              <option key={row._id} value={row._id}>
                {row.topic.charAt(0).toUpperCase() +
                  row.topic.slice(1).toLowerCase()}
              </option>
            ));
            setOptions(topicOptions);
            setLoading(false);
          } else {
            setLoading(false);
            setOptions("")
          }
        } else {
          setLoading(false);
          setOptions("")
        }
      } catch (error) {
        setLoading(false);
        setOptions("")
      }
    };
    setLoading(true);
    fetchTopics();
  }, [deptID]);

  if (loading) {
    return <option>Loading...</option>;
  }

  return <>{options}</>;
};

export default TopicDropdown;
