import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { Last } from "react-bootstrap/esm/PageItem";
import { useHistory } from "react-router-dom";
import { API_ROOT_URL, AWS_BUCKET_NAME } from "../../constants";
import { PROFILE_ASSETS_URL } from "../../constants";
import { BsXCircleFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { UpdateProfileRedux } from "../../../store/actions/ProfileActions";
import s3 from "../../../utilis/aws.config";
// const images = require.context("../../../../../images/profile", true);



const UpdateCompanyProfile = ({ isModalOpen, trackOnclick, companyData }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  //const userID = useSelector((state) => state.auth.auth.id);
  //const [isModalOpen, setIsModalOpen] = useState(isModalOpen);

  const [name, setName] = useState(companyData.profile.name);
  const [email, setEmail] = useState(companyData.profile.email);
  const [aboutCompany, setAboutCompany] = useState(
    companyData.profile.aboutCompany
  );
  const [address, setAddress] = useState(companyData.profile.address);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [logo, setLogo] = useState(companyData.profile.logo);
  const [preview, setPreview] = useState(companyData.profile.logo);
  const [userID, setUserID] = useState(companyData.profile._id);
  const [nameError, setNameError] = useState('');
  const [compAddError, setCompAddError] = useState('');
  //deselect
  const [showClearButton, setShowClearButton] = useState("");

  // Create a state variable to track if any field is changed
  const [isFormChanged, setFormChanged] = useState(false);
  const handleCallback = () => {
    trackOnclick(false);
  };


  // logo image uploader
  const handleFileChange = async (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
    setFormChanged(true); // Set formChanged to true when any field is changed
  };

  const handleNameChange = (enteredName) => {
    setName(enteredName);
    setFormChanged(true);

    if (enteredName.trim() === '') {
      setNameError("Name field can't be blank");
      setFormChanged(false); // Set form changed to false if the name is empty
    } else {
      setNameError('');
      setFormChanged(true);
    }
  };

  const handleAddressChange = (enteredAddress) => {
    setAddress(enteredAddress);
    setFormChanged(true);
    if (enteredAddress.trim() === '') {
      setCompAddError("Address can't be blank");
      setFormChanged(false); // Set form changed to false if the name is empty
    } else {
      setCompAddError('');
      setFormChanged(true);
    }
  };


  const handleClearButtonClick = () => {
    setImage(false);
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate data
    if (nameError || compAddError) {
      // Prevent form submission if there's an error
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("logo", image.data);
    data.append("address", address);
    data.append("aboutCompany", aboutCompany);
    data.append("logo_previous", logo);

    // const userID = "63cea890edb762dfb4abb220";

    const response = await fetch(`${API_ROOT_URL}/company/edit/${userID}`, {
      method: "PUT",
      headers: {
        "x-access-token": token,
      },
      body: data,
    }).then((data) => data.json());

    if ("status" in response && response.status == true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        handleCallback();
        //profile
        // navigate.push("/company-profile");
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response.devMessage === "userDeleted") {
        return swal("Session Timeout ! ", "Please Login Again", "error");
      }
      else {
        return swal("Failed", response?.message, "error");
      }
    }
  };

  const handleInputChange = () => {
    // Set formChanged to true when any field is changed
    setFormChanged(true);
  };

  // re-render page on every update
  useEffect(() => {
  }, [companyData]);

  useEffect(() => {
    // Set initial values and reset formChanged
    setName(companyData.profile.name);
    setEmail(companyData.profile.email);
    setAboutCompany(companyData.profile.aboutCompany);
    setAddress(companyData.profile.address);
    setImage({ preview: "", data: "" });
    setLogo(companyData.profile.logo);
    setPreview(companyData.profile.logo);
    dispatch(UpdateProfileRedux(companyData.profile.logo));
    //setProfileSource(companyData?.profile?.logo);
    setUserID(companyData.profile._id);
    setFormChanged(false); // Reset the formChanged state when the data is loaded
  }, [companyData]);

  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${preview}`,
  });

  return (
    <Modal
      className="modal fade d-flex"
      show={isModalOpen}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content" style={{ width: "60vw" }}>
        <div className="modal-header">
          <h5 className="modal-title">UPDATE PROFILE</h5>
          <RxCross1 size={25} color="white" onClick={handleCallback} />
        </div>
        <div className="modal-body">
          <form className="update-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    readOnly
                    style={{ backgroundColor: "lightgray" }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">Name
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    name="name"
                    placeholder=""
                    value={name}
                    // onChange={(e) => {
                    //   setName(e.target.value);
                    //   handleInputChange(); // Set formChanged when input changes
                    // }}
                    onChange={(e) => handleNameChange(e.target.value)}
                  />
                  {nameError && <p style={{ color: 'red' }}>{nameError}</p>}

                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">Logo</label>
                  <input type="hidden" value={preview} name="logo" />
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={handleFileChange}
                    accept="image/png,image/jpeg,image/jpg"
                  />
                </div>
              </div>
              {image.data ?
                <>
                  <div className="col-lg-12">
                    <div className="instructors-media">
                      <div>
                        <img src={image.preview} width={150} />
                        {showClearButton && (
                          <BsXCircleFill
                            size={25}
                            style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                            onClick={handleClearButtonClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <div className="instructors-media">
                        <img
                          src={url}
                          className="img-thumbnail mb-3"
                          width={150}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">About</label>
                  <textarea
                    rows={2}
                    className="form-control"
                    name="aboutCompany"
                    placeholder=""
                    value={aboutCompany}
                    onChange={(e) => {
                      setAboutCompany(e.target.value);
                      handleInputChange(); // Set formChanged when input changes
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">Address
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <textarea
                    rows={2}
                    className="form-control"
                    name="address"
                    placeholder=""
                    value={address}
                    onChange={(e) => handleAddressChange(e.target.value)}
                  />
                  {compAddError && <p style={{ color: 'red' }}>{compAddError}</p>}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <input
                    type="submit"
                    value="Update Profile"
                    className={`submits ${isFormChanged ? 'enabled' : ''}`}
                    style={{ margin: "auto", display: "flex" }}
                    name="submit"
                    disabled={!isFormChanged}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateCompanyProfile;
