import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import LogoutPage from "./Logout";
import { useSelector } from "react-redux";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { BsArrowRight } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
// import { PiPresentationChartThin } from "react-icons/pi";
// import { PiPresentationLight } from "react-icons/pi";
// import { PiPresentationChartLight } from "react-icons/pi";
import { CiYoutube } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { FcApproval } from "react-icons/fc";
import { FcHighPriority } from "react-icons/fc";
import jwtDecode from "jwt-decode";
import Lottie from "lottie-react"; //animation imports
import noNotifications from "../../../../src/jsx/components/Animations/animation_llgn6j71.json";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { PiLinkLight } from "react-icons/pi";
import s3 from "../../../utilis/aws.config";
import { useProfilePicture } from "../../../store/context/profilePictureProvider";

// api call for rightside bar profile picture
async function getProfileApi(token) {
  const URL = `${API_ROOT_URL}/users/getProfile`;
  return fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  }).then((data) => data.json());
}

const Header = (props) => {
  const dispatch = useDispatch();
  const { profilePicture, setProfilePicture } = useProfilePicture();
  const [loading, setloading] = useState(true);
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const userID = useSelector((state) => state.auth.auth.id);
  const [img, setImg] = useState("dummy-user.png");
  const [profileLink, setProfileLink] = useState();
  const [updateEvent, SetupdateEvent] = useState(false);
  const [callNotification, setCallNotification] = useState("");
  const [hasEmptyData, setHasEmptyData] = useState(false);
  const decoded = jwtDecode(token);
  const tokenDetailsString = localStorage.getItem("userDetails");
  const [requiredData, setRequiredData] = useState([]);
  //for pagination notifications
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [error, setError] = useState(null);
  // Assuming your token structure has userID
  //const currentUserID = decoded.userID;
  const [newData, setNewData] = useState([]);
  const [isCompDeleted, setIsCompDeleted] = useState(false);
  // const [notifications, setNotifications] = useState(newData);

  //api call
  async function getNotifications(page, token) {
    // /console.log("calling")
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      });
    } else {
      return fetch(`${API_ROOT_URL}/dashboard/get-notifications?page=${page}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }).then((data) => data.json());
    }
  }

  const handlePageLoadEvent = async (event) => {
    const response = await getNotifications(currentPage, token);
    if ("status" in response && response.status === true) {
      setNewData(response.data);
      setHasEmptyData(false);
      setloading(false);
      setCallNotification("");
    } else {
      if (
        response?.error?.message === "jwt expired" ||
        response?.message === "Authentication Required"
      ) {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      } else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", response, "error");
      }
    }
  };

  //console.log(newData, "new data")

  // const requiredData = newData.map((i) => {
  //   const newArr = i.allUserID.filter((x) => x.userID === userID);
  //   const isRead =
  //     newArr.length > 0 && newArr[0].hasOwnProperty("isRead")
  //       ? newArr[0].isRead
  //       : false;
  //   const { allUserID, ...item } = i;
  //   const item1 = { ...item, ...{ isRead: newArr[0]?.isRead } };

  //   return item1;
  // });
  // const requiredData = newData.map((i) => {
  //   const newArr = i.allUserID.filter((x) => x.userID === userID);
  //   const isRead =
  //     newArr.length > 0 && newArr[0].hasOwnProperty("isRead")
  //       ? newArr[0].isRead
  //       : false;
  //   const { allUserID, ...item } = i;
  //   const item1 = { ...item, ...{ isRead: newArr[0]?.isRead } };

  //   return item1;
  // });

  //post api for viewed true
  const onSubmit = async (e) => {
    if (allRead) {
      e.preventDefault();
      return;
    }
    try {
      const response = await fetch(
        `${API_ROOT_URL}/dashboard/set-notifications/viewed`,
        {
          method: "POST",
          headers: {
            "x-access-token": token,
          },
        }
      );

      const responseData = await response.json();

      if ("status" in responseData && responseData.status === true) {
        // Call getNotifications to refresh notifications after marking all as read
        const refreshResponse = await getNotifications(1, token);

        if ("status" in refreshResponse && refreshResponse.status === true) {
          // Handle success of refresh, if needed
          setNewData(refreshResponse.data);
          setCurrentPage(1);
          setHasMoreData(refreshResponse.hasNextPage);
          setTotalRecords(refreshResponse.totalRecords);
        } else {
          // Handle failure of refresh, if needed
        }
      } else {
        setHasEmptyData(true);
        // setCallNotification("")
        if (
          responseData?.error?.message === "jwt expired" ||
          responseData?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (responseData?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          swal("Failed", "Error message", "error");
        }
      }
    } catch (error) {
      console.error("Error submitting notification:", error);
      // Handle error if needed
    }
  };

  const allRead = requiredData.every((data) => data.isRead);

  //post api for accepting induction access
  const HandleAcceptRequest = async (inductionId, inductionName) => {
    try {
      const response = await fetch(
        `${API_ROOT_URL}/dashboard/give/access/for/private/induction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify({
            _id: inductionId,
          }),
        }
      ).then((data) => data.json());
      if ("status" in response && response.status === true) {
        // First API call successful
        Swal.fire({
          title: `${inductionName}`,
          text: `Request Accepted`,
          icon: "success",
        }).then(() => {
          setIsCompDeleted(!isCompDeleted);
        });
        setCurrentPage((prevPage) => prevPage + 1);
        await getNotifications(currentPage, token);
        await handlePageLoadEvent();
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3A3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error in HandleAcceptRequest:", error);
    }
  };

  //post api for rejecting induction access
  const HandleRejectRequest = async (inductionId, inductionName) => {
    try {
      const response = await fetch(
        `${API_ROOT_URL}/dashboard/request/rejected/for/private/induction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify({
            _id: inductionId,
          }),
        }
      ).then((data) => data.json());

      if ("status" in response && response.status == true) {
        Swal.fire({
          title: `${inductionName}`,
          text: `Request Rejected`,
          icon: "success",
        }).then(() => {
          setIsCompDeleted(!isCompDeleted);
        });
        setCurrentPage((prevPage) => prevPage + 1);
        await getNotifications(currentPage, token);
        await handlePageLoadEvent();
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error in HandleAcceptRequest:", error);
    }
  };

  useEffect(() => {
    if (updateEvent) {
      SetupdateEvent(false);
    }
  }, [updateEvent]);

  //profile view in right side for every role
  const getProfile = async () => {
    const response = await getProfileApi(token);
    if ("status" in response && response.status === true) {
      if (role === "company") {
        setImg(response?.data?.profile?.logo);
        setProfilePicture(response?.data?.profile?.logo)
        setProfileLink("company-profile");
      } else if (role === "instructor") {
        setImg(response?.data[0]?.profile?.profilePhoto);
        setProfilePicture(response?.data[0]?.profile?.profilePhoto)
        setProfileLink("instructor-profile");
      } else if (role === "department") {
        setImg(response?.data?.profile?.profile_picture);
        setProfilePicture(response?.data?.profile?.profile_picture)
        setProfileLink("department-profile");
      } else {
        setImg(response?.data?.profile?.profilePhoto);
        setProfilePicture(response?.data?.profile?.profilePhoto)
        setProfileLink("profile");
      }
    }
    setloading(false);
  };

  const [websocket, setWebsocket] = useState(null);
  useEffect(() => {
    let ws = new WebSocket("ws://localhost:8081");
    if (process.env.REACT_APP_STAGING === "true") {
      ws = new WebSocket("wss://staging.bjsinduction.com/ws");
    } else if (process.env.REACT_APP_LIVE === "true") {
      ws = new WebSocket("wss://bjsinduction.com/ws");
    }
    setWebsocket(ws);
    ws.addEventListener("open", () => {
      console.log("WebSocket connection established.");
    });

    ws.addEventListener("message", (event) => {
      setCallNotification(event.data);
    });

    ws.addEventListener("close", () => {
      console.log("WebSocket connection closed.");
    });

    return () => {
      console.log("Closing WebSocket connection.");
      ws.close();
    };
  }, []);

  //For fix header
  const [headerFix, setheaderFix] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setCurrentPage((prevPage) => prevPage + 1);

      if (callNotification === "callNotification") {
        try {
          await handlePageLoadEvent();
          const response = await getNotifications(currentPage, token);

          if ("status" in response && response.status === true) {
            setNewData(response.data);
            setloading(false);
          } else {
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    // Run fetchData initially
    fetchData();

    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });

    getProfile();

    return () => {
      window.removeEventListener("scroll", () => {
        setheaderFix(window.scrollY > 50);
      });
    };
  }, [callNotification, token]);

  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${profilePicture}`,
  });


  useEffect(() => {
    const fetchData = async () => {
      setloading(true);

      const response = await getNotifications(1, token);

      if ("status" in response && response.status === true) {
        setNewData(response.data);
        setCurrentPage(1);
        setHasMoreData(response.hasNextPage);
        setTotalRecords(response.totalRecords);
        setloading(false);
        setCallNotification("");
      } else {
        setTotalRecords(0);
        setCallNotification("");
        setloading(false);
      }
    };

    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      });
    } else {
    }
    fetchData();
  }, [callNotification]);

  const loadMoreData = async () => {
    // If there is no more data or hasMoreData is false, return early
    if (!hasMoreData) return;

    setloading(true);
    const nextPage = currentPage + 1;

    try {
      const response = await getNotifications(nextPage, token);

      if ("status" in response && response.status === true) {
        if (response.data.length > 0) {
          setNewData((prevData) => [...prevData, ...response.data]);
          setCurrentPage(nextPage);
          setHasMoreData(response.hasNextPage);
          setTotalRecords(response.totalRecords);
        } else {
          // No more data, set hasMoreData to false
          setHasMoreData(false);
        }
      }
    } catch (err) {
      setError(err);
    }

    setloading(false);
  };

  useEffect(() => {
    const ModifyrequiredData = newData.map((i) => {
      const newArr = i.allUserID.filter((x) => x.userID === userID);
      const isRead =
        newArr.length > 0 && newArr[0].hasOwnProperty("isRead")
          ? newArr[0].isRead
          : false;
      const { allUserID, ...item } = i;
      const item1 = { ...item, ...{ isRead: newArr[0]?.isRead } };

      return item1;
    });
    setRequiredData(ModifyrequiredData);
  }, [newData]);

  useEffect(() => {
    if (isCompDeleted) {
      const fetchData = async () => {
        try {
          const updatedData = await getNotifications(1, token);
          setNewData(updatedData.data);
          setHasMoreData(updatedData.hasNextPage);
          setTotalRecords(updatedData.totalRecords);
          setloading(false);
        } catch (err) {
          setError(err);
        }
      };
      fetchData();
      loadMoreData();
    }
  }, [isCompDeleted]);

  var path = window.location.pathname.split("/");
  let inductionName = path[path.length - 2];

  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;

  if (!path.includes("update-induction")) {
    sessionStorage.removeItem("fakePayload");
  }
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
        ? filterName.filter((f) => f !== "uc")
        : filterName.includes("basic")
          ? filterName.filter((f) => f !== "basic")
          : filterName.includes("jquery")
            ? filterName.filter((f) => f !== "jquery")
            : filterName.includes("table")
              ? filterName.filter((f) => f !== "table")
              : filterName.includes("page")
                ? filterName.filter((f) => f !== "page")
                : filterName.includes("email")
                  ? filterName.filter((f) => f !== "email")
                  : filterName.includes("ecom")
                    ? filterName.filter((f) => f !== "ecom")
                    : filterName.includes("chart")
                      ? filterName.filter((f) => f !== "chart")
                      : filterName.includes("editor")
                        ? filterName.filter((f) => f !== "editor")
                        : filterName;

  function formatTimeAgo(timestamp) {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differnceTime = now - createdAt;
    const seconds = Math.floor(differnceTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const normalTime = new Date(createdAt).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    if (days > 0) {
      return normalTime;
    } else if (hours === 1) {
      return `${hours} hour ago`;
    } else if (hours > 1) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} mins ago`;
    } else {
      return "Just Now";
    }
  }
  const newArray = [];
  const Data = newData.map((i) => newArray.push(i.allUserID));

  function filterByUserIDAndViewed(newArray, userID, viewedValue) {
    const filteredArrays = [];

    // Iterate through the array of new array which is natching user ids
    for (const arr of newArray) {
      const filteredArray = arr.filter(
        (obj) => obj.userID === userID && obj.isRead === viewedValue
      );
      filteredArrays.push(filteredArray);
    }

    return filteredArrays;
  }

  //matching id with logged in user
  const userIDToFind = userID;
  const viewedValueToFind = false;

  const matchingArrays = filterByUserIDAndViewed(
    newArray,
    userIDToFind,
    viewedValueToFind
  );

  // //for viewed notification false values
  const falseValuesArray = matchingArrays
    .flat()
    .filter((obj) => obj.isRead === false);
  //for viewed notification counts

  //i wrote newarray from falseVlauearray
  const ViewedCounts = falseValuesArray.map((i) => i.isRead === false);

  const queryStyle = {
    height: 100,
  };

  //used to display notification panel
  const NotificationBlog = () => {
    return (
      <div className="row dataTables_wrapper no-footer" id="student_wrapper">
        {requiredData.length === 0 ? (
          <div className="h-100">
            <div className="container h-100">
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-md-12">
                  <div
                    className="form-input-content text-center"
                    style={{ marginTop: "150%" }}
                  >
                    <Lottie
                      style={queryStyle}
                      animationData={noNotifications}
                    />
                    <h6
                      style={{
                        color: "#3a3630",
                        letterSpacing: "1px",
                        fontStyle: "italic",
                      }}
                    >
                      No new notifications!
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <InfiniteScroll
              dataLength={requiredData.length}
              next={loadMoreData}
              hasMore={hasMoreData}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {requiredData.map((data, index) => (
                <>
                  <li key={index}>
                    <div>
                      {data?.updateInductionNotification === true ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="mediaBackground">
                                <CiYoutube size={30} />
                              </div>
                              <div className="media-body mt-2">
                                <div>
                                  <div className="">
                                    <strong
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        color: "#007bff",
                                      }}
                                    >
                                      {data.inductionName}{" "}
                                    </strong>
                                    <span className="fs-13">
                                      Induction update is live !
                                    </span>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="mediaBackground">
                                <CiYoutube size={30} />
                              </div>
                              <div className="media-body mt-2">
                                <div>
                                  <div className="">
                                    <strong
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        color: "#007bff",
                                      }}
                                    >
                                      {data.inductionName}{" "}
                                    </strong>
                                    <span className="fs-13">
                                      Induction update is live !
                                    </span>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : data?.updateEventNotification === true ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="mediaBackground">
                                <CiCalendar size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <>
                                    Latest Updates on{" "}
                                    <span
                                      style={{
                                        color: "#007bff",
                                        fontSize: "13px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data.inductionName}
                                    </span>{" "}
                                    Induction & Event{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontWeight: "500",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {data.eventName}
                                    </strong>{" "}
                                    !
                                  </>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="mediaBackground">
                                <CiCalendar size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <>
                                    Latest Updates on{" "}
                                    <span
                                      style={{
                                        color: "#007bff",
                                        fontSize: "13px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data.inductionName}
                                    </span>{" "}
                                    Induction & Event{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontWeight: "500",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {data.eventName}
                                    </strong>{" "}
                                    !
                                  </>
                                </div>
                                <span
                                  className="mx-2 mt-1"
                                  style={{ fontSize: "12px", float: "right" }}
                                >
                                  {formatTimeAgo(data.createdAt)}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ) : data?.requestAccessTest_module === true &&
                        data?.requestRejected === false &&
                        data?.requestAccepted === false ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="mediaBackground">
                                <PiLinkLight size={30} />
                              </div>
                              <div className="media-body">
                                <div className="fs-14 fw-700 mt-2">
                                  <>
                                    <Link
                                      to="/test-access-control"
                                      style={{
                                        color: "#5181cc",
                                        fontSize: "13px",
                                        fontWeight: "900px",
                                        textDecoration: "underline",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Permission Request for Test Module
                                    </Link>
                                  </>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : data?.requestAccepted === true ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="approvalStyle">
                                <FcApproval size={35} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Accepted for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Test Module
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="approvalStyle">
                                <FcApproval size={35} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Accepted for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Test Module
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : data?.requestRejected === true ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="approvalStyle">
                                <FcHighPriority size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Rejected for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Test Module
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="approvalStyle">
                                <FcHighPriority size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Rejected for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Test Module
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : data?.eventCreation === true ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="mediaBackground">
                                <CiCalendar size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div>
                                    Introducing Event{" "}
                                    <strong
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        color: "#007bff",
                                      }}
                                    >
                                      {data.eventName}
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="mediaBackground">
                                <CiCalendar size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div>
                                    Introducing Event{" "}
                                    <strong
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        color: "#007bff",
                                      }}
                                    >
                                      {data.eventName}
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : data?.requestAccessNotification === true &&
                        data?.requestRejected === false &&
                        data?.requestAccepted === false ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="mediaBackground">
                                <CiLock size={30} className="mb-1" />{" "}
                              </div>
                              <div className="media-body mt-2">
                                <div className="mt-2">
                                  <span className="fs-13">
                                    Access Request by{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "#007bff",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {data.accessRequestedByDeptIDName}
                                  </span>{" "}
                                  <span className="fs-12">for </span>
                                  <span
                                    style={{
                                      color: "#007bff",
                                      fontWeight: "500",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {data.inductionName}
                                  </span>
                                  <div
                                    className="mt-1 mx-1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <button
                                      className="AcceptIcon"
                                      onClick={(e) =>
                                        HandleAcceptRequest(
                                          data._id,
                                          data.inductionName
                                        )
                                      }
                                    >
                                      {/* <IoCheckmark size={23} /> */}
                                      Accept
                                    </button>
                                    <button
                                      className="declineIcon"
                                      onClick={(e) =>
                                        HandleRejectRequest(
                                          data._id,
                                          data.inductionName
                                        )
                                      }
                                    >
                                      {/* <RxCross1 size={20} /> */}
                                      Decline
                                    </button>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="mediaBackground">
                                <CiLock size={30} className="mb-1" />{" "}
                              </div>
                              <div className="media-body mt-2">
                                <div className="mt-2">
                                  <span className="fs-13">
                                    Access Request by{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "#007bff",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {data.accessRequestedByDeptIDName}
                                  </span>{" "}
                                  <span className="fs-12">for </span>
                                  <span
                                    style={{
                                      color: "#007bff",
                                      fontWeight: "500",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {data.inductionName}
                                  </span>
                                  <div
                                    className="mt-1 mx-1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <button
                                      className="AcceptIcon"
                                      onClick={(e) =>
                                        HandleAcceptRequest(
                                          data._id,
                                          data.inductionName
                                        )
                                      }
                                    >
                                      {/* <IoCheckmark size={23} /> */}
                                      Accept
                                    </button>
                                    <button
                                      className="declineIcon"
                                      onClick={(e) =>
                                        HandleRejectRequest(
                                          data._id,
                                          data.inductionName
                                        )
                                      }
                                    >
                                      {/* <RxCross1 size={20} /> */}
                                      Decline
                                    </button>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : data?.requestAccepted === true ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="approvalStyle">
                                <FcApproval size={35} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Accepted for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data.inductionName}
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="approvalStyle">
                                <FcApproval size={35} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Accepted for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data.inductionName}
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : data?.requestRejected === true ? (
                        <>
                          {data?.isRead === false ? (
                            <div className="timeline-panel backgroundNotifyRead">
                              <div className="approvalStyle">
                                <FcHighPriority size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Rejected for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data.inductionName}
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="timeline-panel">
                              <div className="approvalStyle">
                                <FcHighPriority size={30} />
                              </div>
                              <div className="media-body">
                                <div>
                                  <div className="mt-3">
                                    Request Rejected for{" "}
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data.inductionName}
                                    </strong>
                                  </div>
                                  <span
                                    className="mx-2 mt-1"
                                    style={{ fontSize: "12px", float: "right" }}
                                  >
                                    {formatTimeAgo(data.createdAt)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </li>
                </>
              ))}
            </InfiniteScroll>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {inductionName === "single-induction-view"
                  ? "Induction View"
                  : inductionName === "add-slide"
                    ? "Add Slides"
                    : inductionName === "update-slide"
                      ? "Update Slides"
                      : inductionName === "update-induction"
                        ? "Update Induction"
                        : inductionName === "viewmcq"
                          ? "View MCQ"
                          : inductionName === "mcq"
                            ? "Create QUIZ"
                            : inductionName === "attemptquiz"
                              ? "Attempt Quiz"
                              : finalName.join(" ").length === 0
                                ? "Dashboard"
                                : finalName.join(" ") === "dashboard dark"
                                  ? "Dashboard"
                                  : finalName.join(" ")}
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="dlab-side-menu">
                <div className="sidebar-social-link ">
                  <ul className="">
                    <Dropdown
                      as="li"
                      className="nav-item dropdown notification_dropdown"
                    >
                      <Dropdown.Toggle
                        className="nav-link i-false c-pointer"
                        variant=""
                        as="a"
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 24 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.7071 8.56414C18.7071 9.74035 19.039 10.4336 19.7695 11.2325C20.3231 11.8211 20.5 12.5766 20.5 13.3963C20.5 14.215 20.2128 14.9923 19.6373 15.6233C18.884 16.3798 17.8215 16.8627 16.7372 16.9466C15.1659 17.0721 13.5937 17.1777 12.0005 17.1777C10.4063 17.1777 8.83505 17.1145 7.26375 16.9466C6.17846 16.8627 5.11602 16.3798 4.36367 15.6233C3.78822 14.9923 3.5 14.215 3.5 13.3963C3.5 12.5766 3.6779 11.8211 4.23049 11.2325C4.98384 10.4336 5.29392 9.74035 5.29392 8.56414V8.16515C5.29392 6.58996 5.71333 5.55995 6.577 4.55164C7.86106 3.08114 9.91935 2.19922 11.9558 2.19922H12.0452C14.1254 2.19922 16.2502 3.12359 17.5125 4.65728C18.3314 5.64484 18.7071 6.63146 18.7071 8.16515V8.56414ZM9.07367 19.1136C9.07367 18.642 9.53582 18.426 9.96318 18.3336C10.4631 18.2345 13.5093 18.2345 14.0092 18.3336C14.4366 18.426 14.8987 18.642 14.8987 19.1136C14.8738 19.5626 14.5926 19.9606 14.204 20.2134C13.7001 20.5813 13.1088 20.8143 12.4906 20.8982C12.1487 20.9397 11.8128 20.9407 11.4828 20.8982C10.8636 20.8143 10.2723 20.5813 9.76938 20.2125C9.37978 19.9606 9.09852 19.5626 9.07367 19.1136Z"
                            fill="#130F26"
                          />
                        </svg>
                        {ViewedCounts.length !== 0 ? (
                          <span className="notification-dot">
                            <span
                              className="text-white"
                              style={{ fontSize: "12px" }}
                            >
                              {ViewedCounts.length}
                            </span>
                          </span>
                        ) : null}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        align="right"
                        className="mt-2 dropdown-menu dropdown-menu-end"
                      >
                        <PerfectScrollbar
                          className="widget-media dlab-scroll p-3 height380"
                          id="scrollableDiv"
                        >
                          <ul className="timeline">
                            <NotificationBlog />
                          </ul>
                          <div
                            className="ps__rail-x"
                            style={{ left: 0, bottom: 0 }}
                          >
                            <div
                              className="ps__thumb-x"
                              tabIndex={0}
                              style={{ left: 0, width: 0 }}
                            />
                          </div>
                          <div
                            className="ps__rail-y"
                            style={{ top: 0, right: 0 }}
                          >
                            <div
                              className="ps__thumb-y"
                              tabIndex={0}
                              style={{ top: 0, height: 0 }}
                            />
                          </div>
                        </PerfectScrollbar>
                        <div className="all-notification" onClick={onSubmit}>
                          <span className="read">
                            {!allRead && (
                              <>
                                <span> Mark as read </span>
                                <Dropdown.Toggle
                                  style={{
                                    position: "absolute",
                                    bottom: 3,
                                    left: 125,
                                  }}
                                  className="markAsRead nav-link ai-icon i-false mt-0 border-0"
                                  onClick={onSubmit}
                                  size={20}
                                >
                                  <BsArrowRight
                                    className="arrow"
                                    style={{
                                      position: "relative",
                                      bottom: 15,
                                      top: -11,
                                      right: 10,
                                      left: 45,
                                    }}
                                  //onClick={onSubmit}
                                  />
                                </Dropdown.Toggle>
                              </>
                            )}
                          </span>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown
                      as="li"
                      className="nav-item dropdown notification_dropdown"
                    >
                      <Link
                        to={"/setting"}
                        className="nav-link i-false c-pointer"
                        variant=""
                        as="a"
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.4023 13.4798C20.7599 13.6577 21.0359 13.9387 21.23 14.2197C21.6082 14.8003 21.5775 15.5121 21.2096 16.1395L20.4942 17.2634C20.1161 17.8627 19.411 18.2373 18.6854 18.2373C18.3277 18.2373 17.9291 18.1437 17.6021 17.9564C17.3364 17.7972 17.0298 17.741 16.7028 17.741C15.691 17.741 14.8428 18.5183 14.8121 19.4455C14.8121 20.5225 13.8719 21.3653 12.6967 21.3653H11.3068C10.1214 21.3653 9.18116 20.5225 9.18116 19.4455C9.16072 18.5183 8.3125 17.741 7.30076 17.741C6.96351 17.741 6.65693 17.7972 6.40144 17.9564C6.07441 18.1437 5.66563 18.2373 5.31816 18.2373C4.58235 18.2373 3.8772 17.8627 3.49908 17.2634L2.79393 16.1395C2.4158 15.5308 2.39536 14.8003 2.77349 14.2197C2.937 13.9387 3.24359 13.6577 3.59106 13.4798C3.8772 13.3487 4.06116 13.1333 4.23489 12.8804C4.74587 12.075 4.43928 11.0167 3.57062 10.5391C2.55888 10.0053 2.23185 8.81591 2.81437 7.88875L3.49908 6.78366C4.09181 5.8565 5.35904 5.52871 6.381 6.0719C7.2701 6.52143 8.42491 6.22174 8.94611 5.4257C9.10962 5.16347 9.2016 4.88251 9.18116 4.60156C9.16072 4.23631 9.27314 3.8898 9.46731 3.60884C9.84543 3.0282 10.5301 2.65359 11.2762 2.63486H12.7171C13.4734 2.63486 14.1581 3.0282 14.5362 3.60884C14.7202 3.8898 14.8428 4.23631 14.8121 4.60156C14.7917 4.88251 14.8837 5.16347 15.0472 5.4257C15.5684 6.22174 16.7232 6.52143 17.6225 6.0719C18.6343 5.52871 19.9117 5.8565 20.4942 6.78366L21.1789 7.88875C21.7717 8.81591 21.4447 10.0053 20.4227 10.5391C19.554 11.0167 19.2474 12.075 19.7686 12.8804C19.9322 13.1333 20.1161 13.3487 20.4023 13.4798ZM9.10962 12.0095C9.10962 13.4798 10.4075 14.6505 12.012 14.6505C13.6165 14.6505 14.8837 13.4798 14.8837 12.0095C14.8837 10.5391 13.6165 9.3591 12.012 9.3591C10.4075 9.3591 9.10962 10.5391 9.10962 12.0095Z"
                            fill="#130F26"
                          />
                        </svg>
                      </Link>
                    </Dropdown>
                  </ul>
                </div>
                <ul>
                  <Dropdown
                    as="li"
                    className="nav-item dropdown header-profile"
                  >
                    <Dropdown.Toggle
                      variant=""
                      as="a"
                      className="nav-link i-false c-pointer"
                    >
                      <img
                        src={url}
                        alt={img}
                        width={20}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="right"
                      className="dropdown-menu dropdown-menu-end"
                    >
                      {role === "company" ? (
                        <Link
                          to={`/company-profile`}
                          className="dropdown-item ai-icon"
                        >
                          <svg
                            id="icon-user1"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-primary me-1"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>
                          <span className="ms-2">Profile</span>
                        </Link>
                      ) : role === "instructor" ? (
                        <Link
                          to={`/instructor-profile`}
                          className="dropdown-item ai-icon"
                        >
                          <svg
                            id="icon-user1"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-primary me-1"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>
                          <span className="ms-2">Profile </span>
                        </Link>
                      ) : role === "department" ? (
                        <Link
                          to={`/department-profile`}
                          className="dropdown-item ai-icon"
                        >
                          <svg
                            id="icon-user1"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-primary me-1"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>
                          <span className="ms-2">Profile </span>
                        </Link>
                      ) : (
                        <Link to={`/profile`} className="dropdown-item ai-icon">
                          <svg
                            id="icon-user1"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-primary me-1"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>
                          <span className="ms-2">Profile</span>
                        </Link>
                      )}
                      <LogoutPage />
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
