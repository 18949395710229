import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
//import DropDownBlog from "../Dashboard/DropDownBlog";
import ActionDropDown from "../Students/ActionDropDown";
import certificate from "./../../../images/svg/degree-certificate.svg";
import clock from "./../../../images/svg/clock-1.svg";
import { useDispatch, useSelector } from "react-redux";
import UpdateProfile from "./UpdateProfile";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import LoadingSpinner from "../../pages/LoadingSpinner";
import { logout } from "../../../store/actions/AuthActions";
import s3 from "../../../utilis/aws.config";
import { useProfilePicture } from "../../../store/context/profilePictureProvider";

const WidgetBlog = ({ changeImage, title, link, Count }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex">
                <img src={changeImage} alt="" />
                <div className="ms-4">
                  <h4>{Count}</h4>
                  <span>{title}</span>
                </div>
              </div>
              <Link to={link}>
                <i className="las la-angle-right text-primary"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// api call
async function getProfileApi(token) {
  const URL = `${API_ROOT_URL}/users/getProfile`;
  return fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  }).then((data) => data.json());
}

const InstructorProfile = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const { profilePicture, setProfilePicture } = useProfilePicture();
  const [loading, setLoading] = useState(true);
  const [instructorData, setInstructorData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getProfile = async () => {
    const response = await getProfileApi(token);
    if ("status" in response && response.status === true) {
      setInstructorData(response.data[0]);
      setProfilePicture(response?.data[0]?.profile?.profilePhoto)
      setLoading(false);
    }
    else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      }
    }
  };

  // callback function to opdate state
  const trackOnclick = (payload) => {
    setIsModalOpen(payload);
  };

  // callback function to opdate state
  const trackDeleteClick = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your record is safe!");
      }
    });
  };

  useEffect(() => {
    getProfile();
  }, [loading, isModalOpen]);

  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${profilePicture}`,
  });

  const pageContent = loading ? (
    <LoadingSpinner />
  ) : (
    <div className="row">
      <div className="col-xl-4 col-xxl-5 col-lg-12">
        <div className="card instructors-box">
          <div className="card-header border-0">
            {/* <DropDownBlog /> */}
            <ActionDropDown
              trackOnclick={trackOnclick}
              trackDeleteClick={trackDeleteClick}
            />
          </div>
          <div className="card-body text-center pb-3">
            <div className="instructors-media">
              {typeof instructorData.profile.profilePhoto !== undefined ||
                instructorData.profile.profilePhoto !== "" ? (
                <img
                  src={url}
                  alt={instructorData.profile.profilePhoto}
                />
              ) : (
                <img src={`${PROFILE_ASSETS_URL}/dummy-user.png`} />
              )}

              <div className="instructors-media-info mt-4">
                <h4 className="mb-1">{instructorData.profile.name}</h4>
                <span className="fs-18">
                  Member Since{" "}
                  {new Date(instructorData.createdAt).getFullYear()}
                </span>
              </div>
            </div>

            <div className="bio text-start my-4">
              <h4 className="mb-3">Bio</h4>
              <div className="bio-content">
                <p>{instructorData.profile.aboutMe}</p>
              </div>
            </div>

            <div className="bio text-start my-4">
              <h4 className="mb-3">Address</h4>
              <div className="bio-content">
                <p>{instructorData.profile.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-xxl-7 col-lg-12 ">
        <div className="row">
          {/* <WidgetBlog
            changeImage={certificate}
            title="Instructors"
            link="instructors"
            Count={instructorData.totalInductions}
          /> */}
          <WidgetBlog
            changeImage={clock}
            title="Inductions"
            link="inductions"
            Count={instructorData.totalInductions}
          />
          {/* <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">My Inductions</h3>
            <Link to={"./inductions"} className="btn btn-primary btn-sm">
              View all
            </Link>
          </div> */}
        </div>
      </div>

      <UpdateProfile
        isModalOpen={isModalOpen}
        trackOnclick={trackOnclick}
        instructorData={instructorData}
      />
    </div>
  );

  return <>{pageContent}</>;
};
export default InstructorProfile;
