import {
  InputBase
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";

const Navbar = () => {
  const navigate = useHistory();

  const data = [
    { title: "Shrimp", subheader: "September 14, 2023" },
    { title: "BJS data", subheader: "September 14, 2023" },
    { title: "Management", subheader: "September 20, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },

    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
    { title: "Service Desk", subheader: "September 10, 2023" },
  ];

  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#5181CC",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "primary",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value.trim()
    setSearchInput(inputValue);
    filterData(inputValue);
  };

  const filterData = (inputValue) => {
    const filtered = data.filter((item) =>
      item.name.includes(inputValue)
    );
    console.log(filtered, "fikll")
    setFilteredData(filtered);
  };

  //go back to Induction navigation
  const goBackToInduction = () => {
    navigate.push('/');
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              BJS FORMS
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <AppBar style={{ backgroundColor: "whitesmoke" }}>
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              className="headingLogin"
              href="#app-bar-with-responsive-menu"
              sx={{
                // display: { xs: 'none', md: 'flex' },
                fontFamily: "poppins",
                fontWeight: 600,
                letterSpacing: "1px",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {" "}
              <span style={{ color: "#FEBA30", marginRight: "10px" }}>
                MASS
              </span>
              <span style={{ color: "#3A3630" }}>TEST</span>
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", justifyContent: "center" },
              }}
            ></Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex", justifyContent: "end" },
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
              
              </div>
              <button className="goBackButton" onClick={goBackToInduction}>
              <IoMdArrowBack size={17} /> Home Page

            </button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
