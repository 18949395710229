import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsXCircleFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
//animation
import Lottie from "lottie-react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
//theme

import "primereact/resources/themes/viva-light/theme.css";
//core
import "primereact/resources/primereact.min.css";
// api call
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import RoleTable from "../SkeletonLoader/RoleTable";
import s3 from "../../../utilis/aws.config";


const Companies = (props) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [modalData, setModalData] = useState({ name: "" });
  const [preview, setPreview] = useState("");
  // model popup usestate
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  // const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const [address, setAddress] = useState();
  const [aboutCompany, setAboutCompany] = useState();
  const [editID, setEditID] = useState();
  const [companyID, setCompanyID] = useState();
  const [isUserStatusChanged, setIsUserStatusChanged] = useState(false);
  const [isCompDeleted, setIsCompDeleted] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [nameError, setNameError] = useState('');
  const [companyIDError, setCompanyIDError] = useState('');
  const [companyAddError, setCompanyAddError] = useState('');

  // Create a state variable to track if any field is changed
  const [isFormChanged, setFormChanged] = useState(false);

  async function getCompanies(token) {

    return fetch(`${API_ROOT_URL}/company/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());


  }
  //deselect
  const [showClearButton, setShowClearButton] = useState("");
  const formRef = useRef(null);
  const changeCompanyStatus = (userID, status) => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: `You want to to change the Status ?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(`${API_ROOT_URL}/users/changeUserStatus`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID, status: status }),
          }).then((data) => data.json());
          if ("status" in response && response.status == true) {
            handlepageLoad();
            swal("Poof! Your record has been updated!", {
              icon: "success",
            }).then(() => {
              setIsUserStatusChanged(!isUserStatusChanged);
            });
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal("Your status is not changed!");
        }
      });
    }
  };

  const handleNameChange = (enteredName) => {
    setName(enteredName);
    setFormChanged(true);
    if (enteredName.trim() === '') {
      setNameError("Name field can't be blank");
      setFormChanged(false); // Set form changed to false if the name is empty
    } else {
      setNameError('');
      setFormChanged(true);
    }
  };

  const handleCompanyIDChange = (enteredID) => {
    setCompanyID(enteredID);
    setFormChanged(true);
    if (enteredID.trim() === '') {
      setCompanyIDError("Slug can't be blank");
      setFormChanged(false); // Set form changed to false if the name is empty
    } else {
      setCompanyIDError('');
      setFormChanged(true);
    }
  };

  const handleCompanyAddChange = (enteredAddress) => {
    setAddress(enteredAddress);
    setFormChanged(true);
    if (enteredAddress.trim() === '') {
      setCompanyAddError("Address can't be blank");
      setFormChanged(false); // Set form changed to false if the name is empty

    } else {
      setCompanyAddError('');
      setFormChanged(true);
    }
  };

  // callback function to update state
  const trackOnclick = (payload, company) => {
    setIsModalOpen(payload);
    if (payload) {
      //setModalData(company);
      setName(company.name);
      setEmail(company.email);
      setLogo({ preview: "", data: "" });
      setAddress(company.address);
      setAboutCompany(company.aboutCompany);
      setCompanyID(company.companyID);
      setEditID(company._id);
      setPreview(company.logo);
    }
  };



  // callback function to update state
  const trackDeleteClick = (userID, deleted) => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: "Once Deleted, Everything Associated with it would be deleted!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(
            `${API_ROOT_URL}/company/deleteCompStatus`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
              body: JSON.stringify({ userID: userID, deleted: deleted }),
            }
          ).then((data) => data.json());
          if ("status" in response && response.status == true) {
            swal("Poof! Your record has been deleted!", {
              icon: "success",
            }).then(() => {
              setIsCompDeleted(!isCompDeleted);
            });
          } else {
            if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
              swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                dispatch(logout(props.history));
              });
            }
            else if (response?.devMessage === "userDeleted") {
              Swal.fire({
                title: "Access Denied",
                text: "Your Account Has been deleted",
                icon: "error",
                footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
              }).then(() => {
                dispatch(logout(props.history));
              });
            } else {
              return swal("Failed", "Error message", "error");
            }
          }
          handlepageLoad();
        } else {
          swal("Your record is safe!",
            {
              icon: "success",
            });
        }
      });
    }
  };

  // Edit company submit handler
  const onSubmitHandle = async (e) => {
    e.preventDefault();
    if (nameError || companyIDError || companyAddError) {
      // Prevent form submission if there's an error
      return;
    }
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      const data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("logo", logo.data);
      data.append("address", address);
      data.append("aboutCompany", aboutCompany);
      data.append("logo", preview);
      data.append("companyID", companyID);
      // data.append("profilePhoto",preview)

      const response = await fetch(`${API_ROOT_URL}/company/edit/${editID}`, {
        method: "PUT",
        headers: {
          "x-access-token": token,
        },
        body: data,
      }).then((data) => data.json());

      if ("status" in response && response.status == true) {
        setIsModalOpen(false);
        return swal("Success", response.message, "success");
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", response.message, "error");
        }
      }
    }
  };

  // logo image uploader
  const handleFileChange = async (e) => {
    const logo = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setLogo(logo);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
    setFormChanged(true); // Set formChanged to true when any field is changed

  };
  const handleClearButtonClick = () => {
    setLogo(false);
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };
  const handleInputChange = () => {
    // Set formChanged to true when any field is changed
    setFormChanged(true);
  };

  // on List companies page first render
  const handlepageLoad = async (event) => {

    const response = await getCompanies(token);
    if ("status" in response && response.status == true) {
      setCompanies(response.data);
      setLoading(false);
      setHasPermission(false);
    } else {
      setHasPermission(true);
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }

  };
  const tokenDetailsString = localStorage.getItem('userDetails');
  // re-render page on every update
  useEffect(() => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      handlepageLoad();
      setFormChanged(false); // Reset the formChanged state when the data is loaded
      setCompanyAddError('');
      setNameError('');
      setCompanyIDError('');
    }
  }, [loading, isModalOpen, isUserStatusChanged, token, tokenDetailsString, props.history]);

  const style = {
    height: 150,
  };

  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${preview}`,
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      companyID: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const modifiedCompanies = companies?.map((company) => {
    const { userID } = company;
    const newStatus = userID.status == true ? "active" : "In-active";
    return { ...company, newStatus };
  });

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="fa fa-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const statusTemplate = (modifiedCompanies) => {
    const isActive = modifiedCompanies?.newStatus === "active";

    return (
      <>
        <label class="switch">
          <input type="checkbox" id="togBtn" checked={isActive} to="/companies"
            onClick={() =>
              changeCompanyStatus(modifiedCompanies?.userID?._id, modifiedCompanies?.userID?.status)
            } />
          <div class="slider round">
            <span class="on">Active</span>
            <span class="off">Inactive</span>
          </div>
        </label>
      </>
    );
  };




  const actionTemplate = (rowData) => {

    const handleCallback = () => {
      trackOnclick(true, rowData);
    };
    const deleteClick = () => {
      trackDeleteClick(rowData._id, rowData.deleted);
    };

    return (
      <div className="d-flex">
        <button onClick={handleCallback} className="editIcon">
          <i className="fas fa-pencil-alt" style={{ fontSize: "17px" }}></i>
        </button>

        <button onClick={deleteClick} className="deleteIcon">
          <i className="fa fa-trash" style={{ fontSize: "17px" }}></i>
        </button>

      </div>
    );
  };

  useEffect(() => {
    initFilters();
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card ">
            <div className="card-body p-3">
              <div className="table-responsive">
                {loading ? <RoleTable /> : <DataTable
                  value={modifiedCompanies.map((rowData, index) => ({
                    ...rowData,
                    serialNumber: index + 1,
                  }))}
                  header={header}
                  dataKey="id"
                  // emptyMessage="No Records found"
                  emptyMessage={
                    <div className="queryContainer">
                      {" "}
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                          <div className="form-input-content text-center">
                            <h1 className="error-text font-weight-bold">
                              <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                            </h1>
                            <h3
                              className="fs-28 mt-3"
                              style={{ color: "#3A3630", fontWeight: "900" }}
                            >
                              {" "}
                            </h3>
                            <h4 style={{ fontStyle: "italic" }}>
                              No Records Found !
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  filters={filters}
                  globalFilterFields={["name",
                    "email",
                    "companyID",
                    "newStatus",]}
                  paginator
                  sortMode="multiple"
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  size="large"
                  stripedRows
                  showGridlines
                  tableStyle={{ minWidth: "50rem" }}
                  filterDisplay="menu"
                  className="headerField"
                >
                  <Column
                    header="Sr.No"
                    headerStyle={{ width: "3rem" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>
                  <Column field="name" sortable header="NAME" filterField="name" style={{ minWidth: '12rem' }} filter filterPlaceholder="Search by Name"></Column>
                  <Column field="email" sortable header="EMAIL" filterField="email" style={{ minWidth: '12rem' }} filter filterPlaceholder="Search by Email"></Column>
                  <Column field="companyID" sortable header="SLUG" filterField="companyID" style={{ minWidth: '12rem' }} filter filterPlaceholder="Search by Slug"></Column>
                  <Column
                    field="newStatus"
                    sortable
                    body={statusTemplate}
                    header="STATUS"
                  ></Column>

                  <Column body={actionTemplate} header="ACTIONS"></Column>
                </DataTable>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade d-flex"
        show={isModalOpen}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content" style={{ width: "60vw" }}>
          <div className="modal-header">
            <h5 className="modal-title text-white fs-20">
              EDIT COMPANY DETAILS{" "}
            </h5>
            <RxCross1
              size={25}
              color="white"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <div className="modal-body">
            <form
              className="company-form"
              onSubmit={(e) => onSubmitHandle(e)}
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      readOnly
                      style={{ backgroundColor: "lightGray" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">Name</label>
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => handleNameChange(e.target.value)}
                    />
                    {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">Logo</label>
                    <input type="hidden" name="logo" value={preview} />
                    <input
                      type="file"
                      className="form-control"
                      name="logo"
                      onChange={handleFileChange}
                      accept="image/jpeg,image/jpg,image/png"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">Slug</label>
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      name="companyID"
                      placeholder="Slug"
                      value={companyID}
                      onChange={(e) => handleCompanyIDChange(e.target.value)}
                    />
                    {companyIDError && <p style={{ color: 'red' }}>{companyIDError}</p>}
                  </div>
                </div>

                {logo.data ?
                  <>
                    <div className="col-lg-12">
                      <div className="instructors-media">
                        <div>
                          <img src={logo.preview} width={150} />
                          {showClearButton && (
                            <BsXCircleFill
                              size={25}
                              style={{ marginBottom: "170px", color: "red", cursor: "pointer" }}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <div className="instructors-media">
                          <img
                            src={url}
                            className="img-thumbnail mb-3"
                            width={150}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">Address</label>
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                    <textarea
                      rows={3}
                      className="form-control"
                      name="address"
                      value={address}
                      onChange={(e) => handleCompanyAddChange(e.target.value)}

                    />
                    {companyAddError && <p style={{ color: 'red' }}>{companyAddError}</p>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">About</label>

                    <textarea
                      rows={3}
                      className="form-control"
                      name="aboutCompany"
                      placeholder="Tell us More"
                      value={aboutCompany}
                      onChange={(e) => {
                        setAboutCompany(e.target.value);
                        handleInputChange(); // Set formChanged when input changes
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input
                      type="submit"
                      value="Update Profile"
                      className={`submits ${isFormChanged ? 'enabled' : ''}`}
                      style={{ margin: "auto", display: "flex" }}
                      name="submit"
                      disabled={!isFormChanged}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </Fragment>
  );

};

export default Companies;