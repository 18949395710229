import { useSelector } from "react-redux";
import useDashboardUrl from "../../utilis/useDashboardUrl";
import { Link } from "react-router-dom";
import useValidUrl from "../../utilis/useValidUrl";
import { useLocation } from "react-router-dom";

function ErrorPage() {
    const role = useSelector((state) => state.auth.auth.role);
    const dashbaordUrl = useDashboardUrl(role);
    const location = useLocation();
    const validurl = useValidUrl(location.pathname.slice(1));

    return (
        !validurl ? (
            <div className="h-100 p-meddle">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-8">
                            <div className="form-input-content text-center error-page">
                                <h1 className="error-text font-weight-bold">404</h1>
                                <h4>
                                    <i className="fa fa-exclamation-triangle text-warning" />{" "}
                                    The page you are looking for is not found!
                                </h4>
                                <p>
                                    You may have mistyped the address or the page may have
                                    moved.
                                </p>
                                <div>
                                    <Link to={dashbaordUrl} className="btn btn-primary">Go to Dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    );

}
export default ErrorPage;