/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import proAnimation from "../../components/Animations/pro.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Lottie from "lottie-react";
import Swal from "sweetalert2";
import swal from "sweetalert";
import jwt_decode from "jwt-decode";
//api root url
import { API_ROOT_URL } from "../../constants";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  DEPARTMENT: "department",
  INSTRUCTOR: "instructor",
  USER: "user",
  END_USER: "end_user",
};

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);

  // const decoded = jwt_decode(token);
  // const accessForTest = decoded.accessForTestFeature.status;

  const [testAccessData, setTestAccessData] = useState("");
  const [loading, setLoading] = useState(true);
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  // super-admin

  let DashBoard = [
      "dashboard",
      "instructor-dashboard",
      "company-dashboard",
      "department-dashboard",
      "user-dashboard",
      "profile",
      "company-profile",
      "end-user-forms-dashboard",
    ],
    department = ["departments", "add-department"],
    companies = ["companies", "add-company"],
    instructor = [
      "instructors",
      "add-instructor",
      "instructor-courses",
      "instructor-students",
    ],
    inductions = [
      "inductions",
      "create-induction",
      //"create-induction-new",
      "single-induction-view",
      //"courses",
      "start-test",
      "add-induction",
      "add-slide",
      "update-induction",
      "attempted-inductions",
      "user-inductions",
      "assign-inductions",
      "assign-induction-users",
      "induction-access-control",
    ],
    topics = ["create-topic"],
    students = ["students", "add-student", "unassigned-users", "users"],
    footer = ["Contact-Us"],
    forms = ["forms", "test-access-control","admin-test-dashboard"];

  const onClickTestCheckAccess = async () => {
    const result = await handleTestAccessDetails();
    if (result === false || result == undefined) {
      TestModuleView();
    }
  };

  //request access for test module
  const TestModuleView = () => {
    handleTestAccessDetails();
    Swal.fire({
      text: "Do you want to Request Access for this Test Module ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#58bad7",
      cancelButtonColor: "#3a3630",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then(async (willChange) => {
      if (willChange.isConfirmed) {
        const response = await fetch(
          `${API_ROOT_URL}/v3/tests/request-access/test-module`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
          Swal.fire({
            title: "Request Sent",
            text: `Wait for confirmation`,
            icon: "success",
            confirmButtonColor: "#58bad7",
          });
        } else {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
          });
        }
      }
    });
  };

  //status check for test access module
  async function getTestAccessDetails(token) {
    const response = await fetch(
      `${API_ROOT_URL}/v3/tests/permission-status/test-module`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );

    return response.json();
  }

  const handleTestAccessDetails = async () => {
    try {
      const { status, accessForTestFeature } = await getTestAccessDetails(
        token
      );

      if (status === true) {
        setTestAccessData(accessForTestFeature);
        return accessForTestFeature;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTestAccessDetails();
  }, [testAccessData]);

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <MM className="metismenu" id="menu">
        {/* Dashboards - Super Admin */}
        {USER_ROLES.SUPER_ADMIN === role ? (
          <>
            <li className={`${DashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/dashboard">
                <i className="bi bi-grid"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
          </>
        ) : null}

        {/* Dashboards - Instructor */}
        {USER_ROLES.INSTRUCTOR === role ? (
          <>
            <li className={`${DashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/instructor-dashboard">
                <i className="bi bi-grid"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
          </>
        ) : null}

        {USER_ROLES.DEPARTMENT === role ? (
          <>
            <li className={`${DashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/department-dashboard">
                <i className="bi bi-grid"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
          </>
        ) : null}

        {/* Dashboards - Company */}
        {USER_ROLES.COMPANY === role ? (
          <li className={`${DashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="" to="/company-dashboard">
              <i className="bi bi-grid"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
        ) : null}

        {/*  Company Module - Visible to Only Super Admin*/}

        {USER_ROLES.SUPER_ADMIN === role ? (
          <>
            <li className={`${companies.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                {" "}
                <i className="bi bi-building"></i>{" "}
                <span className="nav-text">Company</span>
              </Link>
              <ul
                className={`${
                  companies.includes(path) ? "mm-show" : "mm-collapse"
                }`}
              >
                <li>
                  <Link
                    className={`${path === "add-company" ? "mm-active" : ""}`}
                    to="/add-company"
                  >
                    Add{" "}
                  </Link>
                </li>

                <li>
                  <Link
                    className={`${path === "companies" ? "mm-active" : ""}`}
                    to="/companies"
                  >
                    Companies
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${department.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <i className="bi bi-layers"></i>
                <span className="nav-text">Department</span>
              </Link>
              <ul
                className={`${
                  department.includes(path) ? "mm-show" : "mm-collapse"
                }`}
              >
                {/* <li>
                  <Link
                    className={`${path === "add-department" ? "mm-active" : ""}`}
                    to="/add-department"
                  >
                    Add{" "}
                  </Link>
                </li> */}
                <li>
                  <Link
                    className={`${path === "department" ? "mm-active" : ""}`}
                    to="/departments"
                  >
                    Departments
                  </Link>
                </li>
              </ul>
            </li>
          </>
        ) : null}

        {/* Department - Visible for Super Admin and Company Only */}
        {role === "company" ? (
          <li className={`${department.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="bi bi-layers"></i>
              <span className="nav-text">Department</span>
            </Link>
            <ul
              className={`${
                department.includes(path) ? "mm-show" : "mm-collapse"
              }`}
            >
              <li>
                <Link
                  className={`${path === "add-department" ? "mm-active" : ""}`}
                  to="/add-department"
                >
                  Add{" "}
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "department" ? "mm-active" : ""}`}
                  to="/departments"
                >
                  Departments
                </Link>
              </li>
            </ul>
          </li>
        ) : null}

        {role === "super_admin" ||
        role === "company" ||
        role === "department" ? (
          <li className={`${instructor.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              {" "}
              <i className="bi bi-person"></i>{" "}
              <span className="nav-text">Instructor</span>
            </Link>
            <ul
              className={`${
                instructor.includes(path) ? "mm-show" : "mm-collapse"
              }`}
            >
              <li>
                <Link
                  className={`${path === "add-instructor" ? "mm-active" : ""}`}
                  to="/add-instructor"
                >
                  Add
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "instructors" ? "mm-active" : ""}`}
                  to="/instructors"
                >
                  Instructors
                </Link>
              </li>
            </ul>
          </li>
        ) : null}

        {USER_ROLES.SUPER_ADMIN === role ||
        USER_ROLES.COMPANY === role ||
        USER_ROLES.DEPARTMENT === role ||
        USER_ROLES.INSTRUCTOR === role ? (
          <li className={`${inductions.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="inductions">
              <i className="bi bi-book"></i>
              <span className="nav-text">Induction</span>
            </Link>
            <ul
              className={`${
                inductions.includes(path) ? "mm-show" : "mm-collapse"
              }`}
            >
              <li>
                <Link
                  className={`${
                    path === "create-induction" ? "mm-active" : ""
                  }`}
                  to="/create-induction"
                >
                  Create{" "}
                </Link>
              </li>
              {USER_ROLES.DEPARTMENT === role ? (
                <>
                  <li>
                    <Link
                      className={`${
                        path === "assign-inductions" ? "mm-active" : ""
                      }`}
                      to="/assign-inductions"
                    >
                      Assign{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "induction-access-control" ? "mm-active" : ""
                      }`}
                      to="/induction-access-control"
                    >
                      Induction Access{" "}
                    </Link>
                  </li>
                </>
              ) : null}
              {USER_ROLES.INSTRUCTOR === role ? (
                <li>
                  <Link
                    className={`${
                      path === "assign-induction-users" ? "mm-active" : ""
                    }`}
                    to="/assign-induction-users"
                  >
                    Assign{" "}
                  </Link>
                </li>
              ) : null}

              <li>
                <Link
                  className={`${
                    path === "attempted-inductions" ? "mm-active" : ""
                  }`}
                  to="/attempted-inductions"
                >
                  Attempted{" "}
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "inductions" ? "mm-active" : ""}`}
                  to="/inductions"
                >
                  Inductions{" "}
                </Link>
              </li>
            </ul>
          </li>
        ) : null}

        {USER_ROLES.DEPARTMENT === role ? (
          <>
            <li className={`${topics.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="topics">
                <i class="bi bi-columns-gap"></i>
                <span className="nav-text">Topic</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "topics" ? "mm-active" : ""}`}
                    to="/create-topic"
                  >
                    Create{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "topics" ? "mm-active" : ""}`}
                    to="/list-topics"
                  >
                    Topics{" "}
                  </Link>
                </li>
              </ul>
            </li>
          </>
        ) : null}

        {USER_ROLES.USER === role ? (
          <>
            <li className={`${DashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/user-dashboard">
                <i className="bi bi-grid"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            {/* <li className={`${DashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/department-dashboard">
                <i className="bi bi-grid"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li> */}

            <li className={`${inductions.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="inductions">
                <i className="bi bi-book"></i>
                <span className="nav-text">Induction</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "inductions" ? "mm-active" : ""}`}
                    to="/inductions"
                  >
                    Inductions{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "user-inductions" ? "mm-active" : ""
                    }`}
                    to="/user-inductions"
                  >
                    Attempts{" "}
                  </Link>
                </li>
              </ul>
            </li>
          </>
        ) : null}

        {USER_ROLES.USER !== role && role !== "end_user" ? (
          <li className={`${students.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="bi bi-people"></i>
              <span className="nav-text">User</span>
            </Link>
            <ul
              className={`${
                students.includes(path) ? "mm-show" : "mm-collapse"
              }`}
            >
              <li>
                <Link
                  className={`${path === "users" ? "mm-active" : ""}`}
                  to="/users"
                >
                  Assigned{" "}
                </Link>
              </li>
              {/* {USER_ROLES.INSTRUCTOR !== role ? ( */}
              <li>
                <Link
                  className={`${
                    path === "unassigned-users" ? "mm-active" : ""
                  }`}
                  to="/unassigned-users"
                >
                  Unassigned{" "}
                </Link>
              </li>
            </ul>
          </li>
        ) : null}

        {USER_ROLES.SUPER_ADMIN == role ? (
          <>
            <li>
              <Link
                className={`${path === "forms" ? "mm-active" : ""}`}
                to="/admin-test-dashboard"
              >
                <i className="bi bi-layout-text-sidebar"></i>
                <span className="nav-text ">Test Module </span>
              </Link>
            </li>
            <li>
              <Link
                className={`${path === "forms" ? "mm-active" : ""}`}
                to="/test-access-control"
              >
                <i className="bi bi-toggles"></i>
                <span className="nav-text ">Test Access Control </span>
              </Link>
            </li>
          </>
        ) : USER_ROLES.INSTRUCTOR == role ||
          USER_ROLES.COMPANY == role ||
          USER_ROLES.DEPARTMENT == role ? (
          <>
            {testAccessData === false || testAccessData === "" ? (
              <>
                <li>
                  <Link
                    className={`${path === "forms" ? "mm-active" : ""}`}
                    onClick={onClickTestCheckAccess}
                  >
                    <i className="bi bi-layout-text-sidebar"></i>
                    <span className="nav-text ">Test Module </span>
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Link
                  className={`${path === "forms" ? "mm-active" : ""}`}
                  to="/forms"
                >
                  <i className="bi bi-layout-text-sidebar"></i>
                  <span className="nav-text ">Test Module </span>
                </Link>
              </li>
            )}
          </>
        ) : null}

        {USER_ROLES.END_USER === role ? (
          <>
            <li className={`${DashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/end-user-forms-dashboard">
                <i className="bi bi-grid"></i>
                <span className="nav-text">Test</span>
              </Link>
            </li>
          </>
        ) : null}

        {USER_ROLES.END_USER !== role ? (
          <li className={`${footer.includes(path) ? "mm-active" : ""}`}>
            <Link to="/Contact-Us">
              <i className="bi bi-telephone-inbound"></i>
              <span className="nav-text">Contact Us</span>
            </Link>
          </li>
        ) : null}
      </MM>

      <div
        className="copyright"
        style={{ position: "absolute", bottom: "0px" }}
      >
        <p></p>
        <p className="fs-12">
          Made with <span className="heart"></span> by BJS
        </p>
      </div>
    </div>
  );
};

export default SideBar;
