import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getData } from "../APIs";
import { API_ROOT_URL } from "../../constants";

async function getDepartments(token, currentCompany) {
  return fetch(`${API_ROOT_URL}/department/getDepartmentByComp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify({ parentCompany: currentCompany }),
  }).then((data) => data.json());
}

// get all active companies in dropdown
const CompanyDropdown = ({ selectedVal, selectedDeptVal, handleInputChange }) => {
  const token = useSelector((state) => state.auth.auth.token);
  const [currentCompany, setCurrentCompany] = useState(selectedVal);
  const [currentDepartment, setCurrentDepartment] = useState(selectedDeptVal);

  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState();
  const [deptOption, setDeptOption] = useState();

  // call to api
  const getCompanyData = async () => {
    setLoading(true);
    const response = await getData(`${API_ROOT_URL}/company/list`, token);
    if ("status" in response && response.status == true) {
      const rows = response.data.map((row, index) => (
        <option value={row.userID._id}>{row.name}</option>
      ));
      setLoading(false);
      setOption(rows); // Update the state for company options
    }

    if (currentCompany !== "") {
      const resp = await getDepartments(token, currentCompany);
      if ("status" in resp && resp.status == true) {
        const rows = resp.data.map((row, index) => (
          <option value={row._id}>{row.name}</option>
        ));
        setDeptOption(rows);
        setLoading(false);
      }
    }
  };

  //
  const handleCompanyChange = async (e) => {
    const selectedCompany = e.target.value;
    setCurrentCompany(selectedCompany);
    handleInputChange(); // Call the provided function to indicate form changes

    if (selectedCompany === "") {
      return false;
    }
    const response = await getDepartments(token, selectedCompany);
    if ("status" in response && response.status === true) {
      const rows = response.data.map((row, index) => (
        <option value={row._id}>{row.name}</option>
      ));
      setDeptOption(rows); // Update the state for department options
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setCurrentDepartment(selectedDepartment);
    // Additional logic or function calls related to department changes can be added here
    handleInputChange();
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <>
      <div className="col-lg-6">
        <div className="form-group mb-3">
          <label
            className="col-sm-3 col-form-label"
            style={{ fontSize: "14px", fontWeight: "bolder" }}
          >
            {" "}
            Company{" "}
            <span
              className="required-field"
              style={{ color: "red", fontWeight: "200px" }}
            >
              *
            </span>
          </label>
          <select
            name="parentCompany"
            className="btn border-light bg-white col-sm-12"
            style={{ borderRadius: "4px" }}
            onChange={(e) => handleCompanyChange(e)}
            value={currentCompany}
            required
          >
            <option value="">Select</option>
            {option}
          </select>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group mb-3">
          <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
            {" "}
            Department{" "}
            <span
              className="required-field"
              style={{ color: "red", fontWeight: "200px" }}
            >
              *
            </span>
          </label>
          <select
            name="deptID"
            className="btn border-light bg-white col-sm-12"
            style={{ borderRadius: "4px" }}
            value={currentDepartment}
            onChange={(e) => handleDepartmentChange(e)}
            required
          >
            <option value="">Select</option>
            {deptOption} {/* Assuming deptOption is the variable holding department options */}
          </select>
        </div>
      </div>
    </>
  );
};

export default CompanyDropdown;
