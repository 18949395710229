import React, { Fragment, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsXCircleFill } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "semantic-ui-react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import CompanyDropdown from "../Companies/CompanyDropdown";
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const AddInstructor = (props) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const loggedrole = useSelector((state) => state.auth.auth.role);
  const id = useSelector((state) => state.auth.auth.id);
  const lrole = useSelector((state) => state.auth.auth.role);
  const token = useSelector((state) => state.auth.auth.token);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();
  const formStates = watch();
  const { email, password, name, address, aboutMe, deptID } = formStates;
  const { parentCompany, parentDepartment } = formStates;
  const [profilePhoto, setProfilePhoto] = useState("dummy-user.png");
  const [image, setImage] = useState({ preview: "", data: "" });
  const [showClearButton, setShowClearButton] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [deptData, setDeptData] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const tokenDetailsString = localStorage.getItem('userDetails');
  
  async function getDepartments(token) {
    return fetch(`${API_ROOT_URL}/department/getDepartmentByComp`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const callApi = async (token, company) => {
    const response = await getDepartments(token, company);
    if ("status" in response && response.status == true) {
      const rows = response.data.map((row, index) => (
        <option key={row._id} value={row._id}>
          {row.name}
        </option>
      ));
      setDeptData(rows);
    }
  };

  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  };
  const handleClearButtonClick = () => {
    setImage({ preview: "", data: "" });
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };

  const onSubmit = async () => {
    const role = "instructor";
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("password", password);
    data.append("role", role);
    data.append("parentCompany", parentCompany);
    data.append("parentDepartment", parentDepartment);
    data.append("deptID", deptID);
    data.append("profilePhoto", image.data);
    data.append("address", address);
    data.append("aboutMe", aboutMe);
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(()=>{
        dispatch(logout(props.history));
      })
    } else {
    const response = await fetch(`${API_ROOT_URL}/instructor/add`, {
      method: "POST",
      headers: {
        "x-access-token": token,
      },
      body: data,
    }).then((user) => user.json());

    if ("status" in response && response.status === true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
        icon: "success",
      }).then((value) => {
        navigate.push("/instructors");
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  }
  };


  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom" className="popoverSection">
      Password must contain{" "}
      <strong>
        {" "}
        upper case, lower case, special characters and minimum of 6 characters
      </strong>
    </Popover>
  );

  useEffect(() => { }, [errors]);

  return (
    <Fragment>

      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="cardheader">
            <h2
              className="text-white"
              style={{ fontSize: "25px", fontWeight: "900px" }}
            >
              <AiOutlineAppstoreAdd color="#E7EEF0" /> ADD
              <span
                className="fs-25 font-w700 text-center"
                style={{ color: "#FEBA30" }}
              >
                {" "}
                INSTRUCTOR
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Field>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Email{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>
                          Enter a valid Email !
                        </p>
                      )}
                    </div>
                  </div>
                </Form.Field>

                <div className="mb-3 mt-3 row">
                  <label className="col-sm-3 col-form-label">
                    Password{" "}
                    <span className="required-field" style={{ color: "red" }}>
                      *
                    </span>
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={popoverBottom}
                    >
                      <div className="icon mx-2">
                        <i
                          className="fa fa-info mx-2"
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </OverlayTrigger>
                  </label>
                  <div className="col-sm-9">
                    <div className="input-group">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="name"
                        className="form-control"
                        autoComplete="new-password"
                        {...register("password", {
                          required: true,
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,10})/,
                        })}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="input-group-text" onClick={togglePasswordVisibility}>
                        {passwordVisible ? (
                          <FaEye size={20} />
                        ) : (
                          <FaEyeSlash size={20} />
                        )}
                      </span>
                    </div>
                    {errors.password && (
                      <p style={{ color: "red" }}>
                        Enter a valid password
                      </p>
                    )}
                  </div>
                </div>

                <Form.Field>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Name{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        // onChange={(e) => setFirstName(e.target.value)}
                        {...register("name", {
                          required: true,
                          pattern: /^[a-z A-Z]*$/,
                          validate: {
                            noSpecialChars: (value) => {
                              return !/[^a-z A-Z ]/.test(value);
                            }
                          }
                        })}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <p style={{ color: "red" }}>
                          Name is required
                        </p>
                      )}
                      {errors.name && errors.name.type === "pattern" && (
                        <p style={{ color: "red" }}>
                          Only letters are allowed
                        </p>
                      )}
                    </div>
                  </div>
                </Form.Field>

                {/* Super admin dropdowns - company and departments */}
                {loggedrole == "super_admin" ? (
                  <>
                    <Form.Field>
                      <div className="mb-3 row">
                        <label className="col-sm-3 col-form-label">
                          Parent Company{" "}
                          <span
                            className="required-field"
                            style={{ color: "red", fontWeight: "200px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-sm-9">
                          <select
                            name="parentCompany"
                            style={{ borderRadius: "4px", backgroundColor: "white" }}
                            className="select btn"
                            {...register("parentCompany", {
                              required: true,
                            })}
                          >
                            <option value="">Select</option>
                            <CompanyDropdown />
                          </select>

                          {errors.parentCompany && (
                            <p style={{ color: "red" }}>Select Company</p>
                          )}
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="mb-3 row">
                        <label className="col-sm-3 col-form-label">
                          Parent Department{" "}
                          <span
                            className="required-field"
                            style={{ color: "red", fontWeight: "200px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-sm-9">
                          <select
                            name="deptID"
                            style={{ borderRadius: "4px", backgroundColor: "white" }}
                            className="select btn"
                            {...register("deptID", {
                              required: true,
                            })}
                          >
                            <option value="">Select</option>
                            {/* <DepartmentDropdown parentCompany={parentCompany}/> */}
                            <GetDepartmentByComp
                              parentCompany={parentCompany}
                              prevSelected=""
                            />
                          </select>
                          {errors.deptID && (
                            <p style={{ color: "red" }}>Select Department</p>
                          )}
                        </div>
                      </div>
                    </Form.Field>
                  </>
                ) : null}

                {loggedrole === "company" ? (
                  <Form.Field>
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Select Department{" "}
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          name="deptID"
                          className="form-control"
                          {...register("deptID", {
                            required: true,
                          })}
                        >
                          <option value="">Select</option>
                          <GetDepartmentByComp
                            parentCompany={id}
                            prevSelected=""
                          />
                        </select>
                        {errors.deptID && (
                          <p style={{ color: "red" }}>Select the Department</p>
                        )}
                      </div>
                    </div>
                  </Form.Field>
                ) : null}

                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Profile Photo{" "}
                  </label>
                  <div className="col-sm-9">
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        name="profilePhoto"
                        onChange={handleFileChange}
                        accept="image/png,image/jpeg,image/jpg"
                      />
                      {image && (
                        <div style={{ marginTop: 10 }}>
                          <img src={image.preview} width="150" />
                          {showClearButton && (
                            <BsXCircleFill
                              size={25}
                              style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        </div>
                      )}
                      {errors.logo && (
                        <div
                          style={{ color: "red", fontWeight: 600, padding: 5 }}
                        >
                          {errors.logo}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Form.Field>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      About me
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control"
                        {...register("aboutMe", {
                        })}
                      ></textarea>
                    </div>
                  </div>
                </Form.Field>

                <Form.Field>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Address{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>

                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control"
                        {...register("address", {
                          required: true,
                        })}
                      ></textarea>
                      {errors.address && (
                        <p style={{ color: "red" }}>Address is required !</p>
                      )}
                    </div>
                  </div>
                </Form.Field>

                <div className="mb-12 row">
                  <div className="col-sm-12">
                    <button
                      className="submitButtonInduction"
                      type="submit"
                      style={{
                        margin: "auto",
                        display: "flex",
                        float: "right",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddInstructor;
