import { Card, CardHeader, CardMedia, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/system";
import Lottie from "lottie-react";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Query from "../../jsx/components/Animations/138566-frequently-asked-question-faq.json";
import { API_ROOT_URL } from "../../jsx/constants";
import TemplateImage2 from "../formsImages/8262066.jpg";
import TemplateImage1 from "../formsImages/isometric_61.jpg";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/AuthActions";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import { Input, makeStyles } from "@material-ui/core";
import { FcApproval } from "react-icons/fc";
import Tooltip from "@mui/material/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import useDashboardUrl from "../../utilis/useDashboardUrl";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: "20px 20px 20px 20px",
    marginTop: "60px",
    backgroundColor: "whitesmoke",
  },
  input: {
    display: "none",
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default function UserView(props) {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [recentFormsData, setRecentFormsData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [testID, setTestId] = useState();
  const [linkexpiry_timer, setLinkExpiryTimer] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(null);
  const [fileName, setFileName] = useState("");
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const role = useSelector((state) => state.auth.auth.role);
  const token = useSelector((state) => state.auth.auth.token);
  const userRole = useSelector((state) => state.auth.auth.role);
  const navigate = useHistory();
  const TemplateImages = [TemplateImage1, TemplateImage2];
  const dashboardUrl = useDashboardUrl(role);
  //bulk sign up
  const [file, setFile] = useState();
  const classes = useStyles();

  const USER_ROLES = {
    SUPER_ADMIN: "super_admin",
    COMPANY: "company",
    DEPARTMENT: "department",
    INSTRUCTOR: "instructor",
    USER: "user",
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const ViewTestScreen = (Id) => {
    handleMenuClose();
    navigate.push(`/test-forms/${Id}`);
  };

  const HandleEditTest = (itemID) => {
    navigate.push(`/edit-test-forms/${itemID}`);
  };

  const HandleResponseTest = (itemID) => {
    navigate.push(`/responses-forms/${itemID}`);
  };

  async function getRecentForms(token) {
    return fetch(`${API_ROOT_URL}/v3/tests/fetch-tests-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  //GetRecent forms data
  const handlepageLoad = async (event) => {
    try {
      const response = await getRecentForms(token);
      if ("status" in response && response.status === true) {
        setRecentFormsData(response.data);
      } else if (response?.devMessage === "revokedPermisision") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Permission Has been Revoked",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          navigate.push(dashboardUrl);
        });
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //Delete Test Functionality
  const deleteTest = (id) => {
    swal({
      title: "Are you sure?",
      text: `Once Test is Deleted, You will not be able to recover your Data`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        const response = await fetch(
          `${API_ROOT_URL}/v3/tests/delete-test/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
          swal("Poof! Your Test has been Deleted!", {
            icon: "success",
          }).then(() => {
            handlepageLoad();
            setLoading(false);
          });
        } else {
          if (
            response?.error?.message === "jwt expired" ||
            response?.devMessage === "userDeleted" ||
            response?.message === "Authentication Required"
          ) {
            swal("Session Timeout ! ", "Please Login Again", "error").then(
              () => {
                dispatch(logout(props.history));
              }
            );
          } else if (response?.devMessage === "revokedPermisision") {
            Swal.fire({
              title: "Access Denied",
              text: "Your Permission Has been Revoked",
              icon: "error",
              confirmButtonColor: "#3a3630",
              confirmButtonText: "OK",
              footer:
                '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
            }).then(() => {
              navigate.push(dashboardUrl);
            });
          } else {
            return swal("Failed", "Error message", "error");
          }
        }
      } else {
        swal("Your Test is not Deleted!");
      }
    });
  };

  useEffect(() => {
    handlepageLoad();
  }, []);

  const handleClickOpen = (testId) => {
    setTestId(testId);
    setOpen(true);
  };

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    // console.log(e,"file csv")
    const selectedFile = e.target.files[0];
    // setFile(selectedFile);
    // setFileName(selectedFile ? selectedFile.name : "");
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

        if(fileExtension !== "csv"){
          setUploading(false);
        }
      // Check if the file extension is 'csv'
      if (fileExtension === "csv") {
        setFile(selectedFile);
        setUploading(true);
        setTimeout(() => {
          setUploading(false);
        }, 5000);
        setFileName(selectedFile.name);
      } else {
        swal("", "Please select a CSV file.", "warning");
        e.target.value = null;
      }
    }
  };

  const handleSaveChanges = async (e) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("test_id", testID);
    formData.append("linkExpireTime", linkexpiry_timer);
    formData.append("document", file);

    const response = await fetch(`${API_ROOT_URL}/v3/tests/bulk-signup`, {
      method: "POST",
      body: formData,
      headers: {
        "x-access-token": token,
      },
    }).then((data) => data.json());

    if ("status" in response && response.status == true) {
      setIsLoading(false);
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        //navigate.push("/forms");
        setLinkExpiryTimer("");
        setLoading(false);
        setFile("");
        handlepageLoad();
        handleClose();
      });
    } else if (response?.devMessage === "revokedPermisision") {
      Swal.fire({
        title: "Access Denied",
        text: "Your Permission Has been Revoked",
        icon: "error",
        confirmButtonColor: "#3a3630",
        confirmButtonText: "OK",
        footer:
          '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
      }).then(() => {
        navigate.push(dashboardUrl);
      });
    } else {
      setIsLoading(false);
      return swal("Failed", response.message, "error");
    }
  };

  //send link timer errors
  const [inputError, setInputError] = useState("");
  const [validTime, setValidTime] = useState("");

  const handleLinkExpiryTimerChange = (e) => {
    const inputValue = e.target.value;
    const regex =
      /^(\d+(\.\d+)?[hmd])?(\s+\d+(\.\d+)?[hmd])?(\s+\d+(\.\d+)?[hmd])?$/;

    const validInput = regex.test(inputValue);
    setValidTime(validInput);
    if (validInput) {
      setLinkExpiryTimer(inputValue);
      setInputError("");
    } else {
      setLinkExpiryTimer(inputValue);
      setInputError(
        'Invalid format. Please use a number followed by "h", "m", "d", or include a dot.'
      );
    }
  };

  const handleClose = () => {
    setShow(false);
    setLinkExpiryTimer("");
    setFile("");
    setInputError("");
  };
  const handleShow = () => setShow(true);

  const SendTestDialog = () => {
    return (
      <div>
        <Modal
          className="modal-send-test-link"
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="send-test-link-header">
            <Modal.Title>SEND TEST LINK</Modal.Title>
            <div className="cross-outer-icon">
              <RxCross1
                className="cross-icon"
                style={{ float: "right" }}
                size={22}
                color="white"
                onClick={handleClose}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Tooltip
                title="Please enter the link expiry time as a number, followed by (h/m/d) (hours/minutes/days) like 2h or 1.5h"
                arrow
              >
                <TextField
                  id="outlined-basic"
                  label="Link Expiry Time"
                  variant="standard"
                  type="text"
                  fullWidth
                  error={!!inputError}
                  helperText={inputError}
                  value={linkexpiry_timer}
                  onChange={(e) => handleLinkExpiryTimerChange(e)}
                />
              </Tooltip>
            </div>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "400",
                fontFamily: "Poppins",
                color: "gray",
                marginBottom: "10px",
                marginTop: "34px",
              }}
            >
              Import CSV File for Link sharing
            </div>
            <form>
              <Tooltip
                title="Before uploading the CSV file,
                     please double-check to ensure that it contains
                      the correct information for each student, including
                       their roll number, name, email, and phone number."
                arrow
              >
                <Input
                  type="file"
                  id="csvFileInput"
                  accept=".csv"
                  onChange={handleOnChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="csvFileInput">
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    component="span"
                    sx={{ width: "420px", marginRight: "10px" }}
                  >
                    Choose CSV File
                  </Button>
                </label>
              </Tooltip>
              {uploading && file ? (
                <>
                  <div
                    className="mt-1"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "GrayText",
                      textAlign: "center",
                    }}
                  >
                    Uploading {fileName}...
                    <LinearProgress
                      size={30}
                      style={{ alignContent: "center" }}
                    />
                  </div>
                </>
              ) : uploading === null && file ? (
                <>
                  <div
                    className="mt-1"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "GrayText",
                      textAlign: "center",
                    }}
                  >
                    {fileName}{" "}
                  </div>
                </>
              ) : uploading == false && file ? (
                <>
                  <div
                    className="mt-2"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "GrayText",
                      textAlign: "center",
                    }}
                  >
                    File Uploaded Successfully <FcApproval size={20} />
                  </div>
                </>
              ) : null}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              {isLoading ? (
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  Saving
                </LoadingButton>
              ) : (
                <Button
                  disabled={
                    uploading === null || uploading === true || !validTime
                  }
                  variant="outlined"
                  onClick={handleSaveChanges}
                  color="primary"
                >
                  Send Test
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const [filteredFormsData, setFilteredFormsData] = useState(recentFormsData);

  const handleChange = (e) => {
    // e.preventDefault();
    const input = e.target.value;
    setSearchInput(input);

    const filteredData = recentFormsData.filter((form) => {
      return form.name.toLowerCase().includes(input.toLowerCase());
    });

    setFilteredFormsData(filteredData);
  };

  //Download CSV file
  const HandlingDebounce = async (testId) => {
    try {
      const response = await fetch(
        `${API_ROOT_URL}/v3/tests/csv-download/remove-csv`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify({
            test_id: testId,
          }),
        }
      ).then((data) => data.json());
      if ("status" in response && response.status === true) {
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3A3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "revokedPermisision") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Permission Has been Revoked",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            navigate.push(dashboardUrl);
          });
        } 
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error in HandleAcceptRequest:", error);
    }
  };

  // custom dropdown for choosing options menu
  const CustomMenu = ({
    data,
    ViewTestScreen,
    handleClickOpen,
    handleShow,
    HandleEditTest,
    HandleResponseTest,
    deleteTest,
  }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };

    //switch case to handle each case
    const handleMenuItemClick = (action) => {
      switch (action) {
        case "view":
          ViewTestScreen(data._id);
          break;
        case "send":
          handleClickOpen(data._id);
          handleShow();
          break;
        case "edit":
          HandleEditTest(data._id);
          break;
        case "responses":
          HandleResponseTest(data._id);
          break;
        case "delete":
          deleteTest(data._id);
          break;
        default:
          break;
      }

      setMenuOpen(false);
    };
    return (
      <div className="custom-menu" ref={menuRef}>
        <button className="menu-button" onClick={handleMenuToggle}>
          <MoreVertIcon />
        </button>

        {isMenuOpen && (
          <div className="menu">
            <button onClick={() => handleMenuItemClick("view")}>
              User View
            </button>
            <button onClick={() => handleMenuItemClick("send")}>
              Send Test
            </button>
            <button onClick={() => handleMenuItemClick("edit")}>Edit</button>
            <button onClick={() => handleMenuItemClick("responses")}>
              Responses
            </button>
            <button onClick={() => handleMenuItemClick("delete")}>
              Delete
            </button>
          </div>
        )}
      </div>
    );
  };

  // const [openCardId, setOpenCardId] = useState(null);

  // const handleCardToggle = (cardId) => {
  //   setOpenCardId((prevId) => (prevId === cardId ? null : cardId));
  // };

  return (
    <div style={{ marginBottom: "7%" }}>
      <>
        <Row>
          <Col lg="9" className="mx-1">
            <div
              className="fs-24 font-w700 mb-4 mt-2"
              style={{ color: "#3a3630" }}
            >
              Recent Tests
            </div>
          </Col>
          <Col lg="2">
            <div className="mx-3">
              <input
                className="search-input"
                type="search"
                name="search"
                id="search"
                placeholder="Search..."
                value={searchInput}
                onChange={handleChange}
                required
              />
              <p style={{ visibility: "hidden" }}>
                Search Input: {searchInput}
              </p>
            </div>
          </Col>
        </Row>
      </>
      <Grid container spacing={8}>
        {loading ? ( // Render skeleton loader while data is loading
          <>
            {[...Array(8)].map((_, index) => (
              <Grid item key={index} xs={12} sm={4} md={3} lg={2}>
                <Card
                  style={{
                    height: "100%",
                    backgroundColor: "white",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <Skeleton variant="rectangular" height={194} />
                  <Skeleton
                    height={60}
                    width={250}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    height={60}
                    width={60}
                    style={{ margin: "0 auto" }}
                  />
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {recentFormsData.length <= 0 ? (
              <>
                <div className="col-xl-12 col-xxl-12">
                  <div className="app-fullcalendar1 m-auto">
                    <h1 className="error-text font-weight-bold">
                      <Lottie
                        style={{ height: 300, marginTop: 60 }}
                        animationData={Query}
                      />
                    </h1>
                    <h4
                      style={{
                        fontStyle: "italic",
                        textAlign: "center",
                        color: "#3a3630",
                      }}
                    >
                      No, Recent Tests Found !
                    </h4>
                  </div>
                </div>
              </>
            ) : (
              <>
                {searchInput.length === 0 ? (
                  <>
                    {recentFormsData.map((data, index) => (
                      <Grid item key={index} xs={12} sm={5} md={6} lg={3}>
                        <Card
                          style={{
                            backgroundColor: "white",
                            height: "100%",
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="194"
                            width="270"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              padding: "10px",
                            }}
                            src={TemplateImages[index % TemplateImages.length]}
                          />
                          <CardHeader
                            style={{ backgroundColor: "white" }}
                            action={
                              <Row>
                                <Col lg="8">
                                  <Tooltip
                                    title="Debounce back emails will be downloaded here as CSV format"
                                    arrow
                                  >
                                    {data.url && (
                                      <button
                                        download={true}
                                        className="debounceDownload mt-2"
                                        onClick={(e) => {
                                          HandlingDebounce(data._id);
                                          window.location.href = data.url;
                                        }}
                                      >
                                        Download
                                        <DownloadIcon fontSize="small" />
                                      </button>
                                    )}
                                  </Tooltip>
                                </Col>
                                <Col lg="4" className="mt-2">
                                  <CustomMenu
                                    data={data}
                                    ViewTestScreen={ViewTestScreen}
                                    handleClickOpen={handleClickOpen}
                                    handleShow={handleShow}
                                    HandleEditTest={HandleEditTest}
                                    HandleResponseTest={HandleResponseTest}
                                    deleteTest={deleteTest}
                                    // isOpen={openCardId === data._id}
                                    // onToggle={() => handleCardToggle(data._id)}
                                  />
                                </Col>
                              </Row>
                            }
                            title={data.name}
                            subheader={new Date(
                              data.createdAt
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {filteredFormsData.map((data, index) => (
                      <Grid item key={index} xs={12} sm={5} md={6} lg={3}>
                        <Card
                          style={{
                            backgroundColor: "white",
                            height: "100%",
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="194"
                            width="270"
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              padding: "10px",
                            }}
                            src={TemplateImages[index % TemplateImages.length]}
                          />
                          <CardHeader
                            style={{ backgroundColor: "white" }}
                            action={
                              <Row>
                                <Col lg="8">
                                  <Tooltip
                                    title="Debounce back emails will be downloaded here as CSV format"
                                    arrow
                                  >
                                    {data.url && (
                                      <button
                                        download={true}
                                        className="debounceDownload mt-2"
                                        onClick={(e) => {
                                          HandlingDebounce(data._id);
                                          window.location.href = data.url;
                                        }}
                                      >
                                        Download
                                        <DownloadIcon fontSize="small" />
                                      </button>
                                    )}
                                  </Tooltip>
                                </Col>
                                <Col lg="4" className="mt-2">
                                  <CustomMenu
                                    data={data}
                                    ViewTestScreen={ViewTestScreen}
                                    handleClickOpen={handleClickOpen}
                                    handleShow={handleShow}
                                    HandleEditTest={HandleEditTest}
                                    HandleResponseTest={HandleResponseTest}
                                    deleteTest={deleteTest}
                                  />
                                </Col>
                              </Row>
                            }
                            title={data.name}
                            subheader={new Date(
                              data.createdAt
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {SendTestDialog()}
    </div>
  );
}
