import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import 'react-toastify/dist/ReactToastify.css';
import { Form } from "semantic-ui-react";
import swal from "sweetalert";
import { API_ROOT_URL } from "../../constants";
import InductionsByCompany from "../Inductions/components/InductionsByCompany";
import './submit.css';
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";

const EventForm = ({ history, isShowAddModal, onClickHandler, data, setEventData }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const parentCompany = useSelector((state) => state.auth.auth.parentCompany);
  const token = useSelector((state) => state.auth.auth.token);

  //add event states
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [inductionID, setInductionID] = useState();
  const [UserData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState([]);
  let today = new Date();
 

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
  } = useForm();
  const formStates = watch();
  const { title, description, backgroundColor } = formStates;

  async function getUserData(token) {
    return fetch(`${API_ROOT_URL}/users/multi-select/dropdown`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // on List companies page first render
  const handlepageLoad = async (event) => {
    const response = await getUserData(token);
    if ("status" in response && response.status === true) {
      setUserData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  async function getEvents(token) {
    return fetch(`${API_ROOT_URL}/users/getting/events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // on List companies page first render
  const handlepageLoadEvent = async (event) => {
    const response = await getEvents(token);
    if ("status" in response && response.status == true) {
      setEventData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  const onFormSubmit = async () => {
    //Post API to add event in calendar
    const payload = {
      title: title,
      start: start,
      end: end,
      description: description,
      backgroundColor: backgroundColor,
      inductionID: inductionID,
      userIds: [...userID]
    }

    const response = await fetch(
      `${API_ROOT_URL}/instructor/eventAdd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(payload),
    }
    ).then((data) => data.json());

    // checking api response
    if ("status" in response && response.status === true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 1000,
      }).then((value) => {
        handlepageLoadEvent();
        onClickHandler();
        navigate.push("/instructor-dashboard");
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  const [selectedOptions, setSelectedOptions] = useState();

  function handleSelect(data) {
    setSelectedOptions(data);
    const userIds = data.map((i) => i.value);
    setUserID(userIds); // Set the selected users IDs as an array
  }

  //get induction id for new event 
  const InductionFilterHandle = (e) => {
    setInductionID(e.target.value);
  };

  const handleChangeStart = (date) => {
    today.setDate(today.getDate() - 1);
    if (date >= today) {
      setStart(date);
    }
  };
  const handleChangeEnd = (date) => {
    today.setDate(today.getDate() - 1);
    if (date >= today) {
      setEnd(date);
    }
  };


  useEffect(() => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(history));
      })
    } else {
    handlepageLoad();
    handlepageLoadEvent();
    }
  }, [isShowAddModal,history]);

  return (
    <>
      <Modal
        className="modal d-flex"
        show={isShowAddModal}
        size="sm"
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">ADD EVENT</h3>
            <RxCross1 size={25} color="white" onClick={onClickHandler} />
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="card-body">
                <div className="form-group mb-3">
                  <h5>Enter Title and Time</h5>
                  <input type="hidden" name="inductionID" />
                  <div className="form-group ">
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Add Title"
                      {...register('title', {
                        required: true,
                      })
                      }
                    />
                    {errors.title && (
                      <p className="mx-1" style={{ color: "red" }}>
                        Title is required !
                      </p>
                    )}
                  </div>
                </div>

                <div className="basic-form">
                  <div className="form-group ">
                    <div className="row">
                      <div className="col-lg-6 col-md-4">
                        <div className="form-group mb-3">
                          <h5>Start Date</h5>
                          <input type="hidden" name="mainID" />
                          <DatePicker
                            className="btn border-light"
                            selected={start}
                            onChange={handleChangeStart}
                            minDate={today}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <h5>End Date</h5>
                          <DatePicker
                            className="btn border-light"
                            selected={end}
                            onChange={handleChangeEnd}
                            minDate={today}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-4">
                        <div className="form-group mb-3">
                          <h5>Choose Event Priority</h5>
                          <div className="col-sm-12">
                            <select
                              className="btn border-light bg-white col-sm-12"
                              style={{ borderRadius: "4px" }}
                              name="backgroundColor"
                              {...register('backgroundColor', {
                                required: true,
                              })
                              }
                            >
                              <option>Select</option>
                              <option value="rgb(240, 128, 128)">High</option>
                              <option value="rgb(202 223 255)">Medium </option>
                              <option value="rgb(255 205 103)">Low</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <h5>Select Induction</h5>
                          <div className="col-sm-12">
                            <select
                              className="btn border-light bg-white col-sm-12"
                              style={{ borderRadius: "4px" }}
                              name="induction_filter"
                              onChange={(e) => InductionFilterHandle(e)}
                            >
                              <option value="All">Select</option>
                              <InductionsByCompany
                                parentCompany={parentCompany}
                              />
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5>Select Users</h5>
                    <div className="form-group mb-3">
                      <Select
                        options={UserData}
                        placeholder="SELECT USER"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        //onClick={getSelectedID}
                        isMulti
                      />
                    </div>


                    <h5>Description</h5>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        rows="2"
                        id="comment"
                        name="description"
                        placeholder="Enter Description"
                        {...register('description', {
                          required: true,
                        })
                        }
                      ></textarea>
                      {errors.description && (
                        <p className="mx-1" style={{ color: "red" }}>
                          Event Description is required !
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    className="submitButtonInduction"
                    style={{ display: "flex", margin: "auto" }}
                    type="submit"
                  >
                    SUBMIT
                  </button>{" "}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EventForm;
