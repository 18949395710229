import React, { useEffect, useState } from "react";
import { HiArrowSmallUp } from "react-icons/hi2";
import { RiTimerFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
//import { logout } from "../../../store/actions/AuthActions";
import { API_ROOT_URL } from "../../constants";
import { getInduction } from "../APIs";
import AddQuestion from "./Modals/AddQuestion";
import QuizList from "./QuizList";
import { useAtom } from 'jotai';
//import { fakePayloadState } from "./StepOne";
//import { UpdateStepOne } from "./StepOne";
import { UpdateQuiz } from "./Modals/AddQuestion";
import { fakePayloadQuestions } from "./Modals/AddQuestion";
import { GrPrevious } from "react-icons/gr";

const StepThree = ({ goToStep }) => {
  // get id from params {id}
  const navigate = useHistory();
  const { id } = useParams(); // this is induction id
  const history = useHistory();
  // redux store data
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [isShowAddQuestion, setIsShowAddQuestion] = useState(false);
  const [questions, setQuestions] = useState([])
  const [isCompDeleted, setIsCompDeleted] = useState(false);
  const [passPercentage, setPassPercentage] = useState("25");
  const [quizTimer, setquizTimer] = useState("");
  //for Global data
  //const [data, setData] = useAtom(fakePayloadState);
  // const [optionData, setOptionData] = useAtom(optionsAtom);
  const [quesData, setQuesData] = useAtom(fakePayloadQuestions);

  const scrollHalf = () => {
    // scroll to half of the page
    window.scrollTo(0, window.innerHeight / 2);
  }
  const min = 25;
  const max = 99;
  useEffect(() => {
    const unlisten = history.listen(() => {
      // The URL has changed, clear local storage
      localStorage.removeItem('fakePayload');
      // alert('Are you sure?');
    });
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      unlisten();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [history]);

  async function UpdateQuiz(quesData, id, token) {
    const updateForm = {
      passPercentage: passPercentage,
      quizTimer: quizTimer,
    };

    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        // Handle logout or any other action when user details are not available
      });
    }
    try {
      const response = await fetch(`${API_ROOT_URL}/mcq/add-mcq`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(quesData),
      });

      const responseData = await response.json();

      // Check if the API call was successful
      if ("status" in responseData && responseData.status === true) {
        setQuesData("");
      } else {
        console.error("API call failed:", responseData.message);
      }

      return responseData;
    } catch (error) {
      console.error("Error during API call:", error);
      throw error;
    }
  }

  // // callback function to update delete state
  const trackDeleteClick = (row) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        const response = await fetch(
          `${API_ROOT_URL}/mcq/soft-delete/${row}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ row: row }),
          }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
          handlepageLoad();
          if (questions?.length === 1 || questions?.length === 0 || !questions) {
            const null_timer = "00:00"
            const null_percentage = "25"
            handleTimer(null_percentage, null_timer)
          }
          setQuestions(response.data);
          swal("Poof! Your record has been deleted!", {
            icon: "success",
          }).then(() => {
            setIsCompDeleted(!isCompDeleted);
          });
          setQuestions([])
        }
        else {
          return swal("Failed", response.message, "error");
        }
      } else {
        swal("Your record is safe!");
      }
    });
  };
  const handlepageLoad = async () => {
    const response = await fetch(`${API_ROOT_URL}/mcq/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((user) => user.json());
    if ("status" in response && response.status === true) {
      setQuestions(response.data);
      setLoading(false);
      // Set quizTimer to "00:00" if there are no questions initially
      if (response?.data.length === 0) {
        setquizTimer("00:00");
      }
    }
  };

  const onClickHandler = () => {
    handlepageLoad();
    setIsShowAddQuestion(false);
    setLoading(true);
    if (questions.length === 0) {
      setquizTimer("00:00"); // Set quiz timer to "00:00"
    }
  };

  const callingAPI = async (inductionID, token) => {
    const response = await getInduction(inductionID, token);
    if ("status" in response && response.status == true) {
      setquizTimer(response.data.quizTimer)
      setPassPercentage(response.data.passPercentage);
    }
  };

  //To remove fakePayload from the local storage
  const handleBeforeUnload = () => {
    localStorage.removeItem('fakePayload');
    alert('Are you sure?');
  };

  const handleTimer = async (passPercentage, quizTimer) => {
    let formData = new FormData();
    formData.append("passPercentage", passPercentage);
    formData.append("quizTimer", quizTimer);

    try {
      const response = await fetch(`${API_ROOT_URL}/induction/${id}?quizOnly=true`, {
        method: "PUT",
        body: formData,
        headers: {
          "x-access-token": token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // swal("Success!", responseData.message, "success", {
        //   buttons: false,
        //   timer: 2000,
        //   icon: "success",
        // }).then(() => {
        //navigate.push("/inductions");

      } else {
        const errorData = await response.json();
        swal("Failed", errorData.message, "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal("Failed", "An error occurred while submitting the form", "error");
    }
  };

  const handleSubmit = async () => {
    swal("Success!", "Induction has been updated Successfully", "success", {
      buttons: false,
      timer: 2000,
      icon: "success",
    }).then(() => {
      navigate.push("/inductions");
    })
  };

  useEffect(() => {
    if (loading) {
      callingAPI(id, token);
    }
  }, [id, token])

  return (
    <>
      <>
        <form>
          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">
              Pass Percentage
            </label>
            <div className="col-sm-2" style={{ display: "flex" }}>
              <input
                type="number"
                className="form-control"
                name="passPercentage"
                defaultValue={25}
                value={passPercentage}
                min={25}
                max={100}
                onChange={(e) => setPassPercentage(e.target.value)}
              />
              <span style={{ fontWeight: "bold", marginTop: "14px", marginLeft: "3px" }}>%</span>
            </div>
          </div>
          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">Quiz Time (MM:SS)</label>
            <div className="col-sm-3">
              <div className="d-flex">
                <div className="row">
                  <div className="col-lg-5 d-flex mx-1">
                    <input
                      type="number"
                      min="0"
                      max="59"
                      id="minutes"
                      className="form-control"
                      name="minutes"
                      placeholder="MM"
                      defaultValue={"01"}
                      value={
                        (!questions || questions.length === 0) && (!quesData || quesData.length === 0)
                          ? "00"
                          : (typeof quizTimer === 'string' ? quizTimer : "00:00").split(':')[0]
                      }
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const newMinutes = parseInt(inputValue, 10);
                        if (!isNaN(newMinutes) && newMinutes >= 0 && newMinutes <= 59) {
                          setquizTimer(`${String(newMinutes).padStart(2, '0')}:${(quizTimer ?? "00:00").split(':')[1] || "00"}`);
                        }
                      }}
                      disabled={!questions || (questions.length === 0 && !quesData)}
                      style={{ minWidth: "70px", backgroundColor: (!questions || (questions.length === 0 && !quesData)) ? "lightgray" : "inherit" }}
                    />
                    <div style={{ fontWeight: "bold", marginTop: "12px", marginLeft: "3px" }}>:</div>
                    <input
                      type="number"
                      min="0"
                      max="59"
                      id="seconds"
                      className="form-control mx-1"
                      name="seconds"
                      placeholder="SS"
                      defaultValue={"00"}
                      value={
                        (!questions || questions.length === 0) && (!quesData || quesData.length === 0)
                          ? "00"
                          : (typeof quizTimer === 'string' ? quizTimer : "00:00").split(':')[1]
                      }
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const newSeconds = parseInt(inputValue, 10);
                        if (!isNaN(newSeconds) && newSeconds >= 0 && newSeconds <= 59) {
                          setquizTimer(`${(quizTimer ?? "00:00").split(':')[0]}:${String(newSeconds).padStart(2, '0')}`);
                        }
                      }}
                      disabled={!questions || (questions.length === 0 && !quesData)}
                      style={{ minWidth: "70px", backgroundColor: (!questions || (questions.length === 0 && !quesData)) ? "lightgray" : "inherit" }}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="card-header">
          <h4 className="card-title">Quiz
            {" "}
          </h4>
          <div>
            <button
              style={{
                backgroundColor: "#FEBA30",
                color: "white",
                borderRadius: "20px",
              }}
              type="button"
              className="me-2 btn"
              onClick={(e) => setIsShowAddQuestion(true)}
            >
              <span
                className="btn-icon-start text-info"
                style={{ borderRadius: "20px" }}
              >
                <i
                  className="fa fa-plus color-info"
                  style={{ color: "#3A3630" }}
                ></i>
              </span>
              Add
            </button>
            <button className={`setQuizTimer ${quizTimer === "00:00" || quizTimer === "" ? 'pulseAnimation' : ''}`} onClick={scrollHalf}>
              {" "}
              <RiTimerFill size={16} /> {" "} Quiz Timer <HiArrowSmallUp size={18} />
            </button>
          </div>
        </div>
        <QuizList inductionID={id} trackDeleteClick={trackDeleteClick} setQuestions={setQuestions} questions={questions} />
        <AddQuestion
          inductionID={id}
          isShowAddQuestion={isShowAddQuestion}
          onClickHandler={onClickHandler}
        />
      </>
      <div className="d-flex" style={{ justifyContent: "right" }}>
        <button className="previous" onClick={() => {
          goToStep();
        }}>
          <GrPrevious size={15} className="mb-1" /> {" "} Back
        </button>

        <button
          className="updatePublish"
          onClick={async () => {
            if (passPercentage < min) {
              return swal("", "Minimum value should be 25%", "warning");
            } else if (passPercentage > max) {
              return swal("", "Value should not exceed 100%", "warning");
            }
            if ((questions.length > 0 || quesData.length > 0) && (quizTimer === "00:00" || quizTimer === null)) {
              swal("", "Please set the timer for the quiz.", "warning");
              return false;
            }
            handleSubmit();
            await handleTimer(passPercentage, quizTimer);
            await UpdateQuiz(quesData, id, token);
          }}
        >
          Update & Publish
        </button>
      </div>
    </>
  );
};

export default StepThree;