import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { API_ROOT_URL } from "../../../constants";
import TestAcknowledgeModal from "../../Modals/test/TestAcknowledgeModal";
import TestQuestions from "../../Modals/test/TestQuestions";
//animation
import Lottie from "lottie-react";
import Timer from "../../Animations/animation_llwawlvt.json";

const StartTest = () => {

  // console.log(props.inductionID, "props")
  const navigate = useHistory();
  const token = useSelector((state) => state.auth.auth.token);
  const [isAcknowledgementModalOpen, setIsAcknowledgementModalOpen] =
    useState(true);
  const [isQuesScreen, setIsQuesScreen] = useState(false);
  const [questions, setQuestions] = useState(false);
  const [timer, setTimer] = useState("");
  const [startTest, setStartTest] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { id } = useParams();
  // get Questions
  const getQuestions = async () => {
    const response = await fetch(`${API_ROOT_URL}/mcq/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status === true) {
      setQuestions(response.data);
      setTimer(response.quizTimer)
    } else {
      setHasError(true);
    }
  };

  const startTestApi = async () => {
    const response = await fetch(`${API_ROOT_URL}/mcq/start/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status == true) {
      questions.length > 0 && setStartTest(true)
    };
  };
  // handle start test
  const openFullscreen = () => {
    const elem = document.documentElement; // Updated to target the whole document
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const handleStartClick = () => {
    getQuestions();
    setIsAcknowledgementModalOpen(false);
    setIsQuesScreen(true);
    startTestApi();
    openFullscreen(); // Trigger fullscreen when the test starts
  };

  const hidePopUp = () => {
    setIsAcknowledgementModalOpen(false);
    // navigate.push(`/single-induction-view/${id}`);
    navigate.push(`/view-induction/${id}`);
  };

  useEffect(() => { }, [questions]);

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const style = {
    height: 300,
  };
  // In progress
  if (hasError) {
    return (
      <>
        <div className="queryContainer">
          {" "}
          <div className="row justify-content-center align-items-center">
            <div className="col-md-12">
              <div className="form-input-content text-center">
                <h1 className="error-text font-weight-bold">
                  <Lottie style={style} animationData={Timer} />
                </h1>
                <div className="mb-3" style={{ fontStyle: "italic", fontSize: "25px", fontWeight: "500", color: "#36454F" }}>
                  {/* The quiz has not yet been prepared ! */}
                  {/* Quiz creation is still in progress ! */}
                  Quiz is not available yet !
                </div>
                <Link className="mt-3" style={{ textDecoration: "underline" }} to="/inductions" onClick={exitFullscreen}>
                  <span className="mx-3"> <i className="fa fa-reply" />{" "} Back to Inductions</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }


  return (
    <>
      <TestAcknowledgeModal
        show={isAcknowledgementModalOpen}
        startClick={handleStartClick}
        hidePopUp={hidePopUp}
      />
      {timer === "" || timer <= 0 ? (
        <>
          <div className="queryContainer">
            {" "}
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12">
                <div className="form-input-content text-center">
                  <h1 className="error-text font-weight-bold">
                    <Lottie style={style} animationData={Timer} />
                  </h1>
                  <div className="mb-3" style={{ fontStyle: "italic", fontSize: "25px", fontWeight: "500", color: "#36454F" }}>
                    Quiz is not available yet !
                  </div>
                  <Link className="mt-3" style={{ textDecoration: "underline" }} to="/inductions">
                    <span className="mx-3"> <i className="fa fa-reply" />{" "} Back to Inductions</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <TestQuestions show={isQuesScreen} Questions={questions} timer={timer} />
        </>
      )}
    </>
  );
};
export default StartTest;
