import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import { BsXCircleFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { logout } from "../../../store/actions/AuthActions";
import { UpdateProfileRedux } from "../../../store/actions/ProfileActions";
import s3 from "../../../utilis/aws.config";

const UpdateProfile = ({ isModalOpen, trackOnclick, instructorData, history }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [address, setAddress] = useState("");

  const [image, setImage] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [preview, setPreview] = useState("");
  const [nameError, setNameError] = useState('');
  const [instAddError, setInstAddError] = useState('');
  const [userID, setUserID] = useState(instructorData?.profile._id);
  //deselect

  const [showClearButton, setShowClearButton] = useState("");
  // Create a state variable to track if any field is changed
  const [isFormChanged, setFormChanged] = useState(false);

  const handleCallback = () => {
    trackOnclick(false);
  };

  // const handleFileChange = async (e) => {
  //   const img = {
  //     preview: URL.createObjectURL(e.target.files[0]),
  //     data: e.target.files[0],
  //   };
  //   setImage(img);
  // };

  // logo image uploader
  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
    setFormChanged(true); // Set formChanged to true when any field is changed

  };
  const handleClearButtonClick = () => {
    setImage(false);
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };

  const handleInputChange = () => {
    // Set formChanged to true when any field is changed
    setFormChanged(true);
  };

  const handleNameChange = (enteredName) => {
    setName(enteredName);
    setFormChanged(true);
    if (enteredName.trim() === '') {
      setNameError("This field can't be blank");
      setFormChanged(false);
    } else if (!/^[a-z A-Z]+$/.test(enteredName)) {
      setNameError("Only alphabets are allowed");
      setFormChanged(false);
    } else {
      setNameError('');
      setFormChanged(true);
    }
  };

  const handleAddressChange = (enteredAddress) => {
    setAddress(enteredAddress);
    setFormChanged(true);
    if (enteredAddress.trim() === '') {
      setInstAddError("Address can't be blank");
      setFormChanged(false); // Set form changed to false if the name is empty
    } else {
      setInstAddError('');
      setFormChanged(true);

    }
  };


  // on every render change values
  useEffect(() => {
    setName(instructorData?.profile?.name);
    setEmail(instructorData?.email);
    setAboutMe(instructorData?.profile?.aboutMe);
    setAddress(instructorData?.profile?.address);
    setUserID(instructorData?.profile._id);
    setProfilePhoto(instructorData?.profile?.profilePhoto);
    setImage({ preview: "", data: "" });
    setPreview(instructorData?.profile?.profilePhoto);
    dispatch(UpdateProfileRedux(instructorData.profile.profilePhoto))
    setFormChanged(false); // Reset the formChanged state when the data is loaded
    setInstAddError('');
    setNameError('');
    // handleSubmit();
  }, [isModalOpen, instructorData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate data
    if (nameError || instAddError) {
      // Prevent form submission if there's an error
      return;
    }
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(history));
      })
    } else {
      let data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("image", image.data);
      data.append("address", address);
      data.append("aboutMe", aboutMe);
      data.append("profilePhoto", preview);

      // const userID = "63cea890edb762dfb4abb220";

      const response = await fetch(`${API_ROOT_URL}/instructor/edit/${userID}`, {
        method: "PUT",
        body: data,
      }).then((data) => data.json());

      if ("status" in response && response.status == true) {
        return swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).then((value) => {
          handleCallback();
          //profile
          if (role === "super_admin") {
            navigate.push("/instructors");
          }
          // }else{
          //   navigate.push("/instructor-profile");
          // }
        });
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(history));
          });
        }
        else {
          return swal("Failed", response.message, "error");
        }
      }
    }
  };

  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${preview}`,
  });

  return (
    <Modal
      className="modal fade d-flex"
      show={isModalOpen}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className="modal-content "
        style={{
          width: "50vw",
          borderRadius: "4px",
          border: "1px solid #3A3630",
        }}
      >
        <div className="modal-header" style={{ backgroundColor: "#3A3630" }}>
          <h5 className="modal-title text-white fs-20">UPDATE INSTRUCTOR</h5>
          <RxCross1 size={25} color="white" onClick={handleCallback} />
        </div>
        <div className="modal-body">
          <form className="update-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="col-sm-3 col-form-label"
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                  >
                    {" "}
                    Email{" "}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    readOnly
                    style={{ backgroundColor: "lightgray" }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="col-sm-3 col-form-label"
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                  >
                    {" "}
                    Name{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                  />
                  {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3 ">
                  <label
                    className="col-sm-3 col-form-label"
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                  >
                    {" "}
                    Profile Picture
                  </label>
                  <div className="instructors-media"></div>
                  <input type="hidden" value={preview} name="logo" />
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={handleFileChange}
                  />
                </div>
              </div>

              {image.data ?
                <>
                  <div className="col-lg-12">
                    <div className="instructors-media">
                      {image && (
                        <div style={{ marginTop: 10 }}>
                          <img src={image.preview} width={130} />
                          {showClearButton && (
                            <BsXCircleFill
                              size={25}
                              style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <div className="instructors-media">
                        <img
                          src={url}
                          className="img-thumbnail mb-3"
                          width={130}
                        />
                      </div>
                    </div>
                  </div>
                </>

              }


              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="col-sm-3 col-form-label"
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                  >
                    {" "}
                    About
                  </label>

                  <textarea
                    rows={2}
                    className="form-control"
                    name="aboutMe"
                    placeholder=""
                    value={aboutMe}
                    onChange={(e) => {
                      setAboutMe(e.target.value);
                      handleInputChange(); // Set formChanged when input changes
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="col-sm-3 col-form-label"
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                  >
                    {" "}
                    Address {" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px", marginTop: "10px" }}
                    >
                      *
                    </span>
                  </label>

                  <textarea
                    rows={2}
                    className="form-control"
                    name="address"
                    placeholder=""
                    value={address}
                    onChange={(e) => handleAddressChange(e.target.value)}
                  />
                  {instAddError && <p style={{ color: 'red' }}>{instAddError}</p>}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-2 mt-3">
                  <input
                    type="submit"
                    value="Update Profile"
                    className={`submits ${isFormChanged ? 'enabled' : ''}`}
                    style={{ margin: "auto", display: "flex" }}
                    name="submit"
                    disabled={!isFormChanged}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateProfile;
