import Lottie from "lottie-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { API_ROOT_URL } from "../../constants";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Row, Col } from "react-bootstrap";
import { BiSortAlt2 } from "react-icons/bi";

//imports of date filter
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";

const AttemptedInductions = () => {
  const token = useSelector((state) => state.auth.auth.token);
  const [data, setData] = useState(
    document.querySelectorAll("#student_wrapper tbody tr")
  );
  const sort = 10;
  const activePag = useRef(0);
  const [loading, setLoading] = useState(true);
  const [inductions, setInductions] = useState([]);

  //sort function states
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState(null);

  const [profileData, setProfileData] = useState({
    email: "",
    createdAt: "",
    profile: {
      first_name: "",
      last_name: "",
      profilePhoto: "dummy-user.png",
      aboutMe: "",
      address: "",
    },
  });
  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([])
  const [test, setTest] = useState([]);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handlepageLoad = async (event) => {
    const response = await fetch(`${API_ROOT_URL}/users/inductions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());

    if ("status" in response && response.status == true) {
      setInductions(response.data);
      setLoading(false);
      setData(document.querySelectorAll("#student_wrapper tbody tr"));
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  // use effect
  useEffect(() => {
    handlepageLoad();
    setData(document.querySelectorAll("#student_wrapper tbody tr"));
  }, [profileData]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
  };

  //css for button
  const buttonStyle = {
    margin: "auto",
    display: "flex",
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'inductionID.title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      correctAnswers: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      wrongAnswers: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      formattedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const statusTemplate = (userData) => {
    return (
      <>
        <Link
          className={`custombadge fs-14 ${userData?.testStatus === "Fail" ? "custombadge-red" : "custombadge-green"
            }`}
          onClick={(e) => e.preventDefault()}
        >
          {userData?.testStatus}
        </Link>
      </>
    );
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(inductions)
  }

  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
    // setModifiedData(deptData);
  }

  useEffect(() => {
    const modifiedUsers = inductions.map((user) => {
      const formattedDate = new Date(user.createdAt)
      return { ...user, formattedDate };
    });
    setModifiedData(modifiedUsers);
  }, [inductions]);

  const handleSelect = (ranges) => {

    let filtered = inductions.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0); // Set time to midnight

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };

  const joinDateTemplate = (modifiedUsers) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(modifiedUsers.createdAt).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="fa fa-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </>
    );
  };

  const header = renderHeader();
  const endmessage = {
    height: 150,
  };

  const items = Array.from({ length: 5 }, (v, i) => i);
  const bodyTemplate = () => {
    return <Skeleton length={10}></Skeleton>
  }

  const circle = () => {
    return (
      <>
        <div className="w-full md:w-5 p-2">
          <Skeleton width="6rem" height="2rem"></Skeleton>
        </div>
      </>
    )
  }

  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };


  const handleClear = () => {

    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(inductions)
  };

  const dateRangePickerTemp = () => {


    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelect}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>
    )
  }

  //function for sorting in date column
  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card students-list">
          <div className="card-body p-3">
            <div className="table-responsive">
              <div>
                {loading ? (
                  <DataTable size="large" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} stripedRows showGridlines tableStyle={{ minWidth: "50rem" }}
                    header={header} value={items} className="p-datatable-striped">
                    <Column header="INDUCTION" body={bodyTemplate}></Column>
                    <Column header="CORRECTS" body={bodyTemplate}></Column>
                    <Column header="WRONG" body={bodyTemplate}></Column>
                    <Column header="REMARK" body={bodyTemplate}></Column>
                    <Column header="STATUS" body={circle}></Column>
                    <Column header="DATE" body={bodyTemplate}></Column>
                  </DataTable>)
                  :
                  (<DataTable
                    value={modifiedData}
                    header={header}
                    dataKey="id"
                    emptyMessage={
                      <div className="queryContainer">
                        {" "}
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-12">
                            <div className="form-input-content text-center">
                              <h1 className="error-text font-weight-bold">
                                <Lottie style={endmessage} animationData={endmessageanimation} />
                              </h1>
                              <h3
                                className="fs-28 mt-3"
                                style={{ color: "#3A3630", fontWeight: "900" }}
                              >
                                {" "}
                              </h3>
                              <h4 style={{ fontStyle: "italic" }}>
                                No Records Found !
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    filters={filters}
                    globalFilterFields={[
                      "inductionID.title",
                      "correctAnswers",
                      "wrongAnswers",
                      "remark",
                      "testStatus"
                    ]}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    size="large"
                    stripedRows
                    showGridlines
                    tableStyle={{ width: "100%" }}
                    filterDisplay="menu"
                  >
                    <Column
                      field="inductionID.title"
                      sortable
                      header="INDUCTION"
                      filterField="inductionID.title"
                      style={{ width: '30%' }}
                      filter
                      filterPlaceholder="Search by Title"
                    ></Column>

                    <Column
                      field="correctAnswers"
                      sortable
                      header="CORRECTS"
                      style={{ width: '15%' }}
                    ></Column>

                    <Column
                      field="wrongAnswers"
                      sortable
                      header="WRONG"
                      style={{ width: '15%' }}
                    ></Column>

                    <Column field="remark"
                      style={{ width: '20%' }}
                      sortable header="REMARK"></Column>

                    <Column
                      field="testStatus"
                      body={statusTemplate}
                      sortable
                      style={{ width: '10%' }}
                      header="STATUS"
                    ></Column>

                    <Column
                      field="formattedDate"
                      style={{ width: '10%' }}
                      body={joinDateTemplate}
                      header={<>
                        <div>
                          <Row className="textFilter">
                            <Col sm="9">
                              <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                                TEST DATE
                                {" "}
                                <span>
                                  <BiSortAlt2 size={22} />
                                </span>
                              </button>

                            </Col>
                            <Col sm="3" className="iconFilter">
                              <CiFilter style={{marginRight:"10px"}}  size={22} onClick={openDateRangePicker} />
                            </Col>
                          </Row>
                        </div>

                        {showDateRangePicker && (
                          <Modal className="customModal d-flex"
                            show={openDateRangePicker}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <div className="modal-header customModalHeader">
                              <h6 className="modal-title text-white fs-20">
                                {" "}SELECT DATE / DATE RANGE
                              </h6>
                              <div className="cross-outer-icon">
                                <RxCross1
                                  className="cross-icon"
                                  size={22}
                                  color="white"
                                  onClick={closeDateRangePicker}
                                />
                              </div>

                            </div>
                            <div className="modal-content customModalContent">

                              <div>
                                {dateRangePickerTemp()}
                              </div>
                            </div>
                            <Modal.Footer className="d-flex modal-footer customModalFooter">
                              <button className="clearButton" onClick={handleClear}>Clear</button>
                              <button className="applyButton" onClick={handleApply}>Apply</button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </>}
                    // header="TEST DATE"
                    // filterField="formattedDate" 
                    //dataType="date" style={{ minWidth: '10rem' }}
                    // filter
                    // filterElement={dateFilterTemplate}
                    />
                  </DataTable>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttemptedInductions;
