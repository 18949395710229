import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

import { useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import BJSLogo from "../../images/BJSLogo.png";
import DesktopTruck from "../../images/DesktopBackgrounds_Haulage.jpg";
import "./Login.css";

function Login(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const formStates = watch();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false); // State variable to track password visibility

  function onLogin(e) {
    const { userType, email, password } = formStates;
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(userType, email, password, props.history));
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div
      className="login-main-page"
      style={{
        backgroundImage: "url(" + DesktopTruck + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container h-100 ">
        <div className="row h-100 align-items-center justify-contain-center">
          <div className="col-xl-12 mt-3">
            <div className="card">
              <div className="card-body p-0">
                <div className="row m-0">
                  <div
                    className="backgroundgraylogin col-xl-6 col-md-6 text-center"
                  >
                    <div>
                      <div className="text-center my-5">
                        <img
                          className="bjsimage"
                          width="350"
                          src={BJSLogo}
                          alt=""
                          style={{ marginTop: "50px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-md-6 "
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                  >
                    <div className="sign-in-your">
                      <Row className="mb-4">
                        <span
                          className="fs-28 font-w900 text-center"
                          style={{ color: "#FEBA30" }}
                        >
                          Log In{" "}
                          <span
                            className="fs-24 font-w900 text-center"
                            style={{ color: "#3A3630" }}
                          >
                            to Your Account
                          </span>
                        </span>
                      </Row>
                      <div className="login-social"></div>
                      {/* {props.errorMessage && (
                        <div className="bg-red-600 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )} */}
                      <Form className="inline" onSubmit={handleSubmit(onLogin)}>
                        <Form.Field>
                          {" "}
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Email</strong>{" "}
                              <span
                                className="required-field"
                                style={{ color: "red", fontWeight: "200px" }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Email"
                              {...register("email", {
                                required: true,
                              })}
                            />
                          </div>
                        </Form.Field>

                        {errors.email && (
                          <p style={{ color: "red" }}>The Email field is required !</p>
                        )}

                        <Form.Field>
                          {" "}
                          <div className="form-group mb-1">
                            <label className="mb-1  ">
                              <strong>Password</strong>{" "}
                              <span
                                className="required-field"
                                style={{ color: "red", fontWeight: "200px" }}
                              >
                                *
                              </span>
                            </label>
                            <div className="form-control d-flex justify-content-end">
                              <input
                                type={passwordVisible ? "text" : "password"}
                                className="input-group"
                                placeholder="Password"
                                style={{ border: "none" }}
                                {...register("password", {
                                  required: true,
                                })}
                              />
                              <span className=""
                                onClick={togglePasswordVisibility}
                              >
                                {passwordVisible ? (
                                  <FaEye size={20} />
                                ) : (
                                  <FaEyeSlash size={20} />
                                )}
                              </span>
                            </div>
                          </div>
                        </Form.Field>
                        {errors.password && errors.password.type === "required" && (
                          <p style={{ color: "red" }}>
                            The Password field is required !
                          </p>
                        )}
                        <div className="row d-flex justify-content-between mt-4 mb-2">
                          <div className="mb-3">
                            <div className="form-check custom-checkbox ms-1">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="basic_checkbox_1"
                              >
                                Remember my preference
                              </label>
                            </div>
                          </div>
                          <div className="container">
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Link
                                    className="signup"
                                    to="./page-register"

                                  >
                                    Sign up
                                  </Link>
                                </div>
                              </Col>
                              <Col>
                                <Link
                                  className="forgotpass"
                                  to="./forgot-password"
                                >
                                  Forgot password
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="text-center">
                          <button type="submit" className="login">
                            Login
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
