import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { RxCross1 } from "react-icons/rx";

const TestAcknowledgeModal = (props) => {
  // In Progress

  const { id } = useParams();
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Your Evaluation is about to start please read & acknowledge the
          following terms.
        </Modal.Title>

        {/* <Button
            variant=""
            type="button"
            className="btn-close"
            data-dismiss="modal"
            onClick={props.hidePopUp}
          ></Button> */}

        <RxCross1 size={25} color="white" onClick={props.hidePopUp} />
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}

        <h4>1. Switching of tabs will lead to failing the test.</h4>
        <h4>2. You can’t undo your answer after submitting it.</h4>
        <h4>
          3. If you want to skip any question press next without clicking any
          options.
        </h4>
        <h4>4. If you try to exit fullscreen by pressing any key you will fail the test.</h4>
      </Modal.Body>
      <Modal.Footer>
        <div className="acknowledgement-footer">
          <div className="acknowledgement-checkbox">
            <input
              type="checkbox"
              id="acknowledgement-checkbox"
              checked={isAcknowledged}
              onChange={() => setIsAcknowledged((prev) => !prev)}
            />
            <label
              style={{ marginBottom: "0px" }}
              htmlFor="acknowledgement-checkbox"
            >
              <b>I Acknowledge </b>
            </label>
          </div>
          <button
            className="btn btn-success m-3"
            style={{ borderRadius: "66px", padding: "10px 35px 10px 35px" }}
            onClick={props.startClick}
            disabled={!isAcknowledged}
          >
            Start
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TestAcknowledgeModal;
