import React, { useRef, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import { useSelector } from "react-redux";
import InstructorDropdown from "./InstructorDropdown";
import Select from "react-select";
import Lottie from "lottie-react";
import TopicDropdown from "../Topics/TopicDropdown";
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import { FcFilledFilter } from "react-icons/fc";
import Loading from "../Animations/99109-loading.json";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
import { Calendar } from 'primereact/calendar';
//theme
import "primereact/resources/themes/viva-light/theme.css";
//core
import "primereact/resources/primereact.min.css";
import Prototype from "../SkeletonLoader/Prototype"
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { TiTick } from "react-icons/ti";

import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";


const AssignInduction = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [InductionData, setInductionData] = useState([]);
  const [InstructorData, setInstructorData] = useState([]);
  const [InstructorID, setInstructorID] = useState("");
  const [InductionID, setInductionID] = useState([]);
  // const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [alreadyAssigned, setAlreadyAssigned] = useState([]);
  const [checkedInstructorID, setCheckedInstructorID] = useState();
  //const [selectedInstructorID, setSelectedInstructorID] = useState("");
  const [prepopulatedCheckboxes, setPrepopulatedCheckboxes] = useState([]);
  const [filterDept, setFilterDept] = useState();
  const [subTopicList, setSubTopicList] = useState([]);
  const [selectedSubTopics, setSelectedSubTopics] = useState([]);
  const [filterTopicID, setFilterTopicID] = useState("");
  const [subTopicSelectedID, setSubTopicSelectedID] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [disable, setDisable] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState();
  const tokenDetailsString = localStorage.getItem('userDetails');

  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([])
  const [test, setTest] = useState([]);

  //sort function states
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      formattedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      createdByName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);


  async function getEvents(token) {
    return fetch(`${API_ROOT_URL}/induction/index/work`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const handlepageLoadEvent = async (event) => {
    const response = await getEvents(token);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  //fetching on the basis of department
  async function filterByDept(deptID) {
    setFilterDept(deptID);
    let filterInductionApiDept = `${API_ROOT_URL}/induction/filter/department/listonly?filterByDepartment=${deptID}`;
    if (deptID === "all") {
      filterInductionApiDept = `${API_ROOT_URL}/induction/index/work`;
    }
    return fetch(filterInductionApiDept, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const filterByDepartment = async (e) => {
    setSelectedSubTopics([]);
    setSubTopicList([])
    setFilterTopicID("")
    const response = await filterByDept(e);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  //fetching on the basis of department
  async function filterTopic(topicID, deptID) {
    setFilterTopicID(topicID);
    setFilterDept(deptID);
    let filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`;
    if (topicID === "all" && !deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/index/work`;
    } else if (topicID === "all" && deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/department/listonly?filterByDepartment=${deptID}`;
    }
    return fetch(filterInductionApiTopic, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  //fetching on the basis of department
  async function filterTopic(topicID, deptID) {
    setFilterTopicID(topicID);
    setFilterDept(deptID);
    let filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`;
    if (topicID === "all" && !deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/index/work`;
    } else if (topicID === "all" && deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/department/listonly?filterByDepartment=${deptID}`;
    }
    return fetch(filterInductionApiTopic, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const filterByTopic = async (e) => {
    setSelectedSubTopics([]);
    const response = await filterTopic(e, filterDept);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  // SUbtopics MultiSelect Dropdown
  async function getSubtopicsMultiDropdown(topicID) {
    return fetch(
      `${API_ROOT_URL}/department/fetching/subtopics/multidropdown/by/topics/${topicID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
  }
  // Get Subtopics By Topic
  const handleTopicChange = async (e) => {
    setSubTopicSelectedID(e.target.value);
    const response = await getSubtopicsMultiDropdown(filterTopicID);
    if ("status" in response && response.status == true) {
      setSubTopicList(response.data);
      setLoading(false);
    }
    else {
      setSubTopicList([]);
    }
  };

  //filter by subtopic
  //fetching on the basis of department
  async function filterSubTopic(subtopicIDs, topicID) {
    let subtopicIDArray = subtopicIDs.map((subtopicID) => subtopicID.value);
    let filterInductionApiSubTopic = `${API_ROOT_URL}/induction/filter/by/subtopic/listonly`;
    if (topicID && subtopicIDArray.length === 0) {
      return fetch(
        `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    } else if (subtopicIDs === "all") {
      return fetch(
        `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    }
    if (subtopicIDArray.length > 0) {
      return fetch(filterInductionApiSubTopic, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({ subtopicID: subtopicIDArray }),
      }).then((data) => data.json());
    }
  }
  // on List companies page first render
  const filterBySubTopic = async (e) => {
    setSelectedSubTopics(e);
    const response = await filterSubTopic(e, filterTopicID);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  async function getInstructorData(token) {
    return fetch(`${API_ROOT_URL}/instructor/getdropdown`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const handlepageLoad = async (event) => {
    const response = await getInstructorData(token);
    if ("status" in response && response.status == true) {
      setInstructorData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };
  //function of get permissions
  async function getSelectedID(instructorID) {

    return fetch(
      `${API_ROOT_URL}/induction-alloted/getassigned/inductions/${instructorID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
  }

  const handlepageLoaded = async (instructorID) => {
    try {
      const response = await getSelectedID(instructorID);
      if ("status" in response && response.status === true) {
        setAlreadyAssigned(response.data);
        // Extract the IDs of the assigned items
        const assignedIDs = response.data.map((item) => item._id);
        // Set the prepopulated checkboxes based on the assigned IDs
        setPrepopulatedCheckboxes(assignedIDs);
        setLoading(false);
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          setPrepopulatedCheckboxes([])
          setAlreadyAssigned([])
        }
      }
    }
    catch (err) {
      setAlreadyAssigned([]);
      setPrepopulatedCheckboxes([]);
      setCheckedItems([])
      setDisable([])
    }
  }
  function handleSelect(data) {
    setCheckedItems([]);
    setSelectedOptions(data);
    setDisable([data])
    setInstructorID(data.value); // Set the selected instructor IDs as an array
  }


  const payload = {
    inductionID: [...prepopulatedCheckboxes, ...checkedItems],
    instructorID: [InstructorID],
  };

  const onSubmit = async () => {
    setDisable([])
    // Filter the checked items from the InductionData based on the checkedItems state
    const checkedValues = InductionData.filter((item) =>
      checkedItems.includes(item._id)
    );

    // Extract the IDs of the checked items
    const checkedIDs = checkedValues.map((item) => item._id);


    const response = await fetch(
      `${API_ROOT_URL}/induction-alloted/assigninduction`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(payload),
      }
    ).then((data) => data.json());

    if ("status" in response && response.status === true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
        icon: "success",
      }).then((value) => {
        setCheckedItems([]);
        setSelectedOptions([]);
        setPrepopulatedCheckboxes([]);
        setAlreadyAssigned([])
        setInstructorID('')
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  const matchingIDs = () => {
    const payLoadData = payload.inductionID;

    const dataset1 = [];
    const allIDs = alreadyAssigned.map((i) => dataset1.push(i._id));

    if (dataset1.length === 0 && payLoadData.length >= 1) {
      return false;
    }
    if (InstructorID.length == 0) {
      return true
    }
    const checkMatches = dataset1.every((id) => payLoadData.includes(id) && dataset1.length === payLoadData.length);
    return checkMatches;
  };

  const result = matchingIDs();

  const renderHeader = () => {
    return (
      <>
        <div className="card-header flex-wrap p-1 mb-3">
          <h2 className="mt-0 text-white" style={{ letterSpacing: "3px" }}>
            {" "}
            <FcFilledFilter style={{ marginBottom: "5px" }} /> FILTERS
          </h2>
          <Row>
            <Col lg="4" className="mb-3">
              <select
                name="deptID"
                className="select btn"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                onChange={(e) => filterByDepartment(e.target.value)}
              >
                <option value="all">DEPARTMENT</option>
                <GetDepartmentByComp />
              </select>
            </Col>
            <Col lg="4" className="mb-3">
              <select
                name="topicID"
                className="select btn"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                onChange={(e) => filterByTopic(e.target.value)}
                onClick={handleTopicChange}
              >
                <option value="all">TOPIC</option>
                <TopicDropdown deptID={filterDept} />
              </select>
            </Col>
            <Col lg="4" className="mb-3">
              <Select
                options={subTopicList || []}
                className="fw-900 fs-14"
                placeholder="SUBTOPIC"
                onChange={filterBySubTopic}
                isSearchable={true}
                isMulti
                value={selectedSubTopics}
                noOptionsMessage={() => "NO SUBTOPICS"}
              />
            </Col>
          </Row>

        </div>
        <Row>
          <Col lg="3" className="mb-3">
            <Select
              options={InstructorData}
              placeholder="SELECT INSTRUCTOR"
              value={selectedOptions}
              onChange={handleSelect}
              isSearchable={true}
              onClick={getSelectedID}
              noOptionsMessage={() => "Loading"}
            />
          </Col>
          <Col lg="4">
            {
              result ?
                <>
                  <button
                    style={{ cursor: "not-allowed" }}
                    className="greyoutbutton"
                  >
                    SUBMIT
                  </button>
                </>
                :
                <>
                  <button
                    className="submitCheckButton mb-3"
                    type="submit"
                    onClick={onSubmit}
                    style={{ letterSpacing: "1px" }}
                  >
                    SUBMIT
                  </button>
                </>
            }

          </Col>

          <Col lg="5">
            <div className="d-flex justify-content-end">
              <span className="p-input-icon-left">
                <i className="fa fa-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Keyword Search"
                />
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const header = renderHeader();

  const handleCheck = (event) => {
    const { value, checked } = event.target;
    if (prepopulatedCheckboxes.includes(value)) {
      setPrepopulatedCheckboxes((prevPrepopulatedCheckboxes) =>
        prevPrepopulatedCheckboxes.filter((item) => item !== value)
      );
    } else {
      if (checked) {
        setCheckedItems((prevCheckedItems) => [...prevCheckedItems, value]);
      } else {
        setCheckedItems((prevCheckedItems) =>
          prevCheckedItems.filter((item) => item !== value)
        );
      }
    }
  };

  const getCheckedValues = () => {
    const checkedValues = InductionData.filter((item) =>
      checkedItems.includes(item._id)
    );
    return checkedValues;
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(InductionData)
  }

  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
  }


  useEffect(() => {
    //destructuring of Induction data to make formatted date as object
    const modifiedAssign = InductionData.map((data) => {
      const formattedDate = new Date(data.createdAt)
      return { ...data, formattedDate };
    });
    setModifiedData(modifiedAssign)
  }, [InductionData])


  const createdDateTemplate = (modifiedAssign) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3 mx-2" style={{ fontSize: "18px" }}>
          {new Date(modifiedAssign.createdAt).toLocaleDateString("en-GB", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>

      </>
    );
  };

  useEffect(() => {
    // Example function to demonstrate the result of getCheckedValues
    const displayCheckedValues = () => {
      const checkedValues = getCheckedValues();
      setInductionID(checkedValues.map((i) => i._id));
    };

    displayCheckedValues();
  }, [checkedItems]);

  useEffect(() => {
    const gettingAllChecked = () => {
      setCheckedInstructorID(alreadyAssigned.map((i) => i._id));
    };

    gettingAllChecked();
  }, []);

  useEffect(() => {
    if (InstructorID) {
      handlepageLoaded(InstructorID);
    }
  }, [InstructorID]);

  useEffect(() => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      getCheckedValues();
      setTimeout(() => {
        handlepageLoad();
      }, 3000);
      handlepageLoaded();
      handlepageLoadEvent();
      getEvents(token);
    }
  }, [loading, tokenDetailsString]);

  const handleSelectFilter = (ranges) => {

    let filtered = InductionData.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };

  const handleApply = () => {

    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(InductionData)
  };

  const dateRangePickerTemp = () => {

    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelectFilter}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>
    )
  }

  //function for sorting in date column
  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const popoverBottom = (
    <Popover id="popover-positioned-top" title="Popover bottom" className="popoverSection">
      These preselected checkboxes suggest that the <strong>Instructor</strong> is already associated with the checked <TiTick size={15} /> Induction.
    </Popover>
  );

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-3">
              <div className="table-responsive">
                {loading ? <Prototype /> : (<DataTable
                  value={modifiedData.map((rowData, index) => ({
                    ...rowData,
                    serialNumber: index + 1,
                  }))}
                  scrollable scrollHeight="700px"
                  header={header}
                  dataKey="id"
                  emptyMessage={
                    <div className="queryContainer">
                      {" "}
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                          <div className="form-input-content text-center">
                            <h1 className="error-text font-weight-bold">
                              <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                            </h1>
                            <h3
                              className="fs-28 mt-3"
                              style={{ color: "#3A3630", fontWeight: "900" }}
                            >
                              {" "}
                            </h3>
                            <h4 style={{ fontStyle: "italic" }}>
                              No Records Found !
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  filters={filters}
                  globalFilterFields={["title", "createdByName", "formattedDate"]}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  size="large"
                  stripedRows
                  showGridlines
                  tableStyle={{ minWidth: "50rem" }}
                  filterDisplay="menu"
                >
                  <Column
                    header="SR.NO" headerStyle={{ width: "5%" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>
                  <Column
                    field="title"
                    style={{ width: "40%", fontSize: "18px" }}
                    sortable
                    header="SELECT INDUCTION"
                    body={(item) => (
                      <>
                        <div className="row d-flex">
                          <div className="col-xl-1">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item._id}
                              checked={
                                prepopulatedCheckboxes.includes(item._id) ||
                                checkedItems.includes(item._id)
                              }
                              disabled={disable.length == 0}
                              onChange={handleCheck}
                              style={{
                                width: "22px",
                                height: "22px",

                              }}
                            />
                          </div>
                          <div className="col-xl-10 fs-16 fw-900" style={{ marginTop: "3px" }}>
                            {item.title}
                          </div>

                        </div>

                      </>

                    )}
                    filterField="title"
                    filter
                    filterPlaceholder="Search by Title"

                  ></Column>
                  <Column
                    field="createdByName"
                    sortable
                    header="CREATED BY"
                    filterField="createdByName"
                    style={{ width: '30%', fontSize: "16px" }}
                    filter filterPlaceholder="Search by Name"
                  ></Column>
                  <Column
                    field="formattedDate"
                    body={createdDateTemplate}
                    style={{ width: '10%' }}
                    header={<>
                      <div>
                        <Row className="textFilter">
                          <Col sm="10">
                            <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                              CREATED ON
                              {" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>

                          </Col>
                          <Col sm="2">
                            <CiFilter className="" size={22} onClick={openDateRangePicker} />
                          </Col>
                        </Row>
                      </div>

                      {showDateRangePicker && (
                        <Modal className="customModal d-flex"
                          show={openDateRangePicker}
                          size="sm"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered>
                          <div className="modal-header customModalHeader">
                            <h6 className="modal-title text-white fs-20">
                              {" "}SELECT DATE / DATE RANGE
                            </h6>
                            <div className="cross-outer-icon">
                              <RxCross1
                                className="cross-icon"
                                size={22}
                                color="white"
                                onClick={closeDateRangePicker}
                              />
                            </div>

                          </div>
                          <div className="modal-content customModalContent">

                            <div>
                              {dateRangePickerTemp()}
                            </div>
                          </div>
                          <Modal.Footer className="d-flex modal-footer customModalFooter">
                            <button className="clearButton" onClick={handleClear}>Clear</button>
                            <button className="applyButton" onClick={handleApply}>Apply</button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </>}
                  />
                </DataTable>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignInduction;
