import React from "react";

export default function ActionDropDown(props) {
  const handleCallback = () => {
    props.trackOnclick(true, props.mcqData);

  }
  const trackDeleteClick = () => {
    props.trackDeleteClick(props.row);
  }

  return (
    <>
      <div>
        <div className="">
          <button
            onClick={handleCallback}
            href="#"
            className="editIcon"
          >
            <i className="fas fa-pencil-alt"></i>
          </button>
          <button

            onClick={trackDeleteClick}
            href="#"
            className="deleteIcon"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </>
  );
}
