import React from "react";
//react prime datatable
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Navbar from "../NavBar/Navbar";
import Background from "./../formsImages/abstract-gray-mosaic-polygon-surfaced-background.jpg";
import swal from "sweetalert";
import { API_ROOT_URL, USER_ROLES } from "../../jsx/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FcFilledFilter } from "react-icons/fc";
import { InputText } from "primereact/inputtext";
import Select from "react-select";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/AuthActions";
import CompanyDropdown from "../../jsx/components/Companies/CompanyDropdown";
import GetDepartmentByComp from "../../jsx/components/Department/GetDeptByCompany";
import InstructorDropdown from "./InstructorDropdown";
import { Link } from "react-router-dom";
import Prototype from "../../jsx/components/SkeletonLoader/Prototype";
//animations
import Lottie from "lottie-react";
import endmessageanimation from "../../jsx/components/Animations/animation_lloy7bt3.json";

import { Modal } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { CiFilter } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import { BiSortAlt2 } from "react-icons/bi";

const AdminTestList = ({ props }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [recentFormsData, setRecentFormsData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  const [compID, setcompID] = useState("");
  const [deptID, setDeptID] = useState("");
  const [filterDept, setFilterDept] = useState();

  //sortable function states
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortKey, setSortKey] = useState(null);

  //date filter states
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([]);
  const [test, setTest] = useState([]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      formattedDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      "createdBydetails.role": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      description: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      "createdBydetails.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  async function getRecentForms(token) {
    return fetch(`${API_ROOT_URL}/v3/tests/fetch-tests-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const handlepageLoad = async (event) => {
    try {
      const response = await getRecentForms(token);
      if ("status" in response && response.status === true) {
        setRecentFormsData(response.data);
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // api for filter inductions by company
  async function filterTestData(filterId) {
    let filterInductionsApi = `${API_ROOT_URL}/v3/tests/fetch-tests-list?filterId=${filterId}`;
    if (filterId === "all") {
      filterInductionsApi = `${API_ROOT_URL}/v3/tests/fetch-tests-list`;
    }
    return fetch(filterInductionsApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const filterTest = (filterId, role) => {
    if (role === USER_ROLES.COMPANY) {
      setcompID(filterId);
    } else if (role === USER_ROLES.DEPARTMENT) {
      setDeptID(filterId);
    }
    const handlepageLoad = async () => {
      if (role === USER_ROLES.INSTRUCTOR && filterId === "all") {
        filterId = deptID;
      } else if (role === USER_ROLES.DEPARTMENT && filterId === "all") {
        filterId = compID;
      }
      const response = await filterTestData(filterId);
      if ("status" in response && response.status == true) {
        setRecentFormsData(response.data);
        setLoading(false);
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    };
    handlepageLoad();
  };

  useEffect(() => {
    handlepageLoad();
  }, [loading]);

  useEffect(() => {
    initFilters();
  }, []);

  const renderHeader = () => {
    return (
      <>
        <Row>
          <Col lg="2">
            <h2 className="mt-0 text-white" style={{ letterSpacing: "3px" }}>
              {" "}
              <FcFilledFilter style={{ marginBottom: "5px" }} /> FILTERS
            </h2>
          </Col>
          <Col lg="2" className="mb-3">
            <select
              name="parentCompany"
              style={{ borderRadius: "4px", backgroundColor: "white" }}
              className="select btn"
              onChange={(e) => filterTest(e.target.value, USER_ROLES.COMPANY)}
            >
              <option value="all">COMPANY</option>
              <CompanyDropdown />
            </select>
          </Col>
          <Col lg="2" className="mb-3">
            <select
              name="deptID"
              style={{ borderRadius: "4px", backgroundColor: "white" }}
              className="select btn"
              onChange={(e) =>
                filterTest(e.target.value, USER_ROLES.DEPARTMENT)
              }
            >
              <option value="all">DEPARTMENT</option>
              <GetDepartmentByComp parentCompany={compID} />
            </select>
          </Col>
          <Col lg="2" className="mb-3">
            <select
              name="topicID"
              style={{ borderRadius: "4px", backgroundColor: "white" }}
              className="select btn"
              onChange={(e) =>
                filterTest(e.target.value, USER_ROLES.INSTRUCTOR)
              }
              //   onClick={handleTopicChange}
            >
              <option value="all">INSTRUCTOR</option>
              <InstructorDropdown deptID={deptID} compId={compID} />
            </select>
          </Col>
          <Col lg="2" className="mb-3"></Col>
          <Col lg="2">
            <span className="p-input-icon-left">
              <i className="fa fa-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
            </span>
          </Col>
        </Row>
      </>
    );
  };

  const header = renderHeader();

  const createdDateTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(rowData.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}
        </div>
      </>
    );
  };

  const createdByNameTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {rowData.createdBydetails.name}
        </div>
      </>
    );
  };

  const createdByRoleTemplate = (rowData) => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {capitalizeFirstLetter(rowData.createdBydetails.role)}
        </div>
      </>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <Link to={`/test-forms/${rowData._id}`} className="updateButton">
          <i className="fa-solid fa-eye" style={{ fontSize: "18px" }}></i>
        </Link>

        <button
          onClick={(e) => {
            deleteTest(rowData._id);
          }}
          className="deleteIconInductionList"
        >
          <i className="fa fa-trash" style={{ fontSize: "17px" }}></i>
        </button>
      </div>
    );
  };

  //Delete Test Functionality
  const deleteTest = (id) => {
    swal({
      title: "Are you sure?",
      text: `Once Test is Deleted, You will not be able to recover your Data`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        const response = await fetch(
          `${API_ROOT_URL}/v3/tests/delete-test/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
          swal("Poof! Your Test has been Deleted!", {
            icon: "success",
          }).then(() => {
            handlepageLoad();
            setLoading(false);
          });
        } else {
          if (
            response?.error?.message === "jwt expired" ||
            response?.devMessage === "userDeleted" ||
            response?.message === "Authentication Required"
          ) {
            swal("Session Timeout ! ", "Please Login Again", "error").then(
              () => {
                dispatch(logout(props.history));
              }
            );
          } else {
            return swal("Failed", "Error message", "error");
          }
        }
      } else {
        swal("Your Test is not Deleted!");
      }
    });
  };

  useEffect(() => {
    const ModifiedRecentTestData = recentFormsData.map((data) => {
      const { profile } = data;
      const dateWithoutTime = new Date(data.createdAt);
      dateWithoutTime.setHours(0, 0, 0, 0);
      const dateString = dateWithoutTime.toDateString();
      const formattedDate = dateString.substring(4);
      // const formattedDate = new Date(data.createdAt);
      return { ...data, formattedDate };
    });
    setModifiedData(ModifiedRecentTestData);
  }, [recentFormsData]);

  const handleSelect = (ranges) => {
    let filtered = recentFormsData.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate =
        joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);
  };

  const dateRangePickerTemp = () => {
    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: "selection" }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelect}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>
    );
  };

  //to open the date range picker
  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    //setModifiedData(recentFormsData);
  };

  //to close the date range picker
  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
  };

  //for other fields
  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      if (key === "createdAt") {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (sortOrder === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  //with date sort function
  const sortDateTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);

      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
};


  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(recentFormsData);
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card students-list">
          <div className="card-body p-3">
            <div className="table-responsive">
              <div>
                {loading ? (
                  <Prototype />
                ) : (
                  <DataTable
                    value={modifiedData.map((rowData, index) => ({
                      ...rowData,
                      serialNumber: index + 1,
                    }))}
                    header={header}
                    dataKey="id"
                    filters={filters}
                    globalFilterFields={[
                      "name",
                      "description",
                      "createdBydetails.role",
                      "createdBydetails.name",
                    ]}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    size="large"
                    stripedRows
                    showGridlines
                    tableStyle={{ minWidth: "50rem" }}
                    filterDisplay="menu"
                    emptyMessage={
                      <div className="queryContainer">
                        {" "}
                        <div className="row justify-content-center align-items-center">
                          <div
                            className="col-md-12"
                            style={{ height: 350, width: 800 }}
                          >
                            <div className="form-input-content text-center">
                              <h1 className="error-text font-weight-bold">
                                <Lottie
                                  style={{ height: 150 }}
                                  animationData={endmessageanimation}
                                />
                              </h1>
                              <h3
                                className="fs-28 mt-3"
                                style={{ color: "#3A3630", fontWeight: "900" }}
                              >
                                {" "}
                              </h3>
                              <h4 style={{ fontStyle: "italic" }}>
                                No Records Found !
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <Column
                      header="SR.NO"
                      body={(data, options) => options.rowIndex + 1}
                      style={{ width: "5%" }}
                    ></Column>
                    <Column
                      field="name"
                      // sortable
                      style={{ width: "20%" }}
                      // header="TEST TITLE"
                      header={
                        <>
                          <div>
                            <button
                              className="buttonJoinDate"
                              onClick={() => sortTable("name")}
                            >
                              TEST TITLE{" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>
                          </div>
                        </>
                      }
                      filter
                      filterField="name"
                      filterPlaceholder="Search by Title"
                    ></Column>
                    <Column
                      field="description"
                      // sortable
                      // header="SUB TITLE"
                      header={
                        <>
                          <div>
                            <button
                              className="buttonJoinDate"
                              onClick={() => sortTable("description")}
                            >
                              SUB TITLE{" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>
                          </div>
                        </>
                      }
                      style={{ width: "25%" }}
                      filter
                      filterField="description"
                      filterPlaceholder="Search by Sub-Title"
                    ></Column>

                    <Column
                      field="createdBydetails.role"
                      body={createdByRoleTemplate}
                      // sortable
                      // header="ROLE"
                      header={
                        <>
                          <div>
                            <button
                              className="buttonJoinDate"
                              onClick={() => sortTable("createdBydetails.role")}
                            >
                              ROLE{" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>
                          </div>
                        </>
                      }
                      style={{ width: "15%" }}
                      filter
                      filterField="createdBydetails.role"
                      filterPlaceholder="Search by Role"
                    ></Column>
                    <Column
                      body={createdByNameTemplate}
                      // header="CREATED BY"
                      // sortable
                      header={
                        <>
                          <div>
                            <button
                              className="buttonJoinDate"
                              onClick={() => sortTable("createdBydetails.name")}
                            >
                              CREATED BY{" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>
                          </div>
                        </>
                      }
                      field="createdBydetails.name"
                      style={{ width: "15%" }}
                      filter
                      filterField="createdBydetails.name"
                      filterPlaceholder="Search by Name"
                    ></Column>
                    <Column
                      field="formattedDate"
                      body={createdDateTemplate}
                      style={{ width: "12%" }}
                      header={
                        <>
                          <div onClick={() => sortDateTable("formattedDate")}>
                            <Row className="textFilter">
                              <Col sm="10">
                                <button className="buttonJoinDate">
                                  CREATED ON
                                  <span>
                                    <BiSortAlt2 size={22} />
                                  </span>
                                </button>
                              </Col>
                              <Col sm="2">
                                <CiFilter
                                  className="mx-1 sortFilterIcon"
                                  size={22}
                                  onClick={openDateRangePicker}
                                />
                              </Col>
                            </Row>
                          </div>

                          {showDateRangePicker && (
                            <Modal
                              className="customModal d-flex"
                              show={openDateRangePicker}
                              size="sm"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div className="modal-header customModalHeader">
                                <h6 className="modal-title text-white fs-20">
                                  {" "}
                                  SELECT DATE / DATE RANGE
                                </h6>
                                <div className="cross-outer-icon">
                                  <RxCross1
                                    className="cross-icon"
                                    size={22}
                                    color="white"
                                    onClick={closeDateRangePicker}
                                  />
                                </div>
                              </div>
                              <div className="modal-content customModalContent">
                                <div>{dateRangePickerTemp()}</div>
                              </div>
                              <Modal.Footer className="d-flex modal-footer customModalFooter">
                                <button
                                  className="clearButton"
                                  onClick={handleClear}
                                >
                                  Clear
                                </button>
                                <button
                                  className="applyButton"
                                  onClick={handleApply}
                                >
                                  Apply
                                </button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>
                      }
                    />

                    <Column body={actionTemplate} header="ACTIONS"></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTestList;
