import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Image, ImageGroup } from "react-fullscreen-image";
import { useForm } from "react-hook-form";
import { BsCaretLeftFill, BsCaretRightFill, BsFillSendFill } from "react-icons/bs";
import { FaReply } from "react-icons/fa";
import { FcCalendar } from "react-icons/fc";
import { FiMessageSquare } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { SlMagnifierAdd } from "react-icons/sl";
import ReactPaginate from "react-paginate";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { Form } from "semantic-ui-react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL, AWS_BUCKET_NAME, QUERY_ASSETS_URL } from "../../constants";
import NotFound from "../Animations/106840-search-not-found.json";
import Query from "../Animations/138566-frequently-asked-question-faq.json";
import { MdEmail } from "react-icons/md";
import QueryRaiseUser from "./QueryRaiseUser";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import s3 from "../../../utilis/aws.config";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  DEPARTMENT: "department",
  USER: "user",
};

const ContactUs = (props) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const replyID = useSelector((state) => state.auth.auth.id);
  const token = useSelector((state) => state.auth.auth.token);

  const [image, setImage] = useState({ preview: "", data: "" });

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit
  } = useForm();
  const formStates = watch();

  const [replyModal, setreplyModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const role = useSelector((state) => state.auth.auth.role);
  const [preview, setPreview] = useState("");
  const [logo, setLogo] = useState("");
  const [profileData, setProfileData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [replyData, setReplyData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  //deselect
  const [showClearButton, setShowClearButton] = useState("");
  const tokenDetailsString = localStorage.getItem('userDetails');

  //Query reply states 
  // const [reply, setReply] = useState("");
  const [url, setUrl] = useState("");

  const [hasError, setHasError] = useState(false);

  //Read more states
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };
  //const [replyID, setReplyID] = useState("");
  const [queryID, setQueryID] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const postsPerPage = 2;

  // logo image uploader
  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  };


  // const { userName, email, queryDescription, queryTitle } =
  //   formStates;

  // const onFormSubmit = async (e) => {

  //   const data = new FormData();
  //   data.append("userName", userName);

  //   if (USER_ROLES.INSTRUCTOR === role) {
  //     data.append("email", mapping); // Prepopulated email value
  //   } else {
  //     data.append("email", profileData?.email); // Use profileData email for non-instructors
  //   }

  //   data.append("queryDescription", queryDescription);
  //   data.append("queryTitle", queryTitle);
  //   data.append("image", image.data);

  //   const response = await AddPostAPI(token, data);

  //   if ("status" in response && response.status === true) {
  //     reset();
  //     return swal("Success", response.message, "success", {
  //       buttons: false,
  //       timer: 1000,
  //     });
  //   } else {
  //     return swal("Failed", response.message, "error");
  //   }
  // };

  const getSignedUrl = (fileName) => {
    return s3.getSignedUrl("getObject", {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName,
    });
  };

  // api call
  async function getProfileApi(token) {
    const URL = `${API_ROOT_URL}/users/getProfile`;
    return fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  //get profile data(bio) from API
  const getProfile = async () => {
    const response = await getProfileApi(token);
    if ("status" in response && response.status === true) {
      setProfileData(response.data);
      setLoading(false);
    }
  };

  // api call for get queries
  async function getPosts(token) {
    return fetch(`${API_ROOT_URL}/contactus/gettingquery`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // on List companies page first render
  const handlepageLoad = async () => {
    const response = await getPosts(token);
    if ("status" in response && response.status == true) {
      setPostData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      }
    }
  };

  const imagestozoom = [];
  const newImages = postData.map((i) => imagestozoom.push(i.image));

  const textBox = [];
  const data = postData.map((i) => textBox.push(i.queryDescription));

  //getting id
  const getID = (id) => {
    const res = postData.find((data) => id === data._id);
    setQueryID(res._id);
    return res._id;
  };

  const handleReply = (id) => {
    const queryId = getID(id);
    handlepageLoadData(queryId);
    setViewModal(true);
  };
  //For instructor
  const handleReplySubmit = (id) => {
    const queryID = getID(id);
    //onSubmit(queryID);
    setreplyModal(true);
  };

  //post query reply
  const onSubmit = async (e) => {
    const { reply } = formStates;
    const data = new FormData();

    const acceptedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!acceptedFormats.includes(image.data.type)) {
      swal('Error', 'Please select a valid image format (JPEG, PNG, JPG)', 'error');
      return;
    }

    data.append("reply", reply);
    data.append("url", url);
    data.append("image", image.data);
    const response = await fetch(
      `${API_ROOT_URL}/contactus/replyquery/${queryID}`,
      {
        method: "POST",
        body: data,
        headers: {
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
    if ("status" in response && response.status == true) {
      setreplyModal(false);
      handlepageLoadData();
      setValue('reply', '')
      setImage([]);
      setUrl('')
      return swal("Success", response.message, "success");
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", response.message, "error");
      }
    }
  };

  //getting instructor replies
  // api call for get queries
  async function getReplies(token, queryId) {
    //console.log(queryId, `dlfjsdl`);
    return fetch(`${API_ROOT_URL}/contactus/getreply/${queryId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // on List companies page first render
  const handlepageLoadData = async (queryId) => {
    const response = await getReplies(token, queryId);
    if ("status" in response && response.status == true) {
      setReplyData(response.data);
      setLoading(false);
      setHasError(false);
    } else {
      setHasError(true);
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      }
    }
  };

  const startIndex = currentPage * postsPerPage;
  const endIndex = startIndex + postsPerPage;
  const displayedPosts = postData.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      getProfile(token);
      handlepageLoadData();
      handlepageLoad();
    }
  }, [loading, queryID, tokenDetailsString]);

  const style = {
    height: 200,
  };

  const queryStyle = {
    height: 250,
  };

  // Define the mapping variable based on the user's role
  let mapping = "";
  if (USER_ROLES.INSTRUCTOR === role) {
    mapping = profileData.map((i) => i.email);
  }

  return (
    <>
      {USER_ROLES.SUPER_ADMIN === role || USER_ROLES.INSTRUCTOR === role || USER_ROLES.DEPARTMENT === role || USER_ROLES.COMPANY === role ? (
        <div className="row">
          <div className="col-xl-8 col-xxl-7">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <span
                  className="fs-24 font-w900 text-center mb-2"
                  style={{ color: "#3A3630" }}
                >
                  Queries!
                </span>
              </div>

              <div className="tab-content">
                <div className="card-body pt-0">
                  {displayedPosts.length === 0 ? (
                    <>
                      <div className="queryContainer">
                        {" "}
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-12">
                            <div className="form-input-content text-center">
                              <h1 className="error-text font-weight-bold">
                                <Lottie
                                  style={queryStyle}
                                  animationData={Query}
                                />
                              </h1>
                              <h3
                                className="fs-24 mt-3"
                                style={{ color: "#3a3630", fontWeight: "900" }}
                              >
                                {" "}
                              </h3>
                              <h4 style={{ fontStyle: "italic" }}>
                                Raise your Query, So we will reply you shortly.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {displayedPosts.map((data, index) => (
                        <div className="blog-bx row mb-0" key={index}>
                          <div className="col-xl-6 col-xxl-12 mb-0">
                            <ImageGroup>
                              <ul className="images">
                                <li>
                                  <Image
                                    src={getSignedUrl(`${QUERY_ASSETS_URL}/${data?.image}`)}
                                    alt="No, Preview available"
                                  />
                                </li>
                              </ul>
                            </ImageGroup>
                          </div>
                          <div className="col-xl-6 col-xxl-12">
                            <div className="blog-content">
                              <h5 className="mb-3" style={{ color: "#3A3630" }}>
                                {data.queryTitle}
                              </h5>

                              <div
                                className="fs-14"
                                style={{
                                  textAlign: "justify",
                                  marginBottom: "4%",
                                  padding: "1%",
                                }}
                              >
                                <ReactReadMoreReadLess
                                  readMoreClassName="readmore"
                                  readLessClassName="readless"
                                  charLimit={180}
                                  readMoreText={"Read more ▼"}
                                  readLessText={"Read less ▲"}
                                >
                                  {data.queryDescription}
                                </ReactReadMoreReadLess>
                              </div>
                              {USER_ROLES.INSTRUCTOR === role ? (
                                <>
                                  <Row>
                                    <Col lg="7" className="mb-1">
                                      <span className="fs-14 font-w500">
                                        <span className="text-primary">
                                          Query Posted by{" "}
                                        </span>{" "}
                                        -{" "}
                                        {data.userName}
                                      </span>
                                    </Col>
                                    <Col lg="5">
                                      <span className="fs-14 font-w500">
                                        <span className="text-primary">
                                          Date{" "}
                                        </span>{" "}
                                        -{" "}
                                        {new Date(
                                          data.createdAt
                                        ).toLocaleDateString("en-GB", {
                                          day: "numeric",
                                          month: "long",
                                          year: "numeric",
                                        })}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Col lg="12" className="mb-1">
                                    <span className="fs-12 font-w500">
                                      <span className="text-dark">
                                        <MdEmail size={18} className="mb-1" />{" "}
                                      </span>{" "}
                                      -{" "}
                                      <span className="text-primary">
                                        {data.email}
                                      </span>
                                    </span>
                                  </Col>
                                </>
                              ) : null}

                              {USER_ROLES.SUPER_ADMIN === role ||
                                USER_ROLES.COMPANY === role ||

                                USER_ROLES.DEPARTMENT === role ? (
                                <>
                                  <Col lg="12" className="mb-1">
                                    <span className="fs-12 font-w500">
                                      <span className="text-primary">
                                        Query Posted by{" "}
                                      </span>{" "}
                                      -{" "}
                                      {data.userName}
                                    </span>
                                  </Col>
                                  <Col lg="12" className="mb-1">
                                    <span className="fs-12 font-w500">
                                      <span className="text-dark">
                                        <MdEmail size={18} className="mb-1" />{" "}
                                      </span>{" "}
                                      -{" "}
                                      <span className="text-primary">
                                        {data.email}
                                      </span>
                                    </span>
                                  </Col>

                                  <Row>
                                    <>
                                      <Col lg="8" className="mt-3">
                                        <span className="fs-12 font-w500">
                                          <span className="text-primary">
                                            Date{" "}
                                          </span>{" "}
                                          -{" "}
                                          {new Date(
                                            data.createdAt
                                          ).toLocaleDateString("en-GB", {
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                          })}
                                        </span>
                                      </Col>
                                      <Col lg="4" className="">
                                        <button
                                          className="viewReplies"
                                          onClick={() => handleReply(data._id)}
                                        >
                                          <span className="me-1"> </span>
                                          Replies <FiMessageSquare size={18} />
                                        </button>
                                      </Col>
                                    </>
                                  </Row>
                                </>
                              ) : null}
                              <Row>
                                {USER_ROLES.INSTRUCTOR === role ? (
                                  <>
                                    <Col lg="8" className="mt-4">
                                      <button
                                        className="viewReplies"
                                        onClick={() => handleReply(data._id)}
                                      >
                                        <span className="me-1">
                                          {" "}
                                          {/* <i className="fa fa-reply" /> */}
                                        </span>
                                        View replies
                                      </button>
                                    </Col>
                                    <Col lg="4" className="mt-4">
                                      <button
                                        style={{ fontSize: "14px" }}
                                        className="replyButton"
                                        onClick={() =>
                                          handleReplySubmit(data._id)
                                        }
                                      >
                                        <span className="me-2">
                                          {" "}
                                          <i className="fa fa-reply" />
                                        </span>
                                        Reply
                                      </button>
                                    </Col>
                                  </>
                                ) : null}
                              </Row>


                              <Modal
                                show={replyModal}
                                onHide={() => setreplyModal(false)}
                                className="modal fade d-flex"
                              >
                                <div
                                  className="modal-content"
                                  style={{ width: "50vw" }}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title">Query Reply</h5>
                                    <RxCross1
                                      size={25}
                                      color="white"
                                      onClick={() => setreplyModal(false)}
                                    />
                                  </div>
                                  <div className="modal-body">
                                    <form
                                      className="query-form"
                                      // onSubmit={(e) => handleReplySubmit(e)}
                                      onSubmit={handleSubmit(onSubmit)}
                                    >
                                      <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                          <label className="form-label fs-6 fw-bold">
                                            Leave a reply !
                                          </label>
                                          <span
                                            className="required-field"
                                            style={{ color: "red", fontWeight: "200px" }}
                                          >
                                            {" "} *
                                          </span>
                                          <textarea
                                            className="form-control"
                                            rows="4"
                                            name="reply"
                                            placeholder="leave a reply"
                                            //value={reply}
                                            // onChange={(e) =>
                                            //   setReply(e.target.value)
                                            // }
                                            {...register("reply", {
                                              required: true,
                                            })}
                                          ></textarea>
                                          {errors.reply && (
                                            <p style={{ color: "red" }}>
                                              This field is required !
                                            </p>
                                          )}
                                        </div>

                                        <div className="col-lg-6">
                                          <div className="form-group mb-3">
                                            <label className="form-label fs-6 fw-bold">
                                              Add Picture Reply
                                              <span className="text-primary">{" "}(JPG/PNG/JPEG)</span>
                                            </label>
                                            <input
                                              type="file"
                                              className="form-control"
                                              name="image"
                                              onChange={handleFileChange}
                                              accept="image/jpeg,image/jpg,image/png"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="form-group mb-3">
                                            <label className="form-label fs-6 fw-bold">
                                              Leave a URL
                                            </label>
                                            <input
                                              placeholder="enter a url "
                                              className="form-control"
                                              value={url}
                                              onChange={(e) =>
                                                setUrl(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="modal-footer">
                                        <button
                                          type="submit"
                                          value="submit"
                                          className="replyQuery m-auto d-flex"
                                          style={{ fontSize: "15px" }}
                                        >
                                          Reply
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </Modal>

                              {/* view replies modal */}
                              <Modal
                                show={viewModal}
                                onHide={() => setViewModal(false)}
                                className="modal fade d-flex"
                                size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                              >
                                <div
                                  className="modal-content"
                                  style={{ width: "50vw" }}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Query Replies
                                    </h5>
                                    <RxCross1
                                      size={25}
                                      color="white"
                                      onClick={() => setViewModal(false)}
                                    />
                                  </div>
                                  <div
                                    className="modal-body scrollable p-3"
                                    style={{
                                      maxHeight: "800px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <div className="card-body pt-0">
                                      {replyData.length <= 0 ? (
                                        <>
                                          {" "}
                                          <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12 mt-5 mb-5" >
                                              <div className="form-input-content text-center">
                                                <h1 className="error-text font-weight-bold">
                                                  <Lottie
                                                    style={style}
                                                    animationData={NotFound}
                                                  />
                                                </h1>
                                                <div className="warningText">
                                                  <span
                                                    className="font-w900 text-center"
                                                    style={{
                                                      color: "#3a3630",
                                                      fontSize: "30px",
                                                    }}
                                                  >
                                                    Oops!
                                                  </span>
                                                </div>
                                                <h3
                                                  className="fs-20 mt-3"
                                                  style={{
                                                    color: "#feba30",
                                                    fontWeight: "900",
                                                  }}
                                                >
                                                  {" "}
                                                  Replies not Found !
                                                </h3>
                                              </div>
                                            </div>
                                          </div>

                                        </>
                                      ) : (
                                        <>
                                          {replyData.map((data, index) => (
                                            <div
                                              className="blog-bx row"
                                              key={index}
                                            >
                                              <div
                                                className="col-xl-6 col-xxl-12"
                                                style={{ position: "relative" }}
                                              >
                                                <img
                                                  style={{
                                                    border: "1px solid #3a3630",
                                                  }}
                                                  src={getSignedUrl(`${QUERY_ASSETS_URL}/${data?.image}`)}
                                                  alt=""
                                                />{" "}
                                                <a
                                                  className="fs-14 mt-4"
                                                  style={{
                                                    position: "absolute",
                                                    left: "88%",
                                                    // right: "50%",
                                                    top: "67%",
                                                    bottom: "47%",
                                                  }}
                                                  href={getSignedUrl(`${QUERY_ASSETS_URL}/${data?.image}`)}
                                                  target="_blank"
                                                >
                                                  <SlMagnifierAdd size={30} />
                                                </a>
                                              </div>

                                              <Col>
                                                <div
                                                  style={{
                                                    fontSize: "16px",
                                                    textAlign: "justify",
                                                  }}
                                                >
                                                  <ReactReadMoreReadLess
                                                    readMoreClassName="readmore"
                                                    charLimit={200}
                                                    readMoreText={"Read more ▼"}
                                                    readLessClassName="readless"
                                                    readLessText={"Read less ▲"}
                                                  >
                                                    {data.reply}
                                                  </ReactReadMoreReadLess>
                                                </div>

                                                <Col lg="12">
                                                  <h4 className="fs-14 font-w500 mb-3 mt-3">
                                                    URL -{" "}
                                                    <span className="">
                                                      <a
                                                        style={{
                                                          textDecoration:
                                                            "underline",
                                                          color: "#007bff",
                                                        }}
                                                        className="fs-16"
                                                        target="_blank"
                                                        href={data.url}
                                                      >
                                                        {" "}
                                                        {data.url}
                                                      </a>{" "}
                                                    </span>
                                                  </h4>
                                                </Col>
                                                <Row>
                                                  <Col lg="6">
                                                    <h5 className="fs-16 font-w600 mb-3">
                                                      {" "}
                                                      <span className="text-dark">
                                                        <FaReply
                                                          size={18}
                                                          color="#57DB7A"
                                                        />{" "}
                                                      </span>{" "}
                                                      {data?.repliedBy}
                                                    </h5>
                                                  </Col>
                                                  <Col lg="6">
                                                    <h5 className="fs-16 font-w500 mb-3">
                                                      <span className="text-dark">
                                                        <FcCalendar size={22} />{" "}
                                                      </span>{" "}
                                                      -{" "}
                                                      {new Date(
                                                        data.createdAt
                                                      ).toLocaleDateString(
                                                        "en-GB",
                                                        {
                                                          day: "numeric",
                                                          month: "long",
                                                          year: "numeric",
                                                        }
                                                      )}
                                                    </h5>
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </div>
                                          ))}
                                        </>
                                      )}
                                    </div>

                                    <div className="modal-footer"></div>
                                  </div>
                                </div>
                              </Modal>


                            </div>
                          </div>
                          <hr
                            style={{ marginTop: "5px", marginBottom: "10px" }}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-xxl-12">
                  <div className="card-body responsive">
                    <ReactPaginate
                      previousLabel={
                        <BsCaretLeftFill style={{ fontSize: 18, width: 20 }} />
                      }
                      nextLabel={
                        <BsCaretRightFill style={{ fontSize: 18, width: 20 }} />
                      }
                      breakLabel={"..."}
                      marginPagesDisplayed={2}
                      // pageCount={pageCount}
                      pageRangeDisplayed={3}
                      pageCount={Math.ceil(postData.length / postsPerPage)}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      //className={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-xxl-5">
            <div className="row">
              {/* social links */}
              <div className="col-xl-12 col-xxl-12">
                <div className="card blog-card bg-white">
                  <div className="card-body responsive">
                    <h4 className="text-Black mb-4" style={{ color: "#3A3630" }}>
                      Social Links
                    </h4>
                    <div className="row">
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          target="_blank"
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          url="https://uk.linkedin.com/company/bjs-distribution?trk=public_post_feed-actor-name&original_referer=https%3A%2F%2Fin.linkedin.com%2F"
                        />{" "}
                      </div>
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          target="_blank"
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          url="https://www.facebook.com/people/Bjs-distribution/100054365824453/"
                        />{" "}
                      </div>
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          target="_blank"
                          url="https://www.instagram.com/bjsdelivery/"
                        />
                      </div>
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          target="_blank"
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          url="https://twitter.com/bjsdistribuk?lang=en"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {USER_ROLES.SUPER_ADMIN !== role ? (
              <div className="col-xl-12">
                <div
                  className="card blog-card"
                  style={{ backgroundColor: "#3A3630" }}
                >
                  <div className="card-body">
                    <h4 className="text-white">Contact Admin</h4>
                    <ul>
                      <li>
                        <span style={{ paddingRight: "15px" }}>
                          <BsArrowRightCircleFill size={25} />
                        </span>
                        {" "}
                        <p>
                          {" "}
                          For Inquiries, Reach out to the Admin using the following Email.
                        </p>
                      </li>
                      <li>
                        <span>
                          <svg
                            className="me-3"
                            width="25"
                            height="22"
                            viewBox="0 0 28 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.3337 0.333252H4.66699C3.60613 0.333252 2.58871 0.754679 1.83857 1.50482C1.08842 2.25497 0.666992 3.27239 0.666992 4.33325V17.6666C0.666992 18.7274 1.08842 19.7449 1.83857 20.495C2.58871 21.2452 3.60613 21.6666 4.66699 21.6666H23.3337C24.3945 21.6666 25.4119 21.2452 26.1621 20.495C26.9122 19.7449 27.3337 18.7274 27.3337 17.6666V4.33325C27.3337 3.27239 26.9122 2.25497 26.1621 1.50482C25.4119 0.754679 24.3945 0.333252 23.3337 0.333252ZM22.5697 2.99992L14.8003 9.39992C14.5783 9.56884 14.3086 9.66339 14.0297 9.67009C13.7508 9.67679 13.4769 9.59531 13.247 9.43725L5.43099 2.99992H22.5697ZM23.3337 18.9999H4.66699C4.31337 18.9999 3.97423 18.8594 3.72418 18.6094C3.47413 18.3593 3.33366 18.0202 3.33366 17.6666V4.73325L11.6003 11.5333C12.2882 12.0483 13.1249 12.3258 13.9843 12.3239C14.8737 12.3226 15.7386 12.032 16.4483 11.4959L24.667 4.73325V17.6666C24.667 18.0202 24.5265 18.3593 24.2765 18.6094C24.0264 18.8594 23.6873 18.9999 23.3337 18.9999Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <a className="text-white fs-16" href="mailto:info@bjsinduction.com">info@bjsinduction.com</a>
                        {/* <p>info@bjsinduction.com</p> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>




        </div>
      ) : null}

      {USER_ROLES.USER === role ? (
        <div className="row">
          <div className="col-xl-8 col-xxl-7" style={{ marginBottom: "2%" }}>
            <QueryRaiseUser setPostData={setPostData} />
          </div>
          <div className="col-xl-4 col-xxl-5 mt-3">
            <div className="row">
              {/* social links */}
              <div className="col-xl-12 col-xxl-12">
                <div className="card blog-card bg-white">
                  <div className="card-body responsive">
                    <h4 className="text-Black mb-4" style={{ color: "#3A3630" }}>
                      Social Links
                    </h4>
                    <div className="row">
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          target="_blank"
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          url="https://uk.linkedin.com/company/bjs-distribution?trk=public_post_feed-actor-name&original_referer=https%3A%2F%2Fin.linkedin.com%2F"
                        />{" "}
                      </div>
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          target="_blank"
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          url="https://www.facebook.com/people/Bjs-distribution/100054365824453/"
                        />{" "}
                      </div>
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          target="_blank"
                          url="https://www.instagram.com/bjsdelivery/"
                        />
                      </div>
                      <div className="col-xl-3 col-xxl-3 col-sm-3">
                        <SocialIcon
                          target="_blank"
                          style={{
                            background: "none",
                            left: "20%",
                          }}
                          url="https://twitter.com/bjsdistribuk?lang=en"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-8 col-xxl-7">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <span
                  className="fs-24 font-w900 text-center mb-2"
                  style={{ color: "#3A3630" }}
                >
                  Queries!
                </span>
              </div>

              <div className="tab-content">
                <div className="card-body pt-0">
                  {displayedPosts.length === 0 ? (
                    <>
                      <div className="queryContainer">
                        {" "}
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-12">
                            <div className="form-input-content text-center">
                              <h1 className="error-text font-weight-bold">
                                <Lottie
                                  style={queryStyle}
                                  animationData={Query}
                                />
                              </h1>
                              <h3
                                className="fs-24 mt-3"
                                style={{ color: "#3a3630", fontWeight: "900" }}
                              >
                                {" "}
                              </h3>
                              <h4 style={{ fontStyle: "italic" }}>
                                Raise your Query, So we will reply you shortly.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {displayedPosts.map((data, index) => (
                        <div className="blog-bx row mb-0" key={index}>
                          <div className="col-xl-6 col-xxl-12 mb-0">
                            <ImageGroup>
                              <ul className="images">
                                <li>
                                  <Image
                                    src={getSignedUrl(`${QUERY_ASSETS_URL}/${data?.image}`)}
                                    alt="No, Preview available"
                                  />
                                </li>
                              </ul>
                            </ImageGroup>
                          </div>
                          <div className="col-xl-6 col-xxl-12">
                            <div className="blog-content">
                              <h5 className="mb-3" style={{ color: "#3A3630" }}>
                                {data.queryTitle}
                              </h5>

                              <div
                                className="fs-14"
                                style={{
                                  textAlign: "justify",
                                  marginBottom: "4%",
                                  padding: "1%",
                                }}
                              >
                                <ReactReadMoreReadLess
                                  readMoreClassName="readmore"
                                  readLessClassName="readless"
                                  charLimit={180}
                                  readMoreText={"Read more ▼"}
                                  readLessText={"Read less ▲"}
                                >
                                  {data.queryDescription}
                                </ReactReadMoreReadLess>
                              </div>
                              {/* {USER_ROLES.INSTRUCTOR === role ? (
                                <Col lg="8" className="mt-3">
                                  <span className="fs-14 font-w500 mt-3">
                                    <span className="text-primary">
                                      Posted on{" "}
                                    </span>{" "}
                                    -{" "}
                                    {new Date(
                                      data.createdAt
                                    ).toLocaleDateString("en-GB", {
                                      day: "numeric",
                                      month: "long",
                                      year: "numeric",
                                    })}
                                  </span>
                                </Col>
                              ) : null} */}

                              {/* {USER_ROLES.USER === role ||
                                USER_ROLES.INSTRUCTOR === role ||
                                USER_ROLES.DEPARTMENT === role ||
                                USER_ROLES.COMPANY === role ? (
                                <>
                                  <Col lg="12" className="mb-1">
                                    <span className="fs-14 font-w500">
                                      <span className="text-primary">
                                        Query Posted by{" "}
                                      </span>{" "}
                                      -{" "}
                                      {data.userName}
                                    </span>
                                  </Col>
                                  <Col lg="12" className="mb-1">
                                    <span className="fs-12 font-w500">
                                      <span className="text-dark">
                                        <MdEmail size={18} className="mb-1" />{" "}
                                      </span>{" "}
                                      -{" "}
                                      <span className="text-primary">
                                        {data.email}
                                      </span>
                                    </span>
                                  </Col>

                                  <Row>
                                    <>
                                      <Col lg="8" className="mt-3">
                                        <span className="fs-14 font-w500">
                                          <span className="text-primary">
                                            Date{" "}
                                          </span>{" "}
                                          -{" "}
                                          {new Date(
                                            data.createdAt
                                          ).toLocaleDateString("en-GB", {
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                          })}
                                        </span>
                                      </Col>
                                      {USER_ROLES.INSTRUCTOR !== role ? (
                                        <Col lg="4" className="">
                                          <button
                                            className="viewReplies"
                                            onClick={() => handleReply(data._id)}
                                          >
                                            <span className="me-1"> </span>
                                            Replies <FiMessageSquare size={18} />
                                          </button>
                                        </Col>
                                      ) : null}
                                    </>
                                  </Row>
                                </>
                              ) : null} */}

                              <Row>
                                <>
                                  <Col lg="8" className="mt-3">
                                    <span className="fs-14 font-w500">
                                      <span className="text-primary">
                                        Date{" "}
                                      </span>{" "}
                                      -{" "}
                                      {new Date(
                                        data.createdAt
                                      ).toLocaleDateString("en-GB", {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </span>
                                  </Col>
                                  {USER_ROLES.INSTRUCTOR !== role ? (
                                    <Col lg="4" className="">
                                      <button
                                        className="viewReplies"
                                        onClick={() => handleReply(data._id)}
                                      >
                                        <span className="me-1"> </span>
                                        Replies <FiMessageSquare size={18} />
                                      </button>
                                    </Col>
                                  ) : null}
                                </>
                              </Row>

                              <Row>
                                {USER_ROLES.INSTRUCTOR === role ? (
                                  <>
                                    <Col lg="8" className="mt-4">
                                      <button
                                        className="viewReplies"
                                        onClick={() => handleReply(data._id)}
                                      >
                                        <span className="me-1">
                                          {" "}
                                          {/* <i className="fa fa-reply" /> */}
                                        </span>
                                        View replies
                                      </button>
                                    </Col>
                                    <Col lg="4" className="mt-4">
                                      <button
                                        style={{ fontSize: "14px" }}
                                        className="replyButton"
                                        onClick={() =>
                                          handleReplySubmit(data._id)
                                        }
                                      >
                                        <span className="me-2">
                                          {" "}
                                          <i className="fa fa-reply" />
                                        </span>
                                        Reply
                                      </button>
                                    </Col>
                                  </>
                                ) : null}
                              </Row>

                              {/* view replies modal */}
                              <Modal
                                show={viewModal}
                                onHide={() => setViewModal(false)}
                                className="modal fade d-flex"
                                size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                              >
                                <div
                                  className="modal-content"
                                  style={{ width: "50vw" }}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Query Replies
                                    </h5>
                                    <RxCross1
                                      size={25}
                                      color="white"
                                      onClick={() => setViewModal(false)}
                                    />
                                  </div>
                                  <div
                                    className="modal-body scrollable p-3"
                                    style={{
                                      maxHeight: "800px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <div className="card-body pt-0">
                                      {replyData.length <= 0 ? (
                                        <>
                                          {" "}
                                          <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12 mt-5 mb-5" >
                                              <div className="form-input-content text-center">
                                                <h1 className="error-text font-weight-bold">
                                                  <Lottie
                                                    style={style}
                                                    animationData={NotFound}
                                                  />
                                                </h1>
                                                <div className="warningText">
                                                  <span
                                                    className="font-w900 text-center"
                                                    style={{
                                                      color: "#3a3630",
                                                      fontSize: "30px",
                                                    }}
                                                  >
                                                    Oops!
                                                  </span>
                                                </div>
                                                <h3
                                                  className="fs-20 mt-3"
                                                  style={{
                                                    color: "#feba30",
                                                    fontWeight: "900",
                                                  }}
                                                >
                                                  {" "}
                                                  Replies not Found !
                                                </h3>
                                              </div>
                                            </div>
                                          </div>

                                        </>
                                      ) : (
                                        <>
                                          {replyData.map((data, index) => (
                                            <div
                                              className="blog-bx row"
                                              key={index}
                                            >
                                              <div
                                                className="col-xl-6 col-xxl-12"
                                                style={{ position: "relative" }}
                                              >
                                                <img
                                                  style={{
                                                    border: "1px solid #3a3630",
                                                  }}
                                                  src={getSignedUrl(`${QUERY_ASSETS_URL}/${data?.image}`)}
                                                  alt="no"
                                                />{" "}
                                                <a
                                                  className="fs-14 mt-4"
                                                  style={{
                                                    position: "absolute",
                                                    left: "88%",
                                                    // right: "50%",
                                                    top: "67%",
                                                    bottom: "47%",
                                                  }}
                                                  href={getSignedUrl(`${QUERY_ASSETS_URL}/${data?.image}`)}
                                                  target="_blank"
                                                >
                                                  <SlMagnifierAdd size={30} />
                                                </a>
                                              </div>

                                              <Col>
                                                <div
                                                  style={{
                                                    fontSize: "16px",
                                                    textAlign: "justify",
                                                  }}
                                                >
                                                  <ReactReadMoreReadLess
                                                    readMoreClassName="readmore"
                                                    charLimit={200}
                                                    readMoreText={"Read more ▼"}
                                                    readLessClassName="readless"
                                                    readLessText={"Read less ▲"}
                                                  >
                                                    {data.reply}
                                                  </ReactReadMoreReadLess>
                                                </div>
                                                <Col lg="12">
                                                  <h4 className="fs-14 font-w500 mb-3 mt-3">
                                                    URL -{" "}
                                                    <span className="">
                                                      <a
                                                        style={{
                                                          textDecoration:
                                                            "underline",
                                                          color: "#007bff",
                                                        }}
                                                        className="fs-16"
                                                        target="_blank"
                                                        href={data.url}
                                                      >
                                                        {" "}
                                                        {data.url}
                                                      </a>{" "}
                                                    </span>
                                                  </h4>
                                                </Col>
                                                <Row>
                                                  <Col lg="6">
                                                    <h5 className="fs-16 font-w600 mb-3">
                                                      {" "}
                                                      <span className="text-dark">
                                                        <FaReply
                                                          size={18}
                                                          color="#57DB7A"
                                                        />{" "}
                                                      </span>{" "}
                                                      {data?.repliedBy}
                                                    </h5>
                                                  </Col>
                                                  <Col lg="6">
                                                    <h5 className="fs-16 font-w500 mb-3">
                                                      <span className="text-dark">
                                                        <FcCalendar size={22} />{" "}
                                                      </span>{" "}
                                                      -{" "}
                                                      {new Date(
                                                        data.createdAt
                                                      ).toLocaleDateString(
                                                        "en-GB",
                                                        {
                                                          day: "numeric",
                                                          month: "long",
                                                          year: "numeric",
                                                        }
                                                      )}
                                                    </h5>
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </div>
                                          ))}
                                        </>
                                      )}
                                    </div>

                                    <div className="modal-footer"></div>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                          <hr
                            style={{ marginTop: "5px", marginBottom: "10px" }}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-xxl-12">
                  <div className="card-body responsive">
                    <ReactPaginate
                      previousLabel={
                        <BsCaretLeftFill style={{ fontSize: 18, width: 20 }} />
                      }
                      nextLabel={
                        <BsCaretRightFill style={{ fontSize: 18, width: 20 }} />
                      }
                      breakLabel={"..."}
                      marginPagesDisplayed={2}
                      // pageCount={pageCount}
                      pageRangeDisplayed={3}
                      pageCount={Math.ceil(postData.length / postsPerPage)}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      //className={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}




    </>
  );
};
export default ContactUs;