import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function Prototype() {
  const location = useLocation();
  const isAssignInductionsRoute = location.pathname === "/assign-inductions";
  const isinductionaccesscontrol =
    location.pathname === "/induction-access-control";
  const isinductions = location.pathname === "/inductions";
  const isInductionUSer = location.pathname === "/assign-induction-users";
  const isTestAccessControl = location.pathname === "/admin-test-dashboard";

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const renderHeader = () => {
    return (
      <>
        <div
          style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
        >
          <span className="p-input-icon-left">
            <i className="fa fa-search" />
            <InputText placeholder="Keyword Search" />
          </span>
        </div>
      </>
    );
  };
  const header = renderHeader();

  const items = Array.from({ length: 6 }, (v, i) => i);
  const bodyTemplate = () => {
    return <Skeleton length={10}></Skeleton>;
  };

  const circle = () => {
    return (
      <>
        <div className="d-flex">
          <Skeleton size="2rem" className="mr-2"></Skeleton>
        </div>
      </>
    );
  };

  const name = () => {
    return (
      <>
        <div className="w-full md:w-5 p-2">
          <Skeleton
            width="4rem"
            height="2rem"
            borderRadius="16px"
            className="mb-2"
          ></Skeleton>
        </div>
      </>
    );
  };

  const action = () => {
    return (
      <>
        <div className="d-flex w-full md:w-3 p-2">
          <Skeleton
            shape="circle"
            width="3rem"
            height="3rem"
            borderRadius="16px"
            className="mb-2 "
          ></Skeleton>
          <Skeleton
            shape="circle"
            width="3rem"
            height="3rem"
            borderRadius="16px"
            className="mb-2 mx-2 "
          ></Skeleton>
          <Skeleton
            shape="circle"
            width="3rem"
            height="3rem"
            borderRadius="16px"
            className="mb-2 mx-2 "
          ></Skeleton>
        </div>
      </>
    );
  };

  let columns = [
    <Column
      header="Sr.No"
      style={{ width: "5%" }}
      body={bodyTemplate}
    ></Column>,
    <Column
      header="INDUCTION"
      style={{ width: "40%" }}
      body={bodyTemplate}
    ></Column>,
    <Column
      header="CREATED ON"
      style={{ width: "20%" }}
      body={bodyTemplate}
    ></Column>,
    <Column
      header="CREATED BY"
      style={{ width: "20%" }}
      body={bodyTemplate}
    ></Column>,
  ];

  if (isTestAccessControl) {
    const newColumns = [
      <Column
        header="Sr.No"
        style={{ width: "5%" }}
        body={bodyTemplate}
      ></Column>,
      <Column
        header="TEST TITLE"
        style={{ width: "40%" }}
        body={bodyTemplate}
      ></Column>,
      <Column
        header="SUB-TITLE"
        style={{ width: "20%" }}
        body={bodyTemplate}
      ></Column>,
      <Column
        header="CREATED ON"
        style={{ width: "20%" }}
        body={bodyTemplate}
      ></Column>,
      <Column
        header="ROLE"
        style={{ width: "20%" }}
        body={bodyTemplate}
      ></Column>,
      <Column
        header="CREATED BY"
        style={{ width: "20%" }}
        body={bodyTemplate}
      ></Column>,
      <Column
      key="selectColumn"
      header="ACTIONS"
      body={circle}
    />
    ];

    columns = newColumns;
  }

  if (isAssignInductionsRoute) {
    columns.push(
      <Column
        key="selectColumn"
        header="SELECT"
        body={circle}
      />
    );
  } else if (isinductionaccesscontrol) {
    columns.push(
      <Column
        key="permissionColumn"
        header="PERMISSION"
        body={name}
      />
    );
  } else if (isinductions) {
    columns.push(
      <Column
        key="actionColumn"
        header="ACTIONS"
        body={action}
      />
    );
  } else if (isInductionUSer) {
    columns.push(
      <Column
        key="selectColumn"
        header="SELECT"
        body={circle}
      />
    );
  }

  return (
    <div className="col-xl-12">
      <div className="table-responsive">
        <DataTable
          size="large"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          stripedRows
          showGridlines
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          value={items}
          className="p-datatable-striped"
        >
          {columns}
        </DataTable>
      </div>
    </div>
  );
}

export default Prototype;
