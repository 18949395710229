import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FaSearch, FaTimes } from "react-icons/fa";

const FilterComponent = ({ onFilter, onClear, filterText }) => {
  return (
    <>
      <div className="col-xl-5 tableHeading">
        {/* <span className="fs-28 font-w900" style={{ color: "#3A3630" }}>Attempted Inductions</span> */}
        </div>

      <div className="d-flex align-items-end mt-3 col-xl-3">
        <InputGroup className="mb-2 mt-6">
          <FormControl
            type="text"
            placeholder="Search..."
            value={filterText}
            onChange={onFilter}
            className="searchBar"
          />
          <Button
            variant="outline-light"
            onClick={onClear}
            className="searchIcon"
          >
            {filterText ? <FaTimes /> : <FaSearch />}
          </Button>
        </InputGroup>
      </div>
    </>
  );
};

export default FilterComponent;
