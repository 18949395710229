import { IconButton } from "@material-ui/core";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from "react-router-dom";
import { logout } from "../../store/actions/AuthActions";
import { isAuthenticated } from '../../store/selectors/AuthSelectors';


const EndUserNavbar = ({history}) => {
  const navigate = useHistory();
  const dispatch = useDispatch();

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    localStorage.removeItem("userDetails");
    dispatch(logout(history));
    // window.location.reload();
  }

  return (
    <div>

      <AppBar style={{ backgroundColor: "whitesmoke" }}>
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              className="headingLogin"
              href="#app-bar-with-responsive-menu"
              sx={{
                // display: { xs: 'none', md: 'flex' },
                fontFamily: "poppins",
                fontWeight: 600,
                letterSpacing: "1px",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {" "}
              <span style={{ color: "#FEBA30", marginRight: "10px" }}>
                MASS
              </span>
              <span style={{ color: "#3A3630" }}>TEST</span>
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", justifyContent: "center" },
              }}
            ></Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex", justifyContent: "end" },
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}></div>
              {/* <SideProfile/> */}
            </Box>
            {auth && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle style={{ color: "#3a3630" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={onLogout} onChange={handleClose}>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(EndUserNavbar));
