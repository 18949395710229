import React, { useState, useEffect } from "react";
import { API_ROOT_URL } from "../../jsx/constants";
import { useSelector } from "react-redux";

const InstructorDropdown = ({ deptID, compId }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState();
  const token = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        let getInstructors;
        getInstructors = `${API_ROOT_URL}/instructor/getdropdown?deptID=${deptID}&companyId=${compId}`;
        const response = await fetch(getInstructors, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data?.status === true) {
            const topicOptions = data.data.map((row) => (
              <option key={row.value} value={row.value}>
                {row.label.charAt(0).toUpperCase() +
                  row.label.slice(1).toLowerCase()}
              </option>
            ));
            setOptions(topicOptions);
            setLoading(false);
          } else {
            setLoading(false);
            setOptions("");
          }
        } else {
          setLoading(false);
          setOptions("");
        }
      } catch (error) {
        setLoading(false);
        setOptions("");
      }
    };
    setLoading(true);
    fetchTopics();
  }, [deptID]);

  if (loading) {
    return <option>Loading...</option>;
  }

  return <>{options}</>;
};

export default InstructorDropdown;
