import React, { createContext, useContext, useState } from 'react';

const ProfilePictureContext = createContext();

export const useProfilePicture = () => useContext(ProfilePictureContext);

export const ProfilePictureProvider = ({ children }) => {
    const [profilePicture, setProfilePicture] = useState('dummy-user.png');

    return (
        <ProfilePictureContext.Provider value={{ profilePicture, setProfilePicture }}>
            {children}
        </ProfilePictureContext.Provider>
    );
};
