import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BJSLogo from "../../images/BJSLogo.png";
import { useSelector } from "react-redux";
import {
  loadingToggleAction,
  signupAction,
} from "../../store/actions/AuthActions";

import Swal from "sweetalert2";
import swal from "sweetalert";
// image
import { Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DesktopTruck from "../../images/DesktopBackgrounds_Haulage.jpg";

function Register(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [role, setRole] = useState("user");
  const [state, setState] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    setValue
  } = useForm();
  const formStates = watch();

  const dispatch = useDispatch();
  useEffect(() => {
  }, [])
  const { email, password, firstName, lastName, confirmpassword } = formStates;

  //Function to submit the Registration page fields
  const onSubmit = async () => {
    try {
      const data = new FormData();
      data.append("firstName", firstName);
      data.append("lastName", lastName);
      data.append("email", email);
      data.append("password", password);

      dispatch(loadingToggleAction(true));
      const message = dispatch(
        signupAction(firstName, lastName, email, password, role, props.history)
      );
    } catch (error) {
      return swal("Failed", error, "error");
    }
  }

  useEffect(() => {
setValue("")
  }, [props.successMessage])

  //Password eye visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmpasswordVisible);
  };

  //Password requirements popover
  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom" className="popoverSection">
      Password must contain{" "}
      <strong>
        {" "}
        upper case, lower case, special char and minimum of 6 characters
      </strong>
    </Popover>
  );

  return (
    <div
      className="authincation h-100 p-meddle"
      style={{
        backgroundImage: "url(" + DesktopTruck + ")",
        backgroundSize: "cover",
      }}
    >
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-2 mt-0">
                      <img width="600" src={BJSLogo} alt="BJS" />
                    </div>
                    <Row>
                      <span
                        className="fs-24 font-w900 text-center"
                        style={{ color: "#3A3630" }}
                      >
                        Create an{" "}
                        <span
                          className="fs-26 font-w900 text-center"
                          style={{ color: "#FEBA30" }}
                        >
                          Account
                        </span>
                      </span>
                    </Row>
                    {/* {props.errorMessage && (
                      <div className="">{props.errorMessage}</div>
                    )}
                    {props.successMessage && (
                      <div className="">{props.successMessage}</div>
                    )} */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>First Name</strong>
                          <span
                            className="required-field"
                            style={{ color: "red", fontWeight: "200px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          value={firstName}
                          placeholder="First Name"
                          className="form-control"
                          {...register("firstName", {
                            required: true,
                            pattern: /^[A-Z a-z\s]*$/, // Allow alphabets and spaces
                          })}
                        />
                        {errors.firstName && errors.firstName.type === "required" && (
                          <p style={{ color: "red" }}>This field is required</p>
                        )}
                        {errors.firstName && errors.firstName.type === "pattern" && (
                          <p style={{ color: "red" }}>Only alphabets are allowed</p>
                        )}
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Last Name</strong>
                        </label>
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                        <input
                          type="text"
                          value={lastName}
                          placeholder="Last Name"
                          className="form-control"
                          {...register("lastName", {
                            required: true,
                            pattern: /^[A-Z a-z\s]*$/, // Allow alphabets and spaces
                          })}
                        />
                        {errors.lastName && errors.lastName.type === "required" && (
                          <p style={{ color: "red" }}>This field is required</p>
                        )}
                        {errors.lastName && errors.lastName.type === "pattern" && (
                          <p style={{ color: "red" }}>Only alphabets are allowed</p>
                        )}
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                          <span
                            className="required-field"
                            style={{ color: "red", fontWeight: "200px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="email"
                          value={email}
                          placeholder="Email"
                          className="form-control"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                        {errors.email && (
                          <p style={{ color: "red" }}>
                            Please Enter a valid Email
                          </p>
                        )}
                      </div>

                      <label className="mb-1 ">
                        <strong>Password</strong>
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="form-control d-flex justify-content-end mb-1">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          value={password}
                          placeholder="Password"
                          className="input-group"
                          autoComplete="new-password"
                          style={{ border: "none" }}
                          {...register("password", {
                            required: true,
                            pattern:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,10})/,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span className="" onClick={togglePasswordVisibility}>
                          {passwordVisible ? (
                            <FaEye size={20} />
                          ) : (
                            <FaEyeSlash size={20} />
                          )}
                        </span>
                      </div>
                      {errors.password && (
                        <p style={{ color: "red" }}>
                          Please enter a valid password
                          <span>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="bottom"
                              overlay={popoverBottom}
                            >
                              <div className="icon mx-2">
                                <i className="fa fa-info" style={{ fontSize: "10px", paddingLeft: "40%", paddingTop: "15%" }} />
                              </div>
                            </OverlayTrigger>
                          </span>
                        </p>
                      )}

                      <label className="mb-1 mt-2 ">
                        <strong>Confirm Password</strong>
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="form-control d-flex justify-content-end">
                        <input
                          type={confirmpasswordVisible ? "text" : "password"}
                          className="input-group"
                          placeholder="Confirm Password"
                          style={{ border: "none" }}
                          {...register("confirmpassword", {
                            required: true,
                            validate: (val) => {
                              // Retrieve the password from the form using the 'watch' function
                              const password = watch('password');

                              // Check if the confirm password field is empty
                              if (!val) {
                                return "Please confirm your password";
                              }
                              // Check if the confirm password matches the original password
                              if (val !== password) {
                                return "Your passwords do not match";
                              }
                            }
                          })
                          }
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span
                          className=""
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {confirmpasswordVisible ? (
                            <FaEye size={20} />
                          ) : (
                            <FaEyeSlash size={20} />
                          )}
                        </span>
                      </div>
                      {errors.confirmpassword && (
                        <p style={{ color: "red" }}>

                          {
                            confirmpassword === "" ? (
                              <p style={{ color: "red" }}>
                                Re-enter the Password
                              </p>
                            ) : confirmpassword !== password ? (
                              "Password Not Matched"
                            ) : (
                              ""
                            )
                          }
                        </p>
                      )}

                      <div className="text-center mt-4 col-xl-12">
                        <button type="submit" className="signupbutton">
                          Sign up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link
                          className="signup"
                          to="/login"
                        >
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
