import React, { Fragment, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsXCircleFill } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "semantic-ui-react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import { useDispatch } from "react-redux";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { logout } from "../../../store/actions/AuthActions";

const AddCompany = (props) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const token = useSelector((state) => state.auth.auth.token);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [items, setItems] = useState([]);
  const [showClearButton, setShowClearButton] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const formStates = watch();

  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  };
  const handleClearButtonClick = () => {
    setImage({ preview: "", data: "" });
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };

  const tokenDetailsString = localStorage.getItem('userDetails');

  const onSubmit = async () => {
    const { email, password, name, address, companyID, aboutCompany } = formStates;
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("password", password);
    data.append("companyID", companyID);
    data.append("logo", image.data);
    data.append("address", address);
    data.append("aboutCompany", aboutCompany);

    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(()=>{
        dispatch(logout(props.history));
      })
    } else {
    const response = await fetch(`${API_ROOT_URL}/company/add`, {
      method: "POST",
      headers: {
        "x-access-token": token,
      },
      body: data,
    }).then((data) => data.json());

    if ("status" in response && response.status == true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        navigate.push("/companies");
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  }
  };

  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(items));
  }, [errors, items,token,tokenDetailsString]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom" className="popoverSection">
      Password must contain{" "}
      <strong>
        {" "}
        upper case, lower case, special char and minimum of 6 characters
      </strong>
    </Popover>
  );

  return (
    <>
      <Fragment>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="cardheader">
              <h2
                className="text-white"
                style={{ fontSize: "25px", fontWeight: "900px" }}
              >
                <AiOutlineAppstoreAdd color="#E7EEF0" />
                <span className="font-w700"> ADD </span>
                <span
                  className="fs-25 font-w700 text-center"
                  style={{ color: "#FEBA30" }}
                >
                  {" "}
                  COMPANY
                </span>
              </h2>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                  encType="multipart/form-data"
                >
                  <Form.Field>
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label fw-600">
                        Email{" "}
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-sm-9">
                        <input
                          name="email"
                          className="form-control"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                        {errors.email && (
                          <p style={{ color: "red" }}>
                            Enter a valid Email
                          </p>
                        )}
                      </div>
                    </div>
                  </Form.Field>

                  <div className="mb-3 mt-3 row">
                    <label className="col-sm-3 col-form-label">
                      Password{" "}
                      <span className="required-field" style={{ color: "red" }}>
                        *
                      </span>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="bottom"
                        overlay={popoverBottom}
                      >
                        <div className="icon mx-2">
                          <i
                            className="fa fa-info mx-2"
                            style={{ fontSize: "10px" }}
                          />
                        </div>
                      </OverlayTrigger>
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          name="name"
                          className="form-control"
                          autoComplete="new-password"
                          {...register("password", {
                            required: true,
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,10})/,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span className="input-group-text" onClick={togglePasswordVisibility}>
                          {passwordVisible ? (
                            <FaEye size={20} />
                          ) : (
                            <FaEyeSlash size={20} />
                          )}
                        </span>
                      </div>
                      {errors.password && (
                        <p style={{ color: "red" }}>
                          Please enter a valid password
                        </p>
                      )}
                    </div>
                  </div>


                  <div className="mb-3 mt-3 row">
                    <label className="col-sm-3 col-form-label">
                      Company Name{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors.name && (
                        <p style={{ color: "red" }}>
                          Company Name is required !
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Slug{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="companyID"
                        {...register("companyID", {
                          required: true,
                        })}
                      />
                      {errors.companyID && (
                        <p style={{ color: "red" }}>Slug is Required !</p>
                      )}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Logo{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control"
                          name="logo"
                          onChange={handleFileChange}
                          accept="image/png,image/jpeg,image/jpg"
                        />
                        {image && (
                          <div style={{ marginTop: 10 }}>
                            <img src={image.preview} width={150} />
                            {showClearButton && (
                              <BsXCircleFill
                                size={25}
                                style={{ marginBottom: "160px", color: "red", cursor: "pointer" }}
                                onClick={handleClearButtonClick}
                              />
                            )}
                          </div>
                        )}
                        {errors.logo && (
                          <div
                            style={{
                              color: "red",
                              fontWeight: 600,
                              padding: 5,
                            }}
                          >
                            {errors.logo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Address{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="address"
                        placeholder=""
                        {...register("address", {
                          required: true,
                        })}
                      ></textarea>
                      {errors.address && (
                        <p style={{ color: "red" }}>Address is required !</p>
                      )}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      About Company
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="aboutCompany"
                        placeholder=""
                        {...register("aboutCompany", {
                        })}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mb-12 row">
                    <div className="col-sm-12">
                      <button
                        className="submitButtonInduction"
                        type="submit"
                        style={{
                          margin: "auto",
                          display: "flex",
                          float: "right",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCompany;
