import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import { BsXCircleFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { useForm } from "react-hook-form";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { UpdateProfileRedux } from "../../../store/actions/ProfileActions";
import s3 from "../../../utilis/aws.config";

const UpdateProfile = ({ history, isModalOpen, trackOnclick, profileData }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const token = useSelector((state) => state.auth.auth.token);

  const [email, setEmail] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const [showClearButton, setShowClearButton] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false)
  // Create a state variable to track changes in the form
  const [formChanged, setFormChanged] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oneNameError, setOneNameError] = useState(''); // For first name
  const [twoNameError, setTwoNameError] = useState(''); // For last name

  useEffect(() => {
    setEmail(profileData?.profile?.email);
    setAboutMe(profileData?.profile?.aboutMe);
    setImage({ preview: "", data: "" });
    setPreview(profileData?.profile?.profilePhoto);
    dispatch(UpdateProfileRedux(profileData?.profile?.profilePhoto))
  }, [profileData]);

  const handleFirstNameChange = (enteredOneName) => {
    setFirstName(enteredOneName);
    setFormChanged(true);

    if (enteredOneName.trim() === '') {
      setOneNameError("This field can't be blank");
      setFormChanged(false);
    } else if (!/^[a-zA-Z]+$/.test(enteredOneName)) {
      setOneNameError("Only alphabets are allowed");
      setFormChanged(false);
    } else {
      setOneNameError('');
      setFormChanged(true);
    }
  };

  const AboutMeChange = (enteredAbout) => {
    setAboutMe(enteredAbout);
    setFormChanged(true);
  }


  const handleLastNameChange = (enteredTwoName) => {
    setLastName(enteredTwoName);
    setFormChanged(true);

    if (enteredTwoName.trim() === '') {
      setTwoNameError("This field can't be blank");
      setFormChanged(false);
    }
    else if (!/^[a-z A-Z]+$/.test(enteredTwoName)) {
      setTwoNameError("Only alphabets are allowed");
      setFormChanged(false);
    }
    else {
      setTwoNameError('');
      setFormChanged(true);
    }
  };


  const handleCallback = () => {
    trackOnclick(false);
  };

  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
      setFormChanged(true)
    } else {
      setShowClearButton(false);
      setFormChanged(false)
    }
  };
  const handleClearButtonClick = () => {
    setImage(false);
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (oneNameError || twoNameError) {
      return;
    }
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("aboutMe", aboutMe);
    formData.append("image", image.data);
    formData.append("profilePhoto", preview);

    const response = await fetch(`${API_ROOT_URL}/users/update`, {
      method: "PUT",
      body: formData,
      headers: {
        "x-access-token": token,
      },
    }).then((data) => data.json());

    if ("status" in response && response.status == true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        handleCallback();
        //profile
        navigate.push("/profile");
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }

  };

  useEffect(() => {
    setFirstName(profileData?.profile?.first_name || "");
    setLastName(profileData?.profile?.last_name || "");
    setEmail(profileData?.profile?.email || "");
    setAboutMe(profileData?.profile?.aboutMe || "");
    setImage({ preview: "", data: "" });
    setPreview(profileData?.profile?.profilePhoto || "");
    setFormChanged(false); // Reset the formChanged state when the data is loaded
    setOneNameError('');
    setTwoNameError('');
  }, [profileData]);

  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${preview}`,
  });

  return (
    <Modal className="modal fade d-flex" show={isModalOpen}>
      <div className="modal-content" style={{ width: "60vw" }}>
        <div className="modal-header">
          <h5 className="modal-title">UPDATE PROFILE</h5>
          {/* <Button
            variant=""
            type="button"
            className="btn-close"
            data-dismiss="modal"
            onClick={handleCallback}
          ></Button> */}
          <RxCross1 size={25} color="white" onClick={handleCallback} />
        </div>
        <div className="modal-body">
          <form className="update-form" onSubmit={handleSubmit} encType>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">First Name
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={firstName}
                    onChange={(e) => handleFirstNameChange(e.target.value)}
                  />
                  {oneNameError && <p style={{ color: 'red' }}>{oneNameError}</p>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">Last Name
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={lastName}
                    onChange={(e) => handleLastNameChange(e.target.value)}
                  />
                  {twoNameError && <p style={{ color: 'red' }}>{twoNameError}</p>}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">Profile Picture</label>
                  <input type="hidden" value={preview} name="profilePhoto" />
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="form-label fs-6 fw-bold">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                    style={{ backgroundColor: "lightgray" }}
                  />
                </div>
              </div>

              {image.data ?
                <>
                  <div className="col-lg-6">
                    <div className="instructors-media">
                      {image && (
                        <div style={{ marginTop: 10 }}>
                          <img src={image.preview} width={150} />
                          {showClearButton && (
                            <BsXCircleFill
                              size={25}
                              style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <div className="instructors-media">
                        <img
                          src={url}
                          className="img-thumbnail mb-3"
                          width={150}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="aboutMe" className="text-black font-w600">
                    About me
                  </label>
                  <textarea
                    rows={3}
                    className="form-control"
                    name="aboutMe"
                    placeholder=""
                    defaultValue={""}
                    value={aboutMe}
                    onChange={(e) => AboutMeChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <input
                    type="submit"
                    value="Update Profile"
                    className={`submits ${formChanged ? 'enabled' : ''}`}
                    style={{ margin: "auto", display: "flex" }}
                    name="submit"
                    disabled={!formChanged}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateProfile;
