import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import { BsXCircleFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { UpdateProfileRedux } from "../../../store/actions/ProfileActions";
import s3 from "../../../utilis/aws.config";

const UpdateDepartmentProfile = ({ history, isModalOpen, trackOnclick, departmentData }) => {
    //console.log(departmentData, "datatata")
    const dispatch = useDispatch();
    const navigate = useHistory();
    const token = useSelector((state) => state.auth.auth.token);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [aboutDepartment, setAboutDepartment] = useState();
    const [image, setImage] = useState({ preview: "", data: "" });
    const [preview, setPreview] = useState("");
    const [showClearButton, setShowClearButton] = useState("");
    const [editID, setEditID] = useState("");
    const [nameError, setNameError] = useState('');
    const [deptAboutError, setDeptAboutError] = useState('');
    // Create a state variable to track if any field is changed
    const [isFormChanged, setFormChanged] = useState(false);

    const handleCallback = () => {
        trackOnclick(false);
    };

    const handleNameChange = (enteredName) => {
        setName(enteredName);
        setFormChanged(true);
        if (enteredName.trim() === '') {
            setNameError("Name field can't be blank");
            setFormChanged(false); // Set form changed to false if the name is empty
        } else {
            setNameError('');
            setFormChanged(true);
        }
    };

    const handleAboutChange = (enteredAbout) => {
        setAboutDepartment(enteredAbout);
        setFormChanged(true);
        if (enteredAbout.trim() === '') {
            setDeptAboutError("About can't be blank");
            setFormChanged(false); // Set form changed to false if the name is empty
        } else {
            setDeptAboutError('');
            setFormChanged(true);

        }
    };


    const handleFileChange = async (e) => {
        const image = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        };
        setImage(image);
        if (e.target.value) {
            setShowClearButton(true);
        } else {
            setShowClearButton(false);
        }
        setFormChanged(true); // Set formChanged to true when any field is changed

    };
    const handleClearButtonClick = () => {
        setImage(false);
        setShowClearButton(false);
        const inputElement = document.querySelector('input[type="file"]');
        inputElement.value = "";
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (nameError || deptAboutError) {
            // Prevent form submission if there's an error
            return;
        }
        let formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("aboutDepartment", aboutDepartment);
        formData.append("profile_picture", image.data);
        formData.append("profilePhoto", preview);

        const response = await fetch(`${API_ROOT_URL}/department/edit/${editID}`, {
            method: "PUT",
            headers: {
                "x-access-token": token,
            },
            body: formData,
        }).then((data) => data.json());

        if ("status" in response && response.status == true) {
            return swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
            }).then((value) => {
                handleCallback();
                //profile
                navigate.push("/department-profile");
            });
        } else {
            if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
                swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                    dispatch(logout(history));
                });
            }
            else if (response?.devMessage === "userDeleted") {
                Swal.fire({
                    title: "Access Denied",
                    text: "Your Account Has been deleted",
                    icon: "error",
                    footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
                }).then(() => {
                    dispatch(logout(history));
                });
            } else {
                return swal("Failed", response?.message, "error");
            }
        }
    };

    // re-render page on every update
    useEffect(() => {
        if (departmentData && departmentData) {
            setEditID(departmentData?.profile?._id);
            setName(departmentData?.profile?.name);
            setEmail(departmentData?.email);
            setAboutDepartment(departmentData?.profile?.aboutDepartment);
            setImage({ preview: "", data: "" });
            setPreview(departmentData?.profile?.profile_picture);
            dispatch(UpdateProfileRedux(departmentData?.profile?.profile_picture))
            setFormChanged(false); // Reset the formChanged state when the data is loaded
            setNameError('');
        }
    }, [departmentData]);

    // Get the signed URL for the profile image
    const url = s3.getSignedUrl("getObject", {
        Bucket: AWS_BUCKET_NAME,
        Key: `${PROFILE_ASSETS_URL}/${preview}`,
    });

    return (
        <Modal className="modal fade d-flex" show={isModalOpen}>
            <div className="modal-content" style={{ width: "60vw" }}>
                <div className="modal-header">
                    <h5 className="modal-title">UPDATE PROFILE</h5>
                    <RxCross1 size={25} color="white" onClick={handleCallback} />
                </div>

                <div className="modal-body">
                    <form className="update-form" onSubmit={handleSubmit} encType>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="form-label fs-6 fw-bold">Name
                                        <span
                                            className="required-field"
                                            style={{ color: "red", fontWeight: "200px" }}
                                        >
                                            *
                                        </span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        placeholder=""
                                        value={name}
                                        onChange={(e) => handleNameChange(e.target.value)}

                                    />
                                    {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="form-label fs-6 fw-bold">Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled
                                        style={{ backgroundColor: "lightgray" }}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label className="form-label fs-6 fw-bold">Profile Picture</label>
                                    <input type="hidden" value={preview} name="profilePhoto" />
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="profile_picture"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                            {image.data ?
                                <>
                                    <div className="col-lg-6">
                                        <div className="instructors-media">
                                            <div style={{ marginTop: 10 }}>
                                                <img src={image.preview} width={150} />
                                                {showClearButton && (
                                                    <BsXCircleFill
                                                        size={25}
                                                        style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                                                        onClick={handleClearButtonClick}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <div className="instructors-media">
                                                <img
                                                    src={url}
                                                    className="img-thumbnail mb-3"
                                                    width={150}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label className="form-label fs-6 fw-bold">About
                                        <span
                                            className="required-field"
                                            style={{ color: "red", fontWeight: "200px" }}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        name="aboutMe"
                                        placeholder=""
                                        defaultValue={""}
                                        value={aboutDepartment}
                                        onChange={(e) => handleAboutChange(e.target.value)}
                                    />
                                    {deptAboutError && <p style={{ color: 'red' }}>{deptAboutError}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <input
                                        type="submit"
                                        value="Update Profile"
                                        className={`submits ${isFormChanged ? 'enabled' : ''}`}
                                        style={{ margin: "auto", display: "flex" }}
                                        name="submit"
                                        disabled={!isFormChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateDepartmentProfile;
