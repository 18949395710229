import React, { useState } from "react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useEffect } from "react";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { API_ROOT_URL, PROFILE_ASSETS_URL } from "../../constants";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditEvent from "./EditEvent";
import { FcNext } from "react-icons/fc";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department"
};

function Calendar(props) {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.auth.auth.id);
  const role = useSelector((state) => state.auth.auth.role);
  const token = useSelector((state) => state.auth.auth.token);
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCompDeleted, setIsCompDeleted] = useState(false);
  const [editID, setEditID] = useState();
  const [isShowAddQuestion, setIsShowAddQuestion] = useState(false);
  const [isDeleteEvent, setIsDeleteEvent] = useState(false);
  // api call

  async function getEvents(token) {
    return fetch(`${API_ROOT_URL}/users/getting/events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const handlepageLoadEvent = async (event) => {
    const response = await getEvents(token);
    if ("status" in response && response.status == true) {
      setEventData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  //To get data from last 5 inductions
  const handlepageLoad = async (e) => {
    const response = await fetch(`${API_ROOT_URL}/induction/index/work`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status == true) {
      setLoading(false);
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  // // Add question Modal POPUP
  const onClickHandler = () => {
    setIsShowAddQuestion(false);
    // setLoading(true);
  };

  const eventHandler = (input) => {
    const {
      event: {
        _def: { extendedProps },
      },
    } = input;
    const { _id: eventId } = extendedProps;
    if (isDeleteEvent) {
      trackDeleteClick(eventId);
      setIsDeleteEvent(false);
    } else {
      setEditID(eventId);
    }
  };

  // callback function to delete record
  const trackDeleteClick = (editID) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        const response = await fetch(
          `${API_ROOT_URL}/instructor/deleteEvent/${editID}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
          swal("Poof! Your record has been deleted!", {
            icon: "success",
          }).then(() => {
            handlepageLoadEvent();
            setIsCompDeleted(!isCompDeleted);
          });
        } else {
          return swal("Failed", response.message, "error");
        }
      } else {
        swal("Your record is safe!");
      }
    });
  };

  // use effect re rendering hook
  useEffect(() => {
    handlepageLoad();
    handlepageLoadEvent();
    getEvents(token);
  }, [loading, isShowAddQuestion, editID]);

  // Render event popover
  const renderEventPopover = (event) => {
    if (role === USER_ROLES.INSTRUCTOR) {
      return (
        <Popover id="popover-basic" style={{ border: "2px solid #6e6e6e" }}>
          <Popover.Header
            as="h2"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              backgroundColor: "#6e6e6e",
              fontFamily: "sans-serif"
            }}
          >
            {event.inductionName}
          </Popover.Header>
          <Popover.Body>
            <span style={{ fontSize: "13px", fontFamily: "sans-serif", fontWeight: "bold", textDecoration: "underline", color: "#feba30", fontStyle: "italic", letterSpacing: "1px" }}>Description</span>
            <div style={{ textAlign: "justify" }}>
              {event.description}
            </div>
            <ul className="mt-2 mb-2">
              <span style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold", textDecoration: "underline", color: "#feba30", fontStyle: "italic" }}>Assigned Users</span>
              {event.users.map((i) => (
                <li key={i._id}><FcNext />{" "}{i.full_name}</li>
              ))}
            </ul>
          </Popover.Body>
        </Popover>
      );
    } else {
      return (
        <Popover id="popover-basic" style={{ border: "2px solid #6e6e6e" }}>
          <Popover.Header
            as="h2"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              backgroundColor: "#6e6e6e",
              fontFamily: "sans-serif"
            }}
          >
            {event.inductionName}
          </Popover.Header>
          <Popover.Body>
            <span style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold", textDecoration: "underline", color: "#feba30", fontStyle: "italic", letterSpacing: "1px" }}>Description</span>
            <div style={{ textAlign: "justify" }}>
              {event.description}
            </div>
          </Popover.Body>
        </Popover>
      );
    }


  };

  return (
    <div>
      <Fullcalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={"dayGridMonth"}
        headerToolbar={{
          start: "today",
          center: "title",
          end: "dayGridMonth prev,next",
        }}
        height={"40vh"}
        // events={events}
        events={eventData.map((event) => ({
          ...event,
          popover: renderEventPopover(event), // Pass popover to events
        }))}
        eventColor="#F08080"
        eventContent={(eventInfo) => (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="auto"
            overlay={eventInfo.event.extendedProps.popover}
          >
            <div
              className="text-bold "
              style={{
                fontSize: "14px",
                paddingLeft: "10px",
                fontWeight: "600",
                color: "#222021"
              }}
            >
              {eventInfo.event.title} {""}{" "}
              {USER_ROLES.INSTRUCTOR === role ? (
                <>
                  <span style={{ display: "flex", float: "left" }}>
                    <button
                      onClick={(e) => setIsShowAddQuestion(true)}
                      className="btn btn-transparent btn-xs sharp"
                    >
                      {" "}
                      <i
                        className="fas fa-pencil-alt"
                        style={{ color: "black" }}
                      ></i>
                    </button>

                    {/* <div className={`badge light ${(eventInfo.event.priority)? 'badge-secondary': 'badge-danger'}`} >
                       { (eventInfo.event.priority) ? 'high' : 'low'}</div> */}

                    <EditEvent
                      isShowAddQuestion={isShowAddQuestion}
                      onClickHandler={onClickHandler}
                      eventData={eventData}
                      editId={editID}

                    // trackOnclick={trackOnclick}
                    />
                  </span>
                  <span style={{ display: "flex", float: "right" }}>
                    <button
                      onClick={(e) => setIsDeleteEvent(true)}
                      className="btn btn-transparent btn-xs sharp"
                    >
                      {" "}
                      <i className="fa fa-trash" style={{ color: "black" }}></i>
                    </button>
                  </span>
                </>
              ) : null}
            </div>
          </OverlayTrigger>
        )}
        eventClick={eventHandler}
      />
    </div>
  );
}

export default Calendar;
