/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccordionActions from "@material-ui/core/AccordionActions";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { v4 as uuidv4 } from "uuid";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import TimerIcon from "@mui/icons-material/Timer";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { API_ROOT_URL } from "../../jsx/constants";
import { useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { LuTimer } from "react-icons/lu";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { RiPlayListAddLine } from "react-icons/ri";
import { useEffect } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import useDashboardUrl from "../../utilis/useDashboardUrl";
import Swal from "sweetalert2";
import { BsXCircleFill } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 128,
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
  },
  title: {
    marginTop: 0,
    flexGrow: 1,
    alignSelf: "flex-end",
    justifySelf: "center",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    alignContent: "space-between",
    alignItems: "center",
  },
  redAsterisk: {
    color: "red",
  },
}));

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

function EditForm(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const [formDeatils, setFormDetails] = React.useState({});

  //QuestionsTab states
  const navigate = useHistory();
  //token
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const [questions, setQuestions] = React.useState([]);
  const [test_name, setTestName] = useState("");
  const [form_description, setFormDescription] = useState("");
  const [quizTimer, setQuizTimer] = useState("00:00");
  const [showClearButton, setShowClearButton] = useState(false);
  const [sections, setMultipleSections] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [duplicateOptions, setDuplicateOptions] = useState([]);
  const dashboardUrl = useDashboardUrl(role);
  const handleCloseQues = () => {
    setAnchorEl(null);
  };

  //gernerating random numbers for image id
  const generateImageId = () => {
    return uuidv4();
  };
  //for handling image upload
  const [fileInputKey, setFileInputKey] = useState(0);

  // handling image data
  const handleFileChange = (e, sectionIndex, questionIndex) => {
    const file = e.target.files[0];

    const imageId = generateImageId();
    const gettingExt = file.name.slice(file.name.indexOf(".") + 1);
    const question_image_uuid = imageId + "." + gettingExt;
    const image_uuid = imageId;

    if (file) {
      let newSections;
      const reader = new FileReader();
      reader.onloadend = () => {
        setMultipleSections((prevSections) => {
          newSections = [...prevSections];
          const currentQuestions = newSections[sectionIndex]?.questions || [];
          const updatedQuestions = [...currentQuestions];

          updatedQuestions[questionIndex] = {
            ...currentQuestions[questionIndex],
            file: file.name,
            preview: URL.createObjectURL(e.target.files[0]),
            question_image_uuid: image_uuid,
          };

          newSections[sectionIndex] = {
            ...newSections[sectionIndex],
            questions: updatedQuestions,
          };

          return newSections;
        });

        setImagesArray((prevImagesArray) => {
          // create a new File instance
          const newFile = new File([file], question_image_uuid, {
            type: file.type,
          });
          const updatedImagesArray = [...prevImagesArray, newFile];
          return updatedImagesArray;
        });
      };
      reader.readAsDataURL(file);
    }
    setFileInputKey((prevKey) => prevKey + 1);
  };

  // //function to handle clear image preview
  const handleClearImage = (sectionIndex, questionIndex) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections]; // Declare newSections here
      const currentQuestions = newSections[sectionIndex]?.questions || [];
      const updatedQuestions = [...currentQuestions];

      updatedQuestions[questionIndex] = {
        ...currentQuestions[questionIndex],
        file: "",
        preview: "",
        image_uuid: "",
      };

      newSections[sectionIndex] = {
        ...newSections[sectionIndex],
        questions: updatedQuestions,
      };

      return newSections;
    });

    setImagesArray((prevImagesArray) => {
      const updatedImagesArray = prevImagesArray.filter(
        (image, index) => index !== questionIndex
      );
      return updatedImagesArray;
    });

    setShowClearButton(false);

    const remainingImages = sections[sectionIndex]?.questions.some(
      (question) => question.file && question.preview && question.image_uuid
    );

    if (remainingImages) {
      setShowClearButton(true);
    }

    const inputElement = document.querySelector('input[type="file"]');
    if (inputElement) {
      inputElement.value = "";
    }
    setFileInputKey((prevKey) => prevKey + 1);
  };

  //validation errors for required fields
  const hasValidationErrors = () => {
    const duplicateOptionsError = duplicateOptions.some(
      (sectionDuplicates) =>
        sectionDuplicates &&
        sectionDuplicates.some(
          (questionDuplicates) =>
            questionDuplicates && questionDuplicates.some((option) => option)
        )
    );

    const testNameError = test_name.trim() === "";
    const testDescriptionError = form_description.trim() === "";
    const sectionNameEmpty = sections.some(
      (section) => section.section_name.trim() === ""
    );

    const sectionNameDuplicateError = sectionNameErrors.some(
      (error) => !!error
    );

    const questionTextEmptyError = sections.some((section) =>
      section.questions.some((question) => question.question_text.trim() === "")
    );

    const isAnyOptionEmpty = sections.some((section) =>
      section.questions.some(
        (question) =>
          question.options.length < 4 ||
          question.options.some((option) => option.text.trim() === "")
      )
    );

    const answerTextEmptyError = sections.some((section) =>
      section.questions.some(
        (question) => question.correct_answer.trim() === ""
      )
    );

    return (
      duplicateOptionsError ||
      testNameError ||
      testDescriptionError ||
      sectionNameEmpty ||
      sectionNameDuplicateError ||
      questionTextEmptyError ||
      isAnyOptionEmpty ||
      answerTextEmptyError
    );
  };
  //handling delete section
  function deleteSection(i) {
    let ms = [...sections];
    ms.splice(i, 1);
    setMultipleSections(ms);

    handleCloseQues();
  }

  function deleteQuestion(sectionIndex, questionIndex) {
    setMultipleSections((prevSections) => {
      const newSections = prevSections.map((section, index) => {
        if (index === sectionIndex && section.questions.length > 1) {
          const updatedQuestions = section.questions.filter(
            (_, idx) => idx !== questionIndex
          );
          return { ...section, questions: updatedQuestions };
        }
        return section;
      });

      return newSections;
    });
  }

  //handling adding multiple sections
  const addMoreSection = () => {
    const sectionID = uuidv4();
    setMultipleSections((sections) => [
      ...sections,
      {
        section_name: "",
        sectionID: sectionID,
        questions: [
          {
            questionID: uuidv4(),
            question_text: "",
            options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
            correct_answer: "",
            open: true,
          },
        ],
      },
    ]);
  };

  //at least one question is added on component mounts
  useEffect(() => {
    if (sections.length === 0) {
      const sectionID = uuidv4();
      setMultipleSections([
        {
          section_name: "",
          sectionID: sectionID,
          questions: [
            {
              questionID: uuidv4(),
              question_text: "",
              options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
              correct_answer: "",
              open: true,
            },
          ],
        },
      ]);
    }
  });

  //handle section name change
  const [sectionNameErrors, setSectionNameErrors] = useState(
    Array(sections.length).fill("")
  );

  // handle section name change
  const handleSectionNameChange = (text, sectionIndex) => {
    setMultipleSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].section_name = text;

      setSectionNameErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        const isDuplicate = updatedSections.some(
          (section, index) =>
            index !== sectionIndex &&
            section.section_name.trim() === text.trim()
        );
        updatedErrors[sectionIndex] = isDuplicate
          ? "Duplicate section name!"
          : "";
        return updatedErrors;
      });

      return updatedSections;
    });
  };

  const [questionTextErrors, setQuestionTextErrors] = useState([]);
  //handling question values
  const handleQuestionValue = (text, sectionIndex, i) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].questions[i].question_text = text;

      setQuestionTextErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[sectionIndex] = updatedErrors[sectionIndex] || [];

        // Check if question text is empty
        updatedErrors[sectionIndex][i] =
          text.trim() === "" ? "Question text cannot be empty!" : "";

        return updatedErrors;
      });

      return newSections;
    });
  };

  const handleOptionValue = (
    value,
    sectionIndex,
    questionIndex,
    optionIndex
  ) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const section = newSections[sectionIndex];
      const question = section.questions[questionIndex];

      // Checking if the value is being cleared
      const isValueCleared = value.trim() === "";

      // Update the option value
      question.options[optionIndex].text = value;

      // Update duplicate options state
      setDuplicateOptions((prevDuplicates) => {
        const newDuplicates = [...prevDuplicates];
        const sectionDuplicates = newDuplicates[sectionIndex] || [];
        sectionDuplicates[questionIndex] =
          sectionDuplicates[questionIndex] || [];

        // Check for duplicates in the question's options
        const isDuplicateInSection = question.options.some(
          (option, index) =>
            index !== optionIndex &&
            typeof option.text === "string" &&
            option.text.trim() === value.trim()
        );

        // Updating the duplicate flag
        sectionDuplicates[questionIndex][optionIndex] = isDuplicateInSection;

        // Clearing the duplicate flag if the value is cleared
        if (isValueCleared) {
          sectionDuplicates[questionIndex][optionIndex] = false;
        }

        // Check if the correct answer key is non-empty before validating
        if (question.correct_answer.trim() !== "") {
          // Check if the updated option value matches the answer key
          const isAnswerKeyValid = question.options.some(
            (option) => option.text.trim() === question.correct_answer.trim()
          );

          // Update the validation state
          question.isAnswerKeyValid = isAnswerKeyValid;
        }

        return [
          ...newDuplicates.slice(0, sectionIndex),
          sectionDuplicates,
          ...newDuplicates.slice(sectionIndex + 1),
        ];
      });

      return newSections;
    });
  };

  //handling answer key values
  const handleAnswerKey = (text, sectionIndex, questionIndex) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const question = newSections[sectionIndex].questions[questionIndex];

      // Check if the answer key matches any of the options
      //const isAnswerKeyValid = question.options.includes(text);
      const isAnswerKeyValid = question.options.some(
        (option) => option.text === text
      );
      // const isAnswerKeyValid = question.options.some(
      //   option => option.text.trim() === question.correct_answer.trim()
      // );

      // Update the answer key and validation state
      question.correct_answer = text;
      question.isAnswerKeyValid = isAnswerKeyValid;

      return newSections;
    });
  };

  function copyQuestion(
    sectionIndex,
    questionIndex,
    newQuestionText,
    newCorrectAnswer,
    newQuestionID,
    newOptions
  ) {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];

      if (newSections[sectionIndex]) {
        const originalQuestion =
          newSections[sectionIndex].questions[questionIndex];
        const questionID = uuidv4();
        //new questions
        const duplicatedQuestion = {
          question_text: newQuestionText || originalQuestion.question_text,
          correct_answer: newCorrectAnswer || originalQuestion.correct_answer,
          // options: newOptions || [...originalQuestion.options],
          options: newOptions
            ? newOptions.map((option) =>
                typeof option === "string"
                  ? { text: option }
                  : { text: option.text }
              )
            : originalQuestion.options.map((option) => ({ text: option.text })),
          questionID: newQuestionID || questionID,
          open: true,
        };

        newSections[sectionIndex].questions.splice(
          questionIndex + 1,
          0,
          duplicatedQuestion
        );
      } else {
        console.error(`Section at index ${sectionIndex} does not exist.`);
      }

      return newSections;
    });
  }

  function showAsQuestion(i) {
    let qs = [...questions];
    qs[i].open = false;
    setQuestions(qs);
  }

  //handling adding questions field
  const addMoreQuestionField = (sectionIndex) => {
    const questionID = uuidv4();
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].questions.push({
        questionID: questionID,
        question_text: "",
        options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
        correct_answer: "",
        open: true,
      });
      return newSections;
    });
    expandCloseAll();
    handleCloseQues();
  };

  //handling add options
  const addOption = (sectionID, questionIndex) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const section = newSections[sectionID];
      const question = section.questions[questionIndex];

      if (question.options.length < 4) {
        // Add a new empty option
        question.options.push("");

        // Check for duplicate values if there are non-empty input values
        const lastOptionIndex = question.options.length - 1;
        const isDuplicate = question.options
          .slice(0, lastOptionIndex) // Exclude the newly added option
          .some(
            (option, index) =>
              option.trim() === question.options[lastOptionIndex].trim()
          );

        // Update duplicateOptions state only if there are non-empty input values
        if (question.options[lastOptionIndex].trim() !== "") {
          setDuplicateOptions((prevDuplicates) => {
            return isDuplicate
              ? [
                  ...prevDuplicates.filter(
                    (item) =>
                      item.sectionIndex !== sectionID ||
                      item.questionIndex !== questionIndex
                  ),
                  {
                    sectionIndex: sectionID,
                    questionIndex,
                    optionIndex: lastOptionIndex,
                  },
                ]
              : prevDuplicates.filter(
                  (item) =>
                    !(
                      item.sectionIndex === sectionID &&
                      item.questionIndex === questionIndex
                    )
                );
          });
        }
      }

      return newSections;
    });
  };
  //handling removing options
  const removeOption = (sectionIndex, questionIndex, optionIndex) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const section = newSections[sectionIndex];
      const question = section.questions[questionIndex];

      // Remove the option
      const removedOption = question.options.splice(optionIndex, 1)[0];

      // Check for duplicate values after removing the option
      const isDuplicate = question.options.some(
        (option) =>
          option.trim() === removedOption.trim() && option.trim() !== ""
      );

      // Update duplicateOptions state for the specific section and question
      setDuplicateOptions((prevDuplicates) => {
        const newDuplicates = [...prevDuplicates];
        const sectionDuplicates = newDuplicates[sectionIndex] || [];
        sectionDuplicates[questionIndex] =
          sectionDuplicates[questionIndex] || [];

        return isDuplicate
          ? [
              ...newDuplicates.filter(
                (item) =>
                  item.sectionIndex !== sectionIndex ||
                  item.questionIndex !== questionIndex
              ),
              {
                sectionIndex,
                questionIndex,
                optionIndex,
              },
            ]
          : prevDuplicates;
      });

      return newSections;
    });
  };

  function expandCloseAll() {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      qs[j].open = false;
    }
    setQuestions(qs);
  }

  function handleExpand(questionIndex) {
    let qs = questions.map((ques, index) => ({
      ...ques,
      open: index === questionIndex ? !ques.open : false,
    }));

    setQuestions(qs);
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) {
      const sectionIndex = parseInt(source.droppableId, 10);
      const updatedSections = [...sections];
      const section = updatedSections[sectionIndex];
      const updatedQuestions = Array.from(section.questions);
      const [draggedQuestion] = updatedQuestions.splice(source.index, 1);
      updatedQuestions.splice(destination.index, 0, draggedQuestion);
      section.questions = updatedQuestions;
      setMultipleSections(updatedSections);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //go to forms page...
  const navigateToHomePage = () => {
    navigate.push('/forms');
  };

  //API Handling
  const saveQuestions = async (e) => {
    if (hasValidationErrors()) {
      swal({
        title: "Validation Error",
        text: "Please fix validation Errors and complete all Required fields before saving",
        icon: "error",
        customClass: {
          content: "text-center",
        },
      });
      return;
    }

    let formData = new FormData();
    formData.append("test_name", test_name);
    formData.append("description", form_description);
    formData.append("test_timer", quizTimer);
    formData.append("sections", JSON.stringify(sections));

    imagesArray.forEach((file, index) => {
      formData.append("images", file);
    });

    const response = await fetch(`${API_ROOT_URL}/v3/tests/create-tests`, {
      method: "POST",
      body: formData,
      headers: {
        "x-access-token": token,
      },
    }).then((data) => data.json());

    if ("status" in response && response.status === true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        navigateToHomePage();
      });
    } else if (response?.devMessage === "revokedPermisision") {
      Swal.fire({
        title: "Access Denied",
        text: "Your Permission Has been Revoked",
        icon: "error",
        confirmButtonColor: "#3a3630",
        confirmButtonText: "OK",
        footer:
          '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
      }).then(() => {
        navigate.push(dashboardUrl);
      });
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  function SectionUI() {
    return sections.map((section, sectionIndex) => (
      <div key={sectionIndex}>
        <Grid style={{ paddingTop: "10px" }}>
          <Grid style={{ borderTop: "10px solid #feba30", borderRadius: 10 }}>
            <div>
              <div>
                <ThemeProvider theme={formLabelsTheme}>
                  <Paper
                    elevation={2}
                    style={{ width: "100%", backgroundColor: "#faf0e6" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "15px",
                        justifyContent: "space-between",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      <TextField
                        className="mt-0"
                        fullWidth
                        label="Section Title"
                        variant="standard"
                        required={true}
                        size="small"
                        fontSize={30}
                        value={section.section_name}
                        onChange={(e) =>
                          handleSectionNameChange(e.target.value, sectionIndex)
                        }
                        error={!!sectionNameErrors[sectionIndex]}
                        helperText={sectionNameErrors[sectionIndex]}
                      />

                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton
                              aria-label="Image"
                              {...bindTrigger(popupState)}
                            >
                              <MoreVertIcon />
                            </IconButton>

                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={(e) => {
                                  addMoreQuestionField(sectionIndex);
                                  popupState.close(e);
                                }}
                              >
                                <AddIcon
                                  style={{ marginRight: "5px" }}
                                  size={25}
                                />
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "600",

                                    color: "dimgrey",
                                  }}
                                >
                                  Add Question
                                </span>
                              </MenuItem>

                              <MenuItem
                                onClick={(e) => {
                                  deleteSection(sectionIndex);
                                  popupState.close(e);
                                }}
                                disabled={sections.length === 1}
                              >
                                <DeleteIcon
                                  style={{
                                    marginRight: "5px",
                                    color:
                                      sections.length === 1
                                        ? "grey"
                                        : "dimgrey",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color:
                                      sections.length === 1
                                        ? "grey"
                                        : "dimgrey",
                                  }}
                                >
                                  Delete Section
                                </span>
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </div>

                    <div style={{ padding: "20px" }}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={sectionIndex.toString()}>
                          {(provided) => (
                            <div
                              key={sectionIndex}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <>
                                {section.questions.map(
                                  (ques, questionIndex) => (
                                    <Draggable
                                      key={ques.questionID}
                                      draggableId={ques.questionID}
                                      index={questionIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div>
                                            <div
                                              style={{ marginBottom: "15px" }}
                                            >
                                              <div
                                                style={{
                                                  width: "100%",
                                                  marginBottom: "-7px",
                                                }}
                                              >
                                                <DragIndicatorIcon
                                                  style={{
                                                    marginTop: "10px",
                                                    transform: "rotate(-90deg)",
                                                    color: "darkgrey",
                                                  }}
                                                  fontSize="small"
                                                />
                                              </div>

                                              <Accordion
                                                onChange={() => {
                                                  handleExpand(sectionIndex);
                                                }}
                                                expanded={ques.open}
                                              >
                                                <AccordionSummary
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                                  elevation={1}
                                                  style={{ width: "100%" }}
                                                >
                                                  {!ques.open ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems:
                                                          "flex-start",
                                                        marginLeft: "3px",
                                                        paddingTop: "15px",
                                                        paddingBottom: "15px",
                                                      }}
                                                    >
                                                      <Typography
                                                        variant="subtitle1"
                                                        style={{
                                                          marginLeft: "0px",
                                                        }}
                                                      >
                                                        {questionIndex + 1}.{" "}
                                                        {ques.question_text}
                                                      </Typography>

                                                      {ques.options.map(
                                                        (op, j) => (
                                                          <div key={j}>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <FormControlLabel
                                                                disabled
                                                                control={
                                                                  <Radio
                                                                    style={{
                                                                      marginRight:
                                                                        "3px",
                                                                    }}
                                                                  />
                                                                }
                                                                label={
                                                                  <Typography
                                                                    style={{
                                                                      color:
                                                                        "#555555",
                                                                    }}
                                                                  >
                                                                    {
                                                                      ques
                                                                        .options[
                                                                        j
                                                                      ]
                                                                    }
                                                                  </Typography>
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </AccordionSummary>

                                                <AccordionDetails>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "flex-start",
                                                      marginLeft: "15px",
                                                      marginTop: "-15px",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <Typography
                                                        style={{
                                                          marginTop: "20px",
                                                        }}
                                                      >
                                                        {questionIndex + 1}.
                                                      </Typography>

                                                      <TextField
                                                        fullWidth
                                                        placeholder="Question Text *"
                                                        required={true}
                                                        style={{
                                                          marginBottom: "18px",
                                                        }}
                                                        rows={2}
                                                        rowsMax={20}
                                                        multiline={true}
                                                        value={
                                                          ques.question_text
                                                        }
                                                        variant="filled"
                                                        onChange={(e) => {
                                                          handleQuestionValue(
                                                            e.target.value,
                                                            sectionIndex,
                                                            questionIndex
                                                          );
                                                        }}
                                                        error={
                                                          !!questionTextErrors[
                                                            sectionIndex
                                                          ] &&
                                                          !!questionTextErrors[
                                                            sectionIndex
                                                          ][questionIndex]
                                                        }
                                                        helperText={
                                                          questionTextErrors[
                                                            sectionIndex
                                                          ] &&
                                                          questionTextErrors[
                                                            sectionIndex
                                                          ][questionIndex]
                                                        }
                                                      />
                                                      {/* <Tooltip
                                                        title="Add Image"
                                                        arrow
                                                      >
                                                        <IconButton
                                                          aria-label={`upload image for Question ${
                                                            questionIndex + 1
                                                          }`}
                                                          component="label"
                                                        >
                                                          <CropOriginalIcon />
                                                          <input
                                                            type="file"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            onChange={(e) =>
                                                              handleFileChange(
                                                                e,
                                                                sectionIndex,
                                                                questionIndex
                                                              )
                                                            }
                                                            accept="image/png,image/jpeg,image/jpg"
                                                          />
                                                        </IconButton>
                                                      </Tooltip> */}
                                                      <Tooltip
                                                        title="Add Image"
                                                        arrow
                                                      >
                                                        <IconButton
                                                          aria-label={`upload image for Question ${
                                                            questionIndex + 1
                                                          }`}
                                                          component="label"
                                                        >
                                                          <CropOriginalIcon />
                                                          <input
                                                            type="file"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            onChange={(e) =>
                                                              handleFileChange(
                                                                e,
                                                                sectionIndex,
                                                                questionIndex
                                                              )
                                                            }
                                                            accept="image/png,image/jpeg,image/jpg"
                                                            key={fileInputKey}
                                                          />
                                                        </IconButton>
                                                      </Tooltip>
                                                    </div>
                                                    {ques?.preview && (
                                                      <div key={fileInputKey}>
                                                        <img
                                                          src={
                                                            ques?.preview
                                                          }
                                                          width="150"
                                                          alt="Preview"
                                                        />
                                                        <span className="sideCrossButton">
                                                          <BsXCircleFill
                                                            size={25}
                                                            style={{
                                                              marginBottom:
                                                                "110px",
                                                              color: "gray",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              handleClearImage(
                                                                sectionIndex,
                                                                questionIndex
                                                              )
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                    )}
                                                    {/* <div>
                                                      {ques?.image?.preview && (
                                                        <div
                                                          // key={questionIndex}
                                                          key={fileInputKey} 
                                                        >
                                                          <img
                                                            src={
                                                              ques?.image
                                                                ?.preview
                                                            }
                                                            width="150"
                                                            alt="Preview"
                                                          />
                                                          <span className="sideCrossButton">
                                                            <BsXCircleFill
                                                              size={25}
                                                              style={{
                                                                marginBottom:
                                                                  "110px",
                                                                color: "gray",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() =>
                                                                handleClearImage(
                                                                  sectionIndex,
                                                                  questionIndex
                                                                )
                                                              }
                                                            />
                                                          </span>
                                                        </div>
                                                      )}
                                                    </div> */}
                                                    <div
                                                      style={{ width: "100%" }}
                                                    >
                                                      {/* {[0, 1, 2, 3].map( */}
                                                      {ques.options.map(
                                                        (op, j) => (
                                                          // (op, j) => (
                                                          <div key={j}>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                marginLeft:
                                                                  "-12.5px",
                                                                justifyContent:
                                                                  "space-between",
                                                                paddingTop:
                                                                  "5px",
                                                                paddingBottom:
                                                                  "2px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  marginRight:
                                                                    "8px",
                                                                  marginTop:
                                                                    "22px",
                                                                  marginLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                {j + 1}.
                                                              </span>

                                                              <TextField
                                                                fullWidth={true}
                                                                placeholder="Option text *"
                                                                required={true}
                                                                style={{
                                                                  marginTop:
                                                                    "5px",
                                                                  borderColor:
                                                                    duplicateOptions[
                                                                      sectionIndex
                                                                    ] &&
                                                                    duplicateOptions[
                                                                      sectionIndex
                                                                    ][
                                                                      questionIndex
                                                                    ] &&
                                                                    duplicateOptions[
                                                                      sectionIndex
                                                                    ][
                                                                      questionIndex
                                                                    ][j]
                                                                      ? "red"
                                                                      : undefined,
                                                                }}
                                                                // value={
                                                                //   ques.options[
                                                                //     j
                                                                //   ]
                                                                // }

                                                                value={op.text}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleOptionValue(
                                                                    e.target
                                                                      .value,
                                                                    sectionIndex,
                                                                    questionIndex,
                                                                    j
                                                                  );
                                                                }}
                                                              />
                                                              <IconButton
                                                                className="removeOption"
                                                                aria-label="delete"
                                                                onClick={() => {
                                                                  removeOption(
                                                                    sectionIndex,
                                                                    questionIndex,
                                                                    j
                                                                  );
                                                                }}
                                                              >
                                                                <CloseIcon />
                                                              </IconButton>
                                                            </div>

                                                            {duplicateOptions[
                                                              sectionIndex
                                                            ] &&
                                                              duplicateOptions[
                                                                sectionIndex
                                                              ][
                                                                questionIndex
                                                              ] &&
                                                              duplicateOptions[
                                                                sectionIndex
                                                              ][questionIndex][
                                                                j
                                                              ] && (
                                                                <Typography
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                    marginLeft:
                                                                      "25px",
                                                                  }}
                                                                >
                                                                  Duplicate
                                                                  option value!
                                                                </Typography>
                                                              )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    <br></br>
                                                    <div
                                                      style={{ width: "100%" }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          justifyContent:
                                                            "space-between",
                                                          paddingTop: "10px",
                                                        }}
                                                      >
                                                        <WysiwygIcon
                                                          style={{
                                                            color: "grey",
                                                            marginRight: "10px",
                                                            marginLeft: "-5px",
                                                            size: "20px",
                                                            marginTop: "10px",
                                                          }}
                                                        />
                                                        <TextField
                                                          fullWidth={true}
                                                          placeholder="Answer Key *"
                                                          required={true}
                                                          style={{
                                                            marginTop: "5px",
                                                            marginRight: "42px",
                                                            borderColor:
                                                              ques.isAnswerKeyValid ===
                                                              false
                                                                ? "red"
                                                                : undefined,
                                                          }}
                                                          value={
                                                            ques.correct_answer
                                                          }
                                                          onChange={(e) => {
                                                            handleAnswerKey(
                                                              e.target.value,
                                                              sectionIndex,
                                                              questionIndex
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                      {ques.isAnswerKeyValid ===
                                                        false && (
                                                        <Typography
                                                          style={{
                                                            color: "red",
                                                            marginLeft: "25px",
                                                          }}
                                                        >
                                                          Answer key must match
                                                          one of the options.
                                                        </Typography>
                                                      )}
                                                    </div>

                                                    <br></br>
                                                  </div>
                                                </AccordionDetails>

                                                <Divider />

                                                <AccordionActions>
                                                  <Tooltip
                                                    title="Duplicate Question"
                                                    arrow
                                                  >
                                                    <IconButton
                                                      aria-label="Copy"
                                                      onClick={() => {
                                                        copyQuestion(
                                                          sectionIndex,
                                                          questionIndex
                                                        );
                                                      }}
                                                    >
                                                      <FilterNoneIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                  />
                                                  <Tooltip
                                                    title="Delete Question"
                                                    arrow
                                                  >
                                                    <IconButton
                                                      aria-label="delete"
                                                      onClick={() => {
                                                        deleteQuestion(
                                                          sectionIndex,
                                                          questionIndex
                                                        );
                                                      }}
                                                      disabled={
                                                        section.questions
                                                          .length === 1
                                                      }
                                                    >
                                                      <DeleteOutlineIcon
                                                        style={{
                                                          fontSize: "28px",
                                                          color:
                                                            section.questions
                                                              .length === 1
                                                              ? "lightgrey"
                                                              : "dimgrey",
                                                          cursor:
                                                            section.questions
                                                              .length === 1
                                                              ? "none"
                                                              : "pointer",
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </AccordionActions>
                                              </Accordion>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                              </>
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Paper>
                </ThemeProvider>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    ));
  }

  const [openTimer, setOpenTimer] = React.useState(false);

  const handleClickOpenTimer = () => {
    setOpenTimer(true);
  };
  const handleCloseTimer = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpen(false);
    setOpenTimer(false);
  };

  const AddTestTimer = () => {
    return (
      <>
        <div>
          <Dialog onClose={handleCloseTimer} open={openTimer}>
            <div style={{ width: "600px", height: "240px" }}>
              <div className="d-flex">
                <DialogTitle id="customized-dialog-title">
                  <span
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      color: "dimgrey",
                    }}
                  >
                    Add Test Timer
                  </span>
                </DialogTitle>
                <div style={{ marginLeft: "55%", marginTop: "5px" }}>
                  {/* <IconButton onClick={handleCloseTimer}>
                    <CloseIcon />
                  </IconButton> */}
                </div>
              </div>

              <DialogContent dividers>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                ></div>

                <div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "15px",
                        justifyContent: "space-between",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    >
                      <TimerIcon
                        style={{
                          color: "grey",
                          marginRight: "-29px",
                          marginLeft: "-5px",
                          size: "20px",
                          marginTop: "10px",
                        }}
                      />
                      <input
                        type="number"
                        min="0"
                        max="59"
                        id="minutes"
                        className="form-control minutesfield"
                        name="minutes"
                        placeholder="MM"
                        defaultValue={"01"}
                        value={
                          (typeof quizTimer === "string"
                            ? quizTimer
                            : "00:00"
                          ).split(":")[0]
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const newMinutes = parseInt(inputValue, 10);
                          if (
                            !isNaN(newMinutes) &&
                            newMinutes >= 0 &&
                            newMinutes <= 59
                          ) {
                            setQuizTimer(
                              `${String(newMinutes).padStart(2, "0")}:${
                                (quizTimer ?? "00:00").split(":")[1] || "00"
                              }`
                            );
                          }
                        }}
                      />

                      <div
                        style={{
                          fontWeight: "bold",
                          marginTop: "12px",
                          marginLeft: "3px",
                        }}
                      >
                        :
                      </div>

                      <input
                        type="number"
                        min="0"
                        max="59"
                        id="seconds"
                        className="form-control secondsfield"
                        name="seconds"
                        placeholder="SS"
                        defaultValue={"00"}
                        value={
                          (typeof quizTimer === "string"
                            ? quizTimer
                            : "00:00"
                          ).split(":")[1]
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const newSeconds = parseInt(inputValue, 10);
                          if (
                            !isNaN(newSeconds) &&
                            newSeconds >= 0 &&
                            newSeconds <= 59
                          ) {
                            setQuizTimer(
                              `${(quizTimer ?? "00:00").split(":")[0]}:${String(
                                newSeconds
                              ).padStart(2, "0")}`
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions></DialogActions>
              <div
                style={{
                  marginLeft: "482px",
                  marginTop: "12px",
                  marginBottom: "5px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseTimer}
                >
                  CLOSE
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </>
    );
  };

  const QuestionsTab = () => {
    return (
      <>
        <div>
          <div style={{ minHeight: "100vh" }}>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "7px",
                paddingBottom: "30px",
              }}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={5} style={{ width: "100%" }}>
                  <Grid
                    style={{
                      borderTop: "10px solid #feba30",
                      borderRadius: 10,
                      marginTop: "70px",
                    }}
                  >
                    <div>
                      <div>
                        <Paper elevation={2} style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              marginLeft: "15px",
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              marginRight: "15px",
                            }}
                          >
                            <TextField
                              className="mt-0"
                              fullWidth
                              label="Test Title"
                              required={true}
                              variant="standard"
                              size="small"
                              fontSize={30}
                              value={test_name}
                              onChange={(e) => setTestName(e.target.value)}
                            />

                            <TextField
                              className="mt-4"
                              fullWidth
                              label="Test Description"
                              id="fullWidth"
                              size="small"
                              required={true}
                              multiline
                              rows={2}
                              variant="filled"
                              value={form_description}
                              onChange={(e) =>
                                setFormDescription(e.target.value)
                              }
                            />
                          </div>
                        </Paper>
                      </div>
                    </div>
                  </Grid>

                  {/* section Data */}

                  {SectionUI()}

                  <Grid
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div></div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* test timer */}
          {AddTestTimer()}
        </div>
      </>
    );
  };

  return (
    <div>
      <div>
        <div className={classes.root}>
          <AppBar
            style={{ backgroundColor: "white", height: "70px" }}
            elevation={2}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h6"
                noWrap
                style={{ marginTop: "2.5px", color: "black" }}
              >
                {formDeatils.name}
              </Typography>
              <Tabs
                style={{
                  marginBottom: "57px",
                  // marginLeft: "20px",
                  paddingLeft: "42%",
                }}
                className={classes.title}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="CREATE TEST" />
                {/* <Tab label="Responses" /> */}
              </Tabs>
              <Tooltip title="Add More section" arrow>
                <button
                  className="addSectionButton"
                  onClick={addMoreSection}
                  style={{ color: "white" }}
                >
                  <RiPlayListAddLine
                    size={25}
                    fontWeight={800}
                    style={{ marginRight: "2px", color: "#3a3630" }}
                  />{" "}
                </button>
              </Tooltip>
              <Tooltip title="Edit Timer field" arrow>
                <button className="testTimer" onClick={handleClickOpenTimer}>
                  <LuTimer
                    size={25}
                    style={{ letterSpacing: "1px", color: "#3a3630" }}
                  />
                </button>
              </Tooltip>
              <div className="mx-2">
                <button className="buttonSubmit" onClick={saveQuestions}>
                  SUBMIT TEST
                </button>
              </div>
            </Toolbar>
          </AppBar>
        </div>

        <div>
          <TabPanel value={value} index={0}>
            <div>{QuestionsTab()}</div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default EditForm;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
