import { Card, CardActionArea } from "@mui/material";
import { useTheme } from "@mui/system";
import Lottie from "lottie-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Forms from "./Forms";
import add from "../Dashboard/add.json";
import { useHistory } from "react-router-dom";
import Navbar from "../NavBar/Navbar";
import Background from "./../formsImages/abstract-gray-mosaic-polygon-surfaced-background.jpg";
const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department",
};

export default function UserView() {
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useHistory();
  const theme = useTheme();
  const role = useSelector((state) => state.auth.auth.role);
  // Sample data
  const dataNew = [{ title: "New Form Here", subheader: "September 14, 2023" }];
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createTest = () => {
    navigate.push("forms-questions");
  };
  return (
    <div style={{ minHeight: "100vh" }}>
      <Navbar />
      {USER_ROLES.COMPANY === role ||
      USER_ROLES.DEPARTMENT === role ||
      USER_ROLES.INSTRUCTOR === role ? (
        <>
          <div className="blankformBackground">
            <div style={{ padding: "2vw 10vw 3vw 10vw" }}>
              <div
                className="fs-18 font-w500"
                style={{ color: "whitesmoke", paddingBottom: "8px" }}
              >
                Create a new Test
              </div>

              <Card
                onClick={(e) => {
                  createTest(e);
                }}
                style={{
                  //backgroundColor: 'ghostwhite',
                  //boxShadow: '5px 5px whitesmoke',
                  width: "20%",
                  maxHeight: "30%",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <CardActionArea>
                  <Lottie
                    style={{ height: 140, height: 140 }}
                    animationData={add}
                  />
                </CardActionArea>
              </Card>
            </div>
          </div>
          <div className="formsBackground">
            <div style={{ padding: "2vw 10vw 3vw 10vw", minHeight: "70vh" }}>
              <Forms />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            backgroundImage: "url(" + Background + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              padding: "2vw 5vw",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Forms />
          </div>
        </div>
      )}
    </div>
  );
}
