import React, { Fragment, useState } from "react";
import Header from "./Header";
import NavHader from "./NavHader";
import { useLocation } from "react-router-dom";
import SideBar from "./SideBar";
import useValidUrl from "../../../utilis/useValidUrl";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const location = useLocation();
  const validurl = useValidUrl(location.pathname.slice(1));

  const hideSideBar =
    location.pathname.includes("start-test") ||
    location.pathname.includes("view-induction") ||
    location.pathname.includes("forms"); // Add this condition

  return (
    <Fragment>
      {validurl && !hideSideBar && <NavHader />}
      {validurl && !hideSideBar && (
        <Header
          onNote={() => onClick("chatbox")}
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          onClick={() => ClickToAddEvent()}
        />
      )}
      {validurl && !hideSideBar && <SideBar />}
    </Fragment>
  );
};

export default JobieNav;
