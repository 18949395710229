import Lottie from "lottie-react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from 'primereact/calendar';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import { getData } from "../APIs";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import Usertable from "../SkeletonLoader/Usertable";
import AssignUsers from "./AssignUsers";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

import { BiSortAlt2 } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import s3 from "../../../utilis/aws.config";


const UnassignedUsers = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const [userUpdated, setUserUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState();
  const [isUserStatusChanged, setIsUserStatusChanged] = useState(false);
  // filter states
  const [companyFilter, setCompanyFilter] = useState();
  const [deptFilter, setDeptFilter] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);

  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([])
  const [test, setTest] = useState([]);


  //sort function states
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState(null);

  const [data, setData] = useState(
    document.querySelectorAll("#student_wrapper tbody tr")
  );

  const tokenDetailsString = localStorage.getItem('userDetails');

  const changeUserStatus = (userID, status) => {
    // user id
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: `Once status Changed, User will get or loss access to account`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(`${API_ROOT_URL}/users/changeUserStatus`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID, status: status }),
          }).then((data) => data.json());

          if ("status" in response && response.status == true) {
            swal("Poof! Your record has been updated!", {
              icon: "success",
            }).then(() => {
              //checkUserUpdated();
              setIsUserStatusChanged(!isUserStatusChanged);
            });
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal("Your status is not changed!");
        }
      });
    }
  };
  const trackDeleteClick = (userID, deleted) => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to delete this record ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(`${API_ROOT_URL}/users/deleteUserStatus`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID, deleted: deleted }),
          }).then((data) => data.json());
          if ("status" in response && response.status == true) {
            swal("Poof! Your record has been deleted!", {
              icon: "success",
            });
            getUserData(token);
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal("Your record is safe!");
        }
      });
    }
  };

  // callback function to opdate state
  const trackOnclick = (payload, pdata) => {
    if (pdata) {
      setProfileData(pdata);
    }
    setIsModalOpen(payload);
    //checkUserUpdated();
  };
  // call api
  const getUserData = async (token) => {
    var API_URL = `${API_ROOT_URL}/students/unassigned`;

    const response = await getData(API_URL, token);
    if ("status" in response && response.status == true) {
      setUsers(response.data);
      setFilteredUsers(response.data);
      setLoading(false);
      setUserUpdated(false);
      setData(document.querySelectorAll("#student_wrapper tbody tr"));
    } else {
      if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  useEffect(() => {
    const modifiedUsersData = users.map((user) => {
      const { profile } = user;
      const formattedDate = new Date(user.createdAt)
      const newStatus = user.status == true ? "active" : "In-active";
      const fullName = (user.profile.first_name + " " + user.profile.last_name)
      return { ...user, formattedDate, newStatus, fullName };
    });
    // Set the modified data into state
    setModifiedData(modifiedUsersData);
  }, [users]);

  const searchFilter = (e) => {
    const searchVal = e.target.value.trim();
    //setSearchField(searchVal);

    if (searchVal === "" || searchVal.length < 2) {
      //setSearchField("");
      setFilteredUsers(users);
      setData(document.querySelectorAll("#student_wrapper tbody tr"));
    } else {
      const filterUsers = users.filter((user) => {
        var fullname = user.profile.first_name + " " + user.profile.last_name;
        return (
          fullname.toLowerCase().includes(searchVal.toLowerCase()) ||
          user.email.toLowerCase().includes(searchVal.toLowerCase())
        );
      });
      setFilteredUsers(filterUsers);
      setData(document.querySelectorAll("#student_wrapper tbody tr"));
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      // fetch users data
      getUserData(token);
    }
  }, [
    isModalOpen,
    companyFilter,
    deptFilter,
    loading,
    userUpdated,
    isUserStatusChanged,
  ]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      formattedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      email: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(users)
  }

  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
    // setModifiedData(deptData);
  }

  const handleSelect = (ranges) => {
    let filtered = users.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };

  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(users)
  };

  const dateRangePickerTemp = () => {


    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelect}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>


    )
  }

  const joinDateTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(rowData.createdAt).toLocaleDateString("en-GB", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>
      </>
    );
  };

  const statusTemplate = (rowData) => {
    const isActive = rowData?.status === true;

    return (
      <>
        <label class="switch">
          <input type="checkbox" id="togBtn" checked={isActive}
            to="/unassigned-users"
            onClick={() => changeUserStatus(rowData?._id, rowData?.status)} />
          <div class="slider round">
            <span class="on">Active</span>
            <span class="off">Inactive</span>
          </div>
        </label>
      </>
    );
  };

  const actionTemplate = (rowData) => {

    const handleCallback = () => {
      trackOnclick(true, rowData);
    };
    const deleteClick = () => {
      trackDeleteClick(rowData.profile._id, rowData.profile.deleted);
    };

    return (
      <div className="d-flex">
        <button onClick={handleCallback} className="editIcon">
          <i className="fas fa-pencil-alt" style={{ fontSize: "17px" }}></i>
        </button>

        <button onClick={deleteClick} className="deleteIcon">
          <i className="fa fa-trash" style={{ fontSize: "17px" }}></i>
        </button>
      </div>
    );
  };

  useEffect(() => {
    initFilters();
  }, []);

  const getSignedUrl = (fileName) => {
    return s3.getSignedUrl("getObject", {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName,
    });
  };

  const fullNameTemplate = (rowData) => {

    return (
      <>
        <div className="d-flex align-items-center mt-2 mb-2">
          <div>
            <img
              src={getSignedUrl(`${PROFILE_ASSETS_URL}/${rowData?.profile?.profilePhoto}`)}
              alt={rowData?.profile?.profilePhoto}
              width={60}
              height={60}
              className="img-thumbnail"
            />{" "}{rowData.profile.fullName}

          </div>
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="fa fa-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </>
    );
  };

  //sort function for join date
  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const header = renderHeader();


  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-3">
              <div className="table-responsive">
                <div>
                  {loading ? (<Usertable />) : (<DataTable
                    value={modifiedData.map((rowData, index) => ({
                      ...rowData,
                      serialNumber: index + 1,
                    }))}
                    header={header}
                    dataKey="id"
                    emptyMessage={
                      <div className="queryContainer">
                        {" "}
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-12">
                            <div className="form-input-content text-center">
                              <h1 className="error-text font-weight-bold">
                                <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                              </h1>
                              <h3
                                className="fs-28 mt-3"
                                style={{ color: "#3A3630", fontWeight: "900" }}
                              >
                                {" "}
                              </h3>
                              <h4 style={{ fontStyle: "italic" }}>
                                No Records Found !
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    filters={filters}
                    globalFilterFields={[
                      "fullName",
                      "email"
                    ]}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    size="large"
                    stripedRows
                    showGridlines
                    tableStyle={{ width: "100%" }}
                    filterDisplay="menu"
                  >
                    <Column
                      header="Sr.No" headerStyle={{ width: "5%" }}
                      body={(data, options) => options.rowIndex + 1}
                    ></Column>

                    <Column field="fullName"
                      body={fullNameTemplate}
                      sortable header="NAME"
                      filterField="fullName"
                      style={{ width: '30%' }}
                      filter
                      filterPlaceholder="Search by Name" />

                    <Column field="email"
                      sortable header="EMAIL"
                      filterField="email"
                      style={{ width: '25%' }}
                      filter
                      filterPlaceholder="Search by Email" />

                    <Column
                      field="formattedDate"
                      body={joinDateTemplate}
                      style={{ width: '10%' }}
                      header={<>
                        <div>
                          <Row className="textFilter">
                            <Col sm="10">
                              <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                                JOIN DATE
                                {" "}
                                <span>
                                  <BiSortAlt2 size={22} />
                                </span>
                              </button>

                            </Col>
                            <Col sm="2" >
                              <CiFilter size={22} onClick={openDateRangePicker} />
                            </Col>
                          </Row>
                        </div>

                        {showDateRangePicker && (
                          <Modal className="customModal d-flex"
                            show={openDateRangePicker}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <div className="modal-header customModalHeader">
                              <h6 className="modal-title text-white fs-20">
                                {" "}SELECT DATE / DATE RANGE
                              </h6>
                              <div className="cross-outer-icon">
                                <RxCross1
                                  className="cross-icon"
                                  size={22}
                                  color="white"
                                  onClick={closeDateRangePicker}
                                />
                              </div>

                            </div>
                            <div className="modal-content customModalContent">

                              <div>
                                {dateRangePickerTemp()}
                              </div>
                            </div>
                            <Modal.Footer className="d-flex modal-footer customModalFooter">
                              <button className="clearButton" onClick={handleClear}>Clear</button>
                              <button className="applyButton" onClick={handleApply}>Apply</button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </>}
                    />
                    <Column
                      field="status"
                      body={statusTemplate}
                      sortable
                      header="STATUS"
                      style={{ width: '10%' }}
                    ></Column>
                    <Column body={actionTemplate}
                      style={{ width: '10%' }}
                      header="ACTIONS"></Column>
                  </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <AssignUsers
          isModalOpen={isModalOpen}
          trackOnclick={trackOnclick}
          profileData={profileData}
          history={props.history}
        ></AssignUsers>
      ) : null}
    </>
  );
};

export default UnassignedUsers;
