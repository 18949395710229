import React, { useEffect, useState } from "react";
import StudentCal from "../Dashboard/StudentCal"
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import { useSelector } from "react-redux";
import oopsemptybox from "../Animations/138566-frequently-asked-question-faq.json";
import swal from "sweetalert";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import { BsBuildingsFill } from "react-icons/bs";
import { format } from "date-fns";
import Query from "../Animations/138566-frequently-asked-question-faq.json";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import s3 from "../../../utilis/aws.config";


const UserDashboard = (props) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.auth.token);
    const [loading, setLoading] = useState(true);
    const [inductionData, setInductionData] = useState([]);
    const [userData, setUserData] = useState()

    const dateFormat = "dd MMM yyyy"; // format string for date
    //To get data from last 5 inductions
    const handlepageLoad = async (e) => {
        const response = await fetch(`${API_ROOT_URL}/users/fetching/lastrecords`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            },
        }).then((data) => data.json());
        if ("status" in response && response.status === true) {
            setInductionData(response.data);
            setLoading(false);
        } else {
            if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
                swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                    dispatch(logout(props.history));
                });
            } else {
                return swal("Failed", "Error message", "error");
            }
        }
    };

    // api call
    async function getProfileApi(token) {
        const URL = `${API_ROOT_URL}/users/getProfile`;
        return fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            },
        }).then((data) => data.json());
    }

    //get profile data(bio) from API
    const getProfile = async () => {
        const response = await getProfileApi(token);
        if ("status" in response && response.status === true) {
            setUserData(response.data);
            setLoading(false);
        }
    };


    // use effect re rendering hook
    useEffect(() => {
        getProfile(token);
        handlepageLoad();
    }, [token]);

    // Get the signed URL for the profile image
    const url = s3.getSignedUrl("getObject", {
        Bucket: AWS_BUCKET_NAME,
        Key: `${PROFILE_ASSETS_URL}/${userData?.profile?.profilePhoto}`,
    });

    return (
        <div>
            <div className="col-lg-12">
                <div className="profile card card-body px-3 pt-3 pb-0 border-0">
                    <div className="profile-head">
                        <div className="photo-content ">
                            <div
                                className="cover-photo rounded"
                                style={{ display: "flex", justifyContent: "space-evenly" }}
                            >
                            </div>
                        </div>
                        <div className="profile-info">
                            <div className="profile-photo">
                                <img
                                    src={url}
                                    className="img-fluid rounded-circle"
                                    alt="profile"
                                />
                            </div>
                            <div className="profile-details">
                                <div className="profile-name px-3 pt-2">
                                    <h4 className="text-primary mb-0">
                                        {userData?.profile?.first_name +
                                            " " +
                                            userData?.profile?.last_name}{" "}
                                    </h4>
                                    <p>{userData?.parentCompany}</p>
                                </div>
                                <div className="profile-email px-2 pt-2">
                                    <h4 className="text-muted mb-0">{userData?.email}</h4>
                                    <span className="fs-15">
                                        Member Since{" "}
                                        {new Date(userData?.createdAt).getFullYear()}
                                    </span>
                                </div>

                                <Link to={`/profile`} className="ms-auto mt-3">
                                    {" "}
                                    <i className="fa fa-user-circle text-primary me-2 fa-xl " />
                                    View profile
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-7 col-xxl-12">
                    <div className="card">
                        <div className="card-body" style={{ minHeight: "650px" }}>
                            <div className="app-fullcalendar1 m-auto">
                                <StudentCal />
                            </div>
                        </div>
                    </div>
                </div>
                {inductionData.length <= 0 ?
                    (
                        <>
                            <div className="col-xl-5 col-xxl-12">
                                <div className="card">
                                    <div className="card-body" style={{ minHeight: "650px" }}>
                                        <div className="app-fullcalendar1 m-auto">
                                            <h1 className="error-text font-weight-bold">
                                                <Lottie

                                                    style={{ height: 350, marginTop: 60 }}
                                                    animationData={Query}
                                                />
                                            </h1>
                                            <h4 style={{ fontStyle: "italic", textAlign: "center", color: "#3a3630" }}>
                                                No, upcoming Inductions !
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            {/* upcoming inductions mapping last 5 */}
                            <div
                                className="d-flex col-xl-5"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div className="col-xl-12 flex-wrap p-2">
                                    <div className="widget-heading d-flex justify-content-between align-items-center">
                                        <h3 className="m-0">New Inductions</h3>
                                        <Link to={"./inductions"} className="btn btn-sm fs-20 btn-danger"
                                            style={{ borderRadius: "66px", padding: "10px 35px 10px 35px" }}
                                        >
                                            View all
                                        </Link>
                                    </div>

                                    {inductionData?.map((item, ind) => (
                                        <div className="card-schedule flex-wrap p-0" key={ind}>
                                            <span
                                                className={`side-label`}
                                                style={{ backgroundColor: "#E95252" }}
                                            ></span>
                                            <div className="up-comming-schedule ">
                                                <div style={{ maxWidth: "180px" }}>
                                                    <h5 className="mb-0">{item?.title}</h5>
                                                    <div className="mb-sm-0 mb-2">
                                                        <span>createdby :</span>
                                                        <span style={{ color: "#2D2727" }}>
                                                            {" "}
                                                            {item?.createdByName}
                                                        </span>
                                                    </div>
                                                    <div className="mb-sm-0 mb-2">
                                                        <span>Role :</span>
                                                        <span style={{ color: "#2D2727" }}>
                                                            {" "}
                                                            {item?.Usercred?.role}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div
                                                    className="mb-sm-0 mb-2"
                                                    style={{ marginLeft: "15px", marginRight: "30px" }}
                                                >
                                                    {/* <span>Department :</span> */}{" "}
                                                    <BsBuildingsFill
                                                        style={{
                                                            color: "#E95252",
                                                            fontSize: "22px",
                                                        }}
                                                    />
                                                    <span style={{ color: "#2D2727" }}>
                                                        {" "}
                                                        {item?.Department?.name}
                                                    </span>
                                                </div>
                                                <div>
                                                    <svg
                                                        className="me-1"
                                                        width="16"
                                                        height="17"
                                                        viewBox="0 0 16 17"
                                                        fill="8F43EE"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M13 2.73499H12.255V2.25C12.255 1.83999 11.92 1.5 11.505 1.5C11.09 1.5 10.755 1.83999 10.755 2.25V2.73499H8.75V2.25C8.75 1.83999 8.41501 1.5 8 1.5C7.58499 1.5 7.25 1.83999 7.25 2.25V2.73499H5.245V2.25C5.245 1.83999 4.91001 1.5 4.495 1.5C4.07999 1.5 3.745 1.83999 3.745 2.25V2.73499H3C1.48498 2.73499 0.25 3.96499 0.25 5.48498V12.75C0.25 14.265 1.48498 15.5 3 15.5H13C14.515 15.5 15.75 14.265 15.75 12.75V5.48498C15.75 3.96499 14.515 2.73499 13 2.73499ZM14.25 6.31H1.75V5.48498C1.75 4.79498 2.31 4.23499 3 4.23499H3.745V4.69C3.745 5.10498 4.07999 5.44 4.495 5.44C4.91001 5.44 5.245 5.10498 5.245 4.69V4.23499H7.25V4.69C7.25 5.10498 7.58499 5.44 8 5.44C8.41501 5.44 8.75 5.10498 8.75 4.69V4.23499H10.755V4.69C10.755 5.10498 11.09 5.44 11.505 5.44C11.92 5.44 12.255 5.10498 12.255 4.69V4.23499H13C13.69 4.23499 14.25 4.79498 14.25 5.48498V6.31Z"
                                                            fill="#E95252"
                                                        />
                                                    </svg>
                                                    <span>
                                                        {format(new Date(item?.createdAt), dateFormat)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default UserDashboard
