import React, { useEffect } from "react";
import { useState } from "react";
import EndUserNavbar from "./../NavBar/EndUserNavbar";
import Forms from "./Forms";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { API_ROOT_URL } from "../../jsx/constants";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import jwtDecode from "jwt-decode";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EndUserDashboard = () => {
  const navigate = useHistory();
  const token = useSelector((state) => state.auth.auth.token);
  const [open, setOpen] = React.useState(true);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [testData, setTestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const decoded = jwtDecode(token);
  const testID = decoded.test_visible;
  
  const handleClose = (event,reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return;
    }
    setOpen(false);

  };

  async function getTestDetails(token) {
    return fetch(
      `${API_ROOT_URL}/v3/tests/fetch-tests-details/${testID}?applicant=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
  }
  
  const handlepageLoad = async () => {
    try {
      const response = await getTestDetails(token);
      if ("status" in response && response.status === true) {
        setTestData(response.data);
        setLoading(false)
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAcknowledged && isAcknowledged) {
      handlepageLoad();
    }
  }, [token,isAcknowledged]);

  const openFullscreen = () => {
    const elem = document.documentElement; // Updated to target the whole document
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const ViewTestScreen = () => {
    openFullscreen()
    navigate.push(`/test-forms/${testID}`);
  };

  return (
    <div>
      <div style={{ minHeight: "100vh" }}>
        <EndUserNavbar />
        <React.Fragment>
          <div style={{ width: "500px" }}>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Test Acknowledgement
              </DialogTitle>
              {/* <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton> */}
              <DialogContent dividers>
                <Typography gutterBottom>
                  Your Evaluation is about to start please read & acknowledge
                  the following terms.
                </Typography>
                <Typography gutterBottom>
                  1. If you try to exit fullscreen by pressing any key then you will
                  fail the test
                  <br></br>
                  2.Review your answer before submitting, once submit No changes allowed.
                  <br></br>
                  3. If you want to skip any question just press skip button.
                </Typography>
                <Typography gutterBottom></Typography>
              </DialogContent>

              <div className="d-flex justify-content-end align-items-end mx-3 space-between">
                <div
                  className="acknowledgement-checkbox"
                  style={{ marginBottom: "15px" }}
                >
                  <input
                    style={{
                      marginBottom: "10px",
                      width: "15px",
                      height: "15px",
                    }}
                    type="checkbox"
                    id="acknowledgement-checkbox"
                    checked={isAcknowledged}
                    onChange={() => setIsAcknowledged((prev) => !prev)}
                  />
                  <label htmlFor="acknowledgement-checkbox">
                    <span
                      className="mt-2 mb-3"
                      style={{
                        color: "gray",
                        fontWeight: "600",
                        marginRight: "20px",
                      }}
                    >
                      I Acknowledge{" "}
                    </span>
                  </label>
                </div>

                <Button
                  className="mt-2 mb-3"
                  variant="contained"
                  // onClick={openFullscreen}
                  autoFocus
                  disabled={!isAcknowledged}
                  onClick={ViewTestScreen}
                >
                  Start Test
                </Button>
              </div>
            </BootstrapDialog>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default EndUserDashboard;
