
const tableStyles = {
  
    headCells: {
      style: {
        background: "#3a3630",
        // background: "-webkit-linear-gradient(left, #000000, #3a3630)",
        // background: "-moz-linear-gradient(left, #000000, #3a3630)",
        // background: "-ms-linear-gradient(left, #000000, #3a3630)",
        // background: -"o-linear-gradient(left, #000000, #3a3630)",
        // background: "linear-gradient(to right, #000000, #3a3630)",
        fontSize: '16px',
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        fontWeight: 'bolder',
        fontWeight:"500",
        color: "white",
        // backgroundColor: "#3A3630",    
        fontFamily: 'sans-serif',
        marginTop : "5px",
        borderRadius: "1px", 
        paddingTop : "13px",
        paddingBottom : "13px",
      },
    },
    rows: {
		style: {
			fontSize: '14px',
      // fontFamily:"Sans-serif",
      justifyContent: 'center',
      }
    },
    headRow: {
      style: {
        // color:'#223336',
        // backgroundColor: '#e7eef0'
      },
    },
    striped: {
        default: 'red'
    },
  
    cells: {
      style: {
        justifyContent: ''
      }
    },
    ClearButton:{
      style:{
        color:"white",
        backgroundColor:"#E95252",
        
      }
    },
    title:{
      style:{
        backgroundColor:"blue"
      }
    },
    subHeader:{
      style:{
        borderTopLeftRadius:"10px",
        borderTopRightRadius:"10px",
        
      },
      justifyContent:"space-around !important"
    },
  
   
  
    
  }
  export { tableStyles };