import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { logout } from "../../../store/actions/AuthActions";
import { API_ROOT_URL } from "../../constants";
import LoadingSpinner from "../../pages/LoadingSpinner";
import { getInduction } from "../APIs";
import SlidesList from "./SlidesList";

async function addSlide(formValues, token) {
    return fetch(`${API_ROOT_URL}/slides/`, {
        method: "POST",
        headers: {
            "x-access-token": token,
        },
        body: formValues,
    }).then((data) => data.json());
}

const StepTwo = (props) => {
    const dispatch = useDispatch();
    //const { id } = useParams();
    //const token = useSelector((state) => state.auth.auth.token);
    const [loading, setLoading] = useState(true);
    const [slides, setSlides] = useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [inductionIdDelete, setInductionID] = useState("");
    // const dispatch = useDispatch();
    const { id } = useParams(); // this is induction id
    const navigate = useHistory();
    const token = useSelector((state) => state.auth.auth.token);
    const history = useHistory();

    const intialState = {
        slideInductionId: id,
        slideTitle: "",
        slideContent: "",
        order: "",
    };

    const [state, setState] = useState(intialState);
    const editor = useRef(null);

    //Validation messages
    let errorsObj = { slideTitle: "", slideContent: "" };
    const [errors, setErrors] = useState(errorsObj);

    // on click validation remove function
    function handleKeyPress(e) {
        var key = e.key;
        if (key == key) {
            setErrors(errorsObj == false);
        }
    }

    // inside async fuinction
    const callingAPI = async (inductionID, token) => {
        setInductionID(inductionID)
        const response = await getInduction(inductionID, token);
        if ("status" in response && response.status == true) {
            setSlides(response?.slides);
            setLoading(false);
        }
    };

    // Deleteing Slides
    const deleteSlide = (id) => {
        swal({
            title: "Are you sure?",
            text: `Once Slide Deleted, Slide will not show inside slide listing for Users`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willChange) => {
            if (willChange) {
                const response = await fetch(`${API_ROOT_URL}/slides/delete/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": token,
                    },
                }).then((data) => data.json());
                if ("status" in response && response.status == true) {
                    swal("Poof! Your slide has been Deleted!", {
                        icon: "success",
                    }).then(() => {
                        callingAPI(inductionIdDelete, token)
                        //navigate.push(`/update-induction/${id}`)
                        setLoading(false);
                    });
                } else {
                    if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
                        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                            dispatch(logout(props.history));
                        });
                    } else {
                        return swal("Failed", "Error message", "error");
                    }
                }
            } else {
                swal("Your Slide is not Deleted!");
            }
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (state.slideTitle === "") {
            errorObj.slideTitle = "Title is Required!";
            error = true;
        }
        if (state.slideContent === "") {
            errorObj.slideContent = "Slide Content is Required!";
            error = true;
        }

        setErrors(errorObj);
        if (error) return;

        const formData = new FormData(e.target);
        const response = await addSlide(formData, token);

        if ("status" in response && response.status === true) {
            swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
            }).then((value) => {
                callingAPI(id, token);
                setModalOpen(false);

                // Reset form values to initial state
                setState(intialState);
            });
        } else {
            if (
                response?.error?.message === "jwt expired" ||
                response?.message === "Authentication Required"
            ) {
                swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                    dispatch(logout(props.history));
                });
            } else if (response?.devMessage === "userDeleted") {
                Swal.fire({
                    title: "Access Denied",
                    text: "Your Account Has been deleted",
                    icon: "error",
                    confirmButtonColor: "#3a3630",
                    confirmButtonText: "OK",
                    footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
                }).then(() => {
                    dispatch(logout(props.history));
                });
            } else {
                return swal("Failed", response?.message, "error");
            }
        }
    };


    const handleBeforeUnload = () => {
        sessionStorage.removeItem('fakePayload');
        //alert('Are you sure?');
    };

    const handlePopState = () => {
        console.log('Popstate event triggered');
        sessionStorage.removeItem('fakePayload');
        // alert('Are you sure?');
    };

    useEffect(() => {
        const unlisten = history.listen(() => {
            sessionStorage.removeItem('fakePayload');
            // alert('Are you sure?');
        });

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            unlisten();
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [history]);


    useEffect(() => {
        if (loading) {
            callingAPI(id, token);
        }
    }, [])

    // useEffect(() => {
    //     callingAPI();
    // }, [isModalOpen])

    return (
        <>
            <div className="card-header">
                <h4 className="card-title"></h4>
            </div>


            <Modal
                className="modal fade d-flex"
                show={isModalOpen}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-content" style={{ width: "60vw" }}>
                    <div className="modal-header">
                        <h5 className="modal-title text-white fs-20">
                            Slide Details{" "}
                        </h5>
                        <RxCross1
                            size={25}
                            color="white"
                            onClick={() => setModalOpen(false)}
                        />
                    </div>
                    <div className="modal-body">
                        <form
                            className="company-form"
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            <input
                                type="hidden"
                                name="slideInductionId"
                                value={state.slideInductionId}
                            />

                            <div className="card-body">
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">
                                        Slide Title
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            onChange={(e) =>
                                                setState({ ...state, slideTitle: e.target.value })
                                            }
                                            name="slideTitle"
                                            value={state.slideTitle}
                                        //onKeyPress={(e) => handleKeyPress(e)}
                                        />
                                        {errors.slideTitle && (
                                            <div Style="color:red;font-weight:400">
                                                {errors.slideTitle}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">
                                        Slide Content
                                    </label>
                                    <div className="col-sm-9" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                        <JoditEditor
                                            name="slideContent"
                                            rows={10}
                                            ref={editor}
                                            value={state.slideContent}
                                            tabIndex={2}
                                            onBlur={(newContent) => setState({ ...state, slideContent: newContent })}
                                            onChange={(newContent) => setState({ ...state, slideContent: newContent })}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                        />
                                        {errors.slideContent && (
                                            <div style={{ color: 'red', fontWeight: 400 }}>
                                                {errors.slideContent}
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>

                            <div class="text-end toolbar toolbar-bottom p-2">
                                <button class="submitButtonInduction" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            {
                loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <SlidesList
                            setSlides={setSlides}
                            Slides={slides}
                            inductionID={id}
                            deleteSlide={deleteSlide}
                            setModalOpen={setModalOpen}
                        />
                    </>
                )}
        </>
    );
};

export default StepTwo;