import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { API_ROOT_URL } from "../../constants";
import { RxCross1 } from "react-icons/rx";

// // api call
// async function getEvents(token) {
//   return fetch(`${API_ROOT_URL}/api/users//getting/events`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "x-access-token": token,
//     },
//   }).then((data) => data.json());
// }

const EditEvent = ({
  props,
  eventData,
  isUpdate,
  setEventData,
  isShowEditModal,
  onClickHandler,
  editId,
}) => {

  //  console.log(editId,"editId...")
  const navigate = useHistory();

  const editEvent = eventData?.find(i => i._id === editId);
  // console.log(requiredEvent,"requiredEvent")
  //console.log(editEvent, "eve")
  const token = useSelector((state) => state.auth.auth.token);

  const [title, setTitle] = useState(editEvent?.title);
  const [description, setDescription] = useState(editEvent?.description);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  useEffect(() => {
    if (editEvent && Object.keys(editEvent).length !== 0) {
      setStart(new Date(editEvent?.start))
      setEnd(new Date(editEvent?.end))
    }
  }, [editEvent])

  const [loading, setLoading] = useState(true);

  // to get latest Events
  async function getEvents(token) {
    return fetch(`${API_ROOT_URL}/users/getting/events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const handlepageLoadEvent = async (event) => {
    const response = await getEvents(token);
    if ("status" in response && response.status == true) {
      setEventData(response.data);
      setLoading(false);
    } else {
      if (response.error.message === "jwt expired") {
        return swal("Session Timeout ! ", "Please Login Again", "error");
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  const onSubmitHandle = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("start", start);
    data.append("end", end);

    const response = await fetch(
      `${API_ROOT_URL}/instructor/update_event/${editId}`,
      {
        method: "PUT",
        body: data,
        headers: {
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
    if ("status" in response && response.status == true) {

      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 1000,
      }).then((value) => {
        onClickHandler();
        handlepageLoadEvent();
      });
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  useEffect(() => {
  }, [isShowEditModal, loading]);

  return (
    <>
      <Modal
        className="modal d-flex fade show" show={isShowEditModal} size="sm"
        // centered
        style={{ backgroundColor: "transparent ! important" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">EDIT EVENT </h3>
            <RxCross1 size={25} color="white" onClick={onClickHandler} />


            {/* <Button
              variant=""
              type="button"
              className="btn-close"
              data-dismiss="modal"
              onClick={onClickHandler}
              //onClick={() => setIsModalOpen(false)}
            ></Button> */}
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => onSubmitHandle(e)}>
              <div className="card-body">
                <div className="form-group mb-3">
                  <h5>Enter Title and Time</h5>
                  <input type="hidden" name="inductionID" />
                  <div className="form-group ">
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Add Title"
                      // onChange={(e) => {
                      //   setFormState({ ...formState, title: e.target.value });
                      // }}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}

                    />
                  </div>
                </div>

                <div className="basic-form">
                  <div className="form-group ">
                    <div className="row">
                      <div className="col-lg-6 col-md-4">
                        <div className="form-group mb-3">
                          <h5>Start Date</h5>
                          <input type="hidden" name="mainID" />
                          <DatePicker
                            className="btn border-light"
                            selected={start}
                            onChange={(date) => setStart(date)}
                          // onChange={(e) => {
                          //   setFormState({ ...formState, start:date });
                          // }}
                          // value={formState.start}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <h5>End Date</h5>
                          <DatePicker
                            className="btn border-light"
                            selected={end}
                            onChange={(date) => setEnd(date)}
                          // onChange={(e) => {
                          //   setFormState({ ...formState, end:date});
                          // }}
                          // value={formState.end}
                          />
                        </div>
                      </div>
                    </div>

                    <h5>Description</h5>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        rows="2"
                        id="comment"
                        name="description"
                        placeholder="Enter Description"
                        // onChange={(e) =>
                        //   setFormState({ ...formState, description: e.target.value })
                        // }
                        // value={formState.description}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    className="submitButtonInduction"
                    variant="primary"
                    size="m"
                    style={{ display: "flex", margin: "auto" }}
                    type="submit"
                  >
                    Submit
                  </button>{" "}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditEvent;
