import jwt_decode from "jwt-decode";
import Lottie from "lottie-react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { API_ROOT_URL } from "../../constants";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
import CompanyDropdown from "../Companies/CompanyDropdown";
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import UpdateProfile from "./UpdateProfile";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import RoleTable from "../SkeletonLoader/RoleTable";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
//date range filter imports
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department",
};

const Instructors = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const userID = useSelector((state) => state.auth.auth.id);
  //const departmentID = useSelector((state) => state.auth.auth._id);
  const role = useSelector((state) => state.auth.auth.role);
  const token = useSelector((state) => state.auth.auth.token);
  const decoded = jwt_decode(token);
  const parentCompany = useSelector((state) => state.auth.auth.id);
  const [companyID, setcompanyID] = useState();
  const [loading, setLoading] = useState(true);
  const [deptID, setDeptID] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserStatusChanged, setIsUserStatusChanged] = useState(false);
  const [test, setTest] = useState([]);
  //const [mainID, setMainID] = useState("")

  const [instructorData, setInstructorData] = useState({
    profile: {
      name: "",
      email: "",
      aboutMe: "",
      address: "",
      logo: "",
      _id: "",
    },
  });
  const [instructorsList, setInstructosList] = useState([]);
  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([])

  //sort function states
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState(null);

  const tokenDetailsString = localStorage.getItem('userDetails');
  let mainID;

  const getID = (deptID) => {
    return decoded?.deptID;
  };

  mainID = getID(decoded?.deptID);

  // api call
  async function getInstructorApi(role, companyID) {

    var getInstructorsApi = `${API_ROOT_URL}/instructor/list`;
    if (USER_ROLES.COMPANY === role) {
      var getInstructorsApi = `${API_ROOT_URL}/instructor/listByCompany?role=company&parentCompany=${companyID}`;
    }
    if (USER_ROLES.DEPARTMENT === role && mainID) {
      var getInstructorsApi = `${API_ROOT_URL}/instructor?deptID=${mainID}`;
    }
    return fetch(getInstructorsApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());

  }

  /**
   * Instructor API call to filter byb company.
   *
   * @param {*} companyID
   * @returns
   */
  async function filterInstructorApi(companyID, deptID) {
    let filterInstructorsApi = `${API_ROOT_URL}/instructor/filterByCompany?filterByCompany=${companyID}`;
    if (companyID === "all") {
      filterInstructorsApi = `${API_ROOT_URL}/instructor/list`;
    }
    return fetch(filterInstructorsApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // change status
  const changeUserStatus = (userID, status) => {
    // user id
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: `Once status Changed, User will get or loss access to account`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(`${API_ROOT_URL}/users/changeUserStatus`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID, status: status }),
          }).then((data) => data.json());

          if ("status" in response && response.status === true) {
            swal("Poof! Your record has been updated!", {
              icon: "success",
            }).then(() => {
              setIsUserStatusChanged(!isUserStatusChanged);
            });
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal("Your status is not changed!");
        }
      });
    }
  };

  // callback function to opdate state
  const trackOnclick = (payload, userData) => {
    setIsModalOpen(payload);
    if (payload) {
      setInstructorData(userData);
    }
  };
  // callback function to opdate state
  const trackDeleteClick = (userID, deleted) => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to delete this record ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(
            `${API_ROOT_URL}/instructor/deleteInstStatus`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
              body: JSON.stringify({ userID: userID, deleted: deleted }),
            }
          ).then((data) => data.json());
          if ("status" in response && response.status === true) {
            swal("Poof! Your record has been deleted!", {
              icon: "success",
            });
            const response = await getInstructorApi(role, parentCompany);
            if ("status" in response && response.status === true) {
              /* Prepare data for instructor data-table list, start */
              setInstructosList(response.data);
            }
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal("Your record is safe!");
        }
      });
    }
  };

  const filterByCompany = (companyID) => {
    setcompanyID(companyID)
    const handlepageLoad = async (event) => {
      const response = await filterInstructorApi(companyID);

      if ("status" in response && response.status === true) {
        setInstructosList(response.data);
        setLoading(false);
      } else {
        return swal(
          "Failed",
          "Something went wrong, please try again later.",
          "error"
        );
      }
    };
    handlepageLoad();
  };

  /**
   *
   * @param {*} e
   * @returns
   */
  const filterByDepartment = async (e) => {
    setDeptID(e.target.value);

    const URL = `${API_ROOT_URL}/instructor?deptID=${e.target.value}&filterByCompany=${companyID}`;
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());

    if ("status" in response && response.status === true) {
      /* Prepare data for instructor data-table list, start */
      setInstructosList(response.data);
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  // use effect
  useEffect(() => {
    const handlepageLoad = async (event) => {
      if (role === "department") {
        const response = await getInstructorApi(role, parentCompany);
        if ("status" in response && response.status === true) {
          /* Prepare data for instructor data-table list, start */
          setInstructosList(response.data);
          setLoading(false);
        } else {
          setLoading(false);

          if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
            swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
              dispatch(logout(props.history));
            });
          }
        }
      } else {
        const response = await getInstructorApi(role, parentCompany);
        if ("status" in response && response.status === true) {
          /* Prepare data for instructor data-table list, start */
          setInstructosList(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
            swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
              dispatch(logout(props.history));
            });
          }
        }
      }
    }
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      handlepageLoad();
    }
  }, [isModalOpen, isUserStatusChanged, loading]);

  const style = {
    height: 150,
  };


  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'profile.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      formattedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };



  useEffect(() => {
    const Instructors = instructorsList.map((inst) => {
      const { profile } = inst;
      const formattedDate = new Date(inst.createdAt)
      const newStatus = inst.status == true ? "active" : "In-active";
      return { ...inst, formattedDate, newStatus };
    })
    setModifiedData(Instructors)
  }, [instructorsList])

  useEffect(() => {
    getID();
    initFilters();
  }, []);

  const renderHeader = () => {
    return (
      <>
        {USER_ROLES.SUPER_ADMIN === role ? (
          <Row>
            <Col lg="2">
              <select
                name="parentCompany"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                className="select btn mb-3"
                onChange={(e) => filterByCompany(e.target.value)}
              >
                <option value="all">COMPANY</option>
                <CompanyDropdown />
              </select>
            </Col>
            <Col lg="2">
              <select
                name="deptID"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                className="select btn mb-3"
                value={deptID}
                onChange={(e) => filterByDepartment(e)}
              >
                <option value="all">DEPARTMENT</option>
                <GetDepartmentByComp parentCompany={companyID} />
              </select>
            </Col>
            <Col lg="8">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}
        {USER_ROLES.COMPANY === role ? (
          <Row>
            <Col lg="2">
              <select
                name="deptID"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                className="select btn mb-3"
                value={deptID}
                onChange={(e) => filterByDepartment(e)}
              >
                <option value="all">DEPARTMENT</option>
                <GetDepartmentByComp parentCompany={userID} />
              </select>
            </Col>
            <Col lg="10">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}
        {USER_ROLES.DEPARTMENT === role ? (
          <Row>
            <Col lg="12">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}
      </>
    );
  };
  const header = renderHeader();

  const joinDateTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(rowData.createdAt).toLocaleDateString("en-GB", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>
      </>
    );
  };

  const statusTemplate = (rowData) => {
    const isActive = rowData?.status === true;
    return (
      <>
        <label class="switch">
          <input type="checkbox" id="togBtn" checked={isActive} to="/instructors"
            onClick={() => changeUserStatus(rowData?._id, rowData?.status)}
          />
          <div class="slider round">
            <span class="on">Active</span>
            <span class="off">Inactive</span>
          </div>
        </label>
      </>
    );
  };

  const actionTemplate = (rowData) => {

    const handleCallback = () => {
      trackOnclick(true, rowData);
    };
    const deleteClick = () => {
      trackDeleteClick(rowData.profile._id, rowData.profile.deleted);
    };

    return (
      <>
        <div className="d-flex">
          <button onClick={handleCallback} className="editIcon">
            <i className="fas fa-pencil-alt" style={{ fontSize: "18px" }}></i>
          </button>

          <button onClick={deleteClick} className="deleteIcon">
            <i className="fa fa-trash" style={{ fontSize: "18px" }}></i>
          </button>
        </div>
      </>

    );
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(instructorsList)
  }

  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
  }

  const handleSelect = (ranges) => {

    let filtered = instructorsList.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };

  const dateRangePickerTemp = () => {

    return (
      <>
        {/* <button onClick={handleAfterDate}>After</button> */}
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelect}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>
    )
  }

  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(instructorsList)
  };



  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-3">
              <div className="table-responsive">
                {loading ? <RoleTable /> : <DataTable
                  value={modifiedData?.map((rowData, index) => ({
                    ...rowData,
                    serialNumber: index + 1,
                  }))}
                  header={header}
                  dataKey="id"
                  emptyMessage={
                    <div className="queryContainer">
                      {" "}
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                          <div className="form-input-content text-center">
                            <h1 className="error-text font-weight-bold">
                              <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                            </h1>
                            <h3
                              className="fs-28 mt-3"
                              style={{ color: "#3A3630", fontWeight: "900" }}
                            >
                              {" "}
                            </h3>
                            <h4 style={{ fontStyle: "italic" }}>
                              No Records Found !
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  filters={filters}
                  globalFilterFields={[
                    "profile.name",
                    "email",
                  ]}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  size="large"
                  stripedRows
                  showGridlines
                  tableStyle={{ minWidth: "50rem" }}
                  filterDisplay="menu"
                >
                  <Column
                    header="SR.NO" headerStyle={{ width: "5%" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>

                  <Column field="profile.name"
                    sortable header="NAME"
                    filterField="profile.name"
                    style={{ width: '30%' }}
                    filter
                    filterPlaceholder="Search by Name" />

                  <Column
                    field="email"
                    sortable
                    header="EMAIL"
                    filterField="email"
                    style={{ width: '25%' }}
                    filter
                    filterPlaceholder="Search by Email" />

                  <Column
                    field="createdAt"
                    body={joinDateTemplate}
                    style={{ width: '10%' }}
                    header={<>
                      <div>
                        <Row className="InstructorFilter">
                          <Col sm="10">
                            <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                              JOIN DATE
                              {" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>

                          </Col>
                          <Col sm="2">
                            <CiFilter className="" size={22} onClick={openDateRangePicker} />
                          </Col>
                        </Row>
                      </div>

                      {showDateRangePicker && (
                        <Modal className="customModal d-flex"
                          show={openDateRangePicker}
                          size="sm"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered>
                          <div className="modal-header customModalHeader">
                            <h6 className="modal-title text-white fs-20">
                              {" "}SELECT DATE / DATE RANGE
                            </h6>
                            <div className="cross-outer-icon">
                              <RxCross1
                                className="cross-icon"
                                size={22}
                                color="white"
                                onClick={closeDateRangePicker}
                              />
                            </div>

                          </div>
                          <div className="modal-content customModalContent">

                            <div>
                              {dateRangePickerTemp()}
                            </div>
                          </div>
                          <Modal.Footer className="d-flex modal-footer customModalFooter">
                            <button className="clearButton" onClick={handleClear}>Clear</button>
                            <button className="applyButton" onClick={handleApply}>Apply</button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </>}
                  />
                  <Column
                    field="status"
                    body={statusTemplate}
                    sortable
                    header="STATUS"
                    style={{ width: '10%' }}
                  ></Column>
                  <Column body={actionTemplate}
                    style={{ width: '10%' }}
                    header="ACTIONS"></Column>
                </DataTable>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen ? (
        <UpdateProfile
          isModalOpen={isModalOpen}
          trackOnclick={trackOnclick}
          instructorData={instructorData}
        ></UpdateProfile>
      ) : null}
    </>
  );
};
export default Instructors;