import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import CompanyDropdown from "./CompanyDropdown";
import DepartmentByCompany from "../Department/DepartmentByCompany";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import { BsXCircleFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import s3 from "../../../utilis/aws.config";

const USER_ROLES = {
    SUPER_ADMIN: "super_admin",
    COMPANY: "company",
    INSTRUCTOR: "instructor",
    USER: "user",
    DEPARTMENT: "department",
};

const AssignUsers = ({ isModalOpen, trackOnclick, profileData, history }) => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const id = useSelector((state) => state.auth.auth.id);
    const instructorParentCompany = useSelector(
        (state) => state.auth.auth.parentCompany
    ); // used in case of instructor
    const token = useSelector((state) => state.auth.auth.token);
    const userRole = useSelector((state) => state.auth.auth.role);

    // Create a state variable to track if any field is changed
    const [isFormChanged, setFormChanged] = useState(false);

    const intialValue = {
        parentCompany: profileData?.parentCompany,
        deptID: profileData?.deptID,
        userID: profileData?.profile?._id,
        email: profileData?.email,
        first_name: profileData?.profile?.first_name,
        last_name: profileData?.profile?.last_name,
        aboutMe: profileData?.profile?.aboutMe,
        address: profileData?.profile?.address,
        profilePhoto: profileData?.profile?.profilePhoto,
    };

    const [image, setImage] = useState({ preview: "", data: "" });
    const [state, setState] = useState(intialValue);

    //deselect
    const [showClearButton, setShowClearButton] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        // validate data
        if (
            state.parentCompany === "" ||
            state.deptID === "" ||
            state.first_name === "" ||
            state.last_name === ""
        ) {
            return swal("Failed", "Please enter all required fields!", "error");
        }
        if (localStorage.getItem("userDetails") === null) {
            swal("Something went wrong! ", "Please Login Again", "error").then(() => {
                dispatch(logout(history));
            })
        } else {
            let formData = new FormData(e.target);

            const response = await fetch(`${API_ROOT_URL}/users/edit/${state.userID}`, {
                method: "PUT",
                body: formData,
                headers: {
                    "x-access-token": token,
                },
            }).then((data) => data.json());

            if ("status" in response && response.status == true) {
                return swal("Success", response.message, "success", {
                    buttons: false,
                    timer: 2000,
                }).then((value) => {
                    handleCallback();
                    //profile
                    navigate.push("/users");
                });
            } else {
                return swal("Failed", response.message, "error");
            }
        }
    };

    const handleCallback = () => {
        trackOnclick(false);
    };

    const handleInputChange = () => {
        // Set formChanged to true when any field is changed
        setFormChanged(true);
    };

    // logo image uploader
    const handleFileChange = async (e) => {
        const image = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        };
        setImage(image);
        if (e.target.value) {
            setShowClearButton(true);
        } else {
            setShowClearButton(false);
        }
        setFormChanged(true); // Set formChanged to true when any field is changed
    };
    const handleClearButtonClick = () => {
        setImage(false);
        setShowClearButton(false);
        const inputElement = document.querySelector('input[type="file"]');
        inputElement.value = "";
    };

    useEffect(() => {
        setState(intialValue);
        setFormChanged(false); // Reset the formChanged state when the data is loaded
    }, [isModalOpen]);

    // Get the signed URL for the profile image
    const url = s3.getSignedUrl("getObject", {
        Bucket: AWS_BUCKET_NAME,
        Key: `${PROFILE_ASSETS_URL}/${state.profilePhoto}`,
    });

    return (
        <Modal className="modal fade d-flex" show={isModalOpen}>
            <div
                className="modal-content"
                style={{
                    width: "60vw",
                    borderRadius: "4px",
                    border: "1px solid #3A3630",
                }}
            >
                <div className="modal-header " style={{ backgroundColor: "#3A3630" }}>
                    <h5 className="modal-title text-white fs-20">UPDATE PROFILE</h5>
                    <RxCross1 size={25} color="white" onClick={handleCallback} />
                </div>
                <div className="modal-body">
                    <form className="update-form" onSubmit={handleSubmit}>
                        <div className="row">
                            {USER_ROLES.SUPER_ADMIN === userRole ? (
                                <CompanyDropdown
                                    selectedVal={profileData?.parentCompany}
                                    selectedDeptVal={profileData?.deptID}
                                    handleInputChange={handleInputChange} // Pass the function to the CompanyDropdown
                                />
                            ) : null}

                            <div className="col-lg-12">
                                {USER_ROLES.COMPANY === userRole ? (
                                    <div className="form-group mb-3">
                                        <input type="hidden" name="parentCompany" value={id} />
                                        <label className="form-label fs-6 fw-bold">Department
                                            <span
                                                className="required-field"
                                                style={{ color: "red", fontWeight: "200px" }}
                                            >
                                                *
                                            </span>
                                        </label>
                                        <select
                                            className="btn border-light bg-white col-sm-12"
                                            style={{ borderRadius: "4px" }}
                                            name="deptID"
                                            value={state.deptID}
                                            onChange={(e) => {
                                                setState({ ...state, deptID: e.target.value });
                                                handleInputChange(); // Set formChanged when input changes
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <DepartmentByCompany
                                                parentCompany={id}
                                                selectedDeptVal={state.deptID}
                                            />
                                        </select>
                                    </div>
                                ) : null}

                                {USER_ROLES.INSTRUCTOR === userRole || USER_ROLES.DEPARTMENT === userRole ? (
                                    <div className="form-group mb-3">
                                        <input
                                            type="hidden"
                                            name="parentCompany"
                                            value={instructorParentCompany}
                                        />
                                        <label className="form-label fs-6 fw-bold">Department
                                            <span
                                                className="required-field"
                                                style={{ color: "red", fontWeight: "200px" }}
                                            >
                                                *
                                            </span>
                                        </label>
                                        <select
                                            className="btn border-light bg-white col-sm-12"
                                            style={{ borderRadius: "4px" }}
                                            name="deptID"
                                            value={state.deptID}
                                            onChange={(e) => {
                                                setState({ ...state, deptID: e.target.value });
                                                handleInputChange(); // Set formChanged when input changes
                                            }}
                                        //required
                                        >
                                            <option value="">Select</option>
                                            <DepartmentByCompany
                                                parentCompany={instructorParentCompany}
                                                selectedDeptVal={profileData?.deptID}
                                            />
                                        </select>
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                                        {" "}
                                        First Name{" "}
                                    </label>
                                    <input type="hidden" name="mainID" value={profileData?._id} />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="first_name"
                                        value={state.first_name}
                                        onChange={(e) => {
                                            setState({ ...state, first_name: e.target.value });
                                            handleInputChange(); // Set formChanged when input changes
                                        }}
                                        readOnly
                                        style={{ backgroundColor: "lightgray", cursor: "not-allowed" }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                                        {" "}
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        value={state.last_name}
                                        onChange={(e) => {
                                            setState({ ...state, last_name: e.target.value })
                                            handleInputChange(); // Set formChanged when input changes
                                        }}
                                        readOnly
                                        style={{ backgroundColor: "lightgray", cursor: "not-allowed" }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                                        Profile Picture
                                    </label>
                                    <input
                                        type="hidden"
                                        value={state.profilePhoto}
                                        name="profilePhoto"
                                    />
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        readOnly
                                        disabled // Add the disabled attribute to disable the input
                                        style={{ backgroundColor: "lightgray", cursor: "not-allowed" }}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                                        {" "}
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={state.email}
                                        onChange={(e) =>
                                            setState({ ...state, email: e.target.value })
                                        }
                                        disabled
                                        style={{ backgroundColor: "lightgray", cursor: "not-allowed" }}
                                    />
                                </div>
                            </div>
                            {image.data ?
                                <>
                                    <div className="col-lg-12">
                                        <div className="instructors-media">
                                            {image && (
                                                <div style={{ marginTop: 10 }}>
                                                    <img src={image.preview} width={150} />
                                                    {showClearButton && (
                                                        <BsXCircleFill
                                                            size={25}
                                                            style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                                                            onClick={handleClearButtonClick}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                            <div className="user-media">
                                                <img
                                                    src={url}
                                                    className="img-thumbnail mb-3"
                                                    width={150}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }


                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                                        About me
                                    </label>
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        name="aboutMe"
                                        value={state.aboutMe}
                                        onChange={(e) => {
                                            setState({ ...state, aboutMe: e.target.value })
                                            handleInputChange(); // Set formChanged when input changes
                                        }}
                                        readOnly
                                        style={{ backgroundColor: "lightgray", cursor: "not-allowed" }}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                                        Address
                                    </label>
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        name="address"
                                        value={state.address}
                                        onChange={(e) => {
                                            setState({ ...state, address: e.target.value })
                                            handleInputChange(); // Set formChanged when input changes
                                        }}
                                        readOnly
                                        style={{ backgroundColor: "lightgray", cursor: "not-allowed" }}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group mb-2 mt-3">
                                    <input
                                        type="submit"
                                        value="Update Profile"
                                        className={`submits ${isFormChanged ? 'enabled' : ''}`}
                                        style={{ margin: "auto", display: "flex" }}
                                        name="submit"
                                        disabled={!isFormChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default AssignUsers;
