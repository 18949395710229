
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Skeleton } from 'primereact/skeleton';
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';

function AttemptsTable() {
    const location = useLocation();
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    const renderHeader = () => {
        return (
            <>
                <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                    <span className="p-input-icon-left">
                        <i className="fa fa-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Keyword Search"
                        />
                    </span>
                </div>


            </>
        );
    };

    const header = renderHeader();
    const items = Array.from({ length: 5 }, (v, i) => i);
    const bodyTemplate = () => {
        return <Skeleton length={10}></Skeleton>
    }

    const circle = () => {
        return (
            <>
                <div className="w-full md:w-5 p-2">
                    <Skeleton width="6rem" height="2rem"></Skeleton>
                </div>
            </>
        )
    }

    return (
        <div className="col-xl-12">
            <div className="table-responsive">
                <DataTable size="large"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    stripedRows
                    showGridlines
                    tableStyle={{ minWidth: "50rem" }}
                    header={header} value={items} className="p-datatable-striped">
                    <Column header="Sr.No" style={{ width: '5%' }} body={bodyTemplate}></Column>
                    <Column header="NAME" style={{ width: '30%' }} body={bodyTemplate}></Column>
                    <Column header="INDUCTION" style={{ width: '30%' }} body={bodyTemplate}></Column>
                    <Column header="ATTEMPTED INDUCTIONS" style={{ width: '20%' }} body={bodyTemplate}></Column>
                    <Column header="ACTIONS" style={{ width: '10%' }} body={circle}></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default AttemptsTable;