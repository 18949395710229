import JoditEditor from "jodit-react";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { BsXCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ReactSwitch from "react-switch";
import swal from "sweetalert";
import { API_ROOT_URL, AWS_BUCKET_NAME, INDUCTION_ASSETS_URL } from "../../constants";
import LoadingSpinner from "../../pages/LoadingSpinner";
import { getInduction } from "../APIs";
import CompanyDropdown from "../Companies/CompanyDropdown";
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import TopicDropdown from "../Topics/TopicDropdown";
import s3 from "../../../utilis/aws.config";

const USER_ROLES = {
    SUPER_ADMIN: "super_admin",
    COMPANY: "company",
    INSTRUCTOR: "instructor",
    USER: "user",
    DEPARTMENT: "department"
};

const StepOne = ({ goToStep }) => {
    //console.log(trueNext, "truu")
    //const [isPrivate, setIsPrivate] = useState(false);
    const selectTypeChange = (val) => {
        setIsPrivate(val);
    };
    const { id } = useParams(); // this is induction id
    const editor = {};
    //   // redux store data
    const token = useSelector((state) => state.auth.auth.token);
    const decoded = jwt_decode(token);
    const userRole = useSelector((state) => state.auth.auth.role);
    // page states
    const [loading, setLoading] = useState(true);
    const [thumbnail, setThumbnail] = useState("");
    const [preview, setPreview] = useState("");
    const [induction, setInduction] = useState("");
    const [slides, setSlides] = useState();
    // dropdowns
    const [parentCompany, setParentCompany] = useState();
    const [deptID, setDeptID] = useState();
    const [option, setOption] = useState();
    const [showClearButton, setShowClearButton] = useState("");
    const [subTopicList, setSubTopicList] = useState("");
    const [topicID, setTopicID] = useState("");
    const [subtopicID, setSubTopicID] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    //function for set type of induction
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [description, setDescription] = useState("");
    const [content, setContent] = useState("");

    const [errors, setErrors] = useState({ title: '', subTitle: '' });
    const [isFormValid, setIsFormValid] = useState(false);
    //inside async fuinction
    const callingAPI = async (inductionID, token) => {
        const response = await getInduction(inductionID, token);
        if ("status" in response && response.status == true) {
            setInduction(response.data);
            setParentCompany(response.data.parentCompany);
            setDeptID(response.data.deptID);
            setTopicID(response?.data?.topicID)
            setSubTopicID(response?.data?.subtopicID)
            setThumbnail({ preview: "", data: "" });
            setPreview(response.data.thumbnail);
            setSlides(response.slides);
            setLoading(false);
            setIsPrivate(response.data.isPrivate)
        }
    };
    const handleInputChange = () => {
        // Check if all required fields are filled
        const isValid =
            !!induction.title &&
            !!induction.subTitle &&
            !!parentCompany &&
            !!deptID &&
            !!topicID &&
            !!subtopicID;
        setIsFormValid(isValid);
    };

    const handleTitleChange = (e) => {
        const updatedInduction = { ...induction, title: e.target.value };
        setInduction(updatedInduction);
        if (!e.target.value) {
            setErrors({ ...errors, title: "Title can't be blank" });
        } else {
            setErrors({ ...errors, title: '' });
        }
        handleInputChange(); // Add this line
    };

    const handleSubTitleChange = (e) => {
        const updatedInduction = { ...induction, subTitle: e.target.value };
        setInduction(updatedInduction);
        if (!e.target.value) {
            setErrors({ ...errors, subTitle: "Sub-title can't be blank" });
        } else {
            setErrors({ ...errors, subTitle: '' });
        }
        handleInputChange(); // Add this line
    };

    const handleClearButtonClick = () => {
        setThumbnail(false);
        setShowClearButton(false);
        const inputElement = document.querySelector('input[type="file"]');
        inputElement.value = "";
    };
    const handleCompanyChange = async (e) => {
        setSubTopicList("");
        setTopicID("");
        // call api to fetch departments
        setParentCompany(e.target.value);
        const response = await fetch(
            `${API_ROOT_URL}/department/getDepartmentByComp`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
                body: JSON.stringify({ parentCompany: e.target.value }),
            }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
            const rows = response.data.map((row, index) => (
                <option value={row._id}>{row.name}</option>
            ));
            setOption(rows);
        }
    };

    const handleTopicChange = async (e) => {
        // call api to fetch departments
        setOption("");
        setSubTopicList([]);
        const response = await fetch(
            `${API_ROOT_URL}/department/fetching/subtopicsby/topics/${topicID}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
                //body: JSON.stringify({ TopicID: e.target.value }),
            }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
            const rows = response.data.subtopics.map((row, index) => (
                <option value={row._id}>{row.value}</option>
            ));
            // setSubTopicID("")
            setSubTopicList(rows);
        }
    };
    /**
     *
     * @param {*}
     * Update Induction main submit function
     *
     */

    const handleFileChange = async (e) => {
        const thumbnail = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        };
        setThumbnail(thumbnail);
        if (e.target.value) {
            setShowClearButton(true);
        } else {
            setShowClearButton(false);
        }
        //setFormChanged(true); // Set formChanged to true when any field is changed
    };

    const handleSubmit = async (e) => {
        //e.preventDefault();
        if (errors.title || errors.subTitle) {
            // Prevent form submission if there are errors
            return;
        }

        const updateForm = {
            parentCompany: parentCompany,
            deptID: deptID,
            title: title,
            subTitle: subTitle,
            thumbnail: thumbnail.data,
            description: description,
            content: content,
        };

        if (localStorage.getItem("userDetails") === null) {
            swal("Something went wrong! ", "Please Login Again", "error").then(() => {
                // dispatch(logout(props.history));
            })
        } else {
            let formData = new FormData();
            formData.append("title", induction.title);
            formData.append("subTitle", induction.subTitle);
            formData.append("description", induction.description);
            formData.append("content", induction.content);
            formData.append("parentCompany", parentCompany);
            formData.append("deptID", deptID);
            formData.append("topicID", topicID);
            formData.append("subtopicID", subtopicID);
            formData.append("isPrivate", isPrivate);
            formData.append("thumbnail", thumbnail.data);
            formData.append("thumbnail", preview);

            // Call to API
            const response = await fetch(`${API_ROOT_URL}/induction/${id}`, {
                method: "PUT",
                body: formData,
                headers: {
                    "x-access-token": token,
                },
            }).then((data) => data.json());
            if ("status" in response && response.status == true) {
                // setTrueNext(false)
            }
        }
    };
    const hasErrors = () => {
        const hasTitleError = !!errors.title;
        const hasSubTitleError = !!errors.subTitle;
        const hasTopicError = !topicID;
        const hasSubTopicError = !subtopicID;
        const hasCompanyError = !parentCompany;
        const hasDepartmentError = !deptID;

        return (
            hasTitleError ||
            hasSubTitleError ||
            hasTopicError ||
            hasSubTopicError ||
            hasCompanyError ||
            hasDepartmentError
        );
    };

    useEffect(() => {
        handleTopicChange()
        if (loading) {
            callingAPI(id, token);
        }
    }, [loading, parentCompany, deptID, subtopicID, topicID]);

    // Get the signed URL for the profile image
    const url = s3.getSignedUrl("getObject", {
        Bucket: AWS_BUCKET_NAME,
        Key: `${INDUCTION_ASSETS_URL}/${preview}`,
    });

    return (
        <>
            {loading ? (
                // Display the loading spinner while data is loading
                <LoadingSpinner />
            ) : (

                <div className="col-xl-12 col-lg-12">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">
                                    isPrivate?{" "}
                                </label>
                                <div className="col-sm-9">
                                    <div className="d-flex mb-3">
                                        <ReactSwitch checked={isPrivate} onChange={selectTypeChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row mt-3">
                                <label className="col-sm-3 col-form-label">Title
                                    <span className="required-field" style={{ color: "red" }}> *</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        onChange={(e) => {
                                            handleTitleChange(e);
                                            handleInputChange(); // Set formChanged when input changes
                                        }}
                                        value={induction.title}
                                    />
                                    {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">Sub Title
                                    <span className="required-field" style={{ color: "red" }}> *</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subTitle"
                                        onChange={(e) => {
                                            handleSubTitleChange(e);
                                            handleInputChange()
                                        }}
                                        value={induction.subTitle}
                                    />
                                    {errors.subTitle && <p style={{ color: 'red' }}>{errors.subTitle}</p>}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">Thumbnail</label>
                                <div className="col-sm-9">
                                    <input type="hidden" value={preview} name="thumbnail" />
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="thumbnail"
                                        onChange={handleFileChange}
                                        accept="image/png,image/jpeg,image/jpg,image/gif"
                                    />
                                    {thumbnail.data ?
                                        <>
                                            <div style={{ marginTop: 10 }}>
                                                <img src={thumbnail.preview} width="150" />
                                                {showClearButton && (
                                                    <BsXCircleFill
                                                        size={25}
                                                        style={{ marginBottom: "150px", color: "red" }}
                                                        onClick={handleClearButtonClick}
                                                    />
                                                )}
                                            </div>
                                        </>
                                        : <>
                                            <div style={{ marginTop: 10 }}>
                                                <img
                                                    src={url}
                                                    className="img-thumbnail mb-3"
                                                    width={150}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            {USER_ROLES.SUPER_ADMIN === userRole ? (
                                <>
                                    <div className="form-group mb-3">
                                        <div className="mb-4 row">
                                            <label className="col-sm-3 col-form-label">
                                                Parent Company
                                                <span
                                                    className="required-field"
                                                    style={{ color: "red", fontWeight: "200px" }}
                                                >
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <select
                                                    name="parentCompany"
                                                    className="btn border-light bg-white col-sm-12"
                                                    style={{ borderRadius: "4px" }}
                                                    onChange={(e) => {
                                                        {
                                                            handleCompanyChange(e);
                                                            handleInputChange();
                                                        }
                                                    }}
                                                    value={parentCompany}
                                                >
                                                    <option value="">Select</option>
                                                    <CompanyDropdown prevSelected={parentCompany} />
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-sm-3 col-form-label">
                                            Department
                                            <span
                                                className="required-field"
                                                style={{ color: "red", fontWeight: "200px" }}
                                            >
                                                *
                                            </span>
                                        </label>
                                        <div className="col-sm-9">
                                            <select
                                                name="deptID"
                                                className="btn border-light bg-white col-sm-12"
                                                style={{ borderRadius: "4px" }}
                                                onChange={(e) => {
                                                    setDeptID(e.target.value);
                                                    handleInputChange();
                                                }}
                                                value={deptID}
                                            >
                                                <option>Select</option>
                                                <GetDepartmentByComp
                                                    setTopicID={setTopicID}
                                                    setSubTopicList={setSubTopicList}
                                                    parentCompany={parentCompany}
                                                    selectedDeptVal={deptID}
                                                />
                                            </select>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            <div className="form-group mb-3">
                                <div className="mb-4 row">
                                    <label className="col-sm-3 col-form-label">
                                        Topics{" "}
                                        <span
                                            className="required-field"
                                            style={{ color: "red", fontWeight: "200px" }}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <div className="col-sm-9">
                                        <select
                                            name="TopicID"
                                            className="btn border-light bg-white col-sm-12"
                                            style={{ borderRadius: "4px" }}
                                            onClick={handleTopicChange}
                                            value={topicID}
                                            onChange={(e) => {
                                                setTopicID(e.target.value);
                                                handleInputChange()
                                            }}
                                        >
                                            <option>Topic</option>
                                            <TopicDropdown deptID={deptID} />
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-4 row">
                                    <label className="col-sm-3 col-form-label">
                                        SubTopic{" "}
                                        <span
                                            className="required-field"
                                            style={{ color: "red", fontWeight: "200px" }}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <div className="col-sm-9">
                                        <select
                                            name="subtopicID"
                                            className="btn border-light bg-white col-sm-12"
                                            style={{ borderRadius: "4px" }}
                                            onChange={(e) => {
                                                setSubTopicID(e.target.value);
                                                handleInputChange();
                                            }}
                                            value={subtopicID}
                                        //onClick={(e) => handleKeyPress(e)}
                                        >
                                            <option>SubTopics</option>
                                            {subTopicList}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">
                                    About Induction
                                </label>
                                <div className="col-sm-9">
                                    <JoditEditor
                                        name=""
                                        ref={editor}
                                        value={induction.description}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={(newContent) =>
                                            setInduction({
                                                ...induction,
                                                description: newContent,
                                            })
                                        } // preferred to use only this option to update the content for performance reasons
                                        onChange={(newContent) => {
                                            setInduction({
                                                ...induction,
                                                description: newContent,
                                            });
                                            handleInputChange();
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="d-flex mx-3" style={{ float: "right" }}>
                        <button
                            className="updatePublish"
                            disabled={!isFormValid || hasErrors()}
                            style={{ alignSelf: "end" }}
                            onClick={() => {
                                if (isFormValid && !hasErrors()) {
                                    // Only proceed to the next step if the form is valid and there are no errors
                                    goToStep(); // Call the function to move to the next step
                                    handleSubmit(); // Submit the form if needed
                                }
                            }}
                        >
                            Save & Next
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default StepOne;