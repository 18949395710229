import Lottie from "lottie-react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
import CompanyDropdown from "../Companies/CompanyDropdown";
import GetDeptByComp from "../Department/GetDeptByCompany";
import UpdateUserModal from "./UserPopup";
import { Calendar } from 'primereact/calendar';
//theme
//import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/viva-light/theme.css";
import Usertable from "../SkeletonLoader/Usertable";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { BiSortAlt2 } from "react-icons/bi";

import { RxCross1 } from "react-icons/rx";
import { Modal } from "react-bootstrap";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";
import s3 from "../../../utilis/aws.config";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  DEPARTMENT: "department",
  INSTRUCTOR: "instructor",
  USER: "user",
};

const style = {
  height: 150,
};

const Users = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const id = useSelector((state) => state.auth.auth.id);
  const userRole = useSelector((state) => state.auth.auth.role);
  const [searchCompany, setSearchCompany] = useState();
  const [searchDepartment, setSearchDepartment] = useState();
  const [departmentOptions, setDepartmentOptions] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUserStatusChanged, setIsUserStatusChanged] = useState(false);
  const [modifiedData, setModifiedData] = useState([])

  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [test, setTest] = useState([]);

  const [profileData, setProfileData] = useState({
    email: "",
    createdAt: "",
    profile: {
      first_name: "",
      last_name: "",
      profilePhoto: "dummy-user.png",
      aboutMe: "",
      address: "",
    },
    deptID: "",
  });
  const [students, setStudents] = useState([]);
  const tokenDetailsString = localStorage.getItem('userDetails');

  const CompanyChangeFilter = (e) => {
    setSearchCompany(e.target.value);
    setSearchDepartment("All");
    if (e.target.value !== "All") {
      setDepartmentOptions(
        <GetDeptByComp parentCompany={e.target.value} />
      );
    }
  };

  // change department
  const DepartmentChangeFilter = (e) => {
    setSearchDepartment(e.target.value);
  };

  // callback function to update state
  const trackDeleteClick = (userID, deleted) => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to delete this record ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(`${API_ROOT_URL}/users/deleteUserStatus`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID, deleted: deleted }),
          }).then((data) => data.json());
          if ("status" in response && response.status === true) {
            swal("Poof! Your record has been deleted!", {
              icon: "success",
            });
            handlepageLoad();
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal({
            text: `Your record is safe`,
            icon: "success",
          })
        }
      });
    }
  };
  // change status
  const changeUserStatus = (userID, status) => {
    // user id
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: `Once status Changed, User will get or loss access to account`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(`${API_ROOT_URL}/users/changeUserStatus`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID, status: status }),
          }).then((data) => data.json());
          if ("status" in response && response.status === true) {
            swal("Poof! Your record has been updated!", {
              icon: "success",
            }).then(() => {
              setIsUserStatusChanged(!isUserStatusChanged);
              //navigate.push("/students");
            });
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal({
            text: `Your status is not changed`,
            icon: "warning",
          })
        }
        handlepageLoad();
      });
    }
  };
  const handlepageLoad = async (event) => {
    var str = "";
    if (searchCompany !== undefined && searchCompany !== "All") {
      str = "?company=" + searchCompany;
      if (searchDepartment !== undefined) {
        str += "&deptID=" + searchDepartment;
      }
    }
    if (searchDepartment !== undefined && searchCompany === undefined) {
      str = "?deptID=" + searchDepartment;
    }
    const response = await fetch(`${API_ROOT_URL}/students/${str}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status === true) {
      setStudents(response.data);
      setLoading(false);
      setIsUserStatusChanged(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  // callback function to opdate state
  const trackOnclick = (payload, userData) => {
    setIsModalOpen(payload);
    if (payload && userData) {
      setProfileData(userData);
    }
  };

  // use effect
  useEffect(() => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      handlepageLoad();
    }
  }, [ // profileData,
    isModalOpen,
    isUserStatusChanged,
    searchCompany,
    searchDepartment,
  ]);


  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      formattedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      email: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);



  const renderHeader = () => {
    return (
      <>
        {USER_ROLES.COMPANY === userRole ? (
          <Row>
            <Col lg="2">
              <select
                className="select btn"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                name="search_department"
                onChange={(e) => DepartmentChangeFilter(e)}
              >
                <option value="All">DEPARTMENT</option>
                <GetDeptByComp parentCompany={id} />
              </select>
            </Col>
            <Col lg="10">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}

        {USER_ROLES.SUPER_ADMIN === userRole ? (
          <Row>
            <Col lg="2" className="mb-3">
              <select
                className="select btn"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                name="search_company"
                onChange={(e) => CompanyChangeFilter(e)}
                value={searchCompany}
              >
                <option value="All">COMPANY</option>
                <CompanyDropdown />
              </select>
            </Col>
            <Col lg="2" className="mb-3">
              <select
                className="select btn"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                name="search_department"
                onChange={(e) => setSearchDepartment(e.target.value)}
                value={searchDepartment}
              >
                <option value="All">DEPARTMENT</option>
                {departmentOptions}
              </select>
            </Col>

            <Col lg="8">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}

        {USER_ROLES.DEPARTMENT === userRole ||
          USER_ROLES.INSTRUCTOR === userRole ? (
          <Col>
            <div className="d-flex justify-content-end">
              <span className="p-input-icon-left">
                <i className="fa fa-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Keyword Search"
                />
              </span>
            </div>
          </Col>
        ) : null}
      </>
    );
  };
  const header = renderHeader();

  useEffect(() => {
    const modifiedUsersList = students.map((user) => {
      const { profile } = user;
      const formattedDate = new Date(user.createdAt)
      const newStatus = user.status == true ? "active" : "In-active";
      const fullName = (user.profile.first_name + " " + user.profile.last_name)
      return { ...user, formattedDate, newStatus, fullName };
    });
    setModifiedData(modifiedUsersList)
  }, [students])

  const joinDateTemplate = (modifiedUsers) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(modifiedUsers.createdAt).toLocaleDateString("en-GB", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>
      </>
    );
  };

  const statusTemplate = (rowData) => {
    const isActive = rowData?.status === true;

    //console.log(rowData, "is status instructors");
    return (
      <>
        <label class="switch">
          <input type="checkbox" id="togBtn" checked={isActive}
            to="/users"
            onClick={() => changeUserStatus(rowData?._id, rowData?.status)} />
          <div class="slider round">
            <span class="on">Active</span>
            <span class="off">Inactive</span>
          </div>
        </label>
      </>
    );
  };


  const actionTemplate = (rowData) => {

    const handleCallback = () => {
      trackOnclick(true, rowData);
    };
    const deleteClick = () => {
      trackDeleteClick(rowData.profile._id, rowData.profile.deleted);
    };

    return (
      <div className="d-flex">
        <button onClick={handleCallback} className="editIcon">
          <i className="fas fa-pencil-alt" style={{ fontSize: "17px" }}></i>
        </button>{" "}
        <button onClick={deleteClick} className="deleteIcon">
          <i className="fa fa-trash" style={{ fontSize: "17px" }}></i>
        </button>
      </div>
    );
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(students)
  }

  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
    // setModifiedData(deptData);
  }

  const handleSelect = (ranges) => {
    let filtered = students.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };

  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(students)
  };


  const dateRangePickerTemp = () => {


    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelect}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>


    )
  }

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState(null);

  const sortTable = (key) => {

    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  const getSignedUrl = (fileName) => {
    return s3.getSignedUrl("getObject", {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName,
    });
  };


  const fullNameTemplate = (rowData) => {

    return (
      <>
        <div className="d-flex align-items-center">
          <div>
            <div className="d-flex align-items-center mt-2 mb-2">
              <div>
                <img
                  src={getSignedUrl(`${PROFILE_ASSETS_URL}/${rowData?.profile?.profilePhoto}`)}
                  alt={rowData?.profile?.profilePhoto}
                  width={100}
                  height={100}
                  className="img-thumbnail"
                  style={{ borderRadius: "50px" }}

                />{" "}{rowData.profile.fullName}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const endmessage = {
    height: 150,
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card students-list">
          <div className="card-body p-3">
            <div className="table-responsive">
              <div
                id="student_wrapper"
                className="dataTables_wrapper"
              ></div>
              {USER_ROLES.SUPER_ADMIN === userRole ||
                USER_ROLES.COMPANY === userRole ? (
                <div>
                  {loading ? (<Usertable />
                  ) : (
                    <DataTable
                      value={
                        modifiedData.map((rowData, index) => ({
                          ...rowData,
                          serialNumber: index + 1,
                        }))
                      }
                      header={header}
                      dataKey="id"
                      emptyMessage={
                        <div className="queryContainer">
                          {" "}
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                              <div className="form-input-content text-center">
                                <h1 className="error-text font-weight-bold">
                                  <Lottie style={endmessage} animationData={endmessageanimation} />
                                </h1>
                                <h3
                                  className="fs-28 mt-3"
                                  style={{ color: "#3A3630", fontWeight: "900" }}
                                >
                                  {" "}
                                </h3>
                                <h4 style={{ fontStyle: "italic" }}>
                                  No Records Found !
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      filters={filters}
                      globalFilterFields={[
                        "fullName",
                        "email",
                      ]}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      size="large"
                      stripedRows
                      showGridlines
                      tableStyle={{ width: "100%" }}
                      filterDisplay="menu"
                    >
                      <Column
                        header="SR.NO" headerStyle={{ width: "5%" }}
                        body={(data, options) => options.rowIndex + 1}
                      ></Column>
                      <Column field="fullName" body={fullNameTemplate}
                        sortable header="NAME"
                        filterField="fullName"
                        style={{ width: '30%' }}
                        filter
                        filterPlaceholder="Search by Name" />
                      <Column
                        field="email"
                        sortable
                        header="EMAIL"
                        filterField="email"
                        style={{ width: '25%' }}
                        filter
                        filterPlaceholder="Search by Email" />
                      <Column
                        field="createdAt"
                        body={joinDateTemplate}
                        className="columnHeader"
                        style={{ width: '10%' }}
                        header={
                          <>
                            <div>
                              <Row className="textFilter">
                                <Col sm="10">
                                  <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                                    JOIN DATE
                                    {" "}
                                    <span>
                                      <BiSortAlt2 size={22} />
                                    </span>
                                  </button>

                                </Col>
                                <Col sm="2">
                                  <CiFilter size={22} onClick={openDateRangePicker} />
                                </Col>
                              </Row>
                            </div>


                            {showDateRangePicker && (
                              <Modal className="customModal d-flex"
                                show={openDateRangePicker}
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <div className="modal-header customModalHeader">
                                  <h6 className="modal-title text-white fs-20">
                                    {" "}SELECT DATE / DATE RANGE
                                  </h6>
                                  <div className="cross-outer-icon">
                                    <RxCross1
                                      className="cross-icon"
                                      size={22}
                                      color="white"
                                      onClick={closeDateRangePicker}
                                    />
                                  </div>

                                </div>
                                <div className="modal-content customModalContent">

                                  <div>
                                    {dateRangePickerTemp()}
                                  </div>
                                </div>
                                <Modal.Footer className="d-flex modal-footer customModalFooter">
                                  <button className="clearButton" onClick={handleClear}>Clear</button>
                                  <button className="applyButton" onClick={handleApply}>Apply</button>
                                </Modal.Footer>
                              </Modal>
                            )}
                          </>}
                      />
                      <Column
                        field="status"
                        body={statusTemplate}
                        sortable
                        header="STATUS"
                        style={{ width: '10%' }}
                      ></Column>
                      <Column
                        body={actionTemplate}
                        header="ACTIONS"
                        style={{ width: '10%' }}
                      ></Column>
                    </DataTable>
                  )}
                </div>
              ) : null}

              {USER_ROLES.INSTRUCTOR === userRole ? (
                <div>
                  {loading ? (<Usertable />
                  ) : (
                    <DataTable
                      value={modifiedData.map((rowData, index) => ({
                        ...rowData,
                        serialNumber: index + 1,
                      }))}
                      header={header}
                      dataKey="id"
                      // emptyMessage="No Users found"
                      emptyMessage={
                        <div className="queryContainer">
                          {" "}
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                              <div className="form-input-content text-center">
                                <h1 className="error-text font-weight-bold">
                                  <Lottie style={endmessage} animationData={endmessageanimation} />
                                </h1>
                                <h3
                                  className="fs-28 mt-3"
                                  style={{ color: "#3A3630", fontWeight: "900" }}
                                >
                                  {" "}
                                </h3>
                                <h4 style={{ fontStyle: "italic" }}>
                                  No Records Found !
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      filters={filters}
                      globalFilterFields={[
                        "profile.first_name",
                        "profile.last_name",
                        "email",
                      ]}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      size="large"
                      stripedRows
                      showGridlines
                      tableStyle={{ minWidth: "50rem" }}
                      filterDisplay="menu"
                    >
                      <Column
                        header="SR.NO" headerStyle={{ width: "5%" }}
                        body={(data, options) => options.rowIndex + 1}
                      ></Column>
                      <Column field="fullName"
                        body={fullNameTemplate}
                        sortable header="NAME"
                        filterField="fullName"
                        style={{ width: '30%' }}
                        filter
                        filterPlaceholder="Search by Name" />
                      <Column field="email"
                        sortable header="EMAIL"
                        filterField="email"
                        style={{ width: '25%' }}
                        filter filterPlaceholder="Search by Email" />
                      <Column
                        field="formattedDate"
                        body={joinDateTemplate}
                        style={{ width: "10%" }}
                        header={<>
                          <div>
                            <Row className="textFilter">
                              <Col sm="10">
                                <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                                  JOIN DATE
                                  {" "}
                                  <span>
                                    <BiSortAlt2 size={22} />
                                  </span>
                                </button>

                              </Col>
                              <Col sm="2">
                                <CiFilter className="" size={22} onClick={openDateRangePicker} />
                              </Col>
                            </Row>
                          </div>

                          {showDateRangePicker && (
                            <Modal className="customModal d-flex"
                              show={openDateRangePicker}
                              size="sm"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered>
                              <div className="modal-header customModalHeader">
                                <h6 className="modal-title text-white fs-20">
                                  {" "}SELECT DATE / DATE RANGE
                                </h6>
                                <div className="cross-outer-icon">
                                  <RxCross1
                                    className="cross-icon"
                                    size={22}
                                    color="white"
                                    onClick={closeDateRangePicker}
                                  />
                                </div>

                              </div>
                              <div className="modal-content customModalContent">

                                <div>
                                  {dateRangePickerTemp()}
                                </div>
                              </div>
                              <Modal.Footer className="d-flex modal-footer customModalFooter">
                                <button className="clearButton" onClick={handleClear}>Clear</button>
                                <button className="applyButton" onClick={handleApply}>Apply</button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>}
                      />
                      <Column
                        field="status"
                        body={statusTemplate}
                        sortable
                        header="STATUS"
                        style={{ width: '13%' }}
                      ></Column>
                      <Column
                        body={actionTemplate}
                        header="ACTIONS"
                        style={{ width: '13%' }}
                      ></Column>
                    </DataTable>
                  )}
                </div>
              ) : null}

              {USER_ROLES.DEPARTMENT === userRole ? (
                <div>
                  {loading ? (<Usertable />
                  ) : (
                    <DataTable
                      value={modifiedData.map((rowData, index) => ({
                        ...rowData,
                        serialNumber: index + 1,
                      }))}
                      header={header}
                      dataKey="id"
                      // emptyMessage="No Users found"
                      emptyMessage={
                        <div className="queryContainer">
                          {" "}
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                              <div className="form-input-content text-center">
                                <h1 className="error-text font-weight-bold">
                                  <Lottie style={endmessage} animationData={endmessageanimation} />
                                </h1>
                                <h3
                                  className="fs-28 mt-3"
                                  style={{ color: "#3A3630", fontWeight: "900" }}
                                >
                                  {" "}
                                </h3>
                                <h4 style={{ fontStyle: "italic" }}>
                                  No Records Found !
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      filters={filters}
                      globalFilterFields={[
                        "profile.first_name",
                        "profile.last_name",
                        "email",
                      ]}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      size="large"
                      stripedRows
                      showGridlines
                      tableStyle={{ minWidth: "50rem" }}
                      filterDisplay="menu"
                    >
                      <Column
                        header="SR.NO" headerStyle={{ width: "3rem" }}
                        body={(data, options) => options.rowIndex + 1}
                      ></Column>
                      <Column field="fullName"
                        body={fullNameTemplate}
                        sortable header="NAME"
                        filterField="fullName"
                        style={{ minWidth: '22rem' }} filter filterPlaceholder="Search by Name" />
                      <Column field="email"
                        sortable
                        header="EMAIL"
                        filterField="email"
                        style={{ minWidth: '24rem' }} filter filterPlaceholder="Search by Email" />
                      <Column
                        field="formattedDate"
                        body={joinDateTemplate}
                        style={{ width: '14rem' }}
                        header={<>
                          <div>
                            <Row className="textFilter">
                              <Col sm="10">
                                <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                                  JOIN DATE
                                  {" "}
                                  <span>
                                    <BiSortAlt2 size={22} />
                                  </span>
                                </button>

                              </Col>
                              <Col sm="2">
                                <CiFilter className="" size={22} onClick={openDateRangePicker} />
                              </Col>
                            </Row>
                          </div>
                          {showDateRangePicker && (
                            <Modal className="customModal d-flex"
                              show={openDateRangePicker}
                              size="sm"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered>
                              <div className="modal-header customModalHeader">
                                <h6 className="modal-title text-white fs-20">
                                  {" "}SELECT DATE / DATE RANGE
                                </h6>
                                <div className="cross-outer-icon">
                                  <RxCross1
                                    className="cross-icon"
                                    size={22}
                                    color="white"
                                    onClick={closeDateRangePicker}
                                  />
                                </div>

                              </div>
                              <div className="modal-content customModalContent">

                                <div>
                                  {dateRangePickerTemp()}
                                </div>
                              </div>
                              <Modal.Footer className="d-flex modal-footer customModalFooter">
                                <button className="clearButton" onClick={handleClear}>Clear</button>
                                <button className="applyButton" onClick={handleApply}>Apply</button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>}
                      />
                      <Column
                        field="status"
                        body={statusTemplate}
                        sortable
                        header="STATUS"
                      ></Column>
                      <Column
                        body={actionTemplate}
                        header="ACTIONS"
                      ></Column>
                    </DataTable>
                  )}
                </div>
              ) : null}

            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <UpdateUserModal
          isModalOpen={isModalOpen}
          trackOnclick={trackOnclick}
          profileData={profileData}
          history={props.history}
        />

      ) : null}
    </div>

  );
};
export default Users;
