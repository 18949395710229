// QuizContext.js
import React, { createContext, useState, useContext } from 'react';

const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [questions, setQuestions] = useState([]);

    const addQuestion = (newQuestion) => {
        setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    };

    // Function to set questions fetched from the API
    const setApiQuestions = (apiQuestions) => {
        setQuestions(apiQuestions);
    };

    return (
        <QuizContext.Provider value={{ questions, addQuestion, setApiQuestions, formSubmitted, setFormSubmitted }}>
            {children}
        </QuizContext.Provider>
    );
};

export const useQuizContext = () => {
    const context = useContext(QuizContext);
    if (!context) {
        throw new Error('useQuizContext must be used within a QuizProvider');
    }
    return context;
};
