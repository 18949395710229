import { USER_ROLES } from "../jsx/constants"

const useDashboardUrl = (role) => {
    let dashboardUrl;
    if (role === USER_ROLES.COMPANY) {
        dashboardUrl = "/company-dashboard"
    }
    else if (role === USER_ROLES.DEPARTMENT) {
        dashboardUrl = "/department-dashboard"
    }
    else if (role === USER_ROLES.INSTRUCTOR) {
        dashboardUrl = "/instructor-dashboard"
    }
    else { dashboardUrl = "" }
    return dashboardUrl;
}
export default useDashboardUrl;