import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import "swiper/css";
//Images
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";
import { API_ROOT_URL } from "../../constants";
import CompanyDropdown from "../Companies/CompanyDropdown";
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import TopicDropdown from "../Topics/TopicDropdown";
import "./paginationstyle.css";
//animations
import Lottie from "lottie-react";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
//react prime datatable
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FcFilledFilter } from "react-icons/fc";
import Prototype from "../SkeletonLoader/Prototype";
import { Calendar } from 'primereact/calendar';
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { BiSortAlt2 } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import { CiLink } from "react-icons/ci";
//Roles
const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department",
};

const InductionList = ({ props }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const userRole = useSelector((state) => state.auth.auth.role);
  const [loading, setLoading] = useState(true);
  const [InductionData, setInductionData] = useState([]);
  const [filterDept, setFilterDept] = useState();
  const [subTopicList, setSubTopicList] = useState([]);
  const [filterTopicID, setFilterTopicID] = useState();
  const [subTopicSelectedID, setSubTopicSelectedID] = useState("");
  const [compid, setcompID] = useState();
  const [deptID, setDeptID] = useState();
  const [filterCompany, setFilterCompany] = useState();
  const [isCompDeleted, setIsCompDeleted] = useState(false);
  const [selectedSubTopics, setSelectedSubTopics] = useState([]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  //sortable function states
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState(null);
  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([])
  const [test, setTest] = useState([]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      formattedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      createdByName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };


  //api for all inductions list
  async function getInduction(token) {
    return fetch(`${API_ROOT_URL}/induction/index/work`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const handlepageLoadInduction = async (event) => {
    const response = await getInduction(token);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  // callback function to update state
  const trackDeleteClick = (inductionId) => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to delete this record ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(
            `${API_ROOT_URL}/induction/soft/delete/induction`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
              body: JSON.stringify({ inductionId: inductionId }),
            }
          ).then((data) => data.json());
          if ("status" in response && response.status == true) {
            swal("Poof! Your record has been deleted!", {
              icon: "success",
            }).then(() => {
              setIsCompDeleted(!isCompDeleted);
            });
          } else {
            if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
              swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                dispatch(logout(props.history));
              });
            }
            else if (response?.devMessage === "userDeleted") {
              Swal.fire({
                title: "Access Denied",
                text: "Your Account Has been deleted",
                icon: "error",
                footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
              }).then(() => {
                dispatch(logout(props.history));
              });
            } else {
              return swal("Failed", "Error message", "error");
            }
          }
          handlepageLoadInduction();
        } else {
          swal("Your record is safe!");
        }
      });
    }

  };

  // api for filter inductions by company
  async function filterInductions(companyID) {
    setcompID(companyID);
    let filterInductionsApi = `${API_ROOT_URL}/induction/filter/department/listonly?filterByCompany=${companyID}`;
    if (companyID === "all") {
      filterInductionsApi = `${API_ROOT_URL}/induction/index/work`;
    }
    return fetch(filterInductionsApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const filterByCompany = (companyID) => {
    const handlepageLoad = async (event) => {
      setFilterCompany(companyID);
      setSelectedSubTopics([]);
      setFilterDept("all")
      setFilterTopicID("all")
      setSubTopicList([]);
      const response = await filterInductions(companyID);
      if ("status" in response && response.status == true) {
        setInductionData(response.data);
        setLoading(false);
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    };
    handlepageLoad();
  };

  //fetching on the basis of department
  async function filterByDept(deptID, companyID) {
    setDeptID(deptID);
    let filterInductionApiDept = `${API_ROOT_URL}/induction/filter/department/listonly?filterByDepartment=${deptID}&filterByCompany=${companyID}`;
    if (deptID === "all") {
      filterInductionApiDept = `${API_ROOT_URL}/induction/filter/department/listonly?filterByCompany=${companyID}`;
      if (userRole === "company") {
        if (deptID === "all") {
          filterInductionApiDept = `${API_ROOT_URL}/induction/index/work`;
        }
      }
    }
    return fetch(filterInductionApiDept, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const filterByDepartment = (deptID) => {
    setFilterTopicID("all")
    const handlepageLoad = async (event) => {
      setFilterDept(deptID);
      setSelectedSubTopics([]);
      setSubTopicList([]);
      const response = await filterByDept(deptID, compid);
      if ("status" in response && response.status == true) {
        setInductionData(response.data);
        setLoading(false);
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    };
    handlepageLoad();
  };

  //fetching on the basis of department
  async function filterTopic(topicID, deptID, compid) {
    setFilterTopicID(topicID);
    setFilterDept(deptID);
    let filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`;
    if (topicID === "all" && !deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/index/work`;
    } else if (topicID === "all" && deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/department/listonly?filterByDepartment=${deptID}&filterByCompany=${compid}`;
    } else if (topicID === "all") {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/department/listonly?filterByDepartment=${deptID}`;
    }
    return fetch(filterInductionApiTopic, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List companies page first render
  const filterByTopic = async (e) => {
    setSelectedSubTopics([]);
    const response = await filterTopic(e, filterDept, compid);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  // SUbtopics MultiSelect Dropdown
  async function getSubtopicsMultiDropdown(topicID) {
    return fetch(
      `${API_ROOT_URL}/department/fetching/subtopics/multidropdown/by/topics/${topicID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
  }
  // Get Subtopics By Topic
  const handleTopicChange = async (e) => {
    setSubTopicSelectedID(e.target.value);
    const response = await getSubtopicsMultiDropdown(filterTopicID);
    if ("status" in response && response.status == true) {
      setSubTopicList(response.data);
      setLoading(false);
    }
    else {
      setSubTopicList([]);
    }
  };

  //filter by subtopic
  //fetching on the basis of department
  async function filterSubTopic(subtopicIDs, topicID) {
    let subtopicIDArray = subtopicIDs.map((subtopicID) => subtopicID.value);
    let filterInductionApiSubTopic = `${API_ROOT_URL}/induction/filter/by/subtopic/listonly`;
    if (topicID && subtopicIDArray.length === 0) {
      return fetch(
        `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    } else if (subtopicIDs === "all") {
      return fetch(
        `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    }
    if (subtopicIDArray.length > 0) {
      return fetch(filterInductionApiSubTopic, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({ subtopicID: subtopicIDArray }),
      }).then((data) => data.json());
    }
  }
  // on List companies page first render
  const filterBySubTopic = async (e) => {
    setSelectedSubTopics(e);
    const response = await filterSubTopic(e, filterTopicID);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  useEffect(() => {
    handlepageLoadInduction();
    getInduction(token);
  }, [loading]);

  useEffect(() => {
    initFilters();
  }, []);

  const renderHeader = () => {
    return (
      <>
        {USER_ROLES.SUPER_ADMIN === userRole ? (
          <>
            <Row>
              <Col lg="2">
                <h2 className="mt-0 text-white" style={{ letterSpacing: "3px" }}>
                  {" "}
                  <FcFilledFilter style={{ marginBottom: "5px" }} /> FILTERS
                </h2>
              </Col>
              <Col m="2" className="mb-3">
                <select
                  name="parentCompany"
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn"
                  onChange={(e) => filterByCompany(e.target.value)}
                >
                  <option value="all">COMPANY</option>
                  <CompanyDropdown />
                </select>
              </Col>
              <Col m="2" className="mb-3">
                <select
                  name="deptID"
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn"
                  onChange={(e) => filterByDepartment(e.target.value)}
                >
                  <option value="all">DEPARTMENT</option>
                  <GetDepartmentByComp parentCompany={compid} />
                </select>
              </Col>
              <Col m="2" className="mb-3">
                <select
                  name="topicID"
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn"
                  onChange={(e) => filterByTopic(e.target.value)}
                  onClick={handleTopicChange}
                >
                  <option value="all">TOPIC</option>
                  <TopicDropdown deptID={filterDept} compId={compid} />
                </select>
              </Col>
              <Col m="1" className="mb-3">
                <Select
                  options={subTopicList || []}
                  className="fw-900 fs-14"
                  placeholder="SUBTOPIC"
                  onChange={filterBySubTopic}
                  isSearchable={true}
                  isMulti
                  value={selectedSubTopics}
                  noOptionsMessage={() => "NO SUBTOPICS"}
                />
              </Col>
              <Col m="3">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </Col>
            </Row>
          </>
        ) : null}
        {USER_ROLES.COMPANY === userRole ? (
          <>
            <Row>
              <Col lg="3" className="mx-3">
                <h2 className="mt-0 text-white" style={{ letterSpacing: "3px" }}>
                  {" "}
                  <FcFilledFilter style={{ marginBottom: "5px" }} /> FILTERS
                </h2>
              </Col>
              <Col m="2" className="mb-3">
                <select
                  name="deptID"
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn"
                  onChange={(e) => filterByDepartment(e.target.value)}
                >
                  <option value="all">DEPARTMENT</option>
                  <GetDepartmentByComp parentCompany={compid} />
                </select>
              </Col>
              <Col m="2" className="mb-3">
                <select
                  name="topicID"
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn"
                  onChange={(e) => filterByTopic(e.target.value)}
                  onClick={handleTopicChange}
                >
                  <option value="all">TOPIC</option>
                  <TopicDropdown deptID={filterDept} />
                </select>
              </Col>
              <Col m="2" className="mb-3">
                <Select
                  options={subTopicList || []}
                  className="fw-900 fs-14"
                  placeholder="SUBTOPIC"
                  onChange={filterBySubTopic}
                  isSearchable={true}
                  isMulti
                  value={selectedSubTopics}
                  noOptionsMessage={() => "NO SUBTOPICS"}
                />
              </Col>
              <Col lg="3" >
                <div className="d-flex justify-content-end align-items-end">
                  <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder="Keyword Search"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </>
        ) : null}
      </>
    );
  };

  const header = renderHeader();
  const actionTemplate = (rowData) => {

    const deleteClick = () => {

      trackDeleteClick(rowData._id);
    };

    return (
      <div className="d-flex">
        {/* <Link
          to={`/single-induction-view/${rowData._id}`}
          className="eyeButton"
        >
          <FaEye style={{ color: "white" }} size={24} />
        </Link> */}

        <Link to={`/update-induction/${rowData._id}`} className="updateButton">
          <i className="fas fa-pencil-alt" style={{ fontSize: "18px" }}></i>
        </Link>

        <button onClick={deleteClick} className="deleteIconInductionList">
          <i className="fa fa-trash" style={{ fontSize: "17px" }}></i>
        </button>
      </div>
    );
  };

  //to open the date range picker
  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(InductionData)
  }

  //to close the date range picker
  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
  }

  useEffect(() => {
    const ModifiedInductionData = InductionData.map((data) => {
      const { profile } = data;
      const formattedDate = new Date(data.createdAt)
      return { ...data, formattedDate };
    });
    setModifiedData(ModifiedInductionData)
  }, [InductionData])

  const joinDateTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(rowData.createdAt).toLocaleDateString("en-GB", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>
      </>
    );
  };

  const titleTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          <Link
            className="inductionTabletext"
            to={`/single-induction-view/${rowData._id}`}
          >
           {" "}{rowData.title} {" "} 
          </Link>
        </div>
      </>
    );
  };

  const handleSelect = (ranges) => {
    let filtered = InductionData.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };


  const dateRangePickerTemp = () => {
    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelect}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>
    )
  }

  const sortTable = (key) => {

    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleApply = () => {

    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(InductionData)
  };


  return (
    <div>
      {USER_ROLES.SUPER_ADMIN === userRole || USER_ROLES.COMPANY === userRole ? (
        <div className="row">
          <div className="col-xl-12">
            <div className="card students-list">
              <div className="card-body p-3">
                <>
                  <div>
                    {loading ? <Prototype /> : (<DataTable
                      value={modifiedData.map((rowData, index) => ({
                        ...rowData,
                        serialNumber: index + 1,
                      }))}
                      header={header}
                      dataKey="id"
                      emptyMessage={
                        <div className="queryContainer">
                          {" "}
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                              <div className="form-input-content text-center">
                                <h1 className="error-text font-weight-bold">
                                  <Lottie
                                    style={{ height: 150 }}
                                    animationData={endmessageanimation}
                                  />
                                </h1>
                                <h3
                                  className="fs-28 mt-3"
                                  style={{ color: "#3A3630", fontWeight: "900" }}
                                >
                                  {" "}
                                </h3>
                                <h4 style={{ fontStyle: "italic" }}>
                                  No Records Found !
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      filters={filters}
                      globalFilterFields={["title", "createdByName"]}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      size="large"
                      stripedRows
                      showGridlines
                      tableStyle={{ minWidth: "50rem" }}
                      filterDisplay="menu"
                    >
                      <Column
                        header="SR.NO" headerStyle={{ width: "5%" }}
                        body={(data, options) => options.rowIndex + 1}
                      ></Column>
                      <Column
                        field="title"
                        style={{ width: "30%" }}
                        sortable
                        header="INDUCTION"
                        body={titleTemplate}
                        filterField="title" filter filterPlaceholder="Search by Title"

                      ></Column>
                      <Column
                        field="createdAt"
                        body={joinDateTemplate}
                        style={{ width: "8%" }}
                        header={<>
                          <div>
                            <Row className="textFilter">
                              <Col sm="10">
                                <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                                  CREATED ON
                                  {" "}
                                  <span>
                                    <BiSortAlt2 size={22} />
                                  </span>
                                </button>

                              </Col>
                              <Col sm="2">
                                <CiFilter className="mx-2" size={22} onClick={openDateRangePicker} />
                              </Col>
                            </Row>
                          </div>


                          {showDateRangePicker && (
                            <Modal className="customModal d-flex"
                              show={openDateRangePicker}
                              size="sm"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered>
                              <div className="modal-header customModalHeader">
                                <h6 className="modal-title text-white fs-20">
                                  {" "}SELECT DATE / DATE RANGE
                                </h6>
                                <div className="cross-outer-icon">
                                  <RxCross1
                                    className="cross-icon"
                                    size={22}
                                    color="white"
                                    onClick={closeDateRangePicker}
                                  />
                                </div>

                              </div>
                              <div className="modal-content customModalContent">

                                <div>
                                  {dateRangePickerTemp()}
                                </div>
                              </div>
                              <Modal.Footer className="d-flex modal-footer customModalFooter">
                                <button className="clearButton" onClick={handleClear}>Clear</button>
                                <button className="applyButton" onClick={handleApply}>Apply</button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>}
                      />
                      <Column
                        field="createdByName"
                        sortable
                        header="CREATED BY"
                        filterField="createdByName"
                        style={{ width: '25%' }}
                        filter
                        filterPlaceholder="Search by Name"
                      ></Column>
                      <Column body={actionTemplate} header="ACTIONS" style={{ width: '10%' }} ></Column>
                    </DataTable>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default InductionList;