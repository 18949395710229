// Custom hook checking if the url is valid or not 
import { formsRoutes, routes } from "../jsx/index";

const useValidUrl = (url) => {
    const newRoutes = [...routes, ...formsRoutes];

    const isValidUrl = newRoutes.some(route => route.url === url);

    return isValidUrl;
}

export default useValidUrl;
