import React, { useEffect, useState } from "react";

const InductionSubTiltle = (props) => {
  const [inductionSubTiltle, setInductionSubTiltle] = useState("");
  useEffect(() => {
    setInductionSubTiltle(props.title);
  }, [props.title]);

  return (
    <div className="course-content d-flex justify-content-between flex-wrap">
      <div>
        {/* <h3>Sub-Title</h3> */}
        <h3><b>{inductionSubTiltle.charAt(0).toUpperCase() + inductionSubTiltle.slice(1)}</b></h3>
      </div>
    </div>
  );
};

export default InductionSubTiltle;
