import React, { useContext } from "react";
import mongoose from "mongoose";
// React router dom
import { Route, Switch } from "react-router-dom";

// Css
import "./chart.css";
import "./index.css";
import "./step.css";

// Layout
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";
import ScrollToTop from "./layouts/ScrollToTop";
// Dashboard
import Home from "./components/Dashboard/Home";
import Profile from "./components/Dashboard/Profile";
import Setting from "./components/Dashboard/Setting";

//Courses----
import Inductions from "./components/Induction/Inductions";

//Company----
// import ListCompanies from "./components/Add/ListCompanies";
import AddCompany from "./components/Companies/AddCompany";
import ListCompanies from "./components/Companies/Companies";
import CompanyDashboard from "./components/Companies/CompanyDashboard";
import CompanyProfile from "./components/Companies/CompanyProfile";

//Department----
import AddDepartment from "./components/Department/AddDepartment";
import AllDepartments from "./components/Department/ListDepartment";

//Inductions----
import CreateInductionNew from "./components/Inductions/CreateInductionNew";
import SingleInductionView from "./components/Inductions/SingleInductionView";
import StarTest from "./components/Inductions/test/StartTest";

// for User v2
import ViewInduction from "./components/Users/ViewInduction";

// v2 versions
import UpdateInduction from "./components/Induction/UpdateInduction";
import UpdateSlide from "./components/Induction/UpdateSlide";
import AddSlide from "./components/Slides/Add";
//add-induction

//Instructors
import AddInstructor from "./components/Instructor/AddInstructor";
import InstructorProfile from "./components/Instructor/InstructorProfile";
import Instructors from "./components/Instructor/ListInstructor";

import InstructorDashboard from "./components/Instructor/InstructorDashboard";

// Students
import UserDashboard from "./components/Users/UserDashboard";
import Users from "./components/Users/Users";

// import UnassignedUsers from "./components/Students/UnassignedUsers";
import UnassignedUsers from "./components/Users/UnassignedUsers";

import ContactUs from "./components/Footer/ContactUs";

import DepartmentDashboard from "./components/Department/DepartmentDashboard";
import DepartmentProfile from "./components/Department/DepartmentProfile";

import createTopic from "./components/Topics/createTopic";
import ListTopics from "./components/Topics/ListTopics";

import InductionAccessControl from "./components/Induction/InductionAccessControl";
import AssignInductionUsers from "./components/Inductions/AssignInductionUsers";
// App
import { ThemeContext } from "../context/ThemeContext";
import ImportInduction from "./components/Induction/ImportInduction";
import AssignInduction from "./components/Inductions/AssignInduction";
import AttemptedInductions from "./components/Inductions/AttemptedInduction";
import Mcq from "./components/Inductions/Mcq";
import ViewMcq from "./components/Inductions/Viewquiz";
import UserAttemptedInductions from "./components/Users/AttemptedInductions";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import Registration from "./pages/Registration";

import RecentForms from "../bjs-forms/Dashboard/RecentForms"; //this is bjs form
import AddForm from "../bjs-forms/Test/AddForm";

import TestAccessControl from "../bjs-forms/TestAccess/TestAccessControl";
import Testform from "../bjs-forms/Test/TestForm";
import EditTestForm from "../bjs-forms/Test/EditTestForm";
import EndUserDashboard from "./../bjs-forms/Dashboard/EndUserDashboard";
import AdminTestList from "../bjs-forms/Dashboard/AdminTestList";
import ResponsesTab from "../bjs-forms/Test/ResponsesTab";
import ErrorPage from "./pages/ErrorPage";
import useValidUrl from "../utilis/useValidUrl";

export const routes = [
  /// Dashboard
  { url: "", component: Home },
  { url: "login", component: Login },
  { url: "page-register", component: Registration },
  { url: "forgot-password", component: ForgotPassword },
  { url: "dashboard", component: Home },
  { url: "profile", component: Profile },
  { url: "company-profile", component: CompanyProfile },
  { url: "instructor-profile", component: InstructorProfile },
  { url: "setting", component: Setting },
  { url: "company-dashboard", component: CompanyDashboard },
  { url: "user-dashboard", component: UserDashboard },

  // Inductions ----
  { url: "inductions", component: Inductions },
  // { url: "course-details-1", component: CourseDetail1 },
  // { url: "course-details-2", component: CourseDetail2 },

  // Companies ----
  { url: "companies", component: ListCompanies },
  { url: "add-company", component: AddCompany },

  // Department----
  { url: "departments", component: AllDepartments },
  { url: "add-department", component: AddDepartment },

  // Inductions ----
  // { url: "induction/:id", component: InductionDetail },
  { url: "create-induction", component: CreateInductionNew },
  { url: "add-slide/:id", component: AddSlide }, // induction id passed
  { url: "update-slide/:id", component: UpdateSlide }, // slide id passed
  { url: "update-induction/:id", component: UpdateInduction },
  { url: "single-induction-view/:id", component: SingleInductionView },
  { url: "mcq/:inductionID", component: Mcq },
  { url: "viewmcq/:id", component: ViewMcq },
  //{ url: "attemptquiz/:id", component: AttemptQuiz },

  // full screen URLs
  { url: "start-test/:id", component: StarTest },
  { url: "view-induction/:id", component: ViewInduction },

  // Instructors  Pages path
  { url: "instructors", component: Instructors },
  { url: "add-instructor", component: AddInstructor },
  { url: "instructor-dashboard", component: InstructorDashboard },

  //{ url: "users", component: AllStudents },
  { url: "users", component: Users },
  { url: "user-inductions", component: UserAttemptedInductions },
  { url: "attempted-inductions", component: AttemptedInductions },
  { url: "assign-inductions", component: AssignInduction },
  { url: "assign-induction-users", component: AssignInductionUsers },
  { url: "induction-access-control", component: InductionAccessControl },
  { url: "unassigned-users", component: UnassignedUsers },

  { url: "import-induction", component: ImportInduction },

  { url: "Contact-Us", component: ContactUs },

  { url: "department-dashboard", component: DepartmentDashboard },
  { url: "department-profile", component: DepartmentProfile },

  //topics
  { url: "create-topic", component: createTopic },
  { url: "list-topics", component: ListTopics },

  { url: "test-access-control", component: TestAccessControl },
  { url: "admin-test-dashboard", component: AdminTestList },
  //{ url: "end-user-forms-dashboard", component: EndUserDashboard },
  // Apps
  // { url: "app-profile", component: AppProfile },
  // { url: "table-filtering", component: FilteringTable}

  //BJS FORMS

  // { url: "forms", component: RecentForms },
  // { url: "forms-questions", component: AddForm },
];

//console.log(recentFormsData,"recent idsss")

export const formsRoutes = [
  { url: "login", component: Login },
  { url: "forms", component: RecentForms },
  { url: "forms-questions", component: AddForm },
  { url: "test-forms", component: Testform },
  { url: "test-forms/:testId", component: Testform },
  { url: "edit-test-forms", component: EditTestForm },
  { url: "/edit-test-forms/:id", component: EditTestForm },
  { url: "responses-forms", component: ResponsesTab },
  { url: "/responses-forms/:id", component: ResponsesTab },
  { url: "end-user-forms-dashboard", component: EndUserDashboard }
];
const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);

  let path = window.location.pathname;
  const validurl = useValidUrl(path.slice(1))
  path = path.split("/");
  path = mongoose.Types.ObjectId.isValid(path[path.length - 1])
    ? path[path.length - 2]
    : path[path.length - 1];
  let pagePath =
    path.split("-").includes("page") || path.split("-").includes("forgot");
  if (path === "login") {
    return (
      <div>
        <Route exact path={"/login"} component={Login} />
      </div>
    );
  }

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <>
          <Switch>
            {formsRoutes.map((data, i) => (
              <Route
                key={i}
                exact
                path={`/${data.url}`}
                component={data.component}
              />
            ))}
            {/* <Route component={ErrorPage} /> */}
            <Route path="/responses-forms/:id" component={ResponsesTab} />
            <Route path="/edit-test-forms/:id" component={EditTestForm} />
            <Route path="test-forms/:testId" component={Testform} />
          </Switch>
        </>

        {path !== "forms" &&
          path !== "forms-questions" &&
          path !== "test-forms" &&
          // path != "admin-forms-dashboard" &&
          path !== "end-user-forms-dashboard" &&
          path !== "responses-forms" &&
          path !== "edit-test-forms" ? (
          <>
            <div
              className={`${!pagePath ? "content-body" : ""}`}
              style={{ minHeight: window.screen.height - 45 }}
            >
              <div className={`${!pagePath ? "container-fluid" : ""}`}>
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                  {/* Add a Route to render ErrorPage */}

                </Switch>
              </div>
              {!pagePath && validurl && <Footer />}
            </div>
          </>
        ) : null}

        {/* {!pagePath && <Footer />} */}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
