import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { format } from "date-fns";
//import DropDownBlog from "./DropDownBlog";
import EditDropdown from "./EditDropdown";
import swal from "sweetalert";
import Swal from "sweetalert2";
import certificate from "./../../../images/svg/degree-certificate.svg";
import clock from "./../../../images/svg/clock-1.svg";
import { useSelector } from "react-redux";
import UpdateProfile from "./UpdateProfile";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import LoadingSpinner from "../../pages/LoadingSpinner";
import StudentCal from "./StudentCal";
import { BsBuildingsFill } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import oopsemptybox from "../Animations/138566-frequently-asked-question-faq.json";
//animation
import Lottie from "lottie-react";
import Loading from "../Animations/99109-loading.json";
import { RxCross1 } from "react-icons/rx";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { BiCaretDown } from "react-icons/bi";
import { BiCaretUp } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import s3 from "../../../utilis/aws.config";
import { useProfilePicture } from "../../../store/context/profilePictureProvider";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department"
};

const ProfileActivityChart = loadable(() =>
  pMinDelay(import("./Dashboard/ProfileActivityChart"), 1000)
);
const dateFormat = "dd MMM yyyy"; // format string for date

const WidgetBlog = ({ changeImage, title, link, nos }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex">
                <img src={changeImage} alt="" />
                <div className="ms-4">
                  <h4>{nos || 100}</h4>
                  <span>{title}</span>
                </div>
              </div>
              <Link to={`/${link}`}>
                <i className="las la-angle-right text-primary"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// api call
async function getProfileApi(token) {
  const URL = `${API_ROOT_URL}/users/getProfile`;
  return fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  }).then((data) => data.json());
}

const Profile = (props) => {
  const dispatch = useDispatch();
  const { profilePicture, setProfilePicture } = useProfilePicture();
  const userID = useSelector((state) => state.auth.auth._id);
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);

  const [loading, setLoading] = useState(true);
  const [dropSelect, setDropSelect] = useState("This Month");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOnDelete, setIsOnDelete] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [inductionData, setInductionData] = useState();
  const [page, setPage] = useState(1);

  //show more and less functionality in Bio section
  const [showMore, setShowMore] = useState(false);
  const aboutMe = profileData?.profile?.aboutMe || '';
  const wordCount = aboutMe.split(/\s+/).length;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // callback function to update state
  const trackOnclick = (payload) => {
    setIsModalOpen(payload);
  };

  const trackDeleteClick = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your record is safe!");
      }
    });
  };

  //get profile data(bio) from API
  const getProfile = async () => {
    const response = await getProfileApi(token);
    if ("status" in response && response.status === true) {
      setProfileData(response.data);
      setProfilePicture(response.data?.profile?.profilePhoto)
      setLoading(false);
    }
    else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  // console.log(profileData, "profile")

  //To get data from last 5 inductions
  const handlepageLoad = async (e) => {
    const response = await fetch(`${API_ROOT_URL}/users/fetching/lastrecords`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status === true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };
  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${profilePicture}`,
  });
  // use effect re rendering hook
  useEffect(() => {
    getProfile(token);
    if (role === USER_ROLES.USER) {
      handlepageLoad();
    }
  }, [isModalOpen, page, token]);

  const popover = (

    <Popover id="popover-basic">
      <Popover.Header as="h3">Bio</Popover.Header>
      <Popover.Body>
        <p>{profileData?.profile?.aboutMe}</p>
      </Popover.Body>
    </Popover>
  );

  const pageContent = loading ? (
    <Lottie style={{ height: 150 }} animationData={Loading} />
  ) : (
    <>
      {USER_ROLES.INSTRUCTOR === role ? (
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body" style={{ minHeight: "650px" }}>
              <div className="app-fullcalendar1 m-auto">
                <StudentCal />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        {USER_ROLES.USER === role ? (
          <div
            className="col-xl-5 col-xxl-4 col-lg-12 "
          >
            <div
              className="card instructors-box"
            >
              <div className="card-header border-0">
                <EditDropdown
                  trackOnclick={trackOnclick}
                //trackDeleteClick={trackDeleteClick}
                />
                {/* <DropDownBlog
                  trackOnclick={trackOnclick}
                  trackDeleteClick={trackDeleteClick}
                /> */}
              </div>

              <div className="card-body text-center pb-3 mt-3">
                <div className="instructors-media">
                  <img
                    src={url}
                    alt={profileData?.profile?.profilePhoto}
                    style={{ width: 150, height: 150, marginTop: "10px" }}
                  />

                  <div className="instructors-media-info mt-4">
                    <h4 className="mb-1">
                      {profileData?.profile?.first_name +
                        " " +
                        profileData?.profile?.last_name}{" "}
                    </h4>
                    <span className="fs-18">
                      Member Since{" "}
                      {new Date(profileData?.createdAt).getFullYear()}
                    </span>
                  </div>
                </div>

                <div className="bio text-sroletart my-4">
                  <h4 className="mb-3">Bio</h4>
                  <div className="bio-content">
                    <p style={{ textAlign: "justify" }}>
                      {showMore ? `${aboutMe.split(' ').slice(0, 40).join(' ')}` : `${aboutMe.split(' ').slice(0, 35).join(' ')}`}
                      {" "}
                      {wordCount > 15 && (
                        <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover} style={{ zoom: 1 }}>
                          <button className="ShowMore" onClick={toggleShowMore}>
                            {showMore ? 'Show less' : 'Show more'} {showMore ? <BiCaretUp /> : <BiCaretDown />}
                          </button>
                        </OverlayTrigger>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {USER_ROLES.SUPER_ADMIN === role ? (
          <>
            <div className="row">
              <WidgetBlog
                changeImage={certificate}
                title="Companies"
                link="companies"
                nos={profileData?.totalCompanies}
              />
              <WidgetBlog
                changeImage={clock}
                title="Users"
                link="users"
                nos={profileData?.totalUsers}
              />

              <div className="col-xl-4 col-xxl-4 col-lg-12 ">
                <div
                  className="card instructors-box"
                  style={{ height: "640px", marginTop: "0px" }}
                >
                  <div className="card-header border-0">
                    {/* <DropDownBlog
                      trackOnclick={trackOnclick}
                      trackDeleteClick={trackDeleteClick}
                    /> */}

                    <EditDropdown
                      trackOnclick={trackOnclick}
                      trackDeleteClick={trackDeleteClick}
                    />
                  </div>

                  <div className="card-body text-center pb-3 mt-3">
                    <div className="instructors-media">
                      <img
                        src={url}
                        alt={profileData?.profile?.profilePhoto}
                        style={{ width: 200, height: 200 }}
                      />

                      <div className="instructors-media-info mt-4">
                        <h4 className="mb-1">
                          {profileData?.profile?.first_name +
                            " " +
                            profileData?.profile?.last_name}{" "}
                        </h4>
                        <span className="fs-18">
                          Member Since{" "}
                          {new Date(profileData?.createdAt).getFullYear()}
                        </span>
                      </div>
                    </div>

                    <div className="bio text-sroletart my-4">
                      <h4 className="mb-3">Bio</h4>
                      <div className="bio-content">
                        <p>{profileData?.profile?.aboutMe}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <UpdateProfile
        isModalOpen={isModalOpen}
        trackOnclick={trackOnclick}
        profileData={profileData}
      />
    </>
  );

  return <>{pageContent}</>;
};
export default Profile;
