import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { API_ROOT_URL } from "../../../constants";
import "../../../../jsx/induction.css";
import Lottie from "lottie-react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import endmessageanimation from "../../Animations/animation_lloy7bt3.json";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
//theme
import "primereact/resources/themes/viva-light/theme.css";
//core
import "primereact/resources/primereact.min.css";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
};

const Companydashboardlisting = () => {
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const loginUser = useSelector((state) => state.auth.auth.id);
  const parentCompany = useSelector((state) => state.auth.auth.parentCompany);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]); // main listing data
  const [companyFilter, setCompanyFilter] = useState();
  const [inductionFilter, setInductionFilter] = useState();

  // get data and used query string
  const handlepageLoad = async (e) => {
    // query string
    var queryStr = "";
    queryStr = companyFilter
      ? `?company=${companyFilter}`
      : USER_ROLES.COMPANY === role
        ? `?company=${loginUser}`
        : USER_ROLES.INSTRUCTOR === role
          ? `?company=${parentCompany}`
          : "";
    queryStr += inductionFilter ? `&induction=${inductionFilter}` : "";

    const response = await fetch(`${API_ROOT_URL}/induction/users` + queryStr, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status == true) {
      setUsers(response.data);
      setLoading(false);
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  // use effect hook for rerendering
  useEffect(() => {
    handlepageLoad();
  }, [companyFilter, inductionFilter]);

  const InductionFilterHandle = (e) => {
    setInductionFilter(e.target.value);
  };

  //datasets to store users data and avg score
  const dataset1 = [];
  const dataset2 = [];
  const dataset3 = [];

  //users scores
  const dataset5 = []
  const PassFail = users.map((i) => dataset5.push(i.result.map((i) => i.testStatus, i.userID)));

  // Count the number of "Pass" statuses in each inner array
  const passCounts = dataset5.map(innerArray => innerArray.filter(status => status === "Pass").length);

  //calculating max score
  const scoresAll = dataset3.reduce((total, subArray) => {
    subArray.forEach((item) => {
      const { correctAnswers, wrongAnswers, inductionID } = item;
      const scoreAsNumber = parseInt(correctAnswers);
      const otherscoreAsNumber = parseInt(wrongAnswers);
      if (!total[inductionID]) {
        total[inductionID] = 0;
      }
      total[inductionID] = scoreAsNumber + otherscoreAsNumber;
    });
    return total;
  }, {});

  const allTitles = users.map((i) => dataset1.push(i.inductions.title));
  const Details = users.map((i) =>
    dataset2.push(i.inductions._id, i.inductions.title)
  );

  const totalAttempts = users.reduce((acc, user) => {

    const { _id, title } = user.inductions;
    const { totalQuestions } = user;
    const index = acc.findIndex((entry) => entry._id === _id);
    if (index !== -1) {
      acc[index].total += user.total;
    } else {
      acc.push({ _id, title, total: user.total, totalQuestions });
    }
    return acc;
  }, []);

  const totalArray = totalAttempts.map((item) => item.total);
  const passingPercentages = totalArray.map((total, index) => {
    // Check if total is not zero to avoid division by zero
    if (total !== 0) {
      return (passCounts[index] / total) * 100;
    } else {
      return 0; // or handle this case based on your requirements
    }
  });

  totalAttempts.forEach((attempt, index) => {
    const total = attempt.total;
    // Check if total is not zero to avoid division by zero
    if (total !== 0) {
      attempt.passingPercentage = (passCounts[index] / total) * 100;
    }
  });

  //Set passing percentages as titles in totalAttempts
  totalAttempts.forEach((attempt, index) => {
    attempt.passingPercentage = passingPercentages[index];

  });

  //removing duplicate records 
  function removeDuplicates() {
    let unique = [];
    dataset1.forEach((element) => {
      if (!unique.includes(element)) {
        unique.push(element);
      }
    });
    return unique;
  }

  const uniqueTitles = removeDuplicates(dataset1);
  const correctsofAll = {};
  dataset3.forEach((subArray) => {
    subArray.forEach((item) => {
      const { correctAnswers, inductionID } = item;
      const scoreAsNumber = parseInt(correctAnswers);
      if (!correctsofAll[inductionID]) {
        correctsofAll[inductionID] = 0;
      }
      correctsofAll[inductionID] += scoreAsNumber;
    });
  });

  //mapping data from totalattempts and calculate average score
  const updatedTotalAttempts = totalAttempts.map((item) => {
    const { _id, total, totalQuestions } = item;
    const attempts = scoresAll[_id] || 0;
    const totalCorrect = correctsofAll[_id] || 0;
    const MaxScoring = item.totalQuestions * total;
    return {
      _id,
      title: item.title,
      total: attempts,
      totalCount: item.total,
      totalCorrect,
      // FullScore: (totalCorrect / MaxScoring) * 100,
      MaxScoring,
      totalQuestions,
      passingPercentage: item.passingPercentage,

    };
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="fa fa-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const endmessage = {
    height: 150,
  };

  const percentageBar = (rowData) => {
  
    const setPassing = ((rowData.passingPercentage) + "%")
    const FailState = rowData.passingPercentage < 40
    return (
      <>
        <Row style={{ textAlign: "start"}}>
         
          <Col sm="10">
            <div className="progress-box">
              <div className="progress">
                {
                  rowData.passingPercentage < 40 ?
                    (
                      <div
                        className="progress-bar bg-danger"
                        style={{
                          width: setPassing,
                          height: "10px",
                          borderRadius: "4px",
                        }}
                        role="progressbar"
                      ></div>
                    ) : (
                      <div
                        className="progress-bar bg-success"
                        style={{
                          width: setPassing,
                          height: "10px",
                          borderRadius: "4px",
                        }}
                        role="progressbar"
                      ></div>
                    )
                }
              </div>
            </div>
          </Col>
          <Col sm="1" className={`badge ${FailState ? "badge-danger" : "badge-success"}`} style={{fontSize:"12px",fontWeight:"900"}}>
            <span>{parseFloat(rowData.passingPercentage.toFixed(0)) + "%"}</span>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-2">
              <div className="table-responsive">
                <div id="student_wrapper" className="dataTables_wrapper">
                </div>
                <DataTable
                  value={updatedTotalAttempts.map((rowData, index) => ({
                    ...rowData,
                    serialNumber: index + 1,
                  }))}
                  header={header}
                  dataKey="id"
                  emptyMessage={
                    <div className="queryContainer">
                      {" "}
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                          <div className="form-input-content text-center">
                            <h1 className="error-text font-weight-bold">
                              <Lottie style={endmessage} animationData={endmessageanimation} />
                            </h1>
                            <h3
                              className="fs-28 mt-3"
                              style={{ color: "#3A3630", fontWeight: "900" }}
                            >
                              {" "}
                            </h3>
                            <h4 style={{ fontStyle: "italic" }}>
                              No Records Found !
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  filters={filters}
                  globalFilterFields={["title", "FullScore", "totalQuestions"]}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  size="large"
                  stripedRows
                  showGridlines
                  tableStyle={{ minWidth: "50rem" }}
                  filterDisplay="menu"
                  className="headerField"
                >
                  <Column
                    header="Sr.No" headerStyle={{ width: "3rem" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>
                  <Column field="title" sortable header="INDUCTION"></Column>
                  <Column
                    field="totalCount"
                    sortable
                    header="ATTEMPTS"
                  ></Column>
                  <Column
                    field="passingPercentage"
                    sortable
                    header="PASSING PERCENTAGE"
                    body={percentageBar}
                    //body={(rowData) => parseFloat(rowData.passingPercentage.toFixed(2)) + "%"}
                    style={{ textAlign: "start" }}
                  ></Column>
                  {/* <Column
                    field="totalQuestions"
                    sortable
                    header="TOTAL MCQ"
                    style={{ textAlign: "start" }}
                  ></Column> */}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Companydashboardlisting;

