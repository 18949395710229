import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import CourseBlog from "./Dashboard/CourseBlog";

//animation
import Lottie from "lottie-react";
import Loading from "../Animations/99109-loading.json";
//images
import { useSelector } from "react-redux";
import { API_ROOT_URL } from "../../constants";


const LearningActivityChart = loadable(() =>
  pMinDelay(import("./Dashboard/LearningActivityChart"), 1000)
);

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState();
  const token = useSelector((state) => state.auth.auth.token);

  const timelineBlog = [
    { title: "Introduction Wireframe", change: "bg-primary" },
    { title: "Basic React", change: "bg-secondary" },
    { title: "Basic Js", change: "bg-warning" },
  ];

  function UpComingEvent() {
    return (
      <>
        <div className="d-flex justify-content-between side-border">
          <h4 className="mb-0 fs-18 font-w500">5 Jan</h4>
          <div className="dropdown custom-dropdown mb-0">
            {/* <DropDownBlog /> */}
          </div>
        </div>
        <ul className="timeline-active style-4">
          {timelineBlog.map((data, ind) => (
            <li className="d-flex align-items-center" key={ind}>
              <span className="time-1">08.00 AM</span>
              <div className="panel">
                <div className={`line-color ${data.change}`}></div>
                <Link to={"#"} className="timeline-panel text-muted">
                  <span className="d-block">UI Design</span>
                  <h4 className="mb-0">{data.title}</h4>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  }
  //
  const getDashboardData = async () => {
    var ApiURL = `${API_ROOT_URL}/dashboard/`;
    const response = await fetch(ApiURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());

    if ("status" in response && response.status === true) {
      setDashboard(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
    changeBackground({ value: "light", label: "Light" });
  }, [loading]);

  //   useEffect(() => {
  //   getDashboardData();
  //   changeBackground({ value: "dark", label: "Dark" });
  // }, []);

  const [dropSelect, setDropSelect] = useState("This Month");

  const style = {
    height: 150,
  };


  return (
    <div className="App">
      {loading ? (
        <Lottie style={style} animationData={Loading} />
      ) : (
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="col-xl-12 bt-order">
              <CourseBlog dashboard={dashboard} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Home;
