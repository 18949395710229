import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import DropDownBlog from "./DropDownBlog";
import EditDropdown from "../Dashboard/EditDropdown";
import UpdateDepartmentProfile from "../Department/UpdateDepartmentProfife";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import LoadingSpinner from "../../pages/LoadingSpinner";
import "react-toastify/dist/ReactToastify.css";
import certificate from "./../../../images/svg/degree-certificate.svg";
import clock from "./../../../images/svg/clock-1.svg";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import s3 from "../../../utilis/aws.config";
import { useProfilePicture } from "../../../store/context/profilePictureProvider";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department"
};

const dateFormat = "dd MMM yyyy"; // format string for date

const WidgetBlog = ({ changeImage, title, link, dataCount }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex">
                <img src={changeImage} alt="" />
                <div className="ms-4">
                  <h4>{dataCount}</h4>
                  <span>{title}</span>
                </div>
              </div>
              <Link to={`/${link}`}>
                <i className="las la-angle-right text-primary"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// api call
async function getProfileApi(token) {
  const URL = `${API_ROOT_URL}/users/getProfile`;
  return fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  }).then((data) => data.json());
}

const Profile = (props) => {
  const dispatch = useDispatch();
  const { profilePicture, setProfilePicture } = useProfilePicture();
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [page, setPage] = useState(1);
  const [departmentData, setDepartmentData] = useState();
  const [profileImage, setProfileImage] = useState("dummy-user.png")

  // callback function to update state
  const trackOnclick = (payload) => {
    setIsModalOpen(payload);
  };

  const trackDeleteClick = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your record is safe!");
      }
    });
  };

  //get profile data(bio) from API
  const getProfile = async () => {
    const response = await getProfileApi(token);
    if ("status" in response && response.status === true) {
      //setProfileData(response.data);
      setDepartmentData(response.data);
      setLoading(false);
      setProfileImage(response?.data?.profile?.profile_picture);
      setProfilePicture(response?.data?.profile?.profile_picture);
    }
    else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  // use effect re rendering hook
  useEffect(() => {
    getProfile(token);
  }, [isModalOpen, page, token]);

  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${profilePicture}`,
  });


  const pageContent = loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className="row">
        <div className="col-xl-4 col-xxl-5 col-lg-12">
          <div className="card instructors-box">
            <div className="card-header border-0">
              <EditDropdown
                trackOnclick={trackOnclick}
                trackDeleteClick={trackDeleteClick}
              />
              {/* <ActionDropDown
                trackOnclick={trackOnclick}
                trackDeleteClick={trackDeleteClick}
              /> */}
            </div>
            <div className="card-body text-center pb-3">
              <div className="instructors-media">
                {/* <img src={loadImage(profileImg)} /> */}

                <img
                  className="img-fluid"
                  src={url}
                  alt={profileImage}
                />

                <div className="instructors-media-info mt-4">
                  <h4 className="mb-1">{departmentData?.profile?.name}</h4>
                  <span className="fs-18">Member Since 2023</span>
                </div>
              </div>

              <div className="bio text-start my-4">
                {/* <h4 className="mb-3">Bio</h4> */}
                <div className="bio-content">
                  <p>{departmentData?.profile.aboutDepartment}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-xxl-7 col-lg-12 ">
          <div className="row">
            <WidgetBlog
              changeImage={certificate}
              title="Instructors"
              link="instructors"
              dataCount={departmentData?.totalInstructor}
            />
            <WidgetBlog
              changeImage={clock}
              title="Inductions"
              link="inductions"
              dataCount={departmentData?.totalInductions}
            />
            {/* <div className="widget-heading d-flex justify-content-between align-items-center">
              <h3 className="m-0">My Instructors</h3>
              <Link to={`/instructors`} className="btn btn-primary btn-sm">
                View all
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <UpdateDepartmentProfile
        isModalOpen={isModalOpen}
        trackOnclick={trackOnclick}
        profileData={profileData}
        departmentData={departmentData}
      />
    </>
  );

  return <>{pageContent}</>;
};
export default Profile;
