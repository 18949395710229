import { FilterMatchMode, FilterOperator } from "primereact/api";
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";

//animation
import Lottie from "lottie-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { Skeleton } from "primereact/skeleton";
import { FcNext } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";

const ListTopics = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [Topics, setTopics] = useState([]);
  const [updatedTopic, setUpdatedTopic] = useState("");
  const [isCompDeleted, setIsCompDeleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listOnlyStatus, SetListOnlyStatus] = useState(true);
  const tokenDetailsString = localStorage.getItem('userDetails');
  // State variable to track the ID of the currently edited row
  const [currentRowId, setCurrentRowId] = useState(null);
  const [newSubtopic, setNewSubtopic] = useState("");
  const [isFormChanged, setFormChanged] = useState(false);
  const [duplicateSubtopicError, setDuplicateSubtopicError] = useState('');
  const [duplicatesFound, setDuplicatesFound] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  // callback function to update state
  const trackDeleteClick = (userID, deleted) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        const response = await fetch(
          `${API_ROOT_URL}/department/topic/changedeletestatus`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID }),
          }
        ).then((data) => data.json());
        if ("status" in response && response.status == true) {
          swal("Poof! Your record has been deleted!", {
            icon: "success",
          }).then(() => {
            setIsCompDeleted(!isCompDeleted);
          });
        } else {
          if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
            swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
              dispatch(logout(props.history));
            });
          } else {
            return swal("Failed", "Error message", "error");
          }
        }
        handlepageLoad();
      } else {
        swal("Your record is safe!");
      }
    });
  };
  const handleInputChange = () => {
    // Set formChanged to true when any field is changed
    setFormChanged(true);
  };
  // api call
  async function getTopics(token, listOnlyStatus) {
    return fetch(`${API_ROOT_URL}/department/fetching/topics?listOnly=${listOnlyStatus}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  // on List topics page first render
  const handlepageLoad = async (event) => {
    const response = await getTopics(token, listOnlyStatus);
    if ("status" in response && response.status == true) {
      setTopics(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  const trackOnclick = (rowId, rowData) => {
    setIsModalOpen(true);
    setCurrentRowId(rowId);
  };


  //Edit list topic submit handler
  const onSubmitHandle = async (e) => {
    e.preventDefault();


    // Get the current topic and subtopics
    const currentTopic = Topics.find((data) => data._id === currentRowId);
    const { topic, subtopics } = currentTopic;

    // Check if the topic field is empty
    if (!topic) {
      return swal("Failed", "Topic field cannot be empty", "error");
    }

    // Check if any subtopic field is empty
    const subtopicEmpty = subtopics.some((subtopic) => !subtopic.value);

    const subtopicList = currentTopic.subtopics.map((subtopic) => subtopic.value);
  
    //check if any value is duplicate
    const isDuplicate = subtopicList.some((subtopic, index) => subtopicList.indexOf(subtopic) !== index);

    if (isDuplicate) {
      return swal({
            title: "Oops!",
            text: "You cannot add duplicate subtopics",
            icon: "warning"
          });    
    }

    if (subtopicEmpty) {
      return swal("Failed", "Subtopic fields cannot be empty", "error");
    }

    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      });
    } else {
      // Update the payload with the latest subtopics
      const payload = {
        topic,
        subtopics: currentTopic.subtopics,
      };
      if (subtopics.length === 0) {
        return swal("Failed", "Atleast one Subtopic is required", "error");
      }
      // Make the API call
      const response = await fetch(
        `${API_ROOT_URL}/department/topic/edit/${currentRowId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify(payload),
        }
      ).then((data) => data.json());

      if ("status" in response && response.status === true) {
        setIsModalOpen(false);
        return swal("Success", response.message, "success");
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", response?.message, "error");
        }
      }
    }
  };


  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      topic: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      'SubTopics': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);


  useEffect(() => {
    setFormChanged(false);
  }, [isModalOpen]);


  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="fa fa-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const customFilter = (value, filter) => {
    value = value || "";
    filter = filter || "";

    return value.toLowerCase().includes(filter.toLowerCase());
  };

  const header = renderHeader();
  const filteredTopics = Topics.filter((topic) => {
    const topicMatch = customFilter(topic.topic, globalFilterValue);
    const subtopicMatch = topic.subtopics.some((subtopic) =>
      customFilter(subtopic.value, globalFilterValue)
    );

    return topicMatch || subtopicMatch;
  });
  const style = {
    height: 150,
  };

  const addSubtopic = () => {
    const updatedTopics = [...Topics];
    const currentTopic = updatedTopics.find(
      (data) => data._id === currentRowId
    );

    currentTopic.subtopics.push({ value: newSubtopic });
    setTopics(updatedTopics);
    setNewSubtopic("");
  };

  const TopicsTemplate = (rowData) => {
    return (
      <>
        <div>
          {rowData?.topic?.charAt(0).toUpperCase() +
            rowData?.topic?.slice(1).toLowerCase()}
        </div>
      </>
    );
  };

  const handleDeleteSubtopic = (index, currentData) => {
    const updatedTopics = [...Topics];
    const updatedTopic = {
      ...updatedTopics.find((item) => item._id === currentData._id),
    };
    updatedTopic.subtopics.splice(index, 1); // Remove the subtopic at the specified index
    setTopics(updatedTopics);
    handleInputChange(); // Set formChanged when input changes

    // Assuming you also want to update the payload here
    const payload = {
      topic: updatedTopic.topic,
      subtopics: updatedTopic.subtopics,
    };
  }

  const modifiedSubTopics = Topics.map((sub) => {
    const { subtopics } = sub;
    const SubTopics = subtopics.map((subtopic) => subtopic.value)
    return { ...sub, SubTopics };
  });

  const dataset1 = []
  const allArray = Topics.map((i) => dataset1.push(i.subtopics.map((i) => i.value)))

  const subTopicsTemplate = (rowData) => {
    return (
      <>
        {rowData.SubTopics && rowData.SubTopics.length > 0 && (
          <ul style={{ listStyleType: "disc" }}>
            {rowData.SubTopics.map((subTopic) => (
              <li key={subTopic.id}>
                <FcNext /> {subTopic}
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  const actionTemplate = (rowData) => {
    const handleCallback = () => {
      trackOnclick(rowData._id, rowData);
    };
    const deleteClick = () => {
      trackDeleteClick(rowData._id, rowData.deleted);
    };

    return (
      <div className="d-flex">
        <button onClick={handleCallback} className="editIcon">
          <i className="fas fa-pencil-alt" style={{ fontSize: "17px" }}></i>
        </button>

        <button onClick={deleteClick} className="deleteIcon">
          <i className="fa fa-trash" style={{ fontSize: "17px" }}></i>
        </button>
      </div>
    );
  };
  const SkeletonColumn = () => {
    const numberOfSkeletons = 4;

    // Create an array with the desired number of skeletons
    const skeletons = Array.from({ length: numberOfSkeletons }, (_, index) => (
      <div key={index} style={{ marginBottom: '10px' }}>
        <Skeleton length={5} /> {/* Adjust the length as needed */}
      </div>
    ));

    return <div>{skeletons}</div>
  };
  const items = Array.from({ length: 5 }, (v, i) => i);
  const bodyTemplate = () => {
    return <Skeleton length={4}></Skeleton>
  };

  const circle = () => {
    return (
      <>
        <div className="d-flex">
          <Skeleton size="2rem" borderRadius="50%" className="mx-1"></Skeleton>
          <Skeleton size="2rem" borderRadius="50%" className="mr-2"></Skeleton>
        </div>
      </>
    )
  };

  // re-render page on every update
  useEffect(() => {
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      handlepageLoad();
    }
  }, [loading, isModalOpen, tokenDetailsString]);

  const endmessage = {
    height: 150,
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-3">
              <div className="table-responsive">
                {loading ? (
                  <DataTable size="large" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} stripedRows showGridlines tableStyle={{ minWidth: "50rem" }}
                    header={header} value={items} className="p-datatable-striped">
                    <Column header="Sr.No" style={{ width: '10%' }} body={bodyTemplate}></Column>
                    <Column header="TOPIC" style={{ width: '35%' }} body={bodyTemplate} ></Column>
                    <Column header="SUB-TOPIC" style={{ width: '35%' }} body={SkeletonColumn}></Column>
                    <Column header="ACTION" style={{ width: '20%' }} body={circle}></Column>
                  </DataTable>)
                  :
                  (<DataTable
                    value={modifiedSubTopics.map((rowData, index) => ({
                      ...rowData,
                      serialNumber: index + 1,
                    }))}
                    header={header}
                    dataKey="id"
                    emptyMessage={
                      <div className="queryContainer">
                        {" "}
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-12">
                            <div className="form-input-content text-center">
                              <h1 className="error-text font-weight-bold">
                                <Lottie style={endmessage} animationData={endmessageanimation} />
                              </h1>
                              <h3
                                className="fs-28 mt-3"
                                style={{ color: "#3A3630", fontWeight: "900" }}
                              >
                                {" "}
                              </h3>
                              <h4 style={{ fontStyle: "italic" }}>
                                No Records Found !
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    filters={filters}
                    globalFilterFields={["topic", "value", "SubTopics"]}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    size="large"
                    stripedRows
                    showGridlines
                    tableStyle={{ minWidth: "50rem" }}
                    filterDisplay="menu"
                    className="headerField"
                  >
                    <Column
                      header="SR.NO" headerStyle={{ width: "3rem" }}
                      body={(data, options) => options.rowIndex + 1}
                    ></Column>
                    <Column
                      field="topic"
                      body={TopicsTemplate}
                      sortable
                      header="TOPIC"
                    ></Column>
                    <Column
                      field="SubTopics"
                      body={subTopicsTemplate}
                      header="SUB-TOPICS"
                      style={{ minWidth: '12rem' }}
                    ></Column>
                    <Column body={actionTemplate} header="ACTION"></Column>
                  </DataTable>
                  )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="modal fade d-flex"
          show={isModalOpen}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-content" style={{ width: "50vw" }}>
            <div className="modal-header">
              <h5 className="modal-title text-white fs-20">
                EDIT TOPIC DETAILS{" "}
              </h5>
              <RxCross1
                size={25}
                color="white"
                onClick={() => setIsModalOpen(false)}
              />
            </div>
            <div className="modal-body">
              <form className="topics-form" onSubmit={(e) => onSubmitHandle(e)}>
                {Topics?.map((data) => (
                  <div className="row" key={data._id}>
                    {currentRowId === data._id && ( // Checking the current row ID matches the data ID
                      <>
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <label className="fs-16 font-w800">Topic
                              <span
                                className="required-field"
                                style={{ color: "red", fontWeight: "200px" }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={
                                Topics.find((data) => data._id === currentRowId)
                                  .topic.charAt(0).toUpperCase() + Topics.find((data) => data._id === currentRowId)
                                    .topic.slice(1).toLowerCase()
                              }
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (!inputValue.startsWith(' ')) {
                                  const updatedTopics = [...Topics];
                                  const updatedTopic = updatedTopics.find(
                                    (item) => item._id === currentRowId
                                  );
                                  updatedTopic.topic = inputValue;
                                  setTopics(updatedTopics);
                                  handleInputChange(); // formChanged when input changes
                                }
                              }}
                            />
                          </div>
                        </div>
                        <Row>
                          <Col lg="9">
                            <div className="mb-2 mt-3">
                              <h2
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "900px",
                                  color: "3a3630",
                                }}
                              >
                                <span className="font-w700"> SubTopic </span>
                                <span
                                  className="fs-20 font-w700 text-center"
                                  style={{ color: "#FEBA30" }}
                                >
                                  {" "}
                                  Tags
                                  <span
                                    className="required-field"
                                    style={{ color: "red", fontWeight: "200px" }}
                                  >
                                    *
                                  </span>
                                </span>
                              </h2>
                            </div>
                          </Col>
                          <Col lg="3" className="mt-3">
                            <button
                              type="button"
                              className="AddButton"
                              style={{
                                display: "flex",
                                float: "right",
                              }}
                              onClick={addSubtopic}
                            >
                              <span
                                className="btn-icon-start text-info"
                                style={{ borderRadius: "20px" }}
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ color: "#3A3630" }}
                                ></i>
                              </span>
                              Add
                            </button>
                          </Col>
                        </Row>
                        <hr style={{ marginTop: "10px", marginBottom: "20px" }} />

                        {data.subtopics.map((sub, index) => (
                          <div className="col-lg-6" key={index}>
                            <div className="form-group mb-3">
                              <div className="row">
                                <div className="col-xl-1">
                                  <label className="fs-16 font-w600 mt-3">{index + 1}.</label>
                                </div>
                                <div className="col-xl-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="subtopic"
                                    value={sub.value?.charAt(0).toUpperCase() + sub.value?.slice(1).toLowerCase()}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      // Checking if there are leading spaces
                                      if (!inputValue.startsWith(' ')) {
                                        const updatedTopics = [...Topics];
                                        const updatedTopic = {
                                          ...updatedTopics.find((item) => item._id === data._id),
                                        };
                                        updatedTopic.subtopics[index].value = inputValue;
                                        setTopics(updatedTopics);
                                        handleInputChange(); //formChanged when input changes
                                      }
                                    }}
                                  />
                                </div>
                                {index ? (
                                  <div className="col-xl-1">
                                    <i
                                      className="fa fa-trash text-danger mt-3"
                                      onClick={() => handleDeleteSubtopic(index, data)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="col-lg-12">
                          <div className="form-group mb-3 mt-3">
                            <input
                              style={{
                                margin: "auto",
                                display: "flex",
                                paddingLeft: "40px",
                                paddingRight: "40px",
                              }}
                              type="submit"
                              value="Submit"
                              name="submit"
                              className={`submits ${isFormChanged ? 'enabled' : ''}`}
                              disabled={!isFormChanged}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </Fragment>
  );
};

export default ListTopics;
