import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";

import "react-modal-video/css/modal-video.min.css";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../pages/LoadingSpinner";
import CurrentSlide from "./components/CurrentSlide";
import InductionSlidesList from "./components/InductionSlidesList";
import InductionSubTiltle from "./components/InductionSubTiltle";
import InductionTitle from "./components/InductionTitle";

import { getInduction } from "../APIs";

const SingleInductionView = (props) => {
  const [loading, setLoading] = useState(true);
  const [inductionData, setInductionData] = useState();
  const [slideData, setSlideData] = useState();
  const [passingMarksPop, setPassingMarksPop] = useState();

  const [currentSlideContent, setCurrentSlideContent] = useState(null);
  const [isChangeContent, setIsChangeContent] = useState(false);
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const { id } = useParams();

  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    e.preventDefault();

    // console.log("outside");
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  // API call for fetching all induction details with slides
  const handleGetInductionDetail = async (e) => {
    // getInduction
    const response = await getInduction(id, token);

    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setSlideData(response.slides);
      setCurrentSlideContent(response.slides[0]);
      setLoading(false);
      // handleFullScreen();
    }
  };

  const openPopUp = () => {
    //console.log("openPopUp called");
    setPassingMarksPop(true);
    return;
  };

  const hidePopUp = () => {
    //console.log("hidePopUp called");
    setPassingMarksPop(false);
  };

  const setStateOfParent = (newSlide) => {
    setIsChangeContent(true);
    setCurrentSlideContent(newSlide);
    return;
  };
  // On every render
  useEffect(() => {
    if (loading) {
      handleGetInductionDetail();
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [loading, props.setShowSidebar]);

  const PageContent = loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className="col-xl-8 col-xxl-7">
        <div className="card">
          <div className="card-body">
            {/* induction title and stats */}
            <InductionTitle title={inductionData.title} />
           { /* Induction subtitle */}
            <InductionSubTiltle title={currentSlideContent.slideTitle} />
            {/* Slide content area and onclick changeable */}

            <CurrentSlide currentSlideContent={currentSlideContent} />

            {/* About Induction Area with tabs */}
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-xxl-5">
        <InductionSlidesList
          setStateOfParent={setStateOfParent}
          setCurrentSlideContent={setCurrentSlideContent}
          slides={slideData}
          inductionID={id}
        />
      </div>
    </>
  );

  return <div className="row">{PageContent}</div>;
};

export default SingleInductionView;
