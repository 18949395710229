import Avatar from "@mui/material/Avatar";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BiSolidStopwatch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import swal from "sweetalert";
import { API_ROOT_URL, AWS_BUCKET_NAME, QUESTIONS_ASSETS_URL } from "../../jsx/constants";
import "./../../bjs-forms/forms.css";
import { logout } from "../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import { FcApproval } from "react-icons/fc";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import useDashboardUrl from "../../utilis/useDashboardUrl";
import Alert from "@mui/material/Alert";
import s3 from "../../utilis/aws.config";

const Ans = styled.div`
  margin: 10px 0px 0px 0px;
  float: inline-end;
  font-size: 14px;
  font-weight: 500;
`;

const AnsText = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin: 5px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: "20px 20px 20px 20px",
    marginTop: "60px",
    backgroundColor: "whitesmoke",
  },
  input: {
    display: "none",
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const Testform = (props) => {
  const navigate = useHistory();
  const { testId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  //token
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [tabChangeCount, setTabChangeCount] = useState(0);
  const [testData, setTestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentAttemptedQuestion, setCurrentAttemptedQuestion] =
    useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [questionId, setQuestionId] = useState("");
  const [questionsPerPage] = useState(1);
  //quiz
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);

  const [userProfileData, setUserProfileData] = useState();

  const decoded = jwt_decode(token);
  const dashboardUrl = useDashboardUrl(role);
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
    remark: "Test Failed !",
  });

  // api call
  async function getProfileApi(token) {
    const URL = `${API_ROOT_URL}/users/getProfile`;
    return fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const getProfile = async () => {
    const response = await getProfileApi(token);
    if ("status" in response && response.status == true) {
      setUserProfileData(response.data);
      setLoading(false);
    }
  };

  //const questionsPerPage = 3;

  async function getRecentForms(token) {
    if (decoded.role == "end_user") {
      return fetch(
        `${API_ROOT_URL}/v3/tests/fetch-tests-details/${testId}?applicant=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    } else {
      return fetch(`${API_ROOT_URL}/v3/tests/fetch-tests-details/${testId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }).then((data) => data.json());
    }
  }

  const GetAllData = async (event) => {
    try {
      const response = await getRecentForms(token);
      if ("status" in response && response.status === true) {
        setHasPermission(true);
        setTestData(response?.data);
      } else if (response?.devMessage === "revokedPermisision") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Permission Has been Revoked",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          navigate.push(dashboardUrl);
        });
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Dynamically generate sectionsArray from API response
  const sectionsArray = testData.flatMap((section) => section.sections).flat();

  // Dynamically generate questionsArray from API response
  const questionsArray = testData
    .flatMap((section) => section.sections.map((s) => s.questions))
    .flat();

  //questions count
  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  const newQuestions = [...questionsArray];
  const currentQuestion = newQuestions[activeQuestion];

  //correct answer
  let correctAnswer = newQuestions[activeQuestion]?.correct_answer;

  useEffect(() => {
    GetAllData();
    getProfile();
  }, []);

  const handleTabSwitch = (event) => {
    if (document.visibilityState === "visible") {
      setTabChangeCount(tabChangeCount + 1);
    } else {
      if (decoded.role === "end_user") {
        if (tabChangeCount > 0) {
          // swal is a function that displays a modal dialog
          swal({
            title: "Test Failed.",
            text: "You have switched tabs more than once in the test.",
            icon: "warning",
            buttons: {
              okay: "Okay",
            },
            closeOnClickOutside: false,
            dangerMode: true,
          }).then(() => {
            swal("Test Failed. Redirecting you to HomePage", {
              icon: "warning",
            }).then(() => {
              setTabChangeCount(0);
              if (decoded.role === "end_user") {
                submitTest();
                onLogout();
              }
            });
          });
        }
      } else {
        // If tabChangeCount is not greater than 2, incrementing it
        setTabChangeCount(tabChangeCount + 1);
      }
    }
  };

  //Test exiting full screen
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  // Add a function to get the current section
  const getCurrentSection = () => {
    return sectionsArray[currentSectionIndex];
  };

  useEffect(() => {
    if (sectionsArray.length > 0 && currentSection === null) {
      setCurrentSection(sectionsArray[0]);
    }
  }, [sectionsArray, currentSection]);

  useEffect(() => { }, [currentSectionIndex, currentSection]);

  const onAnswerSelected = (answer, index, optionId) => {
    setSelectedAnswerIndex(index);
    setSelectedAnswer(optionId);
  };

  const [attemptedResponses, setAttemptedResponses] = useState([]);

  //Function for Save & Next Button
  const onClickNext = async (questionID, currentPage, selectedAnswer) => {
    const lastPage = Math.ceil(questionsArray.length / questionsPerPage) - 1;
    if (currentPage !== lastPage) {
      if (selectedAnswerIndex === null) {
        swal({
          title: "Attention",
          content: {
            element: "div",
            attributes: {
              innerHTML: `<p style="text-align: center;">Please choose an answer before saving. If you want to skip, just click on the Skip button.</p>`,
            },
          },
          icon: "warning",
          dangerMode: true,
        });
        return;
      }
    }
    if (!selectedAnswer) {
      return;
    }
    const payload = {
      test_id: testId,
      question_id: questionID,
      selected_answer: selectedAnswer,
    };
    if (decoded.role == "end_user") {
      try {
        const response = await fetch(
          `${API_ROOT_URL}/v3/tests/save-drafted/result`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify(payload),
          }
        ).then((data) => data.json());

        // checking api response
        if ("status" in response && response.status === true) {
          setHasPermission(true);
          setAttemptedResponses(response?.marked_response);
          setSelectedAnswer("");
        } else {
          if (
            response?.error?.message === "jwt expired" ||
            response?.devMessage === "userDeleted" ||
            response?.message === "Authentication Required"
          ) {
            swal("Session Timeout ! ", "Please Login Again", "error").then(
              () => {
                dispatch(logout(props.history));
              }
            );
          }
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    }

    //colour green for pagination
    setAttemptedQuestions((prev) => {
      const updatedAttemptedQuestions = [...prev];
      updatedAttemptedQuestions[currentPage] = true;
      return updatedAttemptedQuestions;
    });

    //section change code
    const currentSection = getCurrentSection();

    if (
      currentPage <
      currentSection.start_index + currentSection.questions.length - 2
    ) {
      setCurrentPage(currentPage + 1);
    } else if (currentSectionIndex < sectionsArray.length - 1) {
      setCurrentSectionIndex((prev) => {
        const nextIndex = prev + 1;
        setCurrentSection(sectionsArray[nextIndex]);
        setCurrentPage(sectionsArray[nextIndex].start_index - 1);
        return nextIndex;
      });
    } else {
      //setCurrentPage(0);
      setShowResult(true);
    }

    setSelectedAnswerIndex(null);
  };

  const onClickPrevious = () => {
    const currentSection = getCurrentSection();

    if (currentPage < currentSection.start_index) {
      setCurrentSectionIndex((prev) => {
        const nextIndex = prev - 1;
        setCurrentSection(sectionsArray[nextIndex]);
        setCurrentPage(currentPage - 1);
        return nextIndex;
      });
    } else {
      setCurrentPage((prev) => prev - 1);
    }
    setSelectedAnswerIndex(null);
  };

  //Function for skip button
  const onClickSkip = () => {
    if (currentPage < Math.ceil(questionsArray.length / questionsPerPage) - 1) {
      setCurrentPage((prev) => prev + 1);
      setSelectedAnswerIndex(null);
    }

    if (
      currentPage <
      currentSection.start_index + currentSection.questions.length - 2
    ) {
      setCurrentPage(currentPage + 1);
    } else if (currentSectionIndex < sectionsArray.length - 1) {
      setCurrentSectionIndex((prev) => {
        const nextIndex = prev + 1;
        setCurrentSection(sectionsArray[nextIndex]);
        setCurrentPage(sectionsArray[nextIndex].start_index - 1);
        return nextIndex;
      });
    }

    setUnAttemptedQuestions((prev) => {
      const updatedAttemptedQuestions = [...prev];
      updatedAttemptedQuestions[currentPage] = true;
      return updatedAttemptedQuestions;
    });
  };

  const generatePayload = () => {
    let payloadToSend;

    if (totalTime === 0) {
      // Payload for if time is over
      payloadToSend = {
        test_id: testId,
        remark: "timerOver",
      };
    } else if (!document.fullscreenElement) {
      // Payload for if user switched the tab
      payloadToSend = {
        test_id: testId,
        remark: "tabChanged",
      };
    } else if (attemptedResponses.length === 0) {
      // Payload for if not a single question is marked
      payloadToSend = {
        test_id: testId,
        remark: "notASingleMarked",
      };
    } else {
      payloadToSend = {
        test_id: testId,
        remark: "default",
      };
    }

    return payloadToSend;
  };

  //function to submit last question
  const submitTest = async (questionId, currentPage, selectedAnswer) => {
    try {
      await onClickNext(questionId, currentPage, selectedAnswer);
      await saveTest();
    } catch (error) {
      console.error("Error occurred while submitting test:", error);
    }
  };

  //function to submit the test when user tries to escape the full screen
  const EscapeAndSubmit = async () => {
    try {
      await saveTest();
      await onLogout();
    } catch (error) {
      console.error("Error occurred while submitting test:", error);
    }
  };

  //Function For submit Full Test
  const saveTest = async () => {
    handleClickOpen();
    setShowResult(true);

    if (decoded.role == "end_user") {
      const payloadToSend = generatePayload();
      const response = await fetch(`${API_ROOT_URL}/v3/tests/submit-test`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(payloadToSend),
      }).then((data) => data.json());

      // checking api response
      if ("status" in response && response.status === true) {
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.devMessage === "userDeleted" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    } else if (decoded.role == "super_admin") {
      navigate.push("/admin-test-dashboard");
    } else {
      navigate.push("/forms");
    }
  };

  //Function and states for quiz timer
  let [timeValue] = testData.flatMap((i) => i.timer);

  const convertTimeStringToSeconds = (timeString) => {
    if (typeof timeString !== "string") {
      return null;
    }

    const [paddedMinutes, secondsStr] = timeString.split(":");
    const minutes = parseInt(paddedMinutes, 10);
    const seconds = parseInt(secondsStr, 10);
    return minutes * 60 + seconds;
  };

  const totalTimeString = timeValue;
  const totalSeconds = convertTimeStringToSeconds(totalTimeString);

  //timer function
  const formatTime = (seconds) => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const timerQues = () => {
    setTotalTime(totalSeconds);
    localStorage.setItem("testTimer", JSON.stringify(totalSeconds));
  };

  //Function for running timer -1
  useEffect(() => {
    timerQues();
    const timers = setInterval(() => {
      // setTotalTime((prevTime) => prevTime - 1);
      setTotalTime((prevTime) => {
        if (prevTime <= 0) {
          return 0;
        }
        localStorage.setItem("testTimer", prevTime - 1);
        return prevTime - 1;
      });
    }, 1000);

    document.addEventListener("visibilitychange", handleTabSwitch);

    return () => {
      clearInterval(timers);
      document.removeEventListener("visibilitychange", handleTabSwitch);
    };
  }, [totalSeconds, showResult, tabChangeCount]);

  useEffect(() => {
    if (
      (decoded.role === "instructor" && hasPermission) ||
      (decoded.role === "company" && hasPermission) ||
      (decoded.role === "department" && hasPermission)
    ) {
      if (totalTime === 0) {
        Swal.fire({
          title: "Time is over!",
          icon: "warning",
          confirmButtonText: "Go to homepage",
        }).then(() => {
          navigate.push("/forms");
        });
      }
    } else if (decoded.role === "super_admin") {
      if (totalTime === 0) {
        Swal.fire({
          title: "Time is over!",
          icon: "warning",
          confirmButtonText: "Go to homepage",
        }).then(() => {
          navigate.push("/admin-test-dashboard");
        });
      }
    } else if (decoded.role === "end_user") {
      if (totalTime === 0) {
        Swal.fire({
          title: "Time is over!",
          icon: "warning",
          confirmButtonText: "OK",
        }).then(() => {
          saveTest();
        });
      }
    }
  }, [totalTime, decoded.role, navigate]);

  //if user tries to exit full screen
  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = (event) => {
      if (document.fullscreenElement) {
        event.preventDefault();
        document.addEventListener("keydown", handleEscapeKey);
      } else {
        EscapeAndSubmit();
      }
    };

    const handleKeyDown = (event) => {
      // Check if the F5 key is pressed (keyCode 116) and the page is in full-screen mode
      if (event.keyCode === 116 && document.fullscreenElement) {
        event.preventDefault();
      }

      //prevents hard reload CRTL + SHift + R
      if (
        event.ctrlKey &&
        event.shiftKey &&
        event.keyCode === 82 &&
        document.fullscreenElement
      ) {
        event.preventDefault();
      }

      //windows key presses
      if (event.key === "Meta" && event.location === 1) {
        EscapeAndSubmit();
      }

      // Check if Alt + tab is pressed and prevent default behavior
      if (event.altKey && event.keyCode === 9 && document.fullscreenElement) {
        EscapeAndSubmit();
      }
      // Check if crtl + tab is pressed and prevent default behavior
      if (event.ctrlKey && event.keyCode === 9 && document.fullscreenElement) {
        event.preventDefault();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //pagination green for attempted ones
  const [attemptedQuestions, setAttemptedQuestions] = useState(
    new Array(questionsArray.length).fill(false)
  );

  //pagination yellow for unattempted/skipped ones
  const [unattemptedQuestions, setUnAttemptedQuestions] = useState(
    new Array(questionsArray.length).fill(false)
  );

  const getSignedUrl = (fileName) => {
    return s3.getSignedUrl("getObject", {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName,
    });
  };

  const renderQuestions = () => (
    <>
      <div className="test-container mt-2 mb-2">
        <div className="row">
          <div className="col-xl-8">
            <span className="active-question-no mb-0">
              {addLeadingZero(currentPage * questionsPerPage + 1)}
            </span>
            <span className="total-question mb-0" style={{ color: "darkgray" }}>
              /{addLeadingZero(questionsArray.length)}
            </span>
          </div>
          <div className="d-flex col-xl-4 justify-content-end">
            <h4>
              <BiSolidStopwatch size={20} /> Time Left :{" "}
              <span style={{ color: "red" }}>{formatTime(totalTime)} </span>
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="basic-form mt-0">
            {currentQuestions.map((question, index) => (
              <>
                <div className="questiontext">
                  <p>{question.question_text}</p>
                </div>
                {question.question_image ? (
                  <>
                    <div key={index}>
                      <img
                        src={getSignedUrl(`${QUESTIONS_ASSETS_URL}/${question.question_image}`)}
                        width={200}
                        height={100}
                      // alt="no image"
                      />

                      <ul style={{ marginBottom: "56px", marginTop: "15px" }}>
                        {question?.options?.map((option, index) => (
                          <li
                            onClick={() =>
                              onAnswerSelected(option, index, option._id)
                            }
                            key={option._id}
                            className={`${attemptedResponses.some(
                              (x) =>
                                x.question_id === question._id &&
                                x.selected_answer === option._id
                            )
                              ? "marked-answer"
                              : ""
                              } ${selectedAnswerIndex === index
                                ? "selected-answer"
                                : ""
                              }`}
                          >
                            {option.text}
                          </li>
                        ))}
                      </ul>

                      <Row className="mt-3">
                        <Col lg="6" style={{ float: "left" }}>
                          <button
                            className="previousButton"
                            onClick={onClickPrevious}
                            disabled={currentPage == 0}
                          >
                            Previous
                          </button>

                          <button
                            className="skipButton mx-3"
                            onClick={onClickSkip}
                          >
                            Skip
                          </button>
                        </Col>
                        <Col lg="6">
                          {currentPage ===
                            Math.ceil(questionsArray.length / questionsPerPage) -
                            1 ? (
                            <>
                              {/* <button
                          type="submit"
                          onClick={saveTest}
                          className="nextButton mx-1"
                        >
                          Submit Test
                        </button> */}
                              <button
                                type="submit"
                                onClick={() =>
                                  submitTest(
                                    question._id,
                                    currentPage,
                                    selectedAnswer
                                  )
                                }
                                className="nextButton mx-1"
                              >
                                Save & Finish
                              </button>
                            </>
                          ) : attemptedResponses.some(
                            (x) => x.question_id === question._id
                          ) ? (
                            <>
                              <div>
                                <button
                                  disabled
                                  className="disabledButton mx-1"
                                >
                                  Save & Next
                                </button>
                              </div>
                            </>
                          ) : (
                            <button
                              type="submit"
                              onClick={(e) =>
                                onClickNext(
                                  question._id,
                                  currentPage,
                                  selectedAnswer
                                )
                              }
                              className="nextButton mx-1"
                            >
                              Save & Next
                            </button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <>
                    <div key={index} style={{ height: "45vh" }}>
                      {/* <img
                        src={`${QUESTIONS_ASSETS_URL}/${question.question_image}`}
                        width={200}
                        height={100}
                        // alt="no image"
                      /> */}

                      <ul style={{ marginBottom: "45px" }}>
                        {question?.options?.map((option, index) => (
                          <li
                            style={{ marginTop: "56px" }}
                            onClick={() =>
                              onAnswerSelected(option, index, option._id)
                            }
                            key={option._id}
                            className={`${attemptedResponses.some(
                              (x) =>
                                x.question_id === question._id &&
                                x.selected_answer === option._id
                            )
                              ? "marked-answer"
                              : ""
                              } ${selectedAnswerIndex === index
                                ? "selected-answer"
                                : ""
                              }`}
                          >
                            {option.text}
                          </li>
                        ))}
                      </ul>

                      <Row className="mt-3">
                        <Col lg="4" style={{ float: "left" }}>
                          <button
                            className="previousButton"
                            onClick={onClickPrevious}
                            disabled={currentPage == 0}
                          >
                            Previous
                          </button>

                          <button
                            className="skipButton mx-3"
                            onClick={onClickSkip}
                          >
                            Skip
                          </button>
                        </Col>
                        <Col lg="8">
                          {currentPage ===
                            Math.ceil(questionsArray.length / questionsPerPage) -
                            1 ? (
                            <>
                              {/* <button
                          type="submit"
                          onClick={saveTest}
                          className="nextButton mx-1"
                        >
                          Submit Test
                        </button> */}
                              <button
                                type="submit"
                                onClick={() =>
                                  submitTest(
                                    question._id,
                                    currentPage,
                                    selectedAnswer
                                  )
                                }
                                className="nextButton mx-1"
                              >
                                Save & Finish
                              </button>
                            </>
                          ) : attemptedResponses.some(
                            (x) => x.question_id === question._id
                          ) ? (
                            <>
                              <div>
                                <button
                                  disabled
                                  className="disabledButton mx-1"
                                >
                                  Save & Next
                                </button>
                              </div>
                            </>
                          ) : (
                            <button
                              type="submit"
                              onClick={(e) =>
                                onClickNext(
                                  question._id,
                                  currentPage,
                                  selectedAnswer
                                )
                              }
                              className="nextButton mx-1"
                            >
                              Save & Next
                            </button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
                <div key={index}></div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  const indexOfLastQuestion = (currentPage + 1) * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questionsArray.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const CustomPagination = ({ pageCount, currentPage, onPageChange }) => {
    const renderPageNumbers = () => {
      const pageNumbers = [];

      for (let i = 0; i < pageCount; i++) {
        const isStartOfNewRow = i % 5 === 0;

        pageNumbers.push(
          <li
            key={i}
            className={`page-item ${currentPage === i
              ? "active"
              : attemptedQuestions[i]
                ? "attempted"
                : unattemptedQuestions[i]
                  ? "unattempted"
                  : ""
              }`}
          >
            <button
              className={`page-link ${currentPage === i
                ? "active-link"
                : attemptedQuestions[i]
                  ? "attempted-link"
                  : unattemptedQuestions[i]
                    ? "unattempted-link"
                    : " "
                }`}
              onClick={() => onPageChange(i)}
            >
              {i + 1}
            </button>
          </li>
        );

        if (isStartOfNewRow && i !== 0) {
          pageNumbers.push(<br key={`br-${i}`} className="pagination-br" />);
        }
      }

      return pageNumbers;
    };

    return (
      <nav>
        <ul className="pagination pagination-container">
          {renderPageNumbers()}
        </ul>
      </nav>
    );
  };

  //pagination navigation logic
  const handlePageChange = (selectedPage) => {
    setSelectedAnswerIndex(null);
    //section change code
    const currentSection = getCurrentSection();
    //for moving forward
    if (
      selectedPage <
      currentSection.start_index + currentSection.questions.length - 1
    ) {
      setCurrentPage(selectedPage);
    } else if (currentSectionIndex < sectionsArray.length - 1) {
      setCurrentSectionIndex((prev) => {
        let nextIndex = null;
        let count = 0;
        let rem = selectedPage + 1;
        for (let i = 0; i < sectionsArray.length; i++) {
          let currentQLength = sectionsArray[i].questions.length;
          rem = rem - currentQLength;
          if (rem > 0) {
            count = count + 1;
          } else {
            break;
          }
        }
        nextIndex = count;
        setCurrentSection(sectionsArray[nextIndex]);
        setCurrentPage(selectedPage);
        return nextIndex;
      });
    }

    //previous
    if (selectedPage < currentSection.start_index) {
      setCurrentSectionIndex((prev) => {
        let nextIndex = null;
        let count = 0;
        let rem = selectedPage + 1;
        for (let i = 0; i < sectionsArray.length; i++) {
          let currentQLength = sectionsArray[i].questions.length;
          rem = rem - currentQLength;
          if (rem > 0) {
            count = count + 1;
          } else {
            break;
          }
        }
        nextIndex = count;
        setCurrentSection(sectionsArray[nextIndex]);
        setCurrentPage(selectedPage);
        return nextIndex;
      });
    } else {
      setCurrentPage(selectedPage);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setTestId(testId);
    setOpen(true);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    //onClickNext()
  }, [attemptedResponses]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const saveTestandLogout = () => {
    return (
      <>
        <div>
          {showResult ? (
            <Dialog onClose={handleClose} open={open}>
              <div style={{ width: "600px", height: "400px" }}>
                <div className="d-flex">
                  <DialogTitle id="customized-dialog-title">
                    <span style={{ fontWeight: "600", fontFamily: "Poppins" }}>
                      SUCCESS
                    </span>
                  </DialogTitle>
                  {/* <div style={{ marginLeft: "400px", marginTop: "5px" }}>
                <IconButton 
                onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div> */}
                </div>

                <DialogContent dividers>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FcApproval size={100} />
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      fontFamily: "poppins",
                      fontWeight: "700",
                      color: "#3a3630",
                    }}
                  >
                    Test Submitted !
                  </div>

                  <p
                    className="mt-3"
                    style={{ textAlign: "center", color: "gray" }}
                  >
                    All your responses have been successfully recorded,{" "}
                    <br></br>you may now leave this page.
                  </p>
                </DialogContent>
                <DialogActions></DialogActions>
                <div style={{ marginLeft: "500px", marginTop: "10px" }}>
                  <Button variant="outlined" onClick={onLogout} color="primary">
                    Okay
                  </Button>
                </div>
              </div>
            </Dialog>
          ) : null}
        </div>
      </>
    );
  };

  //function to logout user when its test is completed
  const onLogout = () => {
    localStorage.removeItem("userDetails");
    dispatch(logout(props.history));
    // window.location.reload();
  };

  return (
    <>
      <div style={{ overflowY: "scroll" }}>
        {saveTestandLogout()}
        <div className="question-card">
          {decoded.role === "end_user" ? (
            <Alert
              severity="warning"
              style={{
                width: "45%",
                margin: "auto",
                backgroundColor: "bisque !important",
              }}
            >
              If you will try to EXIT the Full-screen mode, then you will Fail
              the Test
            </Alert>
          ) : null}
          <div className="borderCard">
            <div
              className="section-card d-flex fw-bolder"
              style={{ justifyContent: "space-between" }}
            >
              {sectionsArray?.map((section, index) => (
                <div
                  key={section._id}
                  className={`section ${currentSection?._id === section?._id ? "active" : "unactive"
                    }`}
                  onClick={() => {
                    setCurrentSection(section);
                    setCurrentSectionIndex(index);
                    setCurrentPage(section.start_index - 1);
                  }}
                >
                  {section.section_name}
                  <span
                    className="mx-1"
                    style={{
                      backgroundColor: "white",
                      color: "#3a3630",
                      borderRadius: "66px",
                      padding: "3px 5px",
                      paddingLeft: "5px",
                    }}
                  >
                    {section.start_index} -{" "}
                    {section.start_index + section.questions.length - 1}
                    {"   "}
                  </span>
                </div>
              ))}
            </div>
            <div className="inner-card">{renderQuestions()}</div>
          </div>
        </div>

        <div className="total-card">
          <div className="user-details-card mx-3">
            <Row>
              <Col lg="3">
                <div className="d-inline">
                  <Avatar
                    className="mx-3"
                    src="/broken-image.jpg"
                    style={{ height: "80px", width: "80px", marginTop: "20px" }}
                  />
                </div>
              </Col>
              {decoded.role === "end_user" ? (
                <>
                  <Col lg="9">
                    <div className="usertext mt-3">
                      <p className="mb-1">
                        {userProfileData?.name?.charAt(0).toUpperCase() +
                          userProfileData?.name?.slice(1)}
                      </p>
                      <p className="mb-1">{userProfileData?.email_id}</p>
                      <p>{userProfileData?.test_name}</p>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col lg="9">
                    <div className="usertext mt-3">
                      <p className="mb-1">Student Name</p>
                      <p className="mb-1">student@bjshomedelivery.com</p>
                      <p>Test Name</p>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </div>

          <div className="total-questions">
            <div
              className="card mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "450px",
                marginBottom: "200px",
              }}
            >
              <div class="container-test">
                <AnsText>QUESTION STATUS</AnsText>
                <div class="row-test mt-3">
                  <div class="col-test">
                    <div className="square mx-3"></div>
                    <Ans>Answered </Ans>
                  </div>
                  <div class="col-test">
                    <span className="dot mx-3"></span>
                    <Ans>Skipped Questions</Ans>
                  </div>
                </div>
                <div class="row-test mt-3">
                  <div class="col-test">
                    <div className="quesactive mx-3"></div>
                    <Ans>Active Question</Ans>
                  </div>
                  <div class="col-test mb-3">
                    <span className="notvisited mx-3"></span>
                    <Ans>Not Marked</Ans>
                  </div>
                </div>
              </div>
              {/* <hr style={{ margin: "10px 0" }} /> */}

              <CustomPagination
                pageCount={Math.ceil(questionsArray.length / questionsPerPage)}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testform;
