import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_ROOT_URL } from "../../../constants";

// api call
async function getInductions(token, hasDropDown, parentCompany, deptID) {
    return fetch(`${API_ROOT_URL}/induction/index/work?hasDropdown=${hasDropDown}&filterByCompany=${parentCompany}&filterByDepartment=${deptID}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        },
        // body : JSON.stringify({ "parentCompany" : company})
    }).then((data) => data.json());
}


const InductionsByCompany = (props) => {
    const token = useSelector((state) => state.auth.auth.token);
    const [loading, setLoading] = useState(true);
    const [option, setOption] = useState();
    // const [parentCompany, setParentCompany] = useState(props.parentCompany);
    // const [defaultSelectedVal, setDefaultSelectedVal] = useState(props.prevSelected);

    const callApi = async (token, company) => {
        const response = await getInductions(token, props.hasDropdown, props.parentCompany, props.deptID);
        if ("status" in response && response.status == true) {
            const rows = response.data.map((row, index) => (
                <option key={row._id} value={row._id}>{row.title.slice(0, 20)}</option>
            ));
            setOption(rows);
            setLoading(false);
        }
    }

    useEffect(() => {
        //if(loading){
        callApi(token, props.parentCompany);
        //}
    }, [props.parentCompany]);
    const pageContent = (loading) ? <option>Loading</option> : <>{option}</>;
    return (
        <>
            {pageContent}
        </>
    )
};

export default InductionsByCompany;