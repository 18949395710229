import React from "react";
import { Link } from "react-router-dom";
// image

import { useForm } from "react-hook-form";
import { Form } from 'semantic-ui-react';
import swal from "sweetalert";
import Swal from "sweetalert2";
import BJSLogo from "../../images/BJSLogo.png";
import DesktopTruck from "../../images/DesktopBackgrounds_Haulage.jpg";
import { API_ROOT_URL } from "../constants";

const ForgotPassword = ({ history }) => {

  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const formStates = watch();

    const onSubmit = async () => {
      const { email } = formStates;
      const response = await resetPassword({
        email,
      });
  

    if ("status" in response && response.status === true) {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text:'We have e-mailed your Reset Password link.',
        footer:`Incase you haven't received it please check your Spam or Junk folder.`,
        confirmButtonColor: "#3a3630",

      }).then((value) => {
        //window.location.href = "/login";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  async function resetPassword(email) {
    return fetch(`${API_ROOT_URL}/users/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    }).then((data) => data.json());
  }

  return (
    <div
      className="authincation h-100 p-meddle"
      style={{
        backgroundImage: "url(" + DesktopTruck + ")",
        backgroundSize: "cover",
      }}
    >
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-contain-center">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <img width="600" src={BJSLogo} alt="BJS" />
                      </div>
                      <span
                        className="fs-24 font-w800 text-center d-flex justify-content-center"
                        style={{ color: "#3A3630" }}
                      >
                        Forgot Password
                      </span>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group  mb-4">
                          <label className="">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            defaultValue=""
                            {...register("email",
                              {
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              })}
                          />
                          {errors.email && <p style={{ color: "red" }}>Please check the Email</p>}

                        </div> 
                      
                        <div className="text-center  mb-4">
                          <button type="submit" className="login">
                            Submit
                          </button>
                        </div>
                        <div className="new-account mt-3">
                          <p className="">
                            Go back to your{" "}
                            <Link
                            className="loginsection"
                            to="/login"
                            >
                              Login Section
                            </Link>
                          </p>
                        </div>
                        </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;