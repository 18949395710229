import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";

const UserAttemptedInductioList = ({
  isModalOpen,
  hidePopUp,
  userPopupData,
}) => {
  return (
    <Modal
      className="modal fade"
      show={isModalOpen}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    
      <div className="modal-content" style={{borderRadius:"4px", border:"1px solid #3A3630"}}>
        <div className="modal-header" style={{ backgroundColor: "#3A3630" }}>
          <h5 className="modal-title text-white fs-20"> USER RESULT </h5>
          <RxCross1 size={25} color="white" onClick={hidePopUp}/>
        </div>
        <div className="modal-body scrollable" style={{ maxHeight: "800px", overflowY: "auto" }}>
          <h4>
            User :{" "}
            {userPopupData.profile.first_name +
              " " +
              userPopupData.profile.last_name}{" "}
          </h4>
          <h4>Induction : {userPopupData.inductions.title} </h4>
          <h5>Attempts : {userPopupData.total} </h5>
          <br></br>

          <table
            className="table display mb-4 dataTablesCard order-table card-table text-black application "
            id="application-tbl1_next"
          >
            <thead>
              <tr>
                <th style={{textAlign:"center"}}>Sr. No.</th>
                <th style={{textAlign:"center"}}>Corrects <AiOutlineCheck size={20} style={{backgroundColor:"#57DB7A",color:"white",borderRadius:"15px",padding:"2px"}}/></th>
                <th style={{textAlign:"center"}}>Wrong <AiOutlineClose size={20} style={{backgroundColor:"#E95252",color:"white",borderRadius:"15px",padding:"2px"}}/></th>
                <th style={{textAlign:"center"}}>Test Status </th>
                <th style={{textAlign:"center"}}>Remark </th>
                <th style={{textAlign:"center"}}>Test Dates</th>
              </tr>
            </thead>
            <tbody>
              {userPopupData.result.map((row, index) => (
                <tr key={index}>
                  <td style={{textAlign:"center"}}>{index + 1}</td>
                  <td style={{textAlign:"center"}}>{row.correctAnswers}</td>
                  <td style={{textAlign:"center"}}>{row.wrongAnswers}</td>
                  <td style={{textAlign:"center"}}>
                    <Link
                      className={`custombadge ${
                        row.testStatus === "Fail"
                          ? "custombadge-red"
                          : "custombadge-green"
                      }`}
                      onClick={(e) => e.preventDefault()}
                    >
                      {row.testStatus}
                    </Link>
                  </td>
                  <td style={{textAlign:"center"}}>{row.remark}</td>
                  <td style={{textAlign:"center"}}>
                    {new Date(row.createdAt).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
     
    </Modal>
  );
};

export default UserAttemptedInductioList;
