import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { API_ROOT_URL } from "../jsx/constants";

export const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  DEPARTMENT: "department",
  INSTRUCTOR: "instructor",
  USER: "user",
  END_USER: "end_user"
};

export function signUp(firstName, lastName, email, password, role) {
  //axios call
  const postData = {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    role,
    returnSecureToken: true,
  };
  return axios.post(`${API_ROOT_URL}/users`, postData);
}

export function login(role, email, password) {
  const postData = {
    role,
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(`${API_ROOT_URL}/users/login`, postData);
}

export function formatError(errorResponse) {
  switch (errorResponse.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Incorrect Username or Password", "error", {
        button: "Try Again!",
      });
      break;
    case "USER_DISABLED":
      if (errorResponse.userRole === USER_ROLES.END_USER && !(errorResponse.accountVerified)) {
        Swal.fire({
          title: `Oops`,
          html: `<h6 style="color: gray";>Please verify your account first. Check your email and click on the <span style="color: #f27474;">Verify Account</span>.</h6>`,
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Mail for Activating new user">Contact admin</a>',
        });
      }
      else if (errorResponse.userRole === USER_ROLES.END_USER) {
        Swal.fire({
          title: `Oops`,
          html: `<h6 style="color: gray";>You have already completed the test; resubmissions are not permitted.</h6>`,
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Mail for Activating new user">Contact admin</a>',
        });
      }
      else {
        Swal.fire({
          title: `Oops`,
          html: `<h6 style="color: gray";>Your account has been created but isn't <span style="color: #f27474;">Active</span> yet,
                 you can either wait for activation or contact administration for assistance.</h6>`,
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Mail for Activating new user">Contact admin</a>',
        });
      }
      break;
    case "ACCOUNT_DELETED":
      Swal.fire({
        title: `Oops`,
        html: `<h5 style="color: gray";> Your account has been Deleted.</h5>`,
        icon: "error",
        confirmButtonColor: "#3a3630",
        confirmButtonText: "OK",
        footer:
          '<a href="mailto:info@bjsinduction.com?subject=Mail for Activating new user">Contact admin</a>',
      });
      break;
    default:
      swal("Oops", "Network Error!. Please try again later.", "error", {
        button: "Try Again!",
      });
      break;
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000,
  // );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history, pathName) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }
  const tokenDetails = JSON.parse(tokenDetailsString);
  const expiresDate = new Date(tokenDetails.expiresIn);
  const todaysDate = new Date();
  if (todaysDate > expiresDate) {
    // Token has expired, log out the user
    dispatch(logout(history));
    return;
  }
  if (pathName === "/" || pathName === "/login" || pathName.includes("login")) {
    switch (tokenDetails.role) {
      case "super_admin":
        history.push("/dashboard");
        break;
      case "company":
        history.push("/company-dashboard");
        break;
      case "department":
        history.push("/department-dashboard");
        break;
      case "instructor":
        history.push("/instructor-dashboard");
        break;
      case "end_user":
        history.push("/end-user-forms-dashboard");
        break;
      case "user":
        history.push("/user-dashboard");
        break;
      default:
        break;
    }
  }

  // Token is still valid, confirm the login
  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expiresDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history);
}
