import React from "react";
export default function ActionDropDown(props) {
  const handleCallback = () => {
    props.trackOnclick(true, props.profileData._id);
  };

  const trackDeleteClick = () => {
    return props.deleteSlide(props.profileData._id);
  };

  return (
    <>
      <div>
        <div className="">
          {props.isFirstSlide ? (
            <button
              onClick={handleCallback}
              href="#"
              className="editIcon"
              style={{ display: 'inline' }}
            >
              <i className="fas fa-pencil-alt"></i>
            </button>
          ) : (
            <>
              <button
                onClick={handleCallback}
                href="#"
                className="editIcon"
                style={{ display: 'inline' }}
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
              <button
                onClick={trackDeleteClick}
                href="#"
                className="deleteIcon"
                style={{ display: 'inline' }}
              >
                <i className="fa fa-trash"></i>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}