import React, { useRef, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Row, Col } from "react-bootstrap";
import swal from "sweetalert";
//import { API_ROOT_URL } from "../../constants";
import { useSelector } from "react-redux";
import Select from "react-select";
//import TopicDropdown from "../Topics/TopicDropdown";
import { FcUnlock } from "react-icons/fc";
import ReactSwitch from "react-switch";
import "primereact/resources/themes/viva-light/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core
import endmessageanimation from "../../jsx/components/Animations/animation_lloy7bt3.json";
import Lottie from "lottie-react";
//import Loading from "../Animations/99109-loading.json";
import Prototype from "../../jsx/components/SkeletonLoader/Prototype";
import { Calendar } from "primereact/calendar";
import { useDispatch } from "react-redux";
//import { logout } from "../../../store/actions/AuthActions";
import { BiSortAlt2 } from "react-icons/bi";
//imports date range picker
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { CiFilter, CiLock } from "react-icons/ci";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
import { logout } from "../../store/actions/AuthActions";
import Query from "../../jsx/components/Animations/138566-frequently-asked-question-faq.json";
import { API_ROOT_URL } from "../../jsx/constants";
import { PiCalendarCheckFill } from "react-icons/pi";
import { IoPersonSharp } from "react-icons/io5";
import "../forms.css";
import Switch from "@mui/material/Switch";
import Badge from "@mui/material/Badge";

const TestAccessControl = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [InductionData, setInductionData] = useState([]);
  const [isPrivateState, setIsPrivateState] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  //date filter states
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([]);
  const [test, setTest] = useState([]);
  const [checkedState, setCheckedState] = useState(false);
  //requests
  const [requests, setRequests] = useState([]);
  const [proMembers, setProMembers] = useState([]);

  //sort function states
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortKey, setSortKey] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const tokenDetailsString = localStorage.getItem("userDetails");
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      formattedDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      role: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  //API Integration for Requests Page
  async function getAccessRequests(token) {
    return fetch(`${API_ROOT_URL}/v3/tests/requested-users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // on List companies page first render
  const handlepageLoad = async (event) => {
    const response = await getAccessRequests(token);
    if ("status" in response && response.status == true) {
      setRequests(response.data);
      setLoading(false);
    } else {
      if (
        response?.error?.message === "jwt expired" ||
        response?.message === "Authentication Required"
      ) {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", response.message, "error");
      }
    }
  };

  //API Integration for Requests Page
  async function getProMembers(token) {
    return fetch(`${API_ROOT_URL}/v3/tests/pro-members/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  // on List companies page first render
  const handlepageLoadPro = async (event) => {
    const response = await getProMembers(token);
    if ("status" in response && response.status == true) {
      setProMembers(response.data);
      setLoading(false);
    } else {
      if (
        response?.error?.message === "jwt expired" ||
        response?.message === "Authentication Required"
      ) {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", response.message, "error");
      }
    }
  };

  useEffect(() => {
    handlepageLoad();
    handlepageLoadPro();
  }, [loading]);

  //post api requesting access for test module
  const HandlePermissionRequest = async (userId, testStatus) => {
    try {
      const response = await fetch(
        `${API_ROOT_URL}/v3/tests/access/test-module`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify({
            userId: userId,
            testStatus: testStatus,
          }),
        }
      ).then((data) => data.json());
      if ("status" in response && response.status === true) {
        // First API call successful
        Swal.fire({
          text: `Request Accepted`,
          icon: "success",
        }).then(() => {});
        setIsModalOpen(false);
        handlepageLoadPro();
        handlepageLoad();
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3A3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          Swal.fire({
            text: `Request Rejected`,
            icon: "warning",
          }).then(() => {});
          setIsModalOpen(false);
          handlepageLoadPro();
          handlepageLoad();
        }
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error in HandleAcceptRequest:", error);
    }
  };

  const OnModalOpen = () => {
    setIsModalOpen(true);
  };

  const renderHeader = () => {
    return (
      <>
        <div className="card-header flex-wrap p-0" style={{ border: "none" }}>
          <h3 className="mt-0 text-white" style={{ letterSpacing: "2px" }}>
            {" "}
            <FcUnlock style={{ marginBottom: "5px" }} /> REQUESTS
          </h3>
          <Row>
            <Col lg="7" className="mx-3 mt-2">
              <Badge color="secondary" badgeContent={requests.length}>
                <button
                  className="requestButton mb-3"
                  type="submit"
                  onClick={OnModalOpen}
                  style={{ letterSpacing: "1px" }}
                >
                  Requests
                </button>
              </Badge>
            </Col>
            <Col lg="4" className="mb-3 mt-2">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const header = renderHeader();

  //date display template
  const createdDateTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(
            rowData.mass_test_details.accessForTest.time
          ).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}
        </div>
      </>
    );
  };

  //date display template
  const PermissionTemplate = (rowData) => {
    return (
      <div>
        <Switch
          value={rowData._id}
          checked={rowData.mass_test_details.accessForTest}
          onChange={() =>
            handleCheck(rowData._id, !rowData.mass_test_details.accessForTest)
          }
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
    );
  };

  //post api requesting access for test module
  const handleCheck = async (value, checked) => {
    setLoading(true);
    //setCheckedState(checked);
    if (checked) {
      setCheckedState((prevCheckedItems) => [...prevCheckedItems, checked]);
    }
    try {
      const response = await fetch(
        `${API_ROOT_URL}/v3/tests/revoke/access/test-module`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify({
            userId: value,
            testStatus: checked,
          }),
        }
      ).then((data) => data.json());
      if ("status" in response && response.status === true) {
        // First API call successful
        Swal.fire({
          text: `Permission Revoked`,
          icon: "success",
        }).then(() => {});
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3A3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error in HandleAcceptRequest:", error);
    } finally {
      setCheckedState(checked);
      setLoading(false);
    }
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    //setModifiedData(proMembers);
  };

  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
  };

  useEffect(() => {
    const modifiedAccess = proMembers.map((data) => {
      const dateWithoutTime = new Date(
        data?.mass_test_details?.accessForTest?.time
      );
      dateWithoutTime.setHours(0, 0, 0, 0);

      return { ...data, formattedDate: dateWithoutTime };
    });
    setModifiedData(modifiedAccess);
  }, [proMembers]);

  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(proMembers);
  };

  const handleSelectFilter = (ranges) => {
    let filtered = proMembers.filter((data) => {
      let joinDate = new Date(data?.mass_test_details?.accessForTest?.time);
      joinDate.setHours(0, 0, 0, 0);
      const isSameDate =
        joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }
      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });

    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);
  };

  const dateRangePickerTemp = () => {
    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: "selection" }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelectFilter}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>
    );
  };

  //Function for roles or name fields
  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  //with date sort function
  const sortDateTable = (key) => {
    const sortedData = [...modifiedData];
  
    sortedData.sort((a, b) => {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
  
      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
  
    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  
  useEffect(() => {
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        //dispatch(logout(props.history));
      });
    } else {
      //   handlepageLoad();
      //   handlepageLoaded(deptID);
      //   handlepageLoadEvent();
      //getInductionData(token);
    }
  }, [loading, tokenDetailsString]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-3">
              <div className="table-responsive">
                <DataTable
                  value={modifiedData.map((rowData, index) => ({
                    ...rowData,
                    serialNumber: index + 1,
                    isPrivateState: (
                      <PermissionTemplate
                        rowData={rowData}
                        checked={checkedState}
                        handleCheck={() => handleCheck(rowData.id, false)}
                      />
                    ),
                  }))}
                  header={header}
                  dataKey="id"
                  emptyMessage={
                    <div className="queryContainer">
                      {" "}
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                          <div className="form-input-content text-center">
                            <h1 className="error-text font-weight-bold">
                              <Lottie
                                style={{ height: 150 }}
                                animationData={endmessageanimation}
                              />
                            </h1>
                            <h3
                              className="fs-28 mt-3"
                              style={{ color: "#3A3630", fontWeight: "900" }}
                            >
                              {" "}
                            </h3>
                            <h4 style={{ fontStyle: "italic" }}>
                              No Records Found !
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  filters={filters}
                  globalFilterFields={["email", "role"]}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  size="large"
                  stripedRows
                  showGridlines
                  tableStyle={{ minWidth: "50rem" }}
                  filterDisplay="menu"
                >
                  <Column
                    header="Sr.No"
                    headerStyle={{ width: "5%" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>
                  <Column
                    field="email"
                    style={{ width: "40%" }}
                    header={
                      <>
                        <div>
                          <button
                            className="buttonJoinDate"
                            onClick={() => sortTable("email")}
                          >
                            EMAIL{" "}
                            <span>
                              <BiSortAlt2 size={22} />
                            </span>
                          </button>
                        </div>
                      </>
                    }
                    filterField="email"
                    filter
                    filterPlaceholder="Search by email"
                  ></Column>
                  <Column
                    field="role"
                    header={
                      <>
                        <div>
                          <button
                            className="buttonJoinDate"
                            onClick={() => sortTable("role")}
                          >
                            ROLE{" "}
                            <span>
                              <BiSortAlt2 size={22} />
                            </span>
                          </button>
                        </div>
                      </>
                    }
                    filterField="role"
                    style={{ width: "25%" }}
                    filter
                    filterPlaceholder="Search by Role"
                  ></Column>
                  <Column
                    field="updatedAt"
                    style={{ width: "10%" }}
                    body={createdDateTemplate}
                    header={
                      <>
                        <div>
                          <Row className="textFilter">
                            <Col xs="10">
                              <button
                                className="buttonJoinDate"
                                onClick={() => sortDateTable("formattedDate")}
                              >
                                ACTIVATION{" "}
                                <span>
                                  <BiSortAlt2 size={22} />
                                </span>
                              </button>
                            </Col>
                            <Col xs="2">
                              <CiFilter
                                className=""
                                size={22}
                                onClick={openDateRangePicker}
                              />
                            </Col>
                          </Row>
                        </div>

                        {showDateRangePicker && (
                          <Modal
                            className="customModal d-flex"
                            show={openDateRangePicker}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <div className="modal-header customModalHeader">
                              <h6 className="modal-title text-white fs-20">
                                {" "}
                                SELECT DATE / DATE RANGE
                              </h6>
                              <div className="cross-outer-icon">
                                <RxCross1
                                  className="cross-icon"
                                  size={22}
                                  color="white"
                                  onClick={closeDateRangePicker}
                                />
                              </div>
                            </div>
                            <div className="modal-content customModalContent">
                              <div>{dateRangePickerTemp()}</div>
                            </div>
                            <Modal.Footer className="d-flex modal-footer customModalFooter">
                              <button
                                className="clearButton"
                                onClick={handleClear}
                              >
                                Clear
                              </button>
                              <button
                                className="applyButton"
                                onClick={handleApply}
                              >
                                Apply
                              </button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </>
                    }
                  />

                  <Column
                    field="isPrivateState"
                    header="Permission"
                    body={PermissionTemplate}
                    style={{ textAlign: "start", width: "10%" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade d-flex"
        show={isModalOpen}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content modal-access" style={{ width: "40vw" }}>
          <div className="modal-header">
            <h5 className="modal-title text-white fs-20">Access Requests </h5>
            <RxCross1
              size={25}
              color="white"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    className="table display mb-4 dataTablesCard order-table card-table text-black application border"
                    id="application-tbl1_next"
                  >
                    <tbody className="backgroundNoify">
                      {requests.length <= 0 ? (
                        <>
                          <div className="col-xl-12 col-xxl-12">
                            <div className="app-fullcalendar1 m-auto">
                              <h1 className="error-text font-weight-bold">
                                <Lottie
                                  style={{ height: 300, marginTop: 60 }}
                                  animationData={Query}
                                />
                              </h1>
                              <h4
                                style={{
                                  fontStyle: "italic",
                                  textAlign: "center",
                                  color: "#3a3630",
                                }}
                              >
                                No, Recent Requests Found !
                              </h4>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {requests.map((data, index) => (
                            <tr key={index}>
                              <td></td>
                              <td>
                                <div className="backgroundLock">
                                  <CiLock size={30} className="text-white" />{" "}
                                </div>
                              </td>
                              <td>
                                <span
                                  className="mt-1"
                                  style={{ color: "gray", fontWeight: "400" }}
                                >
                                  Access Request by{" "}
                                </span>
                                <span
                                  style={{
                                    color: "#007bff",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.email}
                                </span>
                                <Row className="mt-1">
                                  <Col>
                                    <span
                                      style={{
                                        color: "gray",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <IoPersonSharp className="mb-1" />{" "}
                                      <span style={{ color: "#007bff" }}>
                                        {data.role.charAt(0).toUpperCase() +
                                          data.role.slice(1)}
                                      </span>{" "}
                                      {"             "}
                                    </span>
                                  </Col>
                                  <Col>
                                    <span
                                      style={{
                                        color: "gray",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <PiCalendarCheckFill
                                        className="mb-1 mx-2"
                                        size={16}
                                      />
                                      {new Date(
                                        data.updatedAt
                                      ).toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </span>
                                  </Col>
                                </Row>
                              </td>
                              <td>
                                <div
                                  className="mt-1 mx-1"
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                  }}
                                >
                                  <button
                                    className="test-AcceptIcon"
                                    onClick={(e) =>
                                      HandlePermissionRequest(data._id, true)
                                    }
                                  >
                                    {/* <IoCheckmark size={23} /> */}
                                    Accept
                                  </button>
                                  <button
                                    className="test-declineIcon"
                                    onClick={(e) =>
                                      HandlePermissionRequest(data._id, false)
                                    }
                                  >
                                    {/* <RxCross1 size={20} /> */}
                                    Decline
                                  </button>{" "}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TestAccessControl;
