import React, { Fragment, useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { logout } from "../../../store/actions/AuthActions";
import { API_ROOT_URL } from "../../constants";

const CreatePermissions = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const [topic, setTopic] = useState('')
  const [subtopics, setSubTopics] = useState([]);
  const [formValues, setFormValues] = useState([{ subtopics: "" }]);
  const tokenDetailsString = localStorage.getItem('userDetails');


  const {
    register,
    formState: { },
    handleSubmit,
    watch,
    setValue,
    reset
  } = useForm()
  const FormStates = watch()

  const addFormFields = () => {
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      setFormValues([...formValues, { subtopics: "" }]);
    }
  };
  const [duplicateSubtopicError, setDuplicateSubtopicError] = useState('');
  const [duplicatesFound, setDuplicatesFound] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];

    if (e && e.target) {
      const trimmedValue = e.target.value.replace(/^\s+/, '');

      newFormValues[i][e.target.name] = trimmedValue;

      const subtopicList = newFormValues.map((value) => value.subtopics).filter(Boolean);
      const isDuplicate = subtopicList.some((subtopic, index) => subtopicList.indexOf(subtopic) !== index);

      if (isDuplicate) {
        setDuplicateSubtopicError('Duplicate subtopic found');
        setDisableSubmit(true);
        setDuplicatesFound(true);
      } else {
        setDuplicateSubtopicError('');
        setDisableSubmit(false);
        setDuplicatesFound(false);
      }

      setSubTopics(newFormValues);
      setFormValues(newFormValues);
    } else {
      console.error("Invalid event object:", e);
    }
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);

    const subtopicList = newFormValues.map((value) => value.subtopics).filter(Boolean);
    const isDuplicate = subtopicList.some((subtopic, index) => subtopicList.indexOf(subtopic) !== index);

    if (isDuplicate) {
      setDuplicateSubtopicError('Duplicate subtopic found');
      setDisableSubmit(true);
      setDuplicatesFound(true);
    } else {
      setDuplicateSubtopicError('');
      setDisableSubmit(false);
      setDuplicatesFound(false);
    }
  };
  const { } = FormStates;

  //POST QUERY API
  const addTopicsAPI = async (token, data) => {
    return await fetch(`${API_ROOT_URL}/department/adding/topics`, {
      method: "POST",
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((data) => data.json());
  };

  // validation message
  let errorObj = {
    topic: "",
  };
  const handleKeyDown = () => {
    setErrors({ topic: '' }); 
  };
  const [errors, setErrors] = useState(errorObj);

  const onSubmit = async (e) => {

    if (disableSubmit) {
      e.preventDefault();
    }
    let error = false;
    const errorObj1 = { ...errorObj };

    if (topic.trim() === "") {
      errorObj1.topic = "Topic is required!";
      error = true;
    } else {
      errorObj1.topic = "";
    }

    setErrors(errorObj1);

    if (error) return;

    setTopic(topic)
    let subtopics_json = [];
    for (var i = 0; i < formValues.length; i++) {
      subtopics_json.push(Object.values(formValues[i]));
    }
    const payload = {
      topic: topic,
      subtopics: subtopics_json.flat(),
    };

    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      const response = await addTopicsAPI(token, payload);
      // checking api response
      if ("status" in response && response.status === true) {
        // Reset the form
        reset(); // This will clear the form fields and errors
        setFormValues([{ subtopics: "" }]);
        return swal("Success", response.message, "success", {
          buttons: false,
          timer: 1000,
        });
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", response.message, "error");
        }
      }
    }
  };

  useEffect(() => { }, [errors]);

  return (
    <>
      <Fragment>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="cardheader">
              <h2
                className="text-white"
                style={{ fontSize: "25px", fontWeight: "900px" }}
              >
                {/* <AiOutlineAppstoreAdd color="#E7EEF0" /> */}
                <span className="font-w700"> CREATE </span>
                <span
                  className="fs-25 font-w700 text-center"
                  style={{ color: "#FEBA30" }}
                >
                  {" "}
                  TOPICS
                </span>
              </h2>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Field>
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>

                        Topic Name{" "}
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-sm-9">
                        <input
                          name="text"
                          className="form-control mb-2"
                          onChange={(e) => {
                            const inputValue = e.target.value.replace(/^\s+/g, ''); // Remove leading spaces only
                            setTopic(inputValue);
                          }}
                          onKeyDown={handleKeyDown}
                          value={topic}
                        />
                        {errors.topic && (
                          <div style={{ color: 'red', fontWeight: 500 }}>
                            {errors.topic}
                          </div>
                        )}

                      </div>
                    </div>
                  </Form.Field>

                  <Row>
                    <Col lg="9">
                      <div className="mb-2 mt-3">
                        <h2
                          style={{
                            fontSize: "20px",
                            fontWeight: "900px",
                            color: "3a3630",
                          }}
                        >
                          <span className="font-w700"> Topic </span>
                          <span
                            className="fs-20 font-w700 text-center"
                            style={{ color: "#FEBA30" }}
                          >
                            {" "}
                            Tags
                          </span>
                        </h2>
                      </div>
                    </Col>
                    <Col lg="3" className="mt-3">
                      <button
                        type="button"
                        className="AddButton"
                        style={{
                          display: "flex",
                          float: "right",
                        }}
                        onClick={() => addFormFields()}
                      >
                        <span
                          className="btn-icon-start text-info"
                          style={{ borderRadius: "20px" }}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ color: "#3A3630" }}
                          ></i>
                        </span>
                        Add
                      </button>
                    </Col>
                  </Row>

                  <hr style={{ marginTop: "10px", marginBottom: "20px" }} />
                  {duplicateSubtopicError && <p style={{ color: 'red' }}>{duplicateSubtopicError}</p>}
                  {formValues.map((element, index) => (
                    <div className="card-body" key={index}>
                      <div className="mb-3 row">
                        <label className="col-sm-3 col-form-label" style={{ fontSize: "14px", fontWeight: "bolder" }}>
                          Sub Topic{" "}
                          <span
                            className="required-field"
                            style={{ color: "red", fontWeight: "200px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => handleChange(index, e)}
                            name="subtopics"
                            value={element.subtopics}
                          />


                        </div>
                        {index ? (
                          <div className="col-xl-2 mb-12 row">
                            <div className="col-sm-3">
                              <button
                                type="button"
                                className="Remove"
                                onClick={() => removeFormFields(index)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>{" "}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  <div className="mb-12 row">
                    <div className="col-sm-12">
                      <button
                        className="submitButtonInduction"
                        type="submit"
                        style={{ margin: 'auto', display: 'flex', float: 'right' }}
                        disabled={disableSubmit} // Disables the submit button if there's a duplicate
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default CreatePermissions;

