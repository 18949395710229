import React, { Fragment, useState } from "react";
//import Multistep from "react-multistep";
import { Step, Stepper } from 'react-form-stepper';
import StepOne from "../Induction/StepOne";
import StepThree from "../Induction/StepThree";
import StepTwo from "../Induction/StepTwo";
//import { FakePayloadProvider } from "./FakePayloadContext";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";

const UpdateInduction = () => {
  const [goSteps, setGoSteps] = useState(0);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="cardheaderupdate">
              <h2
                className="text-white"
                style={{ fontSize: "25px", fontWeight: "900px" }}
              >
                <span className="font-w700"> UPDATE </span>
                <span
                  className="fs-25 font-w700 text-center"
                  style={{ color: "#FEBA30" }}
                >
                  {" "}
                  INDUCTION
                </span>
              </h2>
            </div>

            <div className="card-body">
              <Stepper className="nav-wizard" style={{ marginBottom: "60px" }} activeStep={goSteps} label={false}>
                <Step className="nav-link" onClick={() => setGoSteps(0)} />
                <Step className="nav-link" onClick={() => setGoSteps(1)} />
                <Step className="nav-link" onClick={() => setGoSteps(2)} />
              </Stepper>
              {goSteps === 0 && (
                <>
                  <StepOne
                    goToStep={() => setGoSteps(1)}
                  />
                </>
              )}
              {goSteps === 1 && (
                <>
                  <StepTwo />
                  <div className="text-end toolbar toolbar-bottom p-2">
                    <button className="previous" onClick={() => setGoSteps(0)}><GrPrevious size={15}  /> {" "} Back</button>
                    <button className="updatePublish" onClick={() => setGoSteps(2)}>Next {" "} <GrNext size={15} /></button>
                  </div>
                </>
              )}
              {goSteps === 2 && (
                <>
                  <StepThree
                    goToStep={() => setGoSteps(1)}
                  />

                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateInduction;