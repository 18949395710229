import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_ROOT_URL } from "../../constants";

// api call
async function getDepartments(token, company) {
  return fetch(`${API_ROOT_URL}/department/getDepartmentByComp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify({ parentCompany: company }),
  }).then((data) => data.json());
}

const DepartmentByCompany = ({parentCompany,setTopicID, setSubTopicList}) => {
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        let getDepartments = `${API_ROOT_URL}/department/getDepartmentByComp`;
        const response = await fetch(getDepartments, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify({ parentCompany: parentCompany })
        });

        if (response.ok) {
          const data = await response.json();
          if (data?.status === true) {
            const rows = data.data.map((row, index) => (
              <option key={row._id} value={row._id}>
                {row.name.slice(0, 20)}
              </option>
            ));
            setOption(rows);
            setLoading(false);
          } else {
            setOption("");
            setTopicID("");
            setLoading(false);
            setSubTopicList([]);
          }
        } else {
          setOption("");
          setLoading(false);
        }
      } catch (error) {
        setOption("");
        setLoading(false);
      }
    };
    setLoading(true);
    fetchDepartments();
  }, [parentCompany]);


  if (loading) {
    return <option>Loading...</option>;
  }

  return <>{option}</>;

};

export default DepartmentByCompany;
