import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { BsFillSendFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const USER_ROLES = {
    SUPER_ADMIN: "super_admin",
    COMPANY: "company",
    INSTRUCTOR: "instructor",
    DEPARTMENT: "department",
    USER: "user",
};

const QueryRaiseUser = ({ setPostData, history }) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.auth.token);
    const role = useSelector((state) => state.auth.auth.role);
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState([]);
    const [image, setImage] = useState({ preview: "", data: "" });
    //deselect
    const [showClearButton, setShowClearButton] = useState("");
    const tokenDetailsString = localStorage.getItem('userDetails');

    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        getValues,
        reset
    } = useForm();
    const formStates = watch();
    const { userName, email, queryDescription, queryTitle } =
        formStates;

    // api call
    async function getProfileApi(token) {
        const URL = `${API_ROOT_URL}/users/getProfile`;
        return fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            },
        }).then((data) => data.json());
    }

    //get profile data(bio) from API
    const getProfile = async () => {
        const response = await getProfileApi(token);
        if ("status" in response && response.status === true) {
            setProfileData(response.data);
            setLoading(false);
        }
    };

    //POST QUERY API
    const AddPostAPI = async (token, data) => {
        if (localStorage.getItem("userDetails") === null) {
            swal("Something went wrong! ", "Please Login Again", "error").then(() => {
              dispatch(logout(history));
            })
          } else {
        return await fetch(`${API_ROOT_URL}/contactus/postquery`, {
            method: "POST",
            headers: {
                "x-access-token": token,
            },
            body: data,
        }).then((data) => data.json());
    }
    };

    // logo image uploader
    const handleFileChange = async (e) => {
        const image = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        };
        setImage(image);
        if (e.target.value) {
            setShowClearButton(true);
        } else {
            setShowClearButton(false);
        }
    };

    // api call for get queries
    async function getPosts(token) {
        return fetch(`${API_ROOT_URL}/contactus/gettingquery`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            },
        }).then((data) => data.json());
    }

    // on List companies page first render
    const handlepageLoad = async () => {
        const response = await getPosts(token);
        if ("status" in response && response.status == true) {
            setPostData(response.data);
            setLoading(false);
        } else {
            if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
                swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                    dispatch(logout(history));
                });
            }
            else if (response?.devMessage === "userDeleted") {
                Swal.fire({
                    title: "Access Denied",
                    text: "Your Account Has been deleted",
                    icon: "error",
                    footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
                }).then(() => {
                    dispatch(logout(history));
                });
            }
        }
    };

    const onFormSubmit = async (e) => {
        // Check if the selected file is an image with the allowed formats
        const acceptedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        if (image.data && !acceptedFormats.includes(image.data.type)) {
            swal('Error', 'Please select a valid image format (JPEG, PNG, JPG)', 'error');
            return;
        }

        // If the image format is valid, proceed with form submission
        const data = new FormData();
        data.append('userName', userName);
        if (USER_ROLES.INSTRUCTOR === role) {
            data.append('email', mapping); // Prepopulated email value
        } else {
            data.append('email', profileData?.email); // Use profileData email for non-instructors
        }
        data.append('queryDescription', queryDescription);
        data.append('queryTitle', queryTitle);
        data.append('image', image.data);

        const response = await AddPostAPI(token, data);
        if ('status' in response && response.status === true) {
            reset();
            handlepageLoad();
            swal('Success', response.message, 'success', {
                buttons: false,
                timer: 1000,
            });
        } else {
            if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
                swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
                    dispatch(logout(history));
                });
            }
            else if (response?.devMessage === "userDeleted") {
                Swal.fire({
                    title: "Access Denied",
                    text: "Your Account Has been deleted",
                    icon: "error",
                    footer: '<a href="mailto:info@bjsinduction.com">Contact Admin</a>'
                }).then(() => {
                    dispatch(logout(history));
                });
            } else {
                return swal("Failed", response.message, "error");
            }
        }
    };

    // the mapping variable based on the user's role
    let mapping = "";
    if (USER_ROLES.INSTRUCTOR === role) {
        mapping = profileData.map((i) => i.email);
    }

    useEffect(() => {
        if (localStorage.getItem("userDetails") === null) {
            swal("Something went wrong! ", "Please Login Again", "error").then(() => {
              dispatch(logout(history));
            })
        } else {
            getProfile(token);
            handlepageLoad();
        }
    }, [loading, tokenDetailsString]);

    return (
        <div>
            <div
                className="card"
                style={{
                    margin: "10px",
                    borderRadius: "15px",
                    boxShadow: "5px 8px",
                }}
            >
                <div className="card-header border-0 pb-0">
                    <Row className="mb-0">
                        <span
                            className="fs-28 font-w900 text-center"
                            style={{ color: "#FEBA30" }}
                        >
                            Ask your{" "}
                            <span
                                className="fs-24 font-w900 text-center"
                                style={{ color: "#3A3630" }}
                            >
                                Query !
                            </span>
                        </span>
                    </Row>
                </div>

                <div className="card-body">
                    <Form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="row">
                            <div className="form-group mb-2 col-md-12 mb-3">
                                <label className="form-label fs-6 fw-bold">
                                    Query Title
                                </label> {" "}
                                <span
                                    className="required-field"
                                    style={{ color: "red", fontWeight: "200px" }}
                                >
                                    *
                                </span>
                                <input
                                    type="text"
                                    name="queryTitle"
                                    placeholder="Enter title"
                                    className="form-control"
                                    {...register('queryTitle', {
                                        required: true,
                                    })
                                    }
                                />
                                {errors.queryTitle && (
                                    <p className="mx-1 mt-1" style={{ color: "red" }}>
                                        Enter query title !
                                    </p>
                                )}
                            </div>
                            <div className="form-group mb-3  ">
                                <label className="fw-bold" htmlFor="exampleFormControlTextarea1 ">
                                    Write a Query!
                                </label>
                                {" "}
                                <span
                                    className="required-field"
                                    style={{ color: "red", fontWeight: "200px" }}
                                >
                                    *
                                </span>
                                <textarea
                                    name="query"
                                    placeholder="Leave your query here...."
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    {...register('queryDescription', {
                                        required: true,
                                    })
                                    }
                                ></textarea>
                                {errors.queryDescription && (
                                    <p className="mx-1 mt-1" style={{ color: "red" }}>
                                        Description is required!
                                    </p>
                                )}
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label className="form-label fs-6 fw-bold">Image
                                        <span className="text-primary">{" "}(JPG/PNG/JPEG)</span>
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        onChange={handleFileChange}
                                        accept="image/jpeg,image/jpg,image/png"
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            className="viewButton"
                            type="submit"
                            style={{
                                display: "flex",
                                float: "right",
                            }}
                        >
                            Post <BsFillSendFill size={16} className="m-1" />
                        </button>
                    </Form>
                </div>
            </div>
        </div>

    )
}

export default QueryRaiseUser
