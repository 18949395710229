import React, { useEffect } from "react";
//import QuestionHeader from './QuestionHeader';
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import CloseIcon from "@material-ui/icons/Close";
import AccordionActions from "@material-ui/core/AccordionActions";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { v4 as uuidv4 } from "uuid";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import TimerIcon from "@mui/icons-material/Timer";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { API_ROOT_URL, AWS_BUCKET_NAME, QUESTIONS_ASSETS_URL } from "../../jsx/constants";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/AuthActions";
import Swal from "sweetalert2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import { LuTimer } from "react-icons/lu";
import Toolbar from "@material-ui/core/Toolbar";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import { RiPlayListAddLine } from "react-icons/ri";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useDashboardUrl from "../../utilis/useDashboardUrl";
import { BsXCircleFill } from "react-icons/bs";
import s3 from "../../utilis/aws.config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 128,
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
  },
  title: {
    marginTop: 0,
    flexGrow: 1,
    alignSelf: "flex-end",
    justifySelf: "center",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    alignContent: "space-between",
    alignItems: "center",
  },
}));

function EditTestForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //token
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const navigate = useHistory();
  const [questions, setQuestions] = React.useState([]);
  const [openOfAlert, setOpenOfAlert] = React.useState(false);

  //const [formData, setFormData] = React.useState({});
  const [test_name, setTestName] = useState("");
  const [form_description, setFormDescription] = useState("");
  const [correct_answer, setAnswerKey] = useState("");
  const [timer, setQuizTimer] = useState("");
  const [showClearButton, setShowClearButton] = useState(false);
  const [sections, setMultipleSections] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //edit
  const [EditData, setEditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [reorderData, setReorderData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [formDeatils, setFormDetails] = React.useState({});
  const [duplicateOptions, setDuplicateOptions] = useState([]);
  const [inputError, setInputError] = useState("");
  const dashboardUrl = useDashboardUrl(role);
  const [imagesArray, setImagesArray] = useState([]);
  const [duplicateQuestionsArray, setDuplicateQuestionsArray] = useState([]);

  const handleCloseOfAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //handling adding multiple sections
  const addMoreSection = () => {
    const sectionID = uuidv4();
    const questionID = uuidv4();
    setMultipleSections((sections) => [
      ...sections,
      {
        section_name: "",
        sectionID: sectionID,
        questions: [
          {
            questionID: questionID,
            question_text: "",
            options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
            correct_answer: "",
            open: true,
          },
        ],
      },
    ]);
  };

  //handling adding questions field
  const addMoreQuestionField = (sectionIndex) => {
    const questionID = uuidv4();
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].questions.push({
        questionID: questionID,
        question_text: "",
        options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
        correct_answer: "",
        open: true,
      });
      return newSections;
    });
    expandCloseAll();
    handleClose();
  };

  //handling delete section
  // function deleteSection(i) {
  //   let ms = [...sections];
  //   if (sections.length > 1) {
  //     ms.splice(i, 1);
  //   }
  //   setMultipleSections(ms);
  //   handleClose();
  // }

  // Delete Section
  const deleteSection = (section_id, sectionID) => {
    if (sectionID) {
      setMultipleSections((prevSections) => {
        const newSections = [...prevSections];
        if (newSections.length > 1) {
          const sectionIndex = newSections.findIndex(
            (s) => s.sectionID === sectionID
          );
          if (sectionIndex !== -1) {
            newSections.splice(sectionIndex, 1);
          }
        }
        return newSections;
      });
    } else {
      swal({
        title: "Are you sure?",
        text: `Once Section is Deleted, you won't be able to recover your data`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(
            `${API_ROOT_URL}/v3/tests/delete-section/${section_id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
            }
          ).then((data) => data.json());
          if ("status" in response && response.status == true) {
            swal("Poof! Your Section has been Deleted!", {
              icon: "success",
            }).then(() => {
              GetAllData();
              setLoading(false);
            });
          } else {
            if (
              response?.error?.message === "jwt expired" ||
              response?.devMessage === "userDeleted" ||
              response?.message === "Authentication Required"
            ) {
              swal("Session Timeout ! ", "Please Login Again", "error").then(
                () => {
                  dispatch(logout(props.history));
                }
              );
            } else if (response?.devMessage === "revokedPermisision") {
              Swal.fire({
                title: "Access Denied",
                text: "Your Permission Has been Revoked",
                icon: "error",
                confirmButtonColor: "#3a3630",
                confirmButtonText: "OK",
                footer:
                  '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
              }).then(() => {
                navigate.push(dashboardUrl);
              });
            } else {
              return swal("Failed", "Error message", "error");
            }
          }
        } else {
          swal("", "Your Section is not Deleted!", "warning");
        }
      });
    }
  };

  const [sectionsAll, setSections] = useState([]);

  // Deleteing Section
  const deleteQuestion = (question_id, questionID) => {
    if (questionID) {
      setMultipleSections((prevSections) => {
        const newSections = [...prevSections];
        newSections.forEach((section) => {
          if (section.questions.length > 1) {
            const index = section.questions.findIndex(
              (question) => question.questionID === questionID
            );
            if (index !== -1) {
              section.questions.splice(index, 1);
            }
          }
        });
        return newSections;
        // }})
      });
    } else {
      swal({
        title: "Are you sure?",
        text: `Once Question is Deleted, you won't be able to recover your data`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(
            `${API_ROOT_URL}/v3/tests/delete-question/${question_id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
            }
          ).then((data) => data.json());
          if ("status" in response && response.status == true) {
            swal("Poof! Your question has been Deleted!", {
              icon: "success",
            }).then(() => {
              GetAllData();
              setLoading(false);
            });
          } else {
            if (
              response?.error?.message === "jwt expired" ||
              response?.devMessage === "userDeleted" ||
              response?.message === "Authentication Required"
            ) {
              swal("Session Timeout ! ", "Please Login Again", "error").then(
                () => {
                  dispatch(logout(props.history));
                }
              );
            } else if (response?.devMessage === "revokedPermisision") {
              Swal.fire({
                title: "Access Denied",
                text: "Your Permission Has been Revoked",
                icon: "error",
                confirmButtonColor: "#3a3630",
                confirmButtonText: "OK",
                footer:
                  '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
              }).then(() => {
                navigate.push(dashboardUrl);
              });
            } else {
              return swal("Failed", "Error message", "error");
            }
          }
        } else {
          swal("", "Your Question is not Deleted!", "success");
        }
      });
    }
  };

  //function to clear image
  const handleClearImage = (sectionIndex, questionIndex) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const currentQuestions = newSections[sectionIndex]?.questions || [];
      const updatedQuestions = [...currentQuestions];

      updatedQuestions[questionIndex] = {
        ...currentQuestions[questionIndex],
        file: "",
        preview: "",
        image_uuid: "",
        question_image: "",
      };

      newSections[sectionIndex] = {
        ...newSections[sectionIndex],
        questions: updatedQuestions,
      };

      return newSections;
    });

    setImagesArray((prevImagesArray) => {
      const updatedImagesArray = prevImagesArray.filter(
        (image, index) => index !== questionIndex
      );
      return updatedImagesArray;
    });

    setShowClearButton(false);

    const remainingImages = sections[sectionIndex]?.questions.some(
      (question) => question.file && question.preview && question.image_uuid
    );

    if (remainingImages) {
      setShowClearButton(true);
    }

    const inputElement = document.querySelector('input[type="file"]');
    if (inputElement) {
      inputElement.value = "";
    }
    setFileInputKey((prevKey) => prevKey + 1);
  };

  async function getRecentForms(token) {
    return fetch(`${API_ROOT_URL}/v3/tests/fetch-tests-details/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const GetAllData = async (event) => {
    try {
      const response = await getRecentForms(token);
      if ("status" in response && response.status === true) {
        setEditData(response.data);
        //return response.data;
      } else if (response?.devMessage === "revokedPermisision") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Permission Has been Revoked",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          navigate.push(dashboardUrl);
        });
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllData()
  }, [])

  //gernerating random numbers for image id
  const generateImageId = () => {
    return uuidv4();
  };

  const [newImage, setNewImageStates] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);

  // handling image data
  const handleFileChange = (e, sectionIndex, questionIndex) => {
    const file = e.target.files[0];
    const imageId = generateImageId();
    const gettingExt = file.name.slice(file.name.indexOf(".") + 1);
    const question_image_uuid = imageId + "." + gettingExt;
    const image_uuid = imageId;
    if (file) {
      let newSections;
      const reader = new FileReader();
      reader.onloadend = () => {
        setMultipleSections((prevSections) => {
          newSections = [...prevSections];
          const currentQuestions = newSections[sectionIndex]?.questions || [];
          const updatedQuestions = [...currentQuestions];

          updatedQuestions[questionIndex] = {
            ...currentQuestions[questionIndex],
            file: file.name,
            preview: URL.createObjectURL(e.target.files[0]),
            question_image_uuid: image_uuid,
          };

          newSections[sectionIndex] = {
            ...newSections[sectionIndex],
            questions: updatedQuestions,
          };

          // Check if newImageStates[sectionIndex] is defined before accessing [questionIndex]
          if (newImage[sectionIndex] && newImage[sectionIndex][questionIndex]) {
            setNewImageStates((prevStates) => {
              const newStates = [...prevStates];
              newStates[sectionIndex] = newStates[sectionIndex] || [];
              newStates[sectionIndex][questionIndex] =
                currentQuestions[questionIndex]?.question_image_uuid !==
                image_uuid;
            });
          }

          return newSections;
        });

        setImagesArray((prevImagesArray) => {
          // create a new File instance
          const newFile = new File([file], question_image_uuid, {
            type: file.type,
          });
          const updatedImagesArray = [...prevImagesArray, newFile];
          return updatedImagesArray;
        });
      };

      reader.readAsDataURL(file);
    }
    setFileInputKey((prevKey) => prevKey + 1);
  };

  //handle section name change
  const handleSectionNameChange = (text, sectionIndex) => {
    setMultipleSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].section_name = text;
      return updatedSections;
    });
  };

  function showAsQuestion(i) {
    let qs = [...questions];
    qs[i].open = false;
    setQuestions(qs);
  }

  function expandCloseAll() {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      qs[j].open = false;
    }
    setQuestions(qs);
  }

  //go to forms page...
  const navigateToHomePage = () => {
    navigate.push('/forms')
  };


  //Edit Test details API Integration
  const onSubmitHandle = async () => {
    if (hasValidationErrors()) {
      swal({
        title: "Validation Error",
        text: "Please fix validation Errors and complete all Required fields before saving",
        icon: "error",
        customClass: {
          content: "text-center",
        },
      });
      return;
    }

    const data = new FormData();
    data.append("name", test_name);
    data.append("description", form_description);
    data.append("test_timer", timer);
    data.append("sections", JSON.stringify(sections));

    imagesArray.forEach((file, index) => {
      data.append("images", file);
    });

    const response = await fetch(
      `${API_ROOT_URL}/v3/tests/edit/single-test-details/${id}`,
      {
        method: "PUT",
        headers: {
          "x-access-token": token,
        },
        body: data,
      }
    ).then((data) => data.json());

    if ("status" in response && response.status == true) {
      //setIsModalOpen(false);
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        reorderAPI();
        navigateToHomePage()
      });
    } else {
      if (
        response?.error?.message === "jwt expired" ||
        response?.message === "Authentication Required"
      ) {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      } else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else if (response?.devMessage === "revokedPermisision") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Permission Has been Revoked",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          navigate.push(dashboardUrl);
        });
      } else {
        return swal("Failed", response.message, "error");
      }
    }
  };

  //managing accordion data
  function handleExpand(questionIndex) {
    let qs = questions.map((ques, index) => ({
      ...ques,
      open: index === questionIndex ? !open : false,
    }));

    setQuestions(qs);
  }

  //handling question values
  const handleQuestionValue = (text, sectionIndex, i) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].questions[i].question_text = text;
      return newSections;
    });
  };

  //function to handle option values
  // const handleOptionValue = (
  //   text,
  //   sectionIndex,
  //   questionIndex,
  //   optionIndex
  // ) => {
  //   setMultipleSections((prevSections) => {
  //     const newSections = [...prevSections];
  //     newSections[sectionIndex].questions[questionIndex].options[optionIndex] =
  //       text;
  //     return newSections;
  //   });
  // };

  //handle section name change
  const [sectionNameErrors, setSectionNameErrors] = useState(
    Array(sections.length).fill("")
  );

  const hasValidationErrors = () => {
    const duplicateOptionsError = duplicateOptions.some(
      (sectionDuplicates) =>
        sectionDuplicates &&
        sectionDuplicates.some(
          (questionDuplicates) =>
            questionDuplicates &&
            questionDuplicates.some((option) => option.text)
        )
    );

    const testNameError = test_name.trim() === "";
    const testDescriptionError = form_description.trim() === "";
    const sectionNameEmpty = sections.some(
      (section) => section.section_name.trim() === ""
    );

    const sectionNameDuplicateError = sectionNameErrors.some(
      (error) => !!error
    );

    const questionTextEmptyError = sections.some((section) =>
      section.questions.some((question) => question.question_text.trim() === "")
    );

    // const isAnyOptionEmpty = sections.some((section) =>
    //   section.questions.some(
    //     (question) =>
    //       question.options.length < 4 ||
    //       question.options.some((option) => option.trim() === "")
    //   )
    // );

    const answerTextEmptyError = sections.some((section) =>
      section.questions.some(
        (question) => question.correct_answer === "" || undefined
      )
    );

    return (
      duplicateOptionsError ||
      testNameError ||
      testDescriptionError ||
      sectionNameEmpty ||
      sectionNameDuplicateError ||
      questionTextEmptyError ||
      // isAnyOptionEmpty ||
      answerTextEmptyError
    );
  };

  const [inputErrors, setInputErrors] = useState([]);

  const handleOptionValue = (
    value,
    sectionIndex,
    questionIndex,
    optionIndex
  ) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const section = newSections[sectionIndex];
      const question = section.questions[questionIndex];

      // const isDuplicateInSection = question.options.some(
      //   (option, index) =>
      //     index !== optionIndex && option.trim() === value.trim()
      // );

      const isDuplicateInSection = question.options.some(
        (option, index) =>
          index !== optionIndex &&
          typeof option.text === "string" &&
          option.text.trim() === value.trim()
      );

      // Checking if the value is being cleared
      const isValueCleared = value.trim() === "";

      question.options[optionIndex].text = value;

      setDuplicateOptions((prevDuplicates) => {
        const newDuplicates = [...prevDuplicates];
        const sectionDuplicates = newDuplicates[sectionIndex] || [];
        sectionDuplicates[questionIndex] =
          sectionDuplicates[questionIndex] || [];
        sectionDuplicates[questionIndex][optionIndex] = isDuplicateInSection;

        // Clearing the duplicate flag if the value is cleared
        if (isValueCleared) {
          sectionDuplicates[questionIndex][optionIndex] = false;
        }

        // Check if the correct answer key is non-empty before validating
        if (question.correct_answer.trim() !== "") {
          // Check if the updated option value matches the answer key
          const isAnswerKeyValid = question.options.some(
            (option) => option.text.trim() === question.correct_answer.trim()
          );

          // Update the validation state
          question.isAnswerKeyValid = isAnswerKeyValid;
        }

        return [
          ...newDuplicates.slice(0, sectionIndex),
          sectionDuplicates,
          ...newDuplicates.slice(sectionIndex + 1),
        ];
      });

      return newSections;
    });
  };

  //handling answer key values
  const handleAnswerKey = async (text, sectionIndex, questionIndex) => {
    try {
      setMultipleSections((prevSections) => {
        const newSections = [...prevSections];
        const question = newSections[sectionIndex].questions[questionIndex];
        const isAnswerKeyValid = question.options.some(
          (option) => option.text === text
        );
        question.correct_answer = text;
        question.isAnswerKeyValid = isAnswerKeyValid;

        if (isAnswerKeyValid) {
          updateTestDetails();
        }
        return newSections;
      });
    } catch (error) {
      console.error("Error updating answer key:", error);
    }
  };

  const updateTestDetails = async () => {
    const data = new FormData();
    data.append("name", test_name);
    data.append("description", form_description);
    data.append("test_timer", timer);
    data.append("sections", JSON.stringify(sections));

    imagesArray.forEach((file, index) => {
      data.append("images", file);
    });

    const response = await fetch(
      `${API_ROOT_URL}/v3/tests/edit/single-test-details/${id}`,
      {
        method: "PUT",
        headers: {
          "x-access-token": token,
        },
        body: data,
      }
    );
    const responseData = await response.json();
    if ("status" in responseData && responseData.status === true) {
      GetAllData();
    } else {
      console.log("error");
    }
  };

  //handling removing options
  const removeOption = (sectionIndex, questionIndex, j) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const section = newSections[sectionIndex];
      const updatedQuestions = [...section.questions];

      //checking questions and options are existing or not
      if (
        updatedQuestions[questionIndex] &&
        updatedQuestions[questionIndex].options.length > 1
      ) {
        updatedQuestions[questionIndex].options.splice(j, 1);

        newSections[sectionIndex] = {
          ...section,
          questions: updatedQuestions,
        };

        return newSections;
      }

      return prevSections;
    });
  };

  //Reorder Function
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) {
      const sectionIndex = parseInt(source.droppableId, 10);
      const updatedSections = [...sections];
      const section = updatedSections[sectionIndex];
      const updatedQuestions = Array.from(section.questions);
      const [draggedQuestion] = updatedQuestions.splice(source.index, 1);
      updatedQuestions.splice(destination.index, 0, draggedQuestion);
      section.questions = updatedQuestions;
      setMultipleSections(updatedSections);
      const data = updatedQuestions;

      // const removedData = data.map(({ _id, question_order }) => ({
      //   removedData: { _id, question_order},
      // }));
      const reorderQuestionData = data.map((obj, index) => ({
        _id: obj._id,
        question_order: index + 1,
      }));

      setReorderData(reorderQuestionData);
    }
  };

  //Reorder Questions API
  const reorderAPI = async () => {
    const response = await fetch(`${API_ROOT_URL}/v3/tests/reorder-questions`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(reorderData),
    }).then((data) => data.json());

    if ("status" in response && response.status == true) {
      //setIsModalOpen(false);
      // return swal("Success", response.message, "success", {
      //   buttons: false,
      //   timer: 2000,
      // }).then((value) => {
      // });
    } else {
      if (
        response?.error?.message === "jwt expired" ||
        response?.message === "Authentication Required"
      ) {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      } else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else if (response?.devMessage === "revokedPermisision") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Permission Has been Revoked",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          navigate.push(dashboardUrl);
        });
      } else {
        return swal("Failed", response.message, "error");
      }
    }
  };

  const prepopulateData = () => {
    if (EditData.length > 0) {
      const firstItem = EditData[0];
      setTestName(firstItem.name);
      setFormDescription(firstItem.description);
      setQuizTimer(firstItem.timer);
      setMultipleSections(firstItem.sections);
    }
  };

  //handling add options
  const addOption = (sectionID, i) => {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      const section = newSections[sectionID];
      const question = section.questions[i];

      if (question.options.length < 4) {
        question.options.push("");
      }
      return newSections;
    });
  };

  function copyQuestion(
    sectionIndex,
    questionIndex,
    newQuestionText,
    newCorrectAnswer,
    newQuestionID,
    newOptions
  ) {
    setMultipleSections((prevSections) => {
      const newSections = [...prevSections];
      if (newSections[sectionIndex]) {
        const originalQuestion =
          newSections[sectionIndex].questions[questionIndex];

        const questionID = uuidv4();
        let stringArray = [];
        const copiedOptions = [...originalQuestion.options];

        //Assuming newOptions is an array of strings or objects with a text property
        const newCopyOptions = [];
        const payloadArray = [
          ...copiedOptions.map((option) => {
            if (typeof option === "object" && option.text) {
              return option.text;
            } else {
              return option;
            }
          }),
          ...newCopyOptions.map((option) => {
            if (typeof option === "object" && option.text) {
              return option.text;
            } else {
              return option;
            }
          }),
        ];

        // Adding additional strings from the question object
        //new questions
        const duplicatedQuestion = {
          question_text: newQuestionText || originalQuestion.question_text,
          correct_answer: newCorrectAnswer || "",
          questionID: newQuestionID || questionID,
          //options: newOptions || [...originalQuestion.options],
          //working with text key
          options: newOptions
            ? newOptions.map((option) =>
              typeof option === "string"
                ? { text: option }
                : { text: option.text }
            )
            : originalQuestion.options.map((option) => ({ text: option.text })),
          stringArray: copiedOptions.map((option) => option.text),
          open: true,
        };
        setDuplicateQuestionsArray(duplicatedQuestion);

        // Save duplicatedQuestion to duplicateQuestionsArray
        setDuplicateQuestionsArray((prevArray) => {
          if (Array.isArray(prevArray)) {
            return [...prevArray, duplicatedQuestion];
          } else {
            return [duplicatedQuestion];
          }
        });

        newSections[sectionIndex].questions.splice(questionIndex + 1, 0, {
          ...duplicatedQuestion,
          // options: resultOptions,
        });
      } else {
        console.error(`Section at index ${sectionIndex} does not exist.`);
      }
      setMultipleSections(newSections);
      return newSections;
    });
  }

  useEffect(() => {
    prepopulateData();
  }, [EditData]);

  const getSignedUrl = (fileName) => {
    return s3.getSignedUrl("getObject", {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName,
    });
  };

  function SectionUI() {
    return sections.map((data, sectionIndex) => (
      <div key={data._id}>
        <Grid style={{ paddingTop: "10px" }}>
          <Grid style={{ borderTop: "10px solid #feba30", borderRadius: 10 }}>
            <div>
              <div>
                <Paper
                  elevation={2}
                  style={{ width: "100%", backgroundColor: "#faf0e6" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "15px",
                      justifyContent: "space-between",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    <TextField
                      className="mt-0"
                      fullWidth
                      label="Section Title"
                      variant="standard"
                      size="small"
                      fontSize={30}
                      value={data.section_name}
                      onChange={(e) =>
                        handleSectionNameChange(e.target.value, sectionIndex)
                      }
                      error={!!sectionNameErrors[sectionIndex]}
                      helperText={sectionNameErrors[sectionIndex]}
                    />
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <IconButton
                            aria-label="Image"
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon />
                          </IconButton>

                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onClick={(e) => {
                                addMoreQuestionField(sectionIndex);
                                popupState.close(e);
                              }}
                            >
                              <AddIcon
                                style={{ marginRight: "5px" }}
                                size={25}
                              />
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",

                                  color: "dimgrey",
                                }}
                              >
                                Add Question
                              </span>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                deleteSection(data._id, data.sectionID);
                                popupState.close(e);
                              }}
                              disabled={sections.length <= 1}
                            >
                              <DeleteIcon
                                style={{
                                  marginRight: "5px",
                                  color: sectionIndex <= 1 ? "grey" : "dimgrey",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: sectionIndex <= 1 ? "grey" : "dimgrey",
                                }}
                              >
                                Delete Section
                              </span>
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </div>

                  <div style={{ padding: "20px" }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId={sectionIndex.toString()}>
                        {(provided) => (
                          <div
                            key={sectionIndex}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <>
                              {data.questions.map((ques, questionIndex) => (
                                <Draggable
                                  key={ques.questionID}
                                  draggableId={questionIndex.toString()}
                                  index={questionIndex}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div>
                                        <div style={{ marginBottom: "15px" }}>
                                          <div
                                            style={{
                                              width: "100%",
                                              marginBottom: "-7px",
                                            }}
                                          >
                                            <DragIndicatorIcon
                                              style={{
                                                marginTop: "10px",
                                                transform: "rotate(-90deg)",
                                                color: "darkgrey",
                                              }}
                                              fontSize="small"
                                            />
                                          </div>

                                          <Accordion
                                            onChange={() => {
                                              handleExpand(sectionIndex);
                                            }}
                                            expanded={open}
                                          >
                                            <AccordionSummary
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                              elevation={1}
                                              style={{ width: "100%" }}
                                            >
                                              {!open ? (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    marginLeft: "3px",
                                                    paddingTop: "15px",
                                                    paddingBottom: "15px",
                                                  }}
                                                >
                                                  <Typography
                                                    variant="subtitle1"
                                                    style={{
                                                      marginLeft: "0px",
                                                    }}
                                                  >
                                                    {/* {questionIndex + 1}. {ques.question_text} */}
                                                  </Typography>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </AccordionSummary>

                                            <AccordionDetails>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "flex-start",
                                                  marginLeft: "15px",
                                                  marginTop: "-15px",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      marginTop: "20px",
                                                    }}
                                                  >
                                                    {questionIndex + 1}.
                                                  </Typography>

                                                  <TextField
                                                    fullWidth
                                                    placeholder="Question Text"
                                                    style={{
                                                      marginBottom: "18px",
                                                    }}
                                                    rows={2}
                                                    rowsMax={20}
                                                    multiline={true}
                                                    value={ques.question_text}
                                                    variant="filled"
                                                    onChange={(e) => {
                                                      handleQuestionValue(
                                                        e.target.value,
                                                        sectionIndex,
                                                        questionIndex
                                                      );
                                                    }}
                                                  />
                                                  <Tooltip
                                                    title="Add Image"
                                                    arrow
                                                  >
                                                    <IconButton
                                                      //   aria-label={`upload image for Question ${
                                                      //     questionIndex + 1
                                                      //   }`}
                                                      component="label"
                                                    >
                                                      <CropOriginalIcon />

                                                      <input
                                                        type="file"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        onChange={(e) =>
                                                          handleFileChange(
                                                            e,
                                                            sectionIndex,
                                                            questionIndex
                                                          )
                                                        }
                                                        accept="image/png,image/jpeg,image/jpg"
                                                        key={fileInputKey}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </div>

                                                {ques.preview ? (
                                                  <>
                                                    {ques.preview && (
                                                      <div
                                                        style={{
                                                          marginTop: 10,
                                                        }}
                                                      >
                                                        <img
                                                          src={ques.preview}
                                                          width="150"
                                                          alt="Preview"
                                                          key={fileInputKey}
                                                        />
                                                        <BsXCircleFill
                                                          size={25}
                                                          style={{
                                                            marginBottom:
                                                              "100px",
                                                            color: "gray",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleClearImage(
                                                              sectionIndex,
                                                              questionIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {ques.question_image && (
                                                      <>
                                                        <div key={fileInputKey}
                                                          className=""
                                                          style={{
                                                            marginTop: 0,
                                                            marginLeft: 10,
                                                          }}
                                                        >
                                                          <img
                                                            src={getSignedUrl(`${QUESTIONS_ASSETS_URL}/${ques.question_image}`)}
                                                            width={200}
                                                          />
                                                          <BsXCircleFill
                                                            size={25}
                                                            style={{
                                                              marginBottom:
                                                                "150px",
                                                              color: "gray",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              handleClearImage(
                                                                sectionIndex,
                                                                questionIndex
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                )}

                                                {/* )} */}

                                                <div style={{ width: "100%" }}>
                                                  {ques.options.map(
                                                    (op, index) => (
                                                      <div key={index}>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "row",
                                                            marginLeft:
                                                              "-12.5px",
                                                            justifyContent:
                                                              "space-between",
                                                            paddingTop: "5px",
                                                            paddingBottom:
                                                              "5px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              marginRight:
                                                                "8px",
                                                              marginTop: "22px",
                                                              marginLeft:
                                                                "15px",
                                                            }}
                                                          >
                                                            {index + 1}.
                                                          </span>
                                                          {/* <Radio disabled /> */}
                                                          {/* <TextField
                                                            fullWidth={true}
                                                            placeholder="Option text"
                                                            value={op.text}
                                                            onChange={(e) => {
                                                              handleOptionValue(
                                                                e.target.value,
                                                                sectionIndex,
                                                                questionIndex,
                                                                index
                                                              );
                                                            }}
                                                          /> */}
                                                          <TextField
                                                            fullWidth={true}
                                                            placeholder="Option text *"
                                                            value={op.text}
                                                            // style={{
                                                            //   marginTop: "5px",
                                                            //   borderColor:
                                                            //     (duplicateOptions[
                                                            //       sectionIndex
                                                            //     ] &&
                                                            //       duplicateOptions[
                                                            //         sectionIndex
                                                            //       ][
                                                            //         questionIndex
                                                            //       ] &&
                                                            //       duplicateOptions[
                                                            //         sectionIndex
                                                            //       ][
                                                            //         questionIndex
                                                            //       ][index]) ||
                                                            //     false
                                                            //       ? "red"
                                                            //       : undefined,
                                                            // }}
                                                            style={{
                                                              marginTop: "5px",
                                                              borderColor:
                                                                duplicateOptions[
                                                                  sectionIndex
                                                                ] &&
                                                                  duplicateOptions[
                                                                  sectionIndex
                                                                  ][
                                                                  questionIndex
                                                                  ] &&
                                                                  duplicateOptions[
                                                                  sectionIndex
                                                                  ][
                                                                  questionIndex
                                                                  ][index]
                                                                  ? "red"
                                                                  : undefined,
                                                            }}
                                                            onChange={(e) => {
                                                              handleOptionValue(
                                                                e.target.value,
                                                                sectionIndex,
                                                                questionIndex,
                                                                index
                                                              );
                                                            }}
                                                          />
                                                          <IconButton
                                                            className="removeOption"
                                                            aria-label="delete"
                                                            onClick={() => {
                                                              removeOption(
                                                                sectionIndex,
                                                                questionIndex,
                                                                index
                                                              );
                                                            }}
                                                          >
                                                            <CloseIcon />
                                                          </IconButton>
                                                        </div>
                                                        {duplicateOptions[
                                                          sectionIndex
                                                        ] &&
                                                          duplicateOptions[
                                                          sectionIndex
                                                          ][questionIndex] &&
                                                          duplicateOptions[
                                                          sectionIndex
                                                          ][questionIndex][
                                                          index
                                                          ] && (
                                                            <Typography
                                                              style={{
                                                                color: "red",
                                                                marginLeft:
                                                                  "25px",
                                                              }}
                                                            >
                                                              Duplicate option
                                                              value!
                                                            </Typography>
                                                          )}
                                                        {/* {inputError && <div style={{ color: 'red' }}>{inputError}</div>} */}
                                                        {/* {inputErrors[
                                                          sectionIndex
                                                        ] &&
                                                          inputErrors[
                                                            sectionIndex
                                                          ][questionIndex] && (
                                                            <div
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {
                                                                inputErrors[
                                                                  sectionIndex
                                                                ][questionIndex]
                                                              }
                                                            </div>
                                                          )} */}
                                                      </div>
                                                    )
                                                  )}
                                                </div>

                                                <br></br>
                                                <div style={{ width: "100%" }}>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent:
                                                        "space-between",
                                                      paddingTop: "10px",
                                                    }}
                                                  >
                                                    <WysiwygIcon
                                                      style={{
                                                        color: "grey",
                                                        marginRight: "10px",
                                                        marginLeft: "-5px",
                                                        size: "20px",
                                                        marginTop: "10px",
                                                      }}
                                                    />
                                                    <TextField
                                                      fullWidth={true}
                                                      placeholder="answer Key"
                                                      style={{
                                                        marginTop: "5px",
                                                        marginRight: "42px",
                                                        borderColor:
                                                          ques.isAnswerKeyValid ===
                                                            false
                                                            ? "red"
                                                            : undefined,
                                                      }}
                                                      value={
                                                        ques.correct_answer
                                                      }
                                                      onChange={(e) => {
                                                        handleAnswerKey(
                                                          e.target.value,
                                                          sectionIndex,
                                                          questionIndex
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  {ques.isAnswerKeyValid ===
                                                    false && (
                                                      <Typography
                                                        style={{
                                                          color: "red",
                                                          marginLeft: "25px",
                                                        }}
                                                      >
                                                        Answer key must match one
                                                        of the options.
                                                      </Typography>
                                                    )}
                                                </div>

                                                <br></br>
                                              </div>
                                            </AccordionDetails>

                                            <Divider />

                                            <AccordionActions>
                                              <Tooltip
                                                title="Duplicate Question"
                                                arrow
                                              >
                                                <IconButton
                                                  aria-label="Copy"
                                                  onClick={() => {
                                                    copyQuestion(
                                                      sectionIndex,
                                                      questionIndex
                                                    );
                                                  }}
                                                >
                                                  <FilterNoneIcon />
                                                </IconButton>
                                              </Tooltip>

                                              <Divider
                                                orientation="vertical"
                                                flexItem
                                              />

                                              <Tooltip
                                                title="Delete Question"
                                                arrow
                                              >
                                                <span>
                                                  <IconButton
                                                    aria-label="delete"
                                                    onClick={(e) => {
                                                      deleteQuestion(
                                                        ques._id,
                                                        ques.questionID
                                                      );
                                                    }}
                                                    disabled={
                                                      data.questions.length ===
                                                      1
                                                    }
                                                  >
                                                    <DeleteOutlineIcon
                                                      style={{
                                                        fontSize: "30px",
                                                        color:
                                                          data.questions
                                                            .length === 1
                                                            ? "lightgrey"
                                                            : "dimgrey",
                                                        cursor:
                                                          data.questions
                                                            .length === 1
                                                            ? "none"
                                                            : "pointer",
                                                      }}
                                                    />
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            </AccordionActions>
                                          </Accordion>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </Paper>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    ));
  }

  const EditTestFormUI = () => {
    return (
      <>
        <div>
          <div style={{ minHeight: "100vh" }}>
            <div
              style={{
                marginTop: "80px",
                marginBottom: "7px",
                paddingBottom: "30px",
              }}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={5} style={{ width: "100%" }}>
                  <Grid
                    style={{
                      borderTop: "10px solid #feba30",
                      borderRadius: 10,
                    }}
                  >
                    <div>
                      <div>
                        <Paper elevation={2} style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              marginLeft: "15px",
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              marginRight: "15px",
                            }}
                          >
                            <TextField
                              className="mt-0"
                              fullWidth
                              label="Form Title"
                              variant="standard"
                              size="small"
                              fontSize={30}
                              value={test_name}
                              onChange={(e) => setTestName(e.target.value)}
                            />

                            <TextField
                              className="mt-4"
                              label="Form Description"
                              id="fullWidth"
                              fullWidth
                              size="small"
                              multiline
                              rows={2}
                              variant="filled"
                              value={form_description}
                              onChange={(e) =>
                                setFormDescription(e.target.value)
                              }
                            />
                          </div>
                        </Paper>
                      </div>
                    </div>
                  </Grid>

                  {/* section Data */}

                  {SectionUI()}

                  <Grid
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div></div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* test timer */}
          {AddTestTimer()}
        </div>
      </>
    );
  };

  const [openTimer, setOpenTimer] = React.useState(false);

  const handleClickOpenTimer = () => {
    setOpenTimer(true);
  };
  const handleCloseTimer = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpen(false);
    setOpenTimer(false);
  };

  const goBack = () => {
    navigate.push("/forms");
  };

  const SubmitTimer = () => {
    setOpenTimer(false);
  };

  const AddTestTimer = () => {
    return (
      <>
        <div>
          <Dialog onClose={handleCloseTimer} open={openTimer}>
            <div style={{ width: "600px", height: "250px" }}>
              <div className="d-flex">
                <DialogTitle id="customized-dialog-title">
                  <span
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      color: "dimgrey",
                    }}
                  >
                    Edit Test Timer
                  </span>
                </DialogTitle>
                <div style={{ marginLeft: "55%", marginTop: "5px" }}>
                  {/* <IconButton onClick={handleCloseTimer}>
                    <CloseIcon />
                  </IconButton> */}
                </div>
              </div>

              <DialogContent dividers>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "15px",
                    justifyContent: "space-between",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <TimerIcon
                    style={{
                      color: "grey",
                      marginRight: "-9px",
                      marginLeft: "-5px",
                      size: "20px",
                      marginTop: "10px",
                    }}
                  />
                  <input
                    type="number"
                    min="0"
                    max="59"
                    id="minutes"
                    className="form-control minutesfield"
                    name="minutes"
                    placeholder="MM"
                    defaultValue={"01"}
                    value={
                      (typeof timer === "string" ? timer : "00:00").split(
                        ":"
                      )[0]
                    }
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const newMinutes = parseInt(inputValue, 10);
                      if (
                        !isNaN(newMinutes) &&
                        newMinutes >= 0 &&
                        newMinutes <= 59
                      ) {
                        setQuizTimer(
                          `${String(newMinutes).padStart(2, "0")}:${(timer ?? "00:00").split(":")[1] || "00"
                          }`
                        );
                      }
                    }}
                  />

                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "12px",
                      marginLeft: "3px",
                    }}
                  >
                    :
                  </div>

                  <input
                    type="number"
                    min="0"
                    max="59"
                    id="seconds"
                    className="form-control secondsfield"
                    name="seconds"
                    placeholder="SS"
                    defaultValue={"00"}
                    value={
                      (typeof timer === "string" ? timer : "00:00").split(
                        ":"
                      )[1]
                    }
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const newSeconds = parseInt(inputValue, 10);
                      if (
                        !isNaN(newSeconds) &&
                        newSeconds >= 0 &&
                        newSeconds <= 59
                      ) {
                        setQuizTimer(
                          `${(timer ?? "00:00").split(":")[0]}:${String(
                            newSeconds
                          ).padStart(2, "0")}`
                        );
                      }
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions></DialogActions>
              <div style={{ marginLeft: "482px", marginTop: "15px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitTimer}
                >
                  CLOSE
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </>
    );
  };

  return (
    <div>
      <div>
        <div className={classes.root}>
          <AppBar
            style={{ backgroundColor: "white", height: "70px" }}
            elevation={2}
          >
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h6"
                noWrap
                style={{ marginTop: "2.5px", color: "black" }}
              >
                {formDeatils.name}
              </Typography>
              <Tabs
                style={{
                  marginBottom: "57px",
                  paddingLeft: "42%",
                }}
                className={classes.title}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Questions" />
                {/* <Tab label="Responses" /> */}
              </Tabs>
              <Tooltip title="Add More section" arrow>
                <button className="addSectionButton" onClick={addMoreSection}>
                  <RiPlayListAddLine
                    size={25}
                    fontWeight={800}
                    style={{ marginRight: "2px", color: "#3a3630" }}
                  />{" "}
                </button>
              </Tooltip>
              <Tooltip title="Edit Timer field" arrow>
                <button
                  className="testTimer mx-3"
                  onClick={handleClickOpenTimer}
                >
                  <LuTimer
                    size={25}
                    style={{ letterSpacing: "1px", color: "#3a3630" }}
                  />
                </button>
              </Tooltip>

              <div className="mx-2">
                <button className="buttonSubmit" onClick={onSubmitHandle}>
                  SUBMIT TEST
                </button>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openOfAlert}
            autoHideDuration={3000}
            onClose={handleCloseOfAlert}
            message="Copied to clipboard"
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseOfAlert}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>

        <div>
          <TabPanel value={value} index={0}>
            <div>{EditTestFormUI()}</div>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <ResponsesTab />
          </TabPanel> */}
        </div>
      </div>
    </div>
  );
}
export default EditTestForm;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
