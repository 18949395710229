import React, { useRef, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import { useSelector } from "react-redux";
import Select from "react-select";
import TopicDropdown from "../Topics/TopicDropdown";
import { FcFilledFilter } from "react-icons/fc";
import ReactSwitch from "react-switch";
import "primereact/resources/themes/viva-light/theme.css"; //theme
import "primereact/resources/primereact.min.css";  //core
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
import Lottie from "lottie-react";
import Loading from "../Animations/99109-loading.json";
import Prototype from "../SkeletonLoader/Prototype";
import { Calendar } from 'primereact/calendar';
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import { BiSortAlt2 } from "react-icons/bi";
//imports date range picker
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";

const InductionAccessControl = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const [loading, setLoading] = useState(true);
  const [InductionData, setInductionData] = useState([]);
  const [DepartmentData, setDepartmentData] = useState([]);
  const [deptID, setDeptID] = useState([]);
  const [InductionID, setInductionID] = useState([]);
  const [alreadyAssigned, setAlreadyAssigned] = useState([]);
  const [checkedInstructorID, setCheckedInstructorID] = useState();
  const [prepopulatedCheckboxes, setPrepopulatedCheckboxes] = useState([]);
  const [filterDept, setFilterDept] = useState();
  const [subTopicList, setSubTopicList] = useState([]);
  const [selectedSubTopics, setSelectedSubTopics] = useState([]);
  const [subTopicSelectedID, setSubTopicSelectedID] = useState("");
  const [filterTopicID, setFilterTopicID] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isPrivateState, setIsPrivateState] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState();
  const [disable, setDisable] = useState([])

  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([])
  const [test, setTest] = useState([]);

  //sort function states
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState(null);

  const tokenDetailsString = localStorage.getItem('userDetails');
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      formattedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      createdByName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  //api integration for private Induction data
  async function getInductionData(token) {
    return fetch(`${API_ROOT_URL}/induction/get/all/private/induction`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const handlepageLoadEvent = async (event) => {
    const response = await getInductionData(token);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  //fetching on the basis of department
  async function filterTopic(topicID, deptID) {
    setFilterTopicID(topicID);
    setFilterDept(deptID);
    let filterInductionApiTopic = `${API_ROOT_URL}/induction/get/all/by/topicID/private/induction?topicID=${topicID}`;
    if (topicID === "all" && !deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/get/all/private/induction`;
    } else if (topicID === "all" && deptID) {
      filterInductionApiTopic = `${API_ROOT_URL}/induction/filter/department/listonly?filterByDepartment=${deptID}`;
    }
    return fetch(filterInductionApiTopic, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  //filter by topics dropdown
  const filterByTopic = async (e) => {
    setSelectedSubTopics([]);
    const response = await filterTopic(e, filterDept);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  // Subtopics MultiSelect Dropdown
  async function getSubtopicsMultiDropdown(topicID) {
    return fetch(
      `${API_ROOT_URL}/department/fetching/subtopics/multidropdown/by/topics/${topicID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
  }
  // Get Subtopics By Topic
  const handleTopicChange = async (e) => {
    setSubTopicSelectedID(e.target.value);
    const response = await getSubtopicsMultiDropdown(filterTopicID);
    if ("status" in response && response.status == true) {
      setSubTopicList(response.data);
      setLoading(false);
    }
    else {
      setSubTopicList([]);
    }
  };

  //filter by subtopic
  //fetching on the basis of department
  async function filterSubTopic(subtopicIDs, topicID) {
    let subtopicIDArray = subtopicIDs.map((subtopicID) => subtopicID.value);
    let filterInductionApiSubTopic = `${API_ROOT_URL}/induction/get/all/private/inductionby/subtopics`;
    if (topicID && subtopicIDArray.length === 0) {
      return fetch(
        `${API_ROOT_URL}/induction/get/all/by/topicID/private/induction?topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    } else if (subtopicIDs === "all") {
      return fetch(
        `${API_ROOT_URL}/induction/filter/by/topic/listonly?topicID=${topicID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      ).then((data) => data.json());
    }
    if (subtopicIDArray.length > 0) {
      return fetch(filterInductionApiSubTopic, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({ subtopicID: subtopicIDArray }),
      }).then((data) => data.json());
    }
  }
  //on the basis of subtopics filteration
  const filterBySubTopic = async (e) => {
    setSelectedSubTopics(e);
    const response = await filterSubTopic(e, filterTopicID);
    if ("status" in response && response.status == true) {
      setInductionData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  //api to get department list for dropdown
  async function getDepartmentData(token) {
    return fetch(`${API_ROOT_URL}/department/dropdown/multi`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }
  const handlepageLoad = async (event) => {
    const response = await getDepartmentData(token);
    if ("status" in response && response.status == true) {
      setDepartmentData(response.data);
      setLoading(false);
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  };

  //function for get permissions
  async function getSelectedID(deptID) {
    return fetch(
      `${API_ROOT_URL}/induction/get/permission/for/private/induction/${deptID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    ).then((data) => data.json());
  }

  const handlepageLoaded = async (deptID) => {
    try {
      const response = await getSelectedID(deptID);
      if ("status" in response && response.status === true) {
        setAlreadyAssigned(response.inductionIds);
        // Extracted the IDs of set permission records
        const assignedIDs = response.inductionIds.map((item) => item._id);
        // Set the prepopulated checkboxes based on the assigned IDs
        setPrepopulatedCheckboxes(assignedIDs);
        setLoading(false);
      } else {
        if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        }
        else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
          }).then(() => {
            dispatch(logout(props.history));
          });
        }
        else {
          setPrepopulatedCheckboxes([])
          setAlreadyAssigned([])
        }
      }
    } catch (err) {
      setAlreadyAssigned([]);
      setPrepopulatedCheckboxes([]);
      setCheckedItems([])
      setDisable([])
    }
  }

  function handleSelect(data) {
    setCheckedItems([]);
    setSelectedOptions(data);
    setDisable([data])
    setDeptID(data.value); // Set the selected DeptId 
  }

  // Created array of all prepopulated items
  const allPrepopulatedItems = [...prepopulatedCheckboxes, ...alreadyAssigned.map(item => item._id)];
  // Create an array of all unique items (prepopulated and checked)
  const allUniqueItems = Array.from(new Set([...allPrepopulatedItems, ...checkedItems]));

  const permissionDataArray = allUniqueItems.map(item => {
    const hasGivePermission = checkedItems.includes(item) || (prepopulatedCheckboxes.includes(item) && !isPrivateState);
    return {
      _id: item,
      givePermission: hasGivePermission
    };
  });

  const payload = {
    permissionData: permissionDataArray,
    deptID: deptID,
  };

  const payLoadData = payload?.permissionData.map((i) => i) || [];
  const dataset1 = alreadyAssigned.map((i) => i);
  const checkIdAndPermissionMatch = (payLoadData, dataset1) => {
    // Checking if the length of both arrays is same 
    if (dataset1.length !== payLoadData.length) {
      return false;
    }
    //checking if both arrays length are not same and user is assigning their first record
    if (dataset1.length === 0 && payLoadData.length >= 1) {
      return false;
    }
    // comparing _id and givePermission values 
    for (let i = 0; i < dataset1.length; i++) {
      const oldItem = dataset1.find((item) => item._id === payLoadData[i]._id);
      if (!oldItem || oldItem.givePermission !== payLoadData[i].givePermission) {
        return false;
      }
    }
    // If all _id values and givePermission values match, return true
    return true;
  };

  const result = checkIdAndPermissionMatch(payLoadData, dataset1);
  const onSubmit = async () => {
    setDisable([])
    try {
      const response = await fetch(
        `${API_ROOT_URL}/induction/give/or/withdraw/permission/private/induction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      if (response.ok && responseData.status === true) {
        Swal.fire({
          title: "Success",
          text: responseData.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        }).then((value) => {
          setPrepopulatedCheckboxes([]);
          setAlreadyAssigned([])
          setCheckedItems([]);
          setSelectedOptions([]);
          setDeptID([]);
        });
      } else {
        if (response?.error?.message === "jwt expired" || response?.devMessage === "userDeleted" || response?.message === "Authentication Required") {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else {
          return swal("Failed", "Error message", "error");
        }
      }
    } catch (error) {
      swal("Error", "An error occurred while processing your request.", "error");
    }
  };

  const renderHeader = () => {
    return (
      <>
        <div className="card-header flex-wrap p-1 mb-3">
          <h2 className="mt-0 text-white" style={{ letterSpacing: "3px" }}>
            {" "}
            <FcFilledFilter style={{ marginBottom: "5px" }} /> FILTERS
          </h2>
          <Row>
            <Col lg="6" className="mb-3">
              <select
                name="topicID"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                className="select btn"
                onChange={(e) => filterByTopic(e.target.value)}
                onClick={handleTopicChange}
              >
                <option value="all">TOPIC</option>
                <TopicDropdown deptID={filterDept} createOnly={true} />
              </select>
            </Col>
            <Col lg="6" className="mb-3">
              <Select
                options={subTopicList || []}
                className="fw-900 fs-14"
                placeholder="SUBTOPIC"
                onChange={filterBySubTopic}
                isSearchable={true}
                isMulti
                value={selectedSubTopics}
                noOptionsMessage={() => "NO SUBTOPICS"}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg="3" className="mb-3">
            <Select
              options={DepartmentData}
              value={selectedOptions}
              placeholder="SELECT DEPARTMENT"
              onChange={handleSelect}
              noOptionsMessage={() => "Loading"}
            />
          </Col>
          <Col lg="4">
            {
              result ?
                <>
                  <button
                    style={{ cursor: "not-allowed" }}
                    className="greyoutbutton"
                  >
                    SUBMIT
                  </button>
                </>
                :
                <>
                  <button
                    className="submitCheckButton mb-3"
                    type="submit"
                    onClick={onSubmit}
                    style={{ letterSpacing: "1px" }}
                  >
                    SUBMIT
                  </button>
                </>
            }
          </Col>

          <Col lg="5">
            <div className="d-flex justify-content-end">
              <span className="p-input-icon-left">
                <i className="fa fa-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Keyword Search"
                />
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const header = renderHeader();

  //Function to check if the record exists already or not and (prepopulation)
  const handleCheck = (value, checked) => {
    if (prepopulatedCheckboxes.includes(value)) {
      setPrepopulatedCheckboxes(prevPrepopulatedCheckboxes =>
        prevPrepopulatedCheckboxes.filter(item => item !== value)
      );
    } else {
      if (checked) {
        setCheckedItems(prevCheckedItems => [...prevCheckedItems, value]);
      } else {
        setCheckedItems(prevCheckedItems =>
          prevCheckedItems.filter(item => item !== value)

        );

      }
    }
  }

  //getting the already checked values
  const getCheckedValues = () => {
    const checkedValues = InductionData.filter((item) =>
      checkedItems.includes(item._id)
    );
    return checkedValues;
  };

  const createdDateTemplate = (modifiedAccess) => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(modifiedAccess.createdAt).toLocaleDateString("en-GB", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>
      </>
    );
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(InductionData)
  }

  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
  }

  useEffect(() => {
    const modifiedAccess = InductionData.map((data) => {
      const formattedDate = new Date(data.createdAt)
      return { ...data, formattedDate };
    });
    setModifiedData(modifiedAccess)
  }, [InductionData])

  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(InductionData)
  };

  const handleSelectFilter = (ranges) => {
 
    let filtered = InductionData.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }

      // Check if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Update state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };


  const dateRangePickerTemp = () => {

    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelectFilter}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>
    )
  }

  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    //function to demonstrate the result of getCheckedValues
    const displayCheckedValues = () => {
      const checkedValues = getCheckedValues();
      setInductionID(checkedValues.map((i) => i._id));
    };

    displayCheckedValues();
  }, [checkedItems]);

  useEffect(() => {
    const gettingAllChecked = () => {
      setCheckedInstructorID(alreadyAssigned.map((i) => i._id));
    };

    gettingAllChecked();
  }, []);

  useEffect(() => {
    if (deptID) {
      handlepageLoaded(deptID);
    }
  }, [deptID]);

  useEffect(() => {
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      handlepageLoad();
      handlepageLoaded(deptID);
      handlepageLoadEvent();
      getInductionData(token);
    }
  }, [loading, tokenDetailsString]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-3">
              <div className="table-responsive">
                {loading ? <Prototype /> : (<DataTable
                  value={modifiedData.map((rowData, index) => ({
                    ...rowData,
                    serialNumber: index + 1,
                  }))}
                  header={header}
                  dataKey="id"
                  emptyMessage={
                    <div className="queryContainer">
                      {" "}
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                          <div className="form-input-content text-center">
                            <h1 className="error-text font-weight-bold">
                              <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                            </h1>
                            <h3
                              className="fs-28 mt-3"
                              style={{ color: "#3A3630", fontWeight: "900" }}
                            >
                              {" "}
                            </h3>
                            <h4 style={{ fontStyle: "italic" }}>
                              No Records Found !
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  filters={filters}
                  globalFilterFields={["title", "createdByName"]}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  size="large"
                  stripedRows
                  showGridlines
                  tableStyle={{ minWidth: "50rem" }}
                  filterDisplay="menu"
                >
                  <Column
                    header="Sr.No" headerStyle={{ width: "5%" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>
                  <Column
                    field="title"
                    style={{ width: "40%" }}
                    sortable
                    header="INDUCTION"
                    filterField="title" 
                    filter filterPlaceholder="Search by Title"

                  ></Column>
                  <Column
                    field="createdAt"
                    style={{ width: "11%" }}
                    body={createdDateTemplate}
                    header={<>
                      <div>
                        <Row className="textFilter">
                          <Col sm="10">
                            <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                              CREATED ON
                              {" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>

                          </Col>
                          <Col sm="2">
                            <CiFilter className="" size={22} onClick={openDateRangePicker} />
                          </Col>
                        </Row>
                      </div>

                      {showDateRangePicker && (
                        <Modal className="customModal d-flex"
                          show={openDateRangePicker}
                          size="sm"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered>
                          <div className="modal-header customModalHeader">
                            <h6 className="modal-title text-white fs-20">
                            {" "}SELECT DATE / DATE RANGE
                            </h6>
                            <div className="cross-outer-icon">
                              <RxCross1
                                className="cross-icon"
                                size={22}
                                color="white"
                                onClick={closeDateRangePicker}
                              />
                            </div>

                          </div>
                          <div className="modal-content customModalContent">

                            <div>
                              {dateRangePickerTemp()}
                            </div>
                          </div>
                          <Modal.Footer className="d-flex modal-footer customModalFooter">
                            <button className="clearButton" onClick={handleClear}>Clear</button>
                            <button className="applyButton" onClick={handleApply}>Apply</button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </>}
                  />
                  <Column
                    field="createdByName"
                    sortable
                    header="CREATED BY"
                    filterField="createdByName" 
                    style={{ width: '25%' }} 
                    filter 
                    filterPlaceholder="Search by Name"
                  ></Column>
                  <Column
                    field="isPrivateState"
                    header="Permission" 
                    sortable
                    body={(item) => (
                      <div>
                        <ReactSwitch
                          className="switch-react"
                          value={item._id}
                          checked={
                            prepopulatedCheckboxes.includes(item._id) ||
                            checkedItems.includes(item._id)
                          }
                          disabled={disable.length == 0}
                          onChange={(checked) => handleCheck(item._id, checked)}
                        />
                      </div>
                    )}
                    style={{ textAlign: "start",width: '10%' }}
                  />
                </DataTable>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InductionAccessControl;