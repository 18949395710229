import React from "react";
import ReactApexChart from "react-apexcharts";

const BarGraph = ({ data }) => {
  const categories = data.map((item) => item.section_name);
  const averageCorrectCounts = data.map((item) => `${(item.average_correct_count * 100).toFixed(1)}%`);
  
  // ApexCharts options and series
  const options = {
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "30%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      title: {
        text: "Section",
      },
      categories: categories,
      //tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "Average section score",
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  };

  const series = [
    {
      name: "Average Score",
      data: averageCorrectCounts.map(value => `${value}%`),
    },
  ];
  

  return (
    <div>
      <div id="combined-chart">
        <ReactApexChart options={options} series={series} type="bar" height={250} />
      </div>
    </div>
  );
};


export default BarGraph;
