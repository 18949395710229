import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Tooltip from "@mui/material/Tooltip";
import { API_ROOT_URL } from "../../jsx/constants";
import swal from "sweetalert";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { CSVLink } from "react-csv";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { PiExportBold } from "react-icons/pi";
import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PropTypes from "prop-types";
import BarGraph from "./BarGraph";
import useDashboardUrl from "../../utilis/useDashboardUrl";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import Inductions from "./../../jsx/components/Animations/72264-certificate.json";
import { ColumnGroup } from "primereact/columngroup";
import Zoom from '@mui/material/Zoom';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function ResponsesTab(props) {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const [endUsersData, setEndUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [IsLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  //datatable
  const [testDetails, setTestDetails] = useState();
  const [csvData, setCSVdata] = useState([]);
  const [isCSVReady, setIsCSVReady] = useState(false);
  const [totalAttempts, setTotalAttempts] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [barGraphScore, setBarGraphScore] = useState([]);
  const dt = useRef(null);
  const { id } = useParams();
  const navigate = useHistory();
  const dashboardUrl = useDashboardUrl(role);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getUsersData(token) {
    return fetch(`${API_ROOT_URL}/v3/tests/fetch-result/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  async function getTestDetials(token) {
    return fetch(`${API_ROOT_URL}/v3/tests/fetch-tests-details/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const GetAllData = async (event) => {
    setIsLoading(true);
    try {
      const response = await getUsersData(token);
      if ("status" in response && response.status === true) {
        setIsLoading(false);
        setBarGraphScore(response.breifScore);
        setEndUsersData(response.data);
        setTotalUsers(response.total_users);
        setTotalAttempts(response.total_attempts);
      } else if (response?.devMessage === "revokedPermisision") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Permission Has been Revoked",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          navigate.push(dashboardUrl);
        });
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTestData = async (token) => {
    const test_Details = await getTestDetials(token);
    setTestDetails(test_Details);
  };

  const getCSVdata = async (testDetails) => {
    if (testDetails) {
      const csv_data = [
        [
          "Roll Number",
          "Name",
          ...(testDetails.data?.flatMap((test) => {
            const questionHeaders = [];
            for (let i = 0; i < test?.sections?.length; i++) {
              const sectionHeader = test?.sections[i]?.section_name;
              for (let j = 0; j < test?.sections[i]?.questions.length; j++) {
                questionHeaders.push(`${sectionHeader} - Q${j + 1}`);
              }
            }
            return questionHeaders;
          }) || []),
          "Correct Answers",
          "Wrong Answers",
          "Total Questions",
        ],
        ...endUsersData
          .slice(1)
          .map(
            ({
              user_details,
              combineData,
              total_correct,
              total_wrong,
              total_questions,
            }) => {
              const userRow = [
                user_details?.roll_no,
                user_details?.name,
                ...(testDetails.data.flatMap((test) => {
                  const sectionQuestions = [];
                  for (let i = 0; i < test?.sections?.length; i++) {
                    for (
                      let j = 0;
                      j < test?.sections[i]?.questions.length;
                      j++
                    ) {
                      const question = test.sections[i].questions[j];
                      if (
                        combineData.some(
                          (section) =>
                            section.section_id === test.sections[i]._id &&
                            section.question.some(
                              (q) => q.question_id === question._id
                            )
                        )
                      ) {
                        const userResponse = combineData
                          .find(
                            (section) =>
                              section.section_id === test.sections[i]._id
                          )
                          ?.question.find(
                            (q) => q.question_id === question._id
                          );
                        sectionQuestions.push(
                          userResponse.response_status ? 1 : 0
                        );
                      } else {
                        sectionQuestions.push("NA");
                      }
                    }
                  }
                  return sectionQuestions;
                }) || []),
                total_correct,
                total_wrong,
                total_questions,
              ];

              return userRow;
            }
          ),
      ];
      setCSVdata(csv_data);
    }
    setIsCSVReady(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await GetAllData();
      await getTestData(token);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (testDetails) {
      getCSVdata(testDetails);
    }
  }, [testDetails]);

  const cols = [
    { field: "user_details.roll_no", header: "Roll Number" },
    { field: "user_details.name", header: "Name" },

    ...(endUsersData[0]?.section_results?.map((result, index) => {
      return {
        field: `section_results.${index}.correct_count`,
        style: {
          fontWeight: "bold",
          color: "currentColor",
          textAlign: "center",
        },
      };
    }) || []),

    {
      field: "total_correct",
      style: { fontWeight: "bold", color: "currentColor", textAlign: "center" },
    },
    {
      field: "total_wrong",
      style: { fontWeight: "bold", color: "currentColor", textAlign: "center" },
    },
    {
      field: "total_skipped",
      style: { fontWeight: "bold", color: "currentColor", textAlign: "center" },
    },
    {
      field: "total_questions",
      style: { fontWeight: "bold", color: "currentColor", textAlign: "center" },
    },
  ];

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  

  const header = (
    <>
      <div className="EndUserTable">
        <Row>
          <Col lg="8">
            <h3 style={{ color: "white", letterSpacing: "1px" }}>
              USER RESULT
            </h3>
          </Col>
          <Col lg="4">
            {endUsersData.length > 0 ? (
              <>
                <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                  <div className="exportfeature">
                    <CSVLink
                      className="linkcsv"
                      filename="my-file.csv"
                      data={csvData}
                    >
                      <Tooltip
                        title="Excel sheet key : Correct Answer = 1, Wrong Answer = 0, NA = Not Attempted"
                        arrow
                        TransitionComponent={Zoom}
                      >
                        {" "}
                        Export to CSV{" "}
                      </Tooltip>

                      <PiExportBold
                        size={20}
                        style={{ fontWeight: "800", marginBottom: "5px" }}
                      />
                    </CSVLink>
                  </div>
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );

  const _exporter = React.createRef();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Roll Number" rowSpan={3} />
        <Column header="Name" rowSpan={3} />
        <Column
          header="Number of Correct Answer"
          className="correctCount"
          headerStyle={{
            fontWeight: "bold",
            color: "currentColor",
            textAlign: "center",
          }}
          colSpan={endUsersData[0]?.section_results?.length || 1}
        />
        <Column header="Total Corrects" rowSpan={2} field="total_correct" />
        <Column header="Total Wrong" rowSpan={2} field="total_wrong" />
        <Column header="Skipped Questions" rowSpan={2} field="total_skipped" />
        <Column header="Total Questions" rowSpan={2} field="total_questions" />
      </Row>
      <Row>
        {endUsersData[0]?.section_results?.map((result, index) => {
          // const correctCount =
          //   result.correct_count !== undefined ? result.correct_count : "NA";
          const correctCount =
            result.correct_count !== undefined ? result.correct_count : "NA";
          const field =
            result.correct_count !== undefined
              ? `section_results.${index}.correct_count`
              : "NA";
          return (
            <Column
              key={`section_results_${index}`}
              header={`${result.section_name}`}
              field={field}
              // field={`section_results.${index}.correct_count`}
              style={{
                fontWeight: "bold",
                color: "currentColor",
                textAlign: "center",
              }}
              correctCount={correctCount}
            />
          );
        }) || []}
      </Row>
    </ColumnGroup>
  );

  const ResponseTab = () => {
    return (
      <div className="mt-3">
        <div style={{ minHeight: "100vh", backgroundColor: "whitesmoke" }}>
          <p> Responses</p>
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="mx-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper style={{ width: "100%" }}></Paper>
                <div>
                  <Paper style={{ width: "100%" }}>
                    <div
                      className="mt-1"
                      style={{
                        width: "400px",
                        height: "370px",
                        borderRadius: "3px",
                      }}
                    >
                      <div className="card-body">
                        <div className="student-box d-flex justify-content-between align-items-center flex-wrap">
                          <div>
                            <span
                              className="student-icon"
                              style={{ backgroundColor: "#ff9a9a" }}
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="#b1c8ec"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.1665 19.1666C17.8331 19.1666 20.8331 16.1666 20.8331 12.4999C20.8331 8.83325 17.8331 5.83325 14.1665 5.83325C10.4998 5.83325 7.49981 8.83325 7.49981 12.4999C7.49981 16.1666 10.4998 19.1666 14.1665 19.1666ZM21.6665 17.6666C24.4998 19.9999 28.6665 19.4999 30.9998 16.6666C33.3331 13.8333 32.8331 9.66659 29.9998 7.33325C28.8331 6.33325 27.3331 5.83325 25.8331 5.83325C24.3331 5.83325 22.8331 6.33325 21.6665 7.33325C24.4998 9.66659 24.9998 13.6666 22.6665 16.6666C22.4998 16.9999 21.9998 17.3333 21.6665 17.6666ZM38.1665 32.3333C37.1665 26.1666 31.9998 21.6666 25.8331 21.6666C24.3331 21.6666 22.9998 21.8333 21.6665 22.3333C25.9998 23.8333 29.1665 27.6666 29.8331 32.1666C29.9998 32.9999 29.3331 33.9999 28.3331 33.9999H28.1665H36.4998C37.4998 33.9999 38.1665 33.3333 38.1665 32.3333C38.1665 32.4999 38.1665 32.3333 38.1665 32.3333ZM14.1665 21.6666C7.99981 21.6666 2.66647 26.1666 1.83314 32.3333C1.66647 33.1666 2.33314 34.1666 3.33314 34.1666H3.49981H24.8331C25.8331 34.1666 26.4998 33.4999 26.4998 32.4999V32.3333C25.6665 26.1666 20.3331 21.6666 14.1665 21.6666Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                            <div
                              style={{
                                color: "#464a53",
                                fontSize: "35px",
                                fontWeight: "700",
                              }}
                            >
                              {totalAttempts ? totalAttempts : "0"}{" "}
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#464a53",
                                }}
                              >
                                Responses
                              </span>{" "}
                              / {totalUsers ? totalUsers : "0"}{" "}
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#464a53",
                                }}
                              >
                                Total Users
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: "50%",
                            }}
                          >
                            <Lottie
                              style={{ height: 180 }}
                              animationData={Inductions}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper style={{ width: "600px" }}>
                  <div style={{ padding: "30px" }}>
                    <div class="container-result">
                      <h3
                        className="mt-1"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Section Average Scores
                      </h3>
                    </div>
                    <BarGraph data={barGraphScore} />
                  </div>
                </Paper>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip target=".export-buttons>button" position="bottom" />
            <div
              className="col-xl-12"
              style={{ paddingLeft: "10%", paddingRight: "10%" }}
            >
              <div className="card students-list">
                <div className="card-body p-3">
                  <div className="table-responsive">
                    <DataTable
                      headerColumnGroup={headerGroup}
                      ref={dt}
                      value={endUsersData.slice(1)}
                      showGridlines
                      header={header}
                      tableStyle={{ minWidth: "80rem" }}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      size="large"
                      stripedRows
                    >
                      {cols.map((col, index) => (
                        <Column
                          key={index}
                          field={col.field}
                          style={col.style}
                          // header={col.header}
                        />
                      ))}
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className={classes.root}>
          <AppBar
            style={{ backgroundColor: "white", height: "70px" }}
            elevation={2}
          >
            <Toolbar className={classes.toolbar}>
              <Tabs
                style={{
                  marginBottom: "-26px",
                  paddingLeft: "42%",
                }}
                className={classes.title}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="RESPONSES" />
              </Tabs>

              <div className="mx-2"></div>
            </Toolbar>
          </AppBar>
        </div>
        <>
          <div>
            {IsLoading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                <TabPanel value={value} index={0}>
                  {<div>{ResponseTab()}</div>}
                </TabPanel>
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
}
export default ResponsesTab;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
