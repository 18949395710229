import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import ActionDropDown from "../Induction/components/ActionDropDownSlides";
import { getInduction } from "../APIs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import { MdFormatLineSpacing } from "react-icons/md";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const SlidesList = ({ Slides, deleteSlide, setSlides, setModalOpen }) => {
  const navigate = useHistory();
  const { id } = useParams(); // this is question id
  const editor = useRef(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.auth.token);
  const userRole = useSelector((state) => state.auth.auth.role);
  const [idSlide, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inductionIdDelete, setInductionID] = useState("");
  const [isReordered, setIsReordered] = useState(false);

  const intialState = {
    slideId: "",
    slideInductionId: "",
    slideTitle: "",
    slideContent: "",
  };
  const [state, setState] = useState(intialState);

  const trackOnclick = (slide, _id) => {
    setId(_id);
    // if (idSlide) {
    setIsModalOpen(true);
    // }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setState(intialState);
    setId(" ");
  };

  const getSlideData = async (idSlide, token) => {
    return await fetch(`${API_ROOT_URL}/slides/getbyslideid/${idSlide}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  };

  // get slide data by id
  const callGetApi = async (idSlide, token) => {
    const response = await getSlideData(idSlide, token);
    if ("status" in response && response.status === true) {
      setState(response.data);
      setLoading(false);
    }
  };

  const callingAPI = async (inductionID, token) => {
    setInductionID(inductionID);
    const response = await getInduction(inductionID, token);
    if ("status" in response && response.status === true) {
      setSlides(response?.slides);
      setLoading(false);
    }
  };

  const handleSubmit = async (e, closeModalState) => {
    e.preventDefault();
    if (!state.slideTitle.trim()) {
      // Fixed the if condition
      return swal("Failed", "Slide title cannot be empty", "error");
    }
    const response = await fetch(`${API_ROOT_URL}/slides/${idSlide}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(state),
    }).then((data) => data.json());

    if ("status" in response && response.status === true) {
      return swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        callingAPI(id, token);
        if (closeModalState) {
          setIsModalOpen(false);
        }
      });
    } else {
      if (
        response?.error?.message === "jwt expired" ||
        response?.message === "Authentication Required"
      ) {
        swal("Session Timeout ! ", "Please Login Again", "error").then(
          () => { }
        );
      } else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer:
            '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
        }).then(() => {
          // dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", response?.message, "error");
      }
    }
  };

  const UpdateSlideData = async () => {

    const savedSlideData = JSON.parse(localStorage.getItem("slideDetails"));

    if (savedSlideData && savedSlideData._id === idSlide) {
      await fetch(`${API_ROOT_URL}/slides/${idSlide}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(savedSlideData),
      }).then((data) => data.json());
    }
  }

  // Update localStorage whenever state changes
  const updateLocalStorage = (newState) => {
    setState(newState);
    localStorage.setItem("slideDetails", JSON.stringify(newState));
  };

  useEffect(() => {
    if (isModalOpen) {
      UpdateSlideData();

      const intervalId = setInterval(UpdateSlideData, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isModalOpen) {
        UpdateSlideData();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isModalOpen]);


  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(Slides);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSlides(items);
    setIsReordered(true);
  };

  const reorderSlide = async (data) => {
    try {
      const response = await axios.patch(
        `${API_ROOT_URL}/slides/slides/order`,
        data
      );
    } catch (error) {
      console.error("Error updating data:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while reordering slides.",
      });
    }
  };

  const handleReorderSubmit = () => {
    const updatedPayload = Slides.map((slide, index) => ({
      _id: slide._id,
      slideOrder: index + 1,
    }));

    Swal.fire({
      icon: "success",
      title: "Slides Reordered",
      text: "The slides have been successfully reordered.",
    });
    reorderSlide(updatedPayload);

    setIsReordered(false); // Reset the state after submitting the reorder
  };

  useEffect(() => {
    callGetApi(idSlide, token);
  }, [idSlide]);

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      className="popoverSection"
    >
      <strong className="align-text-center">Drag to Reorder slides</strong>
      <p>You can drag and drop the slides to change their order.</p>
    </Popover>
  );

  function renderButton(text, bgColor, iconColor, onClickHandler) {
    return (
      <button
        className="btn mt-2"
        style={{
          backgroundColor: bgColor,
          color: "white",
          borderRadius: "66px",
        }}
        onClick={onClickHandler}
      >
        <span
          className="btn-icon-start text-info"
          style={{ borderRadius: "20px" }}
        >
          <i className="fa fa-plus color-info" style={{ color: iconColor }}></i>
        </span>
        {text}
      </button>
    );
  }

  function renderReorderButton(onClickHandler, isReordered) {
    return (
      <button
        className="reorder mt-2 mb-3"
        onClick={onClickHandler}
        disabled={!isReordered}
        style={{
          backgroundColor: isReordered ? "#1cc248" : "#91c8a0",
          cursor: isReordered ? "pointer" : "not-allowed",
          marginTop: Slides.length === 1 ? "2rem" : "0",
        }}
      >
        <span className="d-flex justify-content-center align-items-center">
          <MdFormatLineSpacing size={18} style={{ marginRight: "5px" }} />{" "}
          Reorder
        </span>
      </button>
    );
  }

  return (
    <div>
      <div className="card-header" id="slides">
        <span className="d-flex">
          <h3>SLIDES</h3>
          {Slides.length > 1 && (
            <>
              <OverlayTrigger
                className="mt-3"
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={popoverBottom}
              >
                <div className="icon mx-2" style={{ marginTop: "5px" }}>
                  <i
                    className="fa fa-info mx-2 pb-2"
                    style={{ fontSize: "10px" }}
                  />
                </div>
              </OverlayTrigger>
            </>
          )}
        </span>

        <Row>
          {Slides.length > 1 ? (
            <>
              <Col lg="6">
                {renderButton("Add Slide", "#FEBA30", "#3A3630", () =>
                  setModalOpen(true)
                )}
              </Col>
              <Col lg="6">
                {renderReorderButton(handleReorderSubmit, isReordered)}
              </Col>
            </>
          ) : (
            <Col lg="12">
              {renderButton("Add Slide", "#FEBA30", "#3A3630", () =>
                setModalOpen(true)
              )}
            </Col>
          )}
        </Row>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <table
                className="table display mb-4 dataTablesCard order-table card-table text-black application border table-responsive"
                id="application-tbl1_next"
              >
                <thead>
                  <tr>
                    {Slides.length > 1 && (
                      <>
                        <th></th>
                        <th>Order</th>
                      </>
                    )}
                    <th>Slide Title</th>
                    <th>Created On</th>
                    <th style={{ textAlign: "end" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Slides?.map((row, index) => (
                    <Draggable
                      key={row._id}
                      draggableId={row._id}
                      index={index}
                      isDragDisabled={Slides.length <= 1}
                    >
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {Slides.length > 1 && (
                            <>
                              <td>
                                <MdFormatLineSpacing
                                  style={{ cursor: "move" }}
                                />
                              </td>
                              <td>{index + 1}</td>
                            </>
                          )}
                          <td>
                            <div className="d-flex align-items-center">
                              <h4 className="mb-0 fs-16 font-w500">
                                {row?.slideTitle || "N/A"}
                              </h4>
                            </div>
                          </td>
                          <td>
                            {row
                              ? new Date(row.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )
                              : "N/A"}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {row?.slideTitle && (
                              <ActionDropDown
                                trackOnclick={trackOnclick}
                                profileData={row}
                                deleteSlide={deleteSlide}
                                isFirstSlide={index === 0}
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
              </table>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isModalOpen && (
        <div className="modal">
          {/* Add your modal content here */}
          <div className="modal-content">
            <span className="close" onClick={closeModal}></span>

            <Modal
              className="modal fade d-flex"
              show={isModalOpen}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <div className="modal-content" style={{ width: "60vw" }}>
                <div className="modal-header">
                  <h2
                    className="text-white"
                    style={{ fontSize: "25px", fontWeight: "900px" }}
                  >
                    <AiOutlineAppstoreAdd color="#E7EEF0" /> UPDATE
                    <span
                      className="fs-25 font-w700 text-center"
                      style={{ color: "#FEBA30" }}
                    >
                      {" "}
                      SLIDE
                    </span>
                  </h2>
                  <RxCross1 size={25} color="white" onClick={closeModal} />
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">
                            Slide Title{" "}
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              onChange={(e) => updateLocalStorage({
                                ...state,
                                slideTitle: e.target.value,
                              })}
                              name="slideTitle"
                              value={state.slideTitle}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">
                            Slide Content
                          </label>
                          <div
                            className="col-sm-9"
                            style={{ maxHeight: "500px", overflowY: "auto" }}
                          >
                            <JoditEditor
                              name="slideContent"
                              rows={10}
                              ref={editor}
                              value={state.slideContent}
                              tabIndex={2}
                              onBlur={(newContent) => updateLocalStorage({
                                ...state,
                                slideContent: newContent,
                              })}
                              onChange={(newContent) =>
                                updateLocalStorage({
                                  ...state,
                                  slideContent: newContent,
                                })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="" style={{ textAlign: "end" }}>
                        <button
                          className="btn btn-dark"
                          type="submit"
                          onClick={(e) => handleSubmit(e, false)}
                          style={{ borderRadius: "66px", marginRight: "10px" }}
                        >
                          Save Work
                        </button>
                        <button
                          className="btn btn-success sw-btn-next"
                          type="submit"
                          onClick={(e) => handleSubmit(e, true)}
                          style={{ borderRadius: "66px" }}
                        >
                          Update Slide
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlidesList;
