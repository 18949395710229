import Lottie from "lottie-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import InductionsByCompany from "../../components/Inductions/components/InductionsByCompany";
import { API_ROOT_URL } from "../../constants";
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
import CompanyDropdown from "../Companies/CompanyDropdown";
import UserAttemptedInductioList from "../Modals/UserAttemptedInductioList";
//theme
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { Skeleton } from "primereact/skeleton";
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import AttemptsTable from "../SkeletonLoader/AttemptsTable";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department",
};

const InductionUsers = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const userID = useSelector((state) => state.auth.auth.id);
  const role = useSelector((state) => state.auth.auth.role);
  const loginUser = useSelector((state) => state.auth.auth.id);
  const parentCompany = useSelector((state) => state.auth.auth.parentCompany);
  const userRole = useSelector((state) => state.auth.auth.role);
  const loggedInID = useSelector((state) => state.auth.auth.id);
  const [companyFilter, setCompanyFilter] = useState();
  const [hasDropdown, setHasDropdown] = useState(true);
  const [deptID, setDeptID] = useState();
  const [inductionFilter, setInductionFilter] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]); // main listing data
  const [userPopupData, setUserPopupData] = useState();

  const tokenDetailsString = localStorage.getItem('userDetails');
  // callback function to opdate state
  const trackOnclick = (payload, userData) => {
    setIsModalOpen(payload);
    if (userData) {
      setUserPopupData(userData);
    }
  };

  const hidePopUp = () => {
    setIsModalOpen(false);
  };

  const handlepageLoad = async (e) => {
    // query string
    let queryStr = "";
    queryStr = companyFilter
      ? `?company=${companyFilter}`
      : USER_ROLES.COMPANY === role
        ? deptID === undefined ? `?company=${loginUser}` :
          `?company=${loginUser}&deptID=${deptID}` :
        USER_ROLES.DEPARTMENT === role
          ? `?company=${parentCompany}`
          : USER_ROLES.INSTRUCTOR === role
            ? `?company=${parentCompany}`
            : `?super_admin=${userID}`;
    queryStr += inductionFilter ? `&induction=${inductionFilter}` : "";

    const response = await fetch(`${API_ROOT_URL}/induction/users${queryStr}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status == true) {
      setUsers(response.data);
      setLoading(false);
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  // use effect
  useEffect(() => {
    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      handlepageLoad();
    }
  }, [companyFilter, inductionFilter, deptID, tokenDetailsString]);
  //css for button
  const buttonStyle = {
    margin: "auto",
    display: "flex",
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      'inductions.title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const companyFilterHandle = (e) => {
    setCompanyFilter(e.target.value);
    setInductionFilter("All");
  };

  const deptFilterHandle = (e) => {
    setDeptID(e.target.value);
    setInductionFilter("All");
  }

  const inductionFilterHandle = (e) => {
    setInductionFilter(e.target.value);
  };

  const modifiedAttempts = users.map((user) => {
    const { profile } = user;
    const fullName = (user.profile.first_name + " " + user.profile.last_name)
    return { ...user, fullName };
  });


  const actionTemplate = (userData) => {

    const onClickHandler = (userData) => {
      if (localStorage.getItem("userDetails") === null) {
        swal("Something went wrong! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        })
      } else {
        trackOnclick(true, userData);
      }
    };

    return (
      <>
        <div className="d-flex">
          <button
            className="ViewAll"
            type="button"
            onClick={() => onClickHandler(userData)}
          >
            {" "}
            View All
          </button>
        </div>
      </>
    );
  };

  const fullNameTemplate = (modifiedAttempts) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <div>
            {modifiedAttempts.fullName}
          </div>
        </div>
      </>
    );
  };
  const renderHeader = () => {
    return (
      <>
        {USER_ROLES.COMPANY === role ? (
          <>
            <Row>
              <Col lg="2">
                <select
                  name="deptID"
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn mb-3"
                  onChange={(e) => deptFilterHandle(e)}
                >
                  <option value="all">DEPARTMENT</option>
                  <GetDepartmentByComp parentCompany={userID} />
                </select>
              </Col>
              <Col lg="2">
                <select
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn mb-3"
                  name="induction_filter"
                  onChange={(e) => inductionFilterHandle(e)}
                >
                  {/* <option>SELECT INDUCTION</option> */}
                  <option value="All">INDUCTION</option>
                  <InductionsByCompany hasDropdown={hasDropdown} parentCompany={loggedInID} deptID={deptID} />
                </select>
              </Col>
              <Col lg="8">
                <div className="d-flex justify-content-end">
                  <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder="Keyword Search"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </>
        ) : null}
        {USER_ROLES.DEPARTMENT === role ? (
          <>
            <Row>
              <Col lg="2">
                <select
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn mb-3"
                  name="induction_filter"
                  onChange={(e) => inductionFilterHandle(e)}
                >
                  <option value="All">INDUCTION</option>
                  <InductionsByCompany parentCompany={loggedInID} hasDropdown={hasDropdown} />
                </select>
              </Col>
              <Col lg="10">
                <div className="d-flex justify-content-end">
                  <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder="Keyword Search"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </>
        ) : null}
        {USER_ROLES.INSTRUCTOR === role ? (
          <>
            <Row>
              <Col lg="2">
                <select
                  style={{ borderRadius: "4px", backgroundColor: "white" }}
                  className="select btn mb-3"
                  name="induction_filter"
                  onChange={(e) => inductionFilterHandle(e)}
                >
                  {/* <option>SELECT INDUCTION</option> */}
                  <option value="All">INDUCTION</option>
                  <InductionsByCompany parentCompany={parentCompany} hasDropdown={hasDropdown} />
                </select>
              </Col>
              <Col lg="10">
                <div className="d-flex justify-content-end">
                  <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder="Keyword Search"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </>
        ) : null}
        {USER_ROLES.SUPER_ADMIN === role ? (
          <Row>
            <Col lg="2">
              <select
                name="company_filter"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                className="select btn mb-3"
                onChange={(e) => companyFilterHandle(e)}
              >
                <option value="All">COMPANY</option>
                <CompanyDropdown />
              </select>
            </Col>
            <Col lg="2">
              <select
                name="induction_filter"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                className="select btn mb-3"
                onChange={(e) => inductionFilterHandle(e)}
              >
                <option value="All">INDUCTION</option>
                <InductionsByCompany parentCompany={companyFilter} hasDropdown={hasDropdown} />
              </select>
            </Col>
            <Col lg="8">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}
      </>
    );
  };
  const header = renderHeader();

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-3">
              <div className="table-responsive">
                {USER_ROLES.SUPER_ADMIN === userRole ||
                  USER_ROLES.COMPANY === userRole ||
                  USER_ROLES.INSTRUCTOR === userRole ? (
                  <div>
                    {loading ? (<AttemptsTable />) : (<DataTable
                      value={modifiedAttempts.map((rowData, index) => ({
                        ...rowData,
                        serialNumber: index + 1,
                      }))}
                      header={header}
                      dataKey="id"
                      emptyMessage={
                        <div className="queryContainer">
                          {" "}
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                              <div className="form-input-content text-center">
                                <h1 className="error-text font-weight-bold">
                                  <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                                </h1>
                                <h3 className="fs-28 mt-3" style={{ color: "#3A3630", fontWeight: "900" }}></h3>
                                <h4 style={{ fontStyle: "italic" }}>No Records Found !</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      filters={filters}
                      globalFilterFields={[
                        "fullName",
                        "inductions.title",
                        "total"
                      ]}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      size="large"
                      stripedRows
                      showGridlines
                      tableStyle={{ minWidth: "50rem" }}
                      filterDisplay="menu"
                    >
                      <Column
                        header="Sr.No" headerStyle={{ width: "5%" }}
                        body={(data, options) => options.rowIndex + 1}
                      ></Column>
                      <Column
                        field="fullName"
                        body={fullNameTemplate}
                        sortable
                        header="NAME"
                        filterField="fullName" 
                        style={{ minWidth: '25%' }} 
                        filter
                         filterPlaceholder="Search by Name"
                      ></Column>
                      <Column
                        field="inductions.title"
                        sortable
                        header="INDUCTION"
                        filterField="inductions.title" 
                        style={{ minWidth: '30%' }} 
                        filter filterPlaceholder="Search by Title"
                      ></Column>
                      <Column
                        field="total"
                        sortable
                        header="ATTEMPTED INDUCTIONS"
                        style={{ width: "18%" }}
                      ></Column>
                      <Column
                        body={actionTemplate}
                        header="ACTIONS"
                        style={{ width: "10%" }}
                      ></Column>
                    </DataTable>
                    )}
                  </div>
                ) : null}

                {USER_ROLES.DEPARTMENT === userRole ? (
                  <div>
                    {loading ? (<AttemptsTable />) : (<DataTable
                      value={modifiedAttempts.map((rowData, index) => ({
                        ...rowData,
                        serialNumber: index + 1,
                      }))}
                      header={header}
                      dataKey="id"
                      emptyMessage={
                        <div className="queryContainer">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-12">
                              <div className="form-input-content text-center">
                                <h1 className="error-text font-weight-bold">
                                  <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                                </h1>
                                <h3 className="fs-28 mt-3" style={{ color: "#3A3630", fontWeight: "900" }}></h3>
                                <h4 style={{ fontStyle: "italic" }}>No Records Found !</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      filters={filters}
                      globalFilterFields={[
                        "fullName",
                        "inductions.title",
                        "total"
                      ]}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      size="large"
                      stripedRows
                      showGridlines
                      tableStyle={{ minWidth: "50rem" }}
                      filterDisplay="menu"
                    >
                      <Column
                        header="Sr.No" headerStyle={{ width: "3rem" }}
                        body={(data, options) => options.rowIndex + 1}
                      ></Column>
                      <Column
                        field="fullName"
                        body={fullNameTemplate}
                        sortable
                        header="NAME"
                        filterField="fullName" style={{ minWidth: '12rem' }} filter filterPlaceholder="Search by Name"
                      ></Column>
                      <Column
                        field="inductions.title"
                        sortable
                        header="INDUCTION"
                        filterField="inductions.title" style={{ minWidth: '12rem' }} filter filterPlaceholder="Search by Title"
                      ></Column>
                      <Column
                        field="total"
                        sortable
                        header="ATTEMPTED INDUCTIONS"
                        style={{ width: "18%" }}
                      ></Column>
                      <Column
                        body={actionTemplate}
                        header="ACTIONS"
                        style={{ width: "10%" }}
                      ></Column>
                    </DataTable>
                    )}
                  </div>
                ) : null}

                {isModalOpen ? (
                  <UserAttemptedInductioList
                    isModalOpen={isModalOpen}
                    hidePopUp={hidePopUp}
                    userPopupData={userPopupData}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InductionUsers;
