import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { logout } from "../../../../store/actions/AuthActions";
import { API_ROOT_URL } from "../../../constants";
import { atom, useAtom } from "jotai";
export const fakePayloadQuestions = atom([]);

const AddQuestion = ({
  inductionID,
  isShowAddQuestion,
  onClickHandler,
  history,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const token = useSelector((state) => state.auth.auth.token);

  const intialState = {
    inductionID: inductionID,
    question: "",
    answer: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  };

  const [formState, setFormState] = useState(intialState);
  let errorObj = {
    question: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: "",
  };
  const [errors, setErrors] = useState(errorObj);

  // New state to hold the fake payload of questions
  const [fakePayload, setFakePayload] = useAtom(fakePayloadQuestions);

  const onFormSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    var errorObj1 = { ...errorObj };

    const options = [
      formState.option1,
      formState.option2,
      formState.option3,
      formState.option4,
    ];

    if (formState.question === "") {
      errorObj1.question = "Question is required!";
      error = true;
    }

    options.forEach((option, index) => {
      if (option === "") {
        errorObj1[`option${index + 1}`] = `Option ${index + 1} is required`;
        error = true;
      } else if (options.indexOf(option) !== index) {
        errorObj1[`option${index + 1}`] = `Option ${index + 1} is a duplicate!`;
        error = true;
      }
    });

    if (formState.answer === "") {
      errorObj1.answer = "Answer is required";
      error = true;
    } else if (!options.includes(formState.answer)) {
      errorObj1.answer =
        "Answer is not matched with any option! Please verify again!";
      error = true;
    }

    setErrors(errorObj1);
    if (error) return;

    // If no errors, update the fake payload with the new question
    setFakePayload((prevPayload) => [...(prevPayload ?? []), formState]);

    setFormState(intialState);

    // Close the modal by calling onClickHandler
    onClickHandler();
  };

  // on keypress remove validations
  function handleKeyPress(e) {
    var key = e.key;
    if (key == key) {
      setErrors(errorObj == false);
    }
  }


  useEffect(() => {
    setErrors(errorObj);
  }, [isShowAddQuestion]);

  return (
    <Modal
      className="modal fade d-flex"
      show={isShowAddQuestion}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content" style={{ width: "60vw" }}>
        <div className="modal-header">
          <h5 className="modal-title">Add Question !</h5>
          <RxCross1 size={25} color="white" onClick={onClickHandler} />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => onFormSubmit(e)}>
            <div className="card-body">
              <div className="basic-form">
                <h4 className="mb-1">Enter Question</h4>
                <input type="hidden" name="inductionID" value={inductionID} />

                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="2"
                    id="comment"
                    name="question"
                    placeholder="Enter question ...."
                    onChange={(e) => {
                      setFormState({ ...formState, question: e.target.value });
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                  >
                    {formState.question}
                  </textarea>
                  {errors.question && (
                    <div Style="color:red;font-weight:400">
                      {errors.question}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="form-group ">
                  <h4 className="mb-3">Enter your Choices</h4>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="option1"
                      className="form-control input-rounded"
                      placeholder="Option 1"
                      onChange={(e) => {
                        setFormState({ ...formState, option1: e.target.value });
                      }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                    {errors.option1 && (
                      <div Style="color:red;font-weight:400">
                        {errors.option1}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="option2"
                      className="form-control input-rounded"
                      placeholder="Option 2"
                      onChange={(e) =>
                        setFormState({ ...formState, option2: e.target.value })
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                    {errors.option2 && (
                      <div Style="color:red;font-weight:400">
                        {errors.option2}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="option3"
                      className="form-control input-rounded"
                      placeholder="Option 3"
                      onChange={(e) =>
                        setFormState({ ...formState, option3: e.target.value })
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                    {errors.option3 && (
                      <div Style="color:red;font-weight:400">
                        {errors.option3}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="option4"
                      className="form-control input-rounded"
                      placeholder="Option 4"
                      onChange={(e) =>
                        setFormState({ ...formState, option4: e.target.value })
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                    {errors.option4 && (
                      <div Style="color:red;font-weight:400">
                        {errors.option4}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="answer"
                      className="form-control input-rounded"
                      placeholder="Correct Answer"
                      onChange={(e) =>
                        setFormState({ ...formState, answer: e.target.value })
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                    {errors.answer && (
                      <div Style="color:red;font-weight:400">
                        {errors.answer}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="submitButtonInduction"
                  style={{ display: "flex", margin: "auto" }}
                  type="submit"
                >
                  Add Question
                </button>{" "}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default AddQuestion;
