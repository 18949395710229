import React, { Fragment, useEffect, useRef, useState } from "react";
// import Rte from "./Rte";
import JoditEditor from "jodit-react";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import LoadingSpinner from "../../pages/LoadingSpinner";
import CompanyDropdown from "../Companies/CompanyDropdown";
import "../Dashboard/submit.css";
import GetDepartmentByComp from "../Department/GetDeptByCompany";
import TopicDropdown from "../Topics/TopicDropdown";
import ReactSwitch from "react-switch";
import { Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { BsXCircleFill } from "react-icons/bs";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department",
};

const CreateInduction = (props) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  // const userRole = useSelector((state) => state.auth.auth.role);
  const token = useSelector((state) => state.auth.auth.token);
  const userID = useSelector((state) => state.auth.auth.id);
  const userParentCompany = useSelector(
    (state) => state.auth.auth.parentCompany
  );
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [searchDepartment, setSearchDepartment] = useState();
  const [option, setOption] = useState();
  const [inductionDesc, setInductionDesc] = useState();
  const [slideTitle, setSlideTitle] = useState("");
  const [slideContent, setSlideContent] = useState("");
  const [subTopicList, setSubTopicList] = useState("");
  const [newCompressedImage, setNewCompressedImage] = useState("");
  const [formValues, setFormValues] = useState([
    { slideTitle: "", slideContent: "" },
  ]);

  //deselect
  const [showClearButton, setShowClearButton] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const tokenDetailsString = localStorage.getItem("userDetails");
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();
  const FormStates = watch();

  //function to set type of induction
  const selectTypeChange = (val) => {
    setIsPrivate(val);
  };

  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  };
  const handleClearButtonClick = () => {
    setImage({ preview: "", data: "" });
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };

  const editor = useRef(null);
  const id = useSelector((state) => state.auth.auth.id);
  const role = useSelector((state) => state.auth.auth.role);

  // validation messages
  let errorObj = {
    title: "",
    subTitle: "",
    deptID: "",
    slideTitle: "",
    slideContent: "",
    TopicID: "",
    subtopicID: "",
  };
  // const [errors, setErrors] = useState(errorObj);

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setSlideTitle(newFormValues);
    setSlideContent(newFormValues);
    setFormValues(newFormValues);
  };

  // change department
  const DepartmentChangeFilter = (e) => {
    setSearchDepartment(e.target.value);
  };

  // const handleJoditEditorChange = (index, newContent) => {
  //   console.log(newContent,"newcontent")
  //   const arrayImages = []
  //   arrayImages.push(newContent)
  //   console.log(arrayImages,"arrayImages")
  //   let newFormValues = [...formValues];
  //   newFormValues[index]["slideContent"] = newContent;
  //   setFormValues(newFormValues);
  // };

  const handleJoditEditorChange = (index, newContent) => {

    const contentArray = newContent.split("<br>");

    const arrayImages = contentArray.filter((part) => part.includes("<img"));

    if (arrayImages.length > 1) {
      Swal.fire({
        title: "Warning",
        text: "You won't be able to add more than 1 image!",
        icon: "warning",
      });
    }
    let newFormValues = [...formValues];
    newFormValues[index]["slideContent"] = newContent;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { slideTitle: "", slideContent: "" }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const { title, subTitle, parentCompany, deptID, TopicID, subtopicID } =
    FormStates;

  const onSubmit = async (e) => {
    const inductionDetail = {
      isPrivate: isPrivate,
      title: title,
      subTitle: subTitle,
      description: inductionDesc,
      content: "",
      topicID: TopicID,
      subtopicID: subtopicID,
    };

    const inductionDetailAdmin = {
      isPrivate: isPrivate,
      title: title,
      subTitle: subTitle,
      deptID: deptID,
      description: inductionDesc,
      content: "",
      parentCompany: parentCompany,
      topicID: TopicID,
      subtopicID: subtopicID,
    };

    let formData = new FormData();
    if (role === USER_ROLES.DEPARTMENT || role === USER_ROLES.INSTRUCTOR) {
      formData.append("induction", JSON.stringify(inductionDetail));
    } else {
      formData.append("induction", JSON.stringify(inductionDetailAdmin));
    }
    formData.append("thumbnail", image.data);


    // formData.append('slides', JSON.stringify(formValues));
    let slides_json = [];
    for (var i = 0; i < formValues.length; i++) {
      slides_json.push(formValues[i]);
    }
    formData.append("slides", JSON.stringify(slides_json));

    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      });
    } else {
      const response = await fetch(`${API_ROOT_URL}/induction/store`, {
        method: "POST",
        headers: {
          "x-access-token": token,
        },
        body: formData,
      }).then((data) => data.json());

      if ("status" in response && response.status == true) {
        return swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).then((value) => {
          // return <Navigate to="/inductions" />;
          navigate.push("/inductions");
        });
      } else {
        if (
          response?.error?.message === "jwt expired" ||
          response?.message === "Authentication Required"
        ) {
          swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "userDeleted") {
          Swal.fire({
            title: "Access Denied",
            text: "Your Account Has been deleted",
            icon: "error",
            confirmButtonColor: "#3a3630",
            confirmButtonText: "OK",
            footer:
              '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>',
          }).then(() => {
            dispatch(logout(props.history));
          });
        } else if (response?.devMessage === "slideTitle_duplicates") {
          return swal("Failed", "Oops! Duplicate Slide Titles", "error");
        } else {
          return swal("Failed", response?.message, "error");
        }
      }
    }
  };

  const handleCompanyChange = (e) => {
    const selectedCompany = e.target.value;
    setValue("parentCompany", selectedCompany);
    setValue("deptID", "");
  };

  // call api to fetch departments
  const handleTopicChange = async (e) => {
    //console.log("topic change")
    //console.log(TopicID, "topicid")
    setSubTopicList([]);
    const response = await fetch(
      `${API_ROOT_URL}/department/fetching/subtopicsby/topics/${TopicID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        //body: JSON.stringify({ TopicID: e.target.value }),
      }
    ).then((data) => data.json());

    if ("status" in response && response.status == true) {
      const rows = response.data.subtopics.map((row, index) => (
        <option value={row._id}>{row.value}</option>
      ));
      setSubTopicList(rows);
    }
  };

  useEffect(
    (e) => {
      if (loading) {
        // Simulating API request success
        setTimeout(() => {
          // Set form values using setValue
          setValue("parentCompany", parentCompany);
          setValue("userID", userID);
          setValue("userParentCompany", userParentCompany);
          setLoading(false);
          setValue("deptID");
          setValue("TopicID");
          setValue("subtopicID");
        });
      }
    },
    [loading, setValue]
  );

  const handleDepartmentDropdown = async () => {
    setValue("TopicID", "");
    setSubTopicList([]);
    setOption(" ");
    const response = await fetch(
      `${API_ROOT_URL}/department/getDepartmentByComp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({ parentCompany: parentCompany }),
      }
    ).then((data) => data.json());

    if ("status" in response && response.status == true) {
      const rows = response.data.map((row, index) => (
        <option value={row?._id}>{row?.name}</option>
      ));
      setOption(rows);
    }
  };

  useEffect(() => {
    if (loading) {
      if (role === USER_ROLES.COMPANY) {
        //setParentCompany(userID);

        setValue("parentCompany", "userID");
      }
      if (role === USER_ROLES.INSTRUCTOR) {
        // setParentCompany(userParentCompany);
      }
      if (parentCompany) {
        handleDepartmentDropdown();
      }
      setLoading(false);
    }
    handleDepartmentDropdown();
  }, [parentCompany, image, deptID]);

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      className="popoverSection"
    >
      
      <strong>
      Image size{" "}
        {" "}
        must not exceed {" "}
        <span style={{color:"red"}}>
        1 MB.
          </span>
      </strong>
    </Popover>
  );

  const pageContent = loading ? (
    <LoadingSpinner />
  ) : (
    <Fragment>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="cardheader">
            <h4
              className="card-title text-white"
              style={{ fontSize: "25px", fontWeight: "900px" }}
            >
              <AiOutlineAppstoreAdd color="#E7EEF0" />
              <span className="font-w700"> CREATE </span>
              <span
                className="fs-25 font-w700 text-center"
                style={{ color: "#FEBA30" }}
              >
                {" "}
                INDUCTION
              </span>
            </h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    isPrivate?{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <div className="col-sm-9">
                    <div className="d-flex">
                      <ReactSwitch
                        checked={isPrivate}
                        onChange={selectTypeChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Title{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder=""
                      {...register("title", {
                        required: true,
                        pattern: /^[a-z A-Z 0-9]*$/,
                        validate: {
                          noSpecialChars: (value) => {
                            return !/[^a-zA-Z0-9 ]/.test(value);
                          },
                        },
                      })}
                    />
                    {errors.title && errors.title.type === "required" && (
                      <p style={{ color: "red" }}>Title is required</p>
                    )}
                    {errors.title && errors.title.type === "pattern" && (
                      <p style={{ color: "red" }}>
                        Special Characters are not allowed
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Sub Title{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="subTitle"
                      placeholder=""
                      {...register("subTitle", {
                        required: true,
                        pattern: /^[a-z A-Z 0-9]*$/,
                        validate: {
                          noSpecialChars: (value) => {
                            return !/[^a-z A-Z 0-9 ]/.test(value);
                          },
                        },
                      })}
                    />
                    {errors.subTitle && errors.subTitle.type === "required" && (
                      <p style={{ color: "red" }}>Sub-title is required</p>
                    )}
                    {errors.subTitle && errors.subTitle.type === "pattern" && (
                      <p style={{ color: "red" }}>
                        Special Characters are not allowed
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Thumbnail{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <div className="col-sm-9">
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        name="thumbnail"
                        onChange={handleFileChange}
                        accept="image/png,image/jpeg,image/jpg"
                      />
                      {image && (
                        <div style={{ marginTop: 10 }}>
                          <img src={image.preview} width="150" />
                          {showClearButton && (
                            // <button
                            //   type="button"
                            //   className="btn btn-sm btn-danger m-5"
                            //   onClick={handleClearButtonClick}
                            // >
                            //   Clear
                            // </button>
                            <BsXCircleFill
                              size={25}
                              style={{
                                marginBottom: "160px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        </div>
                      )}
                      {errors.thumbnail && (
                        <div
                          style={{ color: "red", fontWeight: 600, padding: 5 }}
                        >
                          {errors.thumbnail}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {USER_ROLES.SUPER_ADMIN === role ? (
                  <div className="form-group mb-3">
                    <div className="mb-4 row">
                      <label className="col-sm-3 col-form-label">
                        Company{" "}
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          name="parentCompany"
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "white",
                          }}
                          className="select btn"
                          onClick={handleCompanyChange}
                          value={parentCompany}
                          {...register("parentCompany", {
                            required: true,
                          })}
                        >
                          <option value="">COMPANY</option>
                          <CompanyDropdown />
                        </select>
                        {errors.parentCompany && (
                          <p style={{ color: "red" }}>Company is Required</p>
                        )}
                      </div>
                    </div>

                    <div className="mb-4 row">
                      <label className="col-sm-3 col-form-label">
                        Department{" "}
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          name="deptID"
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "white",
                          }}
                          className="select btn"
                          // onChange={(e) => setDeptID(e.target.value)}
                          value={deptID}
                          {...register("deptID", {
                            required: true,
                          })}
                        >
                          <option value="">DEPARTMENT</option>
                          <GetDepartmentByComp
                            parentCompany={parentCompany}
                            prevSelected=""
                          />
                        </select>

                        {errors.deptID && (
                          <p style={{ color: "red" }}>Department is Required</p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                {USER_ROLES.COMPANY === role ? (
                  <div className="mb-4 row">
                    <label className="col-sm-3 col-form-label">
                      Department{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="deptID"
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "white",
                        }}
                        className="select btn"
                        {...register("deptID", {
                          required: true,
                        })}
                      >
                        <option value="">DEPARTMENT</option>
                        <GetDepartmentByComp
                          parentCompany={parentCompany}
                          prevSelected=""
                        />
                      </select>
                      {errors.deptID && (
                        <p style={{ color: "red" }}>Department is Required</p>
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="form-group mb-3">
                  <div className="mb-4 row">
                    <label className="col-sm-3 col-form-label">
                      Topic{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="TopicID"
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "white",
                        }}
                        className="select btn"
                        onClick={handleTopicChange}
                        {...register("TopicID", {
                          required: true,
                        })}
                      >
                        <option value="">TOPIC</option>
                        <TopicDropdown
                          deptID={deptID}
                          setValue={setValue}
                          createOnly={true}
                        />
                      </select>
                      {errors.TopicID && (
                        <p style={{ color: "red" }}>Topic is Required</p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <label className="col-sm-3 col-form-label">
                      Sub-Topic{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="subtopicID"
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "white",
                        }}
                        className="select btn"
                        {...register("subtopicID", {
                          required: true,
                        })}
                      >
                        <option value="">SUB-TOPIC</option>
                        {subTopicList}
                      </select>
                      {errors.subtopicID && (
                        <p style={{ color: "red" }}>Sub-Topic is Required</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    About induction{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <div className="col-sm-9">
                    <JoditEditor
                      ref={editor}
                      value={inductionDesc}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setInductionDesc(newContent)}
                      onChange={(newContent) => {
                        setInductionDesc(newContent);
                      }}
                    />
                  </div>
                </div>

                <div className="card-header">
                  <h4 className="card-title">Induction Slides</h4>
                </div>

                {formValues.map((element, index) => (
                  <div className="card-body" key={index}>
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Slide Title{" "}
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          onChange={(newContent) =>
                            handleChange(index, newContent)
                          }
                          name="slideTitle"
                          value={element.slideTitle}
                        />
                        {errors.slideTitle && (
                          <div Style="color:red;font-weight:400;padding:5px;">
                            {errors.slideTitle}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Slide Content{" "}
                        <span
                          className="required-field"
                          style={{ color: "red", fontWeight: "200px" }}
                        >
                          *
                        </span>
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="bottom"
                          overlay={popoverBottom}
                        >
                          <div className="icon mx-2">
                            <i
                              className="fa fa-info mx-2"
                              style={{ fontSize: "10px" }}
                            />
                          </div>
                        </OverlayTrigger>
                      </label>
                      
                      <div className="col-sm-9">
                        <JoditEditor
                          ref={editor}
                          value={element.slideContent}
                          tabIndex={2}
                          onBlur={(newContent) =>
                            handleJoditEditorChange(index, newContent)
                          }
                          onChange={(newContent) => {
                            handleJoditEditorChange(index, newContent);
                          }}
                          name="slideContent"
                        />
                        {errors.slideContent && (
                          <div Style="color:red;font-weight:400;padding:5px;">
                            {errors.slideContent}
                          </div>
                        )}
                      </div>
                    </div>
                    {index ? (
                      <div className="mb-12 row">
                        <div className="col-sm-12">
                          <button
                            type="button"
                            className="RemoveSlide"
                            onClick={() => removeFormFields(index)}
                          >
                            Remove
                          </button>{" "}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}

                <div className="basic-form">
                  <div className="form-group ">
                    <button
                      className="CreateInductionButton"
                      size="m"
                      style={{
                        display: "flex",
                        float: "right",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>

                    <button
                      style={{
                        display: "flex",
                        float: "right",
                      }}
                      type="button"
                      className="AddSlide"
                      onClick={() => addFormFields()}
                    >
                      Add New Slide
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

  return <Fragment>{pageContent}</Fragment>;
};

export default CreateInduction;
