import React, { lazy, Suspense, useEffect } from 'react';
/// Components
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import Index from "./jsx";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./css/style.css";
import { logout } from "./store/actions/AuthActions";

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));

const Login = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
    });
});

function App(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const tokenDetailsString = localStorage.getItem('userDetails');
    const currentPageURL = location.pathname.slice(0, location.pathname.lastIndexOf('/'));

    useEffect(() => {
        if (location.pathname === '/login' || location.pathname.includes('login')) {
            if (!tokenDetailsString) {
                dispatch(logout(props.history));
                return;
            }
            checkAutoLogin(dispatch, props.history, location.pathname);
        } else if (location.pathname === '/page-register' || location.pathname === '/forgot-password') {
        }
        else {
            checkAutoLogin(dispatch, props.history, location.pathname);
        }

    }, [dispatch, props.history, location.pathname, isAuthenticated, tokenDetailsString]);

    /*

    */
    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/forgot-password/' component={ForgotPassword} />
            <Route path='/reset-password/:token' component={ResetPassword} />
        </Switch>
    );
    if (props.isAuthenticated) {
            return (
                <>
                    <Suspense fallback={
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div>
                    }
                    >
                        <Index />
                    </Suspense>
                </>
            );
    } else {
        return (
            <div className="vh-100">

                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
}
;

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));
