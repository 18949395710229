import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API_ROOT_URL } from "../../constants";
import ActionDropDown from "../Inductions/ActionDropDown";
import UpdateMcq from "../Inductions/UpdateMcq";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import Query from "../Animations/138566-frequently-asked-question-faq.json";
import { useDispatch } from "react-redux";
import { fakePayloadQuestions } from "./Modals/AddQuestion";
import { useAtom } from "jotai"; // Import useAtom

const QuizList = ({ inductionID, updateQuesHandler, row, history, trackDeleteClick, setQuestions, questions }) => {
  const dispatch = useDispatch();
  const [mcqData, setMcqData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.auth.token);
  const [quesData, setQuesData] = useAtom(fakePayloadQuestions);
  const combinedData = [...(Array.isArray(questions) ? questions : []), ...quesData];
  const trackOnclick = (payload, pdata) => {
    if (pdata) {
      setMcqData(pdata);
    }
    setIsModalOpen(payload);
  };

  const handlepageLoad = async (e) => {
    const response = await fetch(`${API_ROOT_URL}/mcq/${inductionID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((user) => user.json());

    if ("status" in response && response.status === true) {
      setQuestions(response.data);
      setLoading(false);
    }
  };

  const queryStyle = {
    height: 250,
  };

  const handleRemoveQuestion = (indexToRemove) => {
    if (indexToRemove < questions.length) {
      // If the question is from the database, remove it from questions
      const updatedQuestions = [...questions];
      updatedQuestions.splice(indexToRemove, 1);
      setQuestions(updatedQuestions);
    } else {
      // If the question is from quesData, remove it from quesData
      const updatedQuesData = [...quesData];
      updatedQuesData.splice(indexToRemove - questions.length, 1);
      setQuesData(updatedQuesData);
    }
  };

  useEffect(() => {
    handlepageLoad();
  }, [isModalOpen]);

  return (
    <>
      {combinedData.length === 0 ? (
        <div className="queryContainer">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-12">
              <div className="form-input-content text-center">
                <h1 className="error-text font-weight-bold">
                  <Lottie style={queryStyle} animationData={Query} />
                </h1>
                <h3 className="fs-28 mt-3" style={{ color: "#3a3630", fontWeight: "900" }}></h3>
                <h4 style={{ fontStyle: "italic" }}>Please add questions to enhance the learning experience.</h4>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="table-responsive">
              <div className="dataTables_wrapper">
                <table className="table display mb-4 dataTablesCard order-table card-table text-black application border " id="application-tbl1_next">
                  <thead>
                    <tr>
                      <th style={{ maxWidth: "10%" }}>Sr.No</th>
                      <th style={{ maxWidth: "10%" }}>Question</th>
                      <th style={{ maxWidth: "10%" }}>Answer</th>
                      <th style={{ textAlign: "end" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {combinedData.map((rowData, index) => (
                      <tr key={index} className={quesData.includes(rowData) ? 'unadded-question' : ''}>
                        <td>{index + 1}</td>
                        <td>{rowData.question}</td>
                        <td>{rowData.answer}</td>
                        <td style={{ textAlign: "end" }}>
                          {questions?.includes(rowData) ? (
                            <ActionDropDown trackOnclick={trackOnclick} mcqData={rowData} row={rowData._id} trackDeleteClick={trackDeleteClick} />
                          ) : (
                            <>
                              <button className="deleteIcon" onClick={() => handleRemoveQuestion(index)}>
                                <i className="fa fa-trash"></i>
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
      {isModalOpen ? <UpdateMcq isModalOpen={isModalOpen} trackOnclick={trackOnclick} trackDeleteClick={trackDeleteClick} mcqData={mcqData} /> : null}
    </>
  );
};

export default QuizList;