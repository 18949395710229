import React, { Fragment, useState, useEffect } from "react";
import swal from "sweetalert";
import CompanyDropdown from "../Companies/CompanyDropdown";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { API_ROOT_URL, AWS_BUCKET_NAME, PROFILE_ASSETS_URL } from "../../constants";
import "../Dashboard/submit.css";
import Lottie from "lottie-react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { BsXCircleFill } from "react-icons/bs";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Calendar } from 'primereact/calendar';
import endmessageanimation from "../Animations/animation_lloy7bt3.json";
import { logout } from "../../../store/actions/AuthActions";
//theme
import "primereact/resources/themes/viva-light/theme.css";
import { useDispatch } from "react-redux";
//core
import "primereact/resources/primereact.min.css";
import RoleTable from "../SkeletonLoader/RoleTable";
import { BiSortAlt2 } from "react-icons/bi";

import { RxCross1 } from "react-icons/rx";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CiFilter } from "react-icons/ci";
import s3 from "../../../utilis/aws.config";

// api call
async function getDepartments(token) {
  return fetch(`${API_ROOT_URL}/department/getall`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  }).then((data) => data.json());
}

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  DEPARTMENT: "department",
};
const ListDepartments = (props) => {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.auth.id);
  const token = useSelector((state) => state.auth.auth.token);
  const role = useSelector((state) => state.auth.auth.role);
  const navigate = useHistory();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [parentCompany, setParentCompany] = useState();
  const [deptData, setdeptData] = useState([{ name: "" }]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalData, setModalData] = useState({ name: "" });
  const [editID, setEditID] = useState();
  const [aboutDepartment, setAboutDepartment] = useState("");
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState("");
  const [showClearButton, setShowClearButton] = useState("");
  const [isUserStatusChanged, setIsUserStatusChanged] = useState(false);
  const [companyID, setcompanyID] = useState();
  const [error, setError] = useState('');
  const [aboutError, setAboutError] = useState('');
  const tokenDetailsString = localStorage.getItem('userDetails');

  //date filter states
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [modifiedData, setModifiedData] = useState([])
  const [test, setTest] = useState([]);

  //sort function states
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [sortKey, setSortKey] = useState(null); // The key by which to sort the data

  // Create a state variable to track if any field is changed
  const [isFormChanged, setFormChanged] = useState(false);


  const actionHandler = (department) => {
    setIsModalOpen(true);
    // setModalData(department);
    setName(department?.profile?.name);
    setEmail(department.email);
    setAboutDepartment(department?.profile?.aboutDepartment);
    // setStatus(department.status);
    setImage(department?.profile?.profile_picture);
    setParentCompany(department.parentCompany);
    setEditID(department?.profile?._id);
    setPreview(department?.profile?.profile_picture);
  };

  // logo image uploader
  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
    setFormChanged(true); // Set formChanged to true when any field is changed
  };

  const handleNameChange = (enteredName) => {
    setName(enteredName);
    if (enteredName.trim() === '') {
      setError("Name can't be blank");
      setFormChanged(false); // Set form changed to false if the name is empty
    } else {
      setError('');
      setFormChanged(true);
    }
  };

  const handleAboutChange = (enteredAbout) => {
    setAboutDepartment(enteredAbout);
    setFormChanged(true);
    if (enteredAbout.trim() === '') {
      setAboutError("About can't be blank");
      setFormChanged(false);
    } else {
      setAboutError('');
      setFormChanged(true);
    }
  };

  // change status
  const changeUserStatus = (userID, status) => {
    // user id
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: `Once status Changed, User will get or loss access to account`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(`${API_ROOT_URL}/users/changeUserStatus`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify({ userID: userID, status: status }),
          }).then((data) => data.json());

          if ("status" in response && response.status == true) {
            swal("Poof! Your record has been updated!", {
              icon: "success",
            }).then(() => {
              setIsUserStatusChanged(!isUserStatusChanged);
            });
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal("Your status is not changed!");
        }
      });
    }
  };

  const deleteClick = (userID, deleted) => {
    // show delete confirmation msg
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      swal({
        title: "Are you sure?",
        text: `You want to delete this record ?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willChange) => {
        if (willChange) {
          const response = await fetch(
            `${API_ROOT_URL}/department/changeDeleteStatus`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
              body: JSON.stringify({ userID: userID, deleted: deleted }),
            }
          ).then((data) => data.json());
          if ("status" in response && response.status == true) {
            swal("Poof! Your record has been deleted!", {
              icon: "success",
            });
            handlepageLoad();
          } else {
            return swal("Failed", response.message, "error");
          }
        } else {
          swal("Your record is safe!");
        }
      });
    }
  };

  //Edit Department submit handler
  const onSubmitHandle = async (e) => {
    e.preventDefault();
    if (error || aboutError) {
      // Prevent form submission if there's an error
      return;
    }
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      let data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("profile_picture", image.data);
      data.append("aboutDepartment", aboutDepartment);
      data.append("image", preview);

      const response = await fetch(`${API_ROOT_URL}/department/edit/${editID}`, {
        method: "PUT",
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: data,
      }).then((data) => data.json());

      if ("status" in response && response.status == true) {
        setIsModalOpen(false);
        return swal("Success", response.message, "success");
      } else {
        return swal("Failed", response.message, "error");
      }
    }
  };

  const handleClearButtonClick = () => {
    setImage(false);
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };

  const handlepageLoad = async (event) => {
    const response = await getDepartments(token);
    if ("status" in response && response.status == true) {
      setdeptData(response.data);
      setLoading(false);
      setIsUserStatusChanged(false);
    } else {
      return swal("Failed", response.message, "error");
      setLoading(false);
    }

  };


  //filtering departments by company
  async function filterDepartmentsApi(companyID) {
    filterDepartmentsApi = `${API_ROOT_URL}/department/getall?parentCompany=${companyID}`;
    if (companyID === "all") {
      filterDepartmentsApi = `${API_ROOT_URL}/department/getall`;
    }
    return fetch(filterDepartmentsApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  }

  const filterByCompany = (companyID) => {
    setcompanyID(companyID)
    const handlepageLoadData = async (event) => {
      const response = await filterDepartmentsApi(companyID);
      if ("status" in response && response.status == true) {
        setdeptData(response.data);
        setLoading(false);
      } else {
        return swal(
          "Failed",
          "Something went wrong, please try again later.",
          "error"
        );
      }
    };
    handlepageLoadData();
  };

  useEffect(() => {
    if (localStorage.getItem("userDetails") === null) {
      swal("Something went wrong! ", "Please Login Again", "error").then(() => {
        dispatch(logout(props.history));
      })
    } else {
      handlepageLoad();
      setError('');
      //  actionHandler();
      setImage({ preview: "", data: "" });
      handlepageLoad();
      setFormChanged(false); // Reset the formChanged state when the data is loaded
    }
  }, [loading, isModalOpen, isUserStatusChanged, token, tokenDetailsString, props.history]);
  const style = {
    height: 150,
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'profile.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <>
        {USER_ROLES.SUPER_ADMIN === role ? (
          <Row>
            <Col lg="2">
              <select
                name="parentCompany"
                style={{ borderRadius: "4px", backgroundColor: "white" }}
                className="select btn mb-3"
                onChange={(e) => filterByCompany(e.target.value)}
              >
                <option value="all">COMPANY</option>
                <CompanyDropdown />
              </select>
            </Col>
            <Col lg="10">
              <div className="d-flex justify-content-end">
                <span className="p-input-icon-left">
                  <i className="fa fa-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                  />
                </span>
              </div>
            </Col>
          </Row>
        ) : null}

        {USER_ROLES.SUPER_ADMIN !== role ? (
          <div className="d-flex justify-content-end">
            <span className="p-input-icon-left">
              <i className="fa fa-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
            </span>
          </div>
        ) : null}
      </>
    );
  };
  const header = renderHeader();

  const statusTemplate = (rowData) => {
    const isActive = rowData?.status === true;
    return (
      <>
        <label class="switch">
          <input type="checkbox" id="togBtn" checked={isActive} to="/departments"
            onClick={() => changeUserStatus(rowData?._id, rowData?.status)}
          />
          <div class="slider round">
            <span class="on">Active</span>
            <span class="off">Inactive</span>
          </div>
        </label>
      </>
    );
  };

  const joinDateTemplate = (rowData) => {

    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-3">
          {new Date(rowData.createdAt).toLocaleDateString("en-GB", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>
      </>
    );

  };

  //function to open the calendar
  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
    setModifiedData(deptData)
  }

  //function to close the calendar
  const closeDateRangePicker = () => {
    setShowDateRangePicker(false);
    setStartDate(new Date());
    setEndDate(new Date());
  }

  //modify the data
  useEffect(() => {
    const modifiedDept = deptData.map((dept) => {
      const { profile } = dept;
      const formattedDate = new Date(dept.createdAt);
      const newStatus = dept.status === true ? 'active' : 'In-active';
      return { ...dept, formattedDate, newStatus };
    });
    setModifiedData(modifiedDept);
  }, [deptData]);

  const handleSelect = (ranges) => {

    let filtered = deptData.filter((data) => {
      let joinDate = new Date(data.createdAt);
      joinDate.setHours(0, 0, 0, 0);

      const isSameDate = joinDate.getTime() === ranges.selection.startDate.getTime();
      if (isSameDate) {
        return true;
      }
      // Checking if joinDate is within the selected date range
      return (
        joinDate >= ranges.selection.startDate &&
        joinDate <= ranges.selection.endDate
      );
    });
    //Updating state with selected start and end dates and filtered data
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setTest(filtered);

  };

  const handleApply = () => {
    const clone = [...test];
    setModifiedData(clone);
    closeDateRangePicker();
  };

  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setModifiedData(deptData)
  };

  const dateRangePickerTemp = () => {

    return (
      <>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          showDateDisplay={true}
          showSelectionPreview={true}
          showMonthAndYearPickers={true}
          months={1}
          direction="horizontal"
          editableDateInputs={false}
          calendars={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelect}
          onDismiss={closeDateRangePicker}
          dateDisplayFormat="dd-MMM-yyyy"
          showMonthArrow={true}
        />
      </>


    )
  }

  //sort function for join date
  const sortTable = (key) => {
    const sortedData = [...modifiedData];

    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setModifiedData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  const actionTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <button onClick={() => actionHandler(rowData)} className="editIcon">
          <i className="fas fa-pencil-alt" style={{ fontSize: "15px" }}></i>
        </button>
        <button
          onClick={() =>
            deleteClick(rowData.profile._id, rowData.profile.deleted)
          }
          className="deleteIcon"
        >
          <i className="fa fa-trash" style={{ fontSize: "17px" }}></i>
        </button>
      </div>
    );
  };

  useEffect(() => {
    initFilters();
  }, []);

  // Get the signed URL for the profile image
  const url = s3.getSignedUrl("getObject", {
    Bucket: AWS_BUCKET_NAME,
    Key: `${PROFILE_ASSETS_URL}/${preview}`,
  });


  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-body p-3">
              <div className="table-responsive">
                {loading ? <RoleTable /> : <DataTable
                  value={modifiedData.map((rowData, index) => ({
                    ...rowData,
                    serialNumber: index + 1,
                  }))}
                  header={header}
                  dataKey="id"
                  emptyMessage={
                    <div className="queryContainer">
                      {" "}
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                          <div className="form-input-content text-center">
                            <h1 className="error-text font-weight-bold">
                              <Lottie style={{ height: 150 }} animationData={endmessageanimation} />
                            </h1>
                            <h3
                              className="fs-28 mt-3"
                              style={{ color: "#3A3630", fontWeight: "900" }}
                            >
                              {" "}
                            </h3>
                            <h4 style={{ fontStyle: "italic" }}>
                              No Records Found !
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  filters={filters}
                  globalFilterFields={["profile.name", "email", "newStatus"]}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  size="large"
                  stripedRows
                  showGridlines
                  tableStyle={{ width: "100%" }}
                  filterDisplay="menu"
                >
                  <Column
                    header="Sr.No" headerStyle={{ width: "5%" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>
                  <Column field="profile.name"
                    sortable header="NAME"
                    filterField="profile.name"
                    style={{ width: '30%' }}
                    filter filterPlaceholder="Search by Name" />

                  <Column field="email"
                    sortable header="EMAIL"
                    filterField="email"
                    style={{ width: '25%' }}
                    filter
                    filterPlaceholder="Search by Email" />

                  <Column
                    field="createdAt"
                    body={joinDateTemplate}
                    filterField="formattedDate"
                    style={{ width: '10%' }}
                    header={<>
                      <div>
                        <Row className="textFilter">
                          <Col sm="10">
                            <button className="buttonJoinDate" onClick={() => sortTable('createdAt')} >
                              JOIN DATE
                              {" "}
                              <span>
                                <BiSortAlt2 size={22} />
                              </span>
                            </button>

                          </Col>
                          <Col sm="2">
                            <CiFilter className="" size={22} onClick={openDateRangePicker} />
                          </Col>
                        </Row>
                      </div>

                      {showDateRangePicker && (
                        <Modal className="customModal d-flex"
                          show={openDateRangePicker}
                          size="sm"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered>
                          <div className="modal-header customModalHeader">
                            <h6 className="modal-title text-white fs-20">
                              {" "}SELECT DATE / DATE RANGE
                            </h6>
                            <div className="cross-outer-icon">
                              <RxCross1
                                className="cross-icon"
                                size={22}
                                color="white"
                                onClick={closeDateRangePicker}
                              />
                            </div>

                          </div>
                          <div className="modal-content customModalContent">

                            <div>
                              {dateRangePickerTemp()}
                            </div>
                          </div>
                          <Modal.Footer className="d-flex modal-footer customModalFooter">
                            <button className="clearButton" onClick={handleClear}>Clear</button>
                            <button className="applyButton" onClick={handleApply}>Apply</button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </>}

                  />
                  <Column
                    field="status"
                    body={statusTemplate}
                    sortable
                    header="STATUS"
                    style={{ width: '10%' }}
                  />
                  <Column body={actionTemplate} header="ACTIONS"
                    style={{ width: '10%' }} />
                </DataTable>
                }
              </div>
            </div>
          </div>
        </div>
      </div>



      <Modal
        className="modal fade d-flex"
        show={isModalOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          className="modal-content "
          style={{
            width: "60vw",
            borderRadius: "4px",
            border: "1px solid #3A3630",
          }}
        >
          <div className="modal-header" style={{ backgroundColor: "#3A3630" }}>
            <h5 className="modal-title text-white fs-20">UPDATE DEPARTMENT</h5>
            <RxCross1
              size={25}
              color="white"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <div className="modal-body">
            <form className="update-form" onSubmit={onSubmitHandle}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">Name</label>
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => handleNameChange(e.target.value)}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      readOnly
                      style={{ backgroundColor: "lightgray" }}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3 ">
                    <label className="form-label fs-6 fw-bold">Logo</label>
                    <div className="instructors-media"></div>
                    <input type="hidden" value={preview} name="logo" />
                    <input
                      type="file"
                      className="form-control"
                      name="profile_picture"
                      onChange={handleFileChange}
                      accept="image/png,image/jpeg,image/jpg"
                    />
                  </div>
                </div>

                {image.data ?
                  <>
                    <div className="col-lg-6">
                      <div className="instructors-media">
                        <div style={{ marginTop: 10 }}>
                          <img src={image.preview} width={150} />
                          {showClearButton && (
                            <BsXCircleFill
                              size={25}
                              style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <div className="instructors-media">
                          <img
                            src={url}
                            className="img-thumbnail mb-3"
                            width={150}
                          />
                        </div>
                      </div>
                    </div>
                  </>

                }
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label fs-6 fw-bold">About</label>
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                    <textarea
                      rows={2}
                      className="form-control"
                      name="aboutDepartment"
                      placeholder=""
                      value={aboutDepartment}
                      onChange={(e) => handleAboutChange(e.target.value)}
                    />
                    {aboutError && <p style={{ color: 'red' }}>{aboutError}</p>}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input
                      type="submit"
                      value="Update Profile"
                      className={`submits ${isFormChanged ? 'enabled' : ''}`}
                      style={{ margin: "auto", display: "flex" }}
                      name="submit"
                      disabled={!isFormChanged}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default ListDepartments;
