import React, { Fragment, useState } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useForm } from "react-hook-form";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsXCircleFill } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "semantic-ui-react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { API_ROOT_URL } from "../../constants";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const AddDepartment = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.auth.token);
  const loggedrole = useSelector((state) => state.auth.auth.role);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();
  const formStates = watch();

  const { email, password, name, aboutDepartment } = formStates;

  const navigate = useHistory();
  const [image, setImage] = useState({ preview: "", data: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);

  const tokenDetailsString = localStorage.getItem('userDetails');
  const handleFileChange = async (e) => {
    const image = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(image);
    if (e.target.value) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  };

  const handleClearButtonClick = () => {
    setImage({ preview: "", data: "" });
    setShowClearButton(false);
    const inputElement = document.querySelector('input[type="file"]');
    inputElement.value = "";
  };

  const onSubmit = async () => {
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("password", password);
    data.append("profile_picture", image.data);
    data.append("aboutDepartment", aboutDepartment);

    if (!tokenDetailsString) {
      swal("Something went wrong! ", "Please Login Again", "error").then(()=>{
        dispatch(logout(props.history));
      })
    } else {
    const response = await fetch(`${API_ROOT_URL}/department/add`, {
      method: "POST",
      headers: {
        "x-access-token": token,
      },
      body: data,
    }).then((data) => data.json());

    if (response.status === true) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        navigate.push("/departments");
      });
    } else {
      if (response?.error?.message === "jwt expired" || response?.message === "Authentication Required") {
        swal("Session Timeout ! ", "Please Login Again", "error").then(() => {
          dispatch(logout(props.history));
        });
      }
      else if (response?.devMessage === "userDeleted") {
        Swal.fire({
          title: "Access Denied",
          text: "Your Account Has been deleted",
          icon: "error",
          confirmButtonColor: "#3a3630",
          confirmButtonText: "OK",
          footer: '<a href="mailto:info@bjsinduction.com?subject=Account Deletion Query">Contact Admin</a>'
        }).then(() => {
          dispatch(logout(props.history));
        });
      } else {
        return swal("Failed", "Error message", "error");
      }
    }
  }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      className="popoverSection"
    >
      Password must contain{" "}
      <strong>
        upper case, lower case, special char and minimum of 6 characters
      </strong>
    </Popover>
  );

  //css for button
  const buttonStyle = {
    margin: "auto",
    display: "flex",
    float: "right",
  };

  return (
    <Fragment>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="cardheader">
            <h2
              className="text-white"
              style={{ fontSize: "25px", fontWeight: "900px" }}
            >
              <AiOutlineAppstoreAdd color="#E7EEF0" />
              <span className="font-w700"> ADD </span>
              <span
                className="fs-25 font-w700 text-center"
                style={{ color: "#FEBA30" }}
              >
                {" "}
                DEPARTMENT
              </span>
            </h2>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Email{" "}
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>
                          Please Enter a valid Email
                        </p>
                      )}
                    </div>
                  </div>
                </Form.Group>

                <div className="mb-3 mt-3 row">
                  <label className="col-sm-3 col-form-label">
                    Password{" "}
                    <span className="required-field" style={{ color: "red" }}>
                      *
                    </span>
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={popoverBottom}
                    >
                      <div className="icon mx-2">
                        <i
                          className="fa fa-info mx-2"
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </OverlayTrigger>
                  </label>
                  <div className="col-sm-9">
                    <div className="input-group">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="name"
                        className="form-control"
                        autoComplete="new-password"
                        {...register("password", {
                          required: true,
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,10})/,
                        })}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="input-group-text" onClick={togglePasswordVisibility}>
                        {passwordVisible ? (
                          <FaEye size={20} />
                        ) : (
                          <FaEyeSlash size={20} />
                        )}
                      </span>
                    </div>
                    {errors.password && (
                      <p style={{ color: "red" }}>
                        Enter a valid password
                      </p>
                    )}
                  </div>
                </div>


                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Department Name{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      {...register("name", {
                        required: true,
                      })}
                    />
                    {errors.name && (
                      <div
                        style={{
                          color: "red",
                          padding: "5px",
                        }}
                      >Name Is required
                        {errors.name.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Profile Photo{" "}
                    <span
                      className="required-field"
                      style={{ color: "red", fontWeight: "200px" }}
                    >
                      *
                    </span>
                  </label>
                  <div className="col-sm-9">
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        name="profile_picture"
                        onChange={handleFileChange}
                        accept="image/png,image/jpeg,image/jpg"
                      />
                      {image.preview && (
                        <div style={{ marginTop: 10 }}>
                          <img src={image.preview} width="150" alt="Preview" />
                          {showClearButton && (
                            <BsXCircleFill
                              size={25}
                              style={{ marginBottom: "140px", color: "red", cursor: "pointer" }}
                              onClick={handleClearButtonClick}
                            />
                          )}
                        </div>
                      )}
                      {errors.profile_picture && (
                        <div
                          style={{ color: "red", fontWeight: 600, padding: 5 }}
                        >
                          {errors.profile_picture.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Form.Group>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      About Department
                      <span
                        className="required-field"
                        style={{ color: "red", fontWeight: "200px" }}
                      >
                        *
                      </span>
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control"
                        {...register("aboutDepartment", {
                          required: true,
                        })}
                      ></textarea>
                      {errors.aboutDepartment && (
                        <p style={{ color: "red" }}>
                          About Section is required!
                        </p>
                      )}
                    </div>
                  </div>
                </Form.Group>

                <div className="mb-12 row">
                  <div className="col-sm-12">
                    <button
                      className="submitButtonInduction"
                      type="submit"
                      style={buttonStyle}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddDepartment;
