import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../pages/LoadingSpinner";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  DEPARTMENT:"department",
  USER: "user",
  DEPARTMENT:"department"
};

const CurrentSlideView = (props) => {
  const [hasError, setHasError] = useState(false);
  //const [isLoading, setIsLoading] = useState(true);
  const [slideContent, setSlideContent] = useState(
    props.currentSlideContent.slideContent
  );

  const role = useSelector((state) => state.auth.auth.role);

  useEffect(() => {
      setSlideContent(props.currentSlideContent.slideContent);
  }, [props.currentSlideContent.slideContent]);

  const createMarkup = (theExactHtmlWithTag) => {
    return { __html: theExactHtmlWithTag };
  };

  const renderSlideContent = () => {
    return (
      <div
        className="card-body pt-0 pb-0 scrollable"
        style={{ maxHeight: USER_ROLES.USER === role ? "700px" : "570px", behavior: "smooth" }}
        id="slideDataDiv"
      >
          <div dangerouslySetInnerHTML={createMarkup(slideContent)} />
      </div>
    );
  };

  return (
    <>
      {USER_ROLES.USER === role && renderSlideContent()}
      {(USER_ROLES.SUPER_ADMIN === role ||
        USER_ROLES.COMPANY === role ||
        USER_ROLES.DEPARTMENT === role ||
        USER_ROLES.INSTRUCTOR === role) && renderSlideContent()}
    </>
  );
};

export default CurrentSlideView;
