import { UPDATE_PROFILE_ACTION } from "../actions/ProfileActions";

const initialState = {
    profileUrl:""
};


export function ProfileReducer(state = initialState, action) {
    if (action.type === UPDATE_PROFILE_ACTION) {
        return {
            ...state,
            profileUrl: action.payload
        };
    }
    return state;
}    