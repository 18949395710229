
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from 'primereact/skeleton';
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useLocation } from 'react-router-dom';

function Roletable() {
    const location = useLocation();
    const isCompanies = location.pathname === '/companies';
    const isdeptuser = location.pathname === '/departments';
    const isinst = location.pathname === '/instructors';
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    const renderHeader = () => {
        return (
            <>
                <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                    <span className="p-input-icon-left">
                        <i className="fa fa-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Keyword Search"
                        />
                    </span>
                </div>


            </>
        );
    };
    const header = renderHeader();

    const items = Array.from({ length: 5 }, (v, i) => i);
    const bodyTemplate = () => {
        return <Skeleton length={10}></Skeleton>
    }

    const circle = () => {
        return (
            <>
                <div className="d-flex">
                    <Skeleton size="2rem" borderRadius="50%" className="mx-1"></Skeleton>
                    <Skeleton size="2rem" borderRadius="50%" className="mr-2"></Skeleton>
                </div>
            </>
        )
    }

    const name = () => {
        return (
            <>
                <div className="d-flex">
                    <Skeleton length={7} className="mr-2"></Skeleton>
                </div>
            </>
        )
    }


    const columns = [
        <Column header="Sr.No" body={bodyTemplate}></Column>,
        <Column header="NAME" body={bodyTemplate}></Column>,
        <Column header="EMAIL" body={bodyTemplate}></Column>,

    ];

    if (isCompanies) {
        columns.push(
            <Column
                key="sulgColumn"
                header="SLUG"
                body={name}
            />
        );
    }

    else if (isdeptuser) {
        columns.push(
            <Column
                key="joinDateColumn"
                header="JOIN DATE"
                //style={{ width: "10%" }}
                body={name}
            />
        );
    }

    else if (isinst) {
        columns.push(
            <Column
                key="joinDateColumn"
                header="JOIN DATE"
                //style={{ width: "10%" }}
                body={name}
            />
        );
    }

    return (
        <div className="col-xl-12">
            <div className="table-responsive">
                <DataTable size="large"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    stripedRows
                    showGridlines
                    tableStyle={{ minWidth: "50rem" }}
                    header={header} value={items} className="p-datatable-striped">
                    {columns}
                    <Column header="STATUS" style={{ width: '5%' }} body={bodyTemplate}></Column>
                    <Column header="ACTIONS" style={{ width: '10%' }} body={circle}></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default Roletable;