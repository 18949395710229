import React, { useEffect, useState, useRef } from "react";
import { TfiTimer } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { API_ROOT_URL } from "../../../constants";
import DesktopTruck from "./images/DesktopBackgrounds_Haulage.jpg";
import "./quiz.css";
//animation
import Lottie from "lottie-react";
import Grades from "../../Animations/animation_llxjql42.json";
import Swal from 'sweetalert2';

const submitTestApi = async (id, token, dataPass) => {
  return await fetch(`${API_ROOT_URL}/mcq/submit/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(dataPass),
  }).then((data) => data.json());
};

const TestQuestions = (props) => {
  const startTestLinkRef = useRef(null); // Step 1: Create a ref
  const navigate = useHistory();
  const token = useSelector((state) => state.auth.auth.token);
  const { id } = useParams();
  const [activeQuestion, setActiveQuestion] = useState(0);
  const timeValue = props?.timer;
  console.log(timeValue,"timeValue timeValue timeValue")
  //quiz timer
  const [totalTime, setTotalTime] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [tabChangeCount, setTabChangeCount] = useState(0);

  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
    remark: "Test Failed !",

  });

  // setting questions
  const questions = props.Questions;
  let choices = [
    questions[activeQuestion].option1,
    questions[activeQuestion].option2,
    questions[activeQuestion].option3,
    questions[activeQuestion].option4,
  ];
  let correctAnswer = questions[activeQuestion].answer;

  // on next question function
  const onClickNext = async () => {
    setSelectedAnswerIndex(null);
    setResult((prev) =>
      selectedAnswer
        ? {
          ...prev,
          score: prev.score + 1,
          correctAnswers: prev.correctAnswers + 1,
        }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    );
    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const onAnswerSelected = (answer, index) => {
    setSelectedAnswerIndex(index);
    if (answer === correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  const handleTabSwitch = (event) => {
    // Assuming tabChangeCount is defined elsewhere in your code
    if (document.visibilityState === "visible") {
      // If the document is visible, increment the tabChangeCount
      setTabChangeCount(tabChangeCount + 1);
    } else {
      if (tabChangeCount > 2) {
        // Assuming swal is a function that displays a modal dialog
        swal({
          title: "Test Failed.",
          text: "You have switched tabs more than 3 times in the test.",
          icon: "warning",
          buttons: {
            okay: "Okay",
          },
          closeOnClickOutside: false,
          dangerMode: true,
        }).then(() => {
          // Assuming navigate is a router object (e.g., React Router)
          swal("Test Failed. Redirecting you to inductions", {
            icon: "warning",
          }).then(() => {
            // Assuming you want to reset tabChangeCount and submit test data
            setTabChangeCount(0);
            const data = {
              score: 0,
              correctAnswers: 0,
              wrongAnswers: 0,
              remark: "Test Failed! Due to tab switch",
            };

            // Assuming submitTestApi is a function to submit test data
            submitTestApi(id, token, data);
            // Assuming navigate.push is used to redirect the user
            navigate.push("/user-inductions");
          });
        });
      } else {
        // If tabChangeCount is not greater than 2, increment it
        setTabChangeCount(tabChangeCount + 1);
      }
    }
  };

  const timerQues = () => {
    setTotalTime(timeValue);
  };

  console.log(totalTime,"totaltime.....")

  const [navigateToInductions, setNavigateToInductions] = useState(false);

  useEffect(() => {
    timerQues();
    const timers = setInterval(() => {
      setTotalTime((prevTime) => prevTime - 1);
    }, 1000);

    document.addEventListener("visibilitychange", handleTabSwitch);

    if (showResult) {
      var data = { ...result, remark: "Test completed!" };
    }

    return () => {
      clearInterval(timers);
      document.removeEventListener("visibilitychange", handleTabSwitch);
    };
  }, [showResult, tabChangeCount, timeValue]);

  useEffect(() => {
    if (totalTime === 0) {
      setShowResult(true);
    }
  }, [totalTime]);

  useEffect(() => {
    if (navigateToInductions) {
      // Navigate to the "inductions" page
      setNavigateToInductions(false); // Reset the flag
      var data = {
        score: 0,
        correctAnswers: 0,
        wrongAnswers: 0,
        remark: "Test Failed!",
      };
      submitTestApi(id, token, data);
      navigate.push("/user-inductions");
    }
  }, [navigateToInductions]);

  // async func
  const submitTestApiCall = async (id, token, data) => {
    const response = await submitTestApi(id, token, data);
    if ("status" in response && response.status == true) {
    } else {
      return swal("Failed", response.message, "error");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const unblock = navigate.block((location, action) => {
      // Prevent navigation if the user tries to go back
      if (action === 'POP') {
        return false;
      }
    });
    // Cleanup the block when the component unmounts
    return () => unblock();
  }, [navigate]);

  useEffect(() => {
    const handleRightClick = (e) => {
      e.preventDefault(); // Prevent the default right-click context menu
    };

    // Add the event listener to the document body
    document.body.addEventListener('contextmenu', handleRightClick);
    return () => {
      // Clean up the event listener when the component unmounts
      document.body.removeEventListener('contextmenu', handleRightClick);
    };
  }, []); // Empty dependency array ensures the effect runs only once


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey && e.code === 'KeyR' && e.key === "F9") {
        e.preventDefault();
        // You can add your custom logic here if needed
      }
      e.preventDefault();
      // You can add your custom logic here if needed
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // The empty dependency array ensures that the effect runs once after the initial

  useEffect(() => {
    const handleFullscreenExit = (e) => {
      if (!document.fullscreenElement || e.key === 'Escape') {
        // User is attempting to exit full-screen
        const data = {
          score: 0,
          correctAnswers: 0,
          wrongAnswers: 0,
          remark: 'Test Incomplete!',
        };
        // Add your logic to call the submitTestApi and navigate when exiting fullscreen
        submitTestApiCall(id, token, data);
        navigate.push('/user-inductions');
        // Show a sweet alert message
        Swal.fire({
          icon: 'error',
          title: 'Test Failed!',
          text: 'You exited full-screen mode before completing the test.',
        });
      }
    };
    // Add the event listener to detect exiting fullscreen mode
    document.addEventListener('fullscreenchange', handleFullscreenExit);
    document.addEventListener('webkitfullscreenchange', handleFullscreenExit);
    document.addEventListener('mozfullscreenchange', handleFullscreenExit);
    document.addEventListener('MSFullscreenChange', handleFullscreenExit);
    // Cleanup the event listeners when the component unmounts
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenExit);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenExit);
      document.removeEventListener('mozfullscreenchange', handleFullscreenExit);
      document.removeEventListener('MSFullscreenChange', handleFullscreenExit);
    };
  }, [id, token, result, navigate]);



  useEffect(() => {
    if (showResult) {
      var data = { ...result, remark: "Test completed !" };
      submitTestApiCall(id, token, data);
    }
  }, [showResult, tabChangeCount, timeValue]);

  if (!props.timer) {
    return null;
  }

  const style = {
    height: 200,
  };

  return (
    <div
      className="background-modal"
      style={{
        backgroundImage: "url(" + DesktopTruck + ")",
        backgroundSize: "cover",
      }}
    >
      {props.children}

      {showResult ? (
        <>
          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header">
                  <h2 className="card-title" style={{}}>Here are your Results !</h2>
                </div>
                <Lottie style={style} animationData={Grades} />
                <h4
                  className="m-auto mt-3 badge bg-success"
                  style={{ fontSize: "20px" }}
                >
                  Test Attempted Successfully !
                </h4>
                <div className="card-body d-flex justify-content-center m-2">
                  <h2 style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
                    You Scored{" "}
                    <span
                      style={{
                        color: "#FEBA30",
                        fontSize: "30px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {result.score}
                    </span>{" "}
                    out of{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "30px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {questions.length}
                    </span>{" "}
                    !
                  </h2>
                </div>
                <div className="mb-3 row text-justify">
                  <div
                    className="col-sm-12 text-center d-flex m-auto"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Link className="mt-3" style={{ textDecoration: "underline" }} to="/inductions" onClick={exitFullscreen}>
                      <span className="mx-3"> <i className="fa fa-reply" />{" "} Back to Inductions</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="quiz-container">
          <div className="card-header">
            <h2 className="mb-0">Start The Quiz!</h2>
          </div>
          <div className="row">
            <div className="col-xl-8">
              <span className="active-question-no mb-0">
                {addLeadingZero(activeQuestion + 1)}
              </span>
              <span className="total-question mb-0" style={{ color: "darkgray" }}>
                /{addLeadingZero(questions.length)}
              </span>
            </div>
            <div className="d-flex col-xl-4 justify-content-end">
              <h4>
                <TfiTimer size={16} />{' '}
                <span style={{ color: "red" }}>{formatTime(totalTime)} </span>
                seconds
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="basic-form mt-0">
              <h3>{questions[activeQuestion].question}</h3>
              <ul>
                {choices.map((answer, index) => (
                  <li
                    onClick={() => onAnswerSelected(answer, index)}
                    key={answer}
                    onAnswerSelected
                    className={
                      selectedAnswerIndex === index ? "selected-answer" : null
                    }
                  >
                    {answer}
                  </li>
                ))}
              </ul>
              <div className="mb-3 row">
                <div className="col-sm-12 mt-3">
                  <button
                    type="submit"
                    onClick={() => {
                      onClickNext();
                      // Open a new tab and request full screen mode
                      const newTab = window.open(startTestLinkRef.current.href, '_blank');
                      if (newTab) {
                        newTab.document.documentElement.requestFullscreen();
                      }
                    }}
                    className="btn btn-success"
                    style={{
                      borderRadius: "66px",
                      padding: "10px 35px 10px 35px",
                      margin: "auto",
                      float: "right",
                    }}
                  >
                    {activeQuestion === questions.length - 1 ? "Finish" : "Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TestQuestions;
